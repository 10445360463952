import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import notificationIcon from "../../assets/images/icons/notification.svg";
import { useAppSelect } from "../../hooks/redux";

export const Notifications = () => {
  const navigate = useNavigate();
  const { user } = useAppSelect((state) => state.auth);
  const audioRef = useRef<any>();

  const handleGoToNotificationPage = () => navigate("/notifications");

  useEffect(() => {
    if (user?.alert_status === 1) {
      audioRef.current.play();
    }
  }, [user]);

  return (
    <StyledNotifications
      onClick={handleGoToNotificationPage}
      onTouchStart={handleGoToNotificationPage}
    >
      {user && user?.notifications > 0 ? (
        <div className={user?.alert_status === 1 ? "alert-animation" : ""}>
          {user?.notifications > 100 ? "99+" : user?.notifications}
        </div>
      ) : null}
      <img src={notificationIcon} alt="" />
      <audio
        src={require("../../assets/audio/notification.mp3")}
        ref={audioRef}
      ></audio>
    </StyledNotifications>
  );
};

const StyledNotifications = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  background: #b1b1b1;
  border-radius: 10px;
  flex-shrink: 0;
  div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    top: -5px;
    right: -4px;
    min-height: 18px;
    min-width: 18px;
    padding: 5px;
    border-radius: 10px;
    color: #fff;
    line-height: 0;
    background: #c41315;
  }
`;
