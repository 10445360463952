import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { ColumnFilter } from "../../components/ColumnFilter/ColumnFilter";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import {
  useLazyCargosChangeSendCustomsStatusQuery,
  useLazyDeleteCargosQuery,
} from "../../store/cargo/cargo.api";
import {
  Confirm,
  ConfirmInterface,
  CONFIRM_INIT,
} from "../../components/Confirm/Confirm";
import { useState } from "react";
import { Search } from "./Search";

interface Props {
  selectedCargos: number[];
  onClearSelectedCargos: () => void;
  activeStatus: string | number | undefined;
  selectedClient: string | undefined;
  onSelectClient: (clientId: string) => void;
}

export const Header = ({
  selectedCargos,
  onClearSelectedCargos,
  activeStatus,
  selectedClient,
  onSelectClient,
}: Props) => {
  const { t } = useTranslation("common");
  const [deleteCargos] = useLazyDeleteCargosQuery();
  const [cargosChangeSendCustomsStatus] =
    useLazyCargosChangeSendCustomsStatusQuery();
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);

  const handleDeleteCargos = () => {
    deleteCargos({ cargos: selectedCargos }).then(() =>
      onClearSelectedCargos()
    );
  };

  const handleConfirmDeleteCargos = () => {
    setComfirmData({
      open: true,
      title:
        selectedCargos.length === 1
          ? t("cargo.confirmDelete")
          : t("cargo.confirmDeleteCargos"),
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => handleDeleteCargos(),
    });
  };

  const handleChangeCargosSendCustomsStatus = (status: string) => {
    cargosChangeSendCustomsStatus({ cargos: selectedCargos, status }).then(() =>
      onClearSelectedCargos()
    );
  };

  return (
    <StyledHeader>
      <Confirm {...confirmData} />
      <PageHeader title={t("Клиентские грузы")} classes="page-header" />
      <div className="buttons">
        <Search
          selectedClient={selectedClient}
          onSelectClient={onSelectClient}
        />
        {selectedClient && (
          <div className="actions-btns">
            <Button
              title={"Можно отправлять на таможню"}
              onClick={() => handleChangeCargosSendCustomsStatus("1")}
              color="dark-green"
              disabled={selectedCargos.length === 0}
            />
            <Button
              title={"Нельзя отправлять на таможню"}
              onClick={() => handleChangeCargosSendCustomsStatus("0")}
              color="orange"
              disabled={selectedCargos.length === 0}
            />
            <Button
              title={t("common.delete")}
              onClick={handleConfirmDeleteCargos}
              color="red"
              disabled={selectedCargos.length === 0}
            />
            <ColumnFilter classes="column-filter-btn" />
          </div>
        )}
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttons {
    width: 100%;
  }
  .actions-btns {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  @media (max-width: 1600px) {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  @media (max-width: 1330px) {
    .column-filter-btn {
      padding: 0;
    }
  }
  @media (max-width: 1000px) {
    .buttons {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1000px) {
    .page-header {
      display: none;
    }
    .action-btn {
      display: none;
    }
    .actions-btns {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }
`;
