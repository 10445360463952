import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useLazyResetPasswordQuery } from "../../store/auth/auth.api";
import { Input } from "../Input/Input";
import { Button } from "./Button";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

interface Props {
  onChangeType: (
    value: "login" | "forgotPassword" | "newPassword" | "success"
  ) => void;
  userEmail?: string;
}

export const NewPassword = ({ onChangeType, userEmail }: Props) => {
  const { t } = useTranslation("common");
  const [resetPassword, { isLoading }] = useLazyResetPasswordQuery();
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordAgain, setNewPasswordAgain] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleChangeNewPassword = (value: string) => setNewPassword(value);
  const handleChangeNewPasswordAgain = (value: string) =>
    setNewPasswordAgain(value);

  const handleSubmit = () => {
    if (newPassword.length < 8) {
      setError(t("settings.passwordLength"));
    } else if (newPassword === newPasswordAgain) {
      setError(null);
      resetPassword({
        email: userEmail ?? "",
        password: newPassword,
        password_confirmation: newPasswordAgain,
      }).then((response) => {
        if (response.status === "fulfilled") {
          onChangeType("success");
        }
      });
    } else {
      setError(t("setting.passwordNotSimilar"));
    }
  };

  return (
    <StyledNewPassword>
      {error && <ErrorMessage message={error} />}
      <Input
        label={t("auth.newPassword")}
        value={newPassword}
        onChange={handleChangeNewPassword}
      />
      <Input
        label={t("auth.again")}
        value={newPasswordAgain}
        onChange={handleChangeNewPasswordAgain}
        classes="new-password-again-input"
      />
      <Button
        label={t("auth.enter")}
        onClick={handleSubmit}
        disabled={isLoading}
      />
    </StyledNewPassword>
  );
};

const StyledNewPassword = styled.div`
  .new-password-again-input {
    margin: 14px 0 44px;
  }
  @media (max-width: 420px) {
    .new-password-again-input {
      margin: 20px 0 60px;
    }
  }
`;
