import styled from "styled-components";
import { Button } from "../Button/Button";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const SupportModal = ({ open, onClose }: Props) => (
  <StyledSupportModal open={open}>
    <div className="modal">
      <div className="title">Служба поддержки</div>
      <div className="content">
        <div className="number">
          <a href="tel:+77023337777">+7 702 333 77 77</a>
          <Button
            title="Спасибо за обращение!"
            onClick={onClose}
            classes="submit-btn"
            color="blue"
          />
        </div>
      </div>
    </div>
  </StyledSupportModal>
);

interface StyledSupportModalProps {
  open: boolean;
}

const StyledSupportModal = styled.div<StyledSupportModalProps>`
  transition: all 0.3s ease;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(87, 87, 86, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  .modal {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    max-width: 90%;
    width: 500px;
    .title {
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
      color: #575756;
      padding: 20px 20px 43px;
    }
    .content {
      padding: 0 30px 30px;
      text-align: center;
      a {
        display: block;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        color: #575756;
        margin-bottom: 25px;
      }
      .submit-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        width: 100%;
        height: 60px;
      }
    }
  }
`;
