import styled from "styled-components";
import { UserInfo } from "./UserInfo";
import { Actions } from "./Actions";

interface Props {
  userName: string;
  userAvatar: string | undefined;
  createdAt: string;
  onDelete: () => void;
  onToggleEdit: () => void;
  isOwner: boolean;
  isEdit: boolean;
}

export const Header = ({
  userName,
  userAvatar,
  createdAt,
  onDelete,
  onToggleEdit,
  isOwner,
  isEdit,
}: Props) => {
  return (
    <StyledHeader isEdit={isEdit}>
      <UserInfo
        userName={userName}
        userAvatar={userAvatar}
        createdAt={createdAt}
      />
      {isOwner && (
        <Actions
          onDelete={onDelete}
          onToggleEdit={onToggleEdit}
          isEdit={isEdit}
        />
      )}
    </StyledHeader>
  );
};

interface StyledHeaderProps {
  isEdit: boolean;
}

const StyledHeader = styled.div<StyledHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 36px 5px 5px;
  border-radius: 20px;
  background: ${(props) => (props.isEdit ? "#94E18C" : "#f5f5f5")};
`;
