import styled from "styled-components";
import { Button } from "../Button/Button";
import { useEffect, useState } from "react";
import { Dropdown } from "./Dropdown";

interface Props {
  disabled: boolean;
  onAction: (type: string) => void;
  title: string;
  options: { title: string; value: string; isClose?: boolean }[];
  onClose?: () => void;
}

export const DropdownButton = ({
  disabled,
  onAction,
  title,
  options,
  onClose,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleAction = (type: string, isClose?: boolean) => {
    onAction(type);
    isClose && handleClose();
  };

  useEffect(() => {
    disabled && setOpen(false);
  }, [disabled]);

  return (
    <StyledDropdownButton>
      <Dropdown open={open} onAction={handleAction} options={options} />
      <Button
        title={title}
        onClick={() => (open ? handleClose() : setOpen(true))}
        color="blue"
        classes="btn"
        disabled={disabled}
      />
    </StyledDropdownButton>
  );
};

const StyledDropdownButton = styled.div`
  position: relative;
  @media (max-width: 820px) {
    width: 100%;
  }
`;
