import { useState, useEffect } from "react";
import { KpiModal } from "./KpiModal/KpiModal";
import { Header } from "./Header";
import { KpiTable } from "./KpiTable/KpiTable";
import { useLazyGetKpisQuery } from "../../store/cargo/cargo.api";

export const Kpi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [kpiModal, setKpiModal] = useState<boolean>(false);
  const [selectedKpi, setSelectedKpi] = useState<any | null>(null);
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });
  const [getKpis, { data, isLoading }] = useLazyGetKpisQuery();

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
    getKpis({ sortBy: title, sortDesc: updatedDesc });
  };

  const handleCloseModal = (isUpdateData?: boolean) => {
    setKpiModal(false);
    setSelectedKpi(null);
    isUpdateData && getKpis({ sortBy: sort.field, sortDesc: sort.desc });
  };

  const handleEditKpi = (data: any) => {
    setKpiModal(true);
    setSelectedKpi(data);
  };

  useEffect(() => {
    getKpis({ sortBy: sort.field, sortDesc: sort.desc });
  }, []);

  return (
    <div>
      <KpiModal
        open={kpiModal}
        onClose={handleCloseModal}
        selectedKpi={selectedKpi}
      />
      <Header onAdd={() => setKpiModal(true)} />
      <KpiTable
        loading={loading || isLoading}
        data={data}
        onEdit={handleEditKpi}
        sort={sort}
        onSort={handleSort}
      />
    </div>
  );
};
