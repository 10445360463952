import styled from "styled-components";
import { Content } from "./Content";
import { Logo } from "./Logo";
import { MobileContent } from "./MobileContent";

interface Props {
  onEditProfile: () => void;
}

export const Header = ({ onEditProfile }: Props) => {
  return (
    <StyledHeader>
      <Logo />
      <MobileContent onEditProfile={onEditProfile} />
      <Content onEditProfile={onEditProfile} />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  grid-row: 1/2;
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  border-radius: 0 0 20px 20px;
  height: max-content;
  @media (min-width: 1600px) {
    border-radius: 0 0 20px 20px;
  }
  @media (min-width: 1000px) {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    height: 100px;
  }
`;
