import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ColumnOption {
  title: string;
  show: boolean;
  fieldName: string;
}

interface CargoState {
  activeCargo: string | null;
  cargoSearch: string;
  cargoColumns: ColumnOption[];
}

export const COLUMN_OPTIONS: ColumnOption[] = [
  { title: "cargo.hsCode", show: true, fieldName: "ns" },
  { title: "cargo.companyName", show: true, fieldName: "company_name" },
  { title: "cargo.category", show: true, fieldName: "category" },
  { title: "cargos.createdAt", show: true, fieldName: "created_at" },
  { title: "cargo.sealNumber", show: true, fieldName: "seal_id" },
  { title: "cargo.timeKz", show: true, fieldName: "start_storage_date" },
  {
    title: "cargos.declatationsNumber",
    show: true,
    fieldName: "declarations_number",
  },
  { title: "cargo.status", show: true, fieldName: "status_id" },
  { title: "cargos.volume", show: true, fieldName: "product_volume" },
  { title: "cargo.weight", show: true, fieldName: "weight" },
];

const initialState: CargoState = {
  activeCargo: null,
  cargoSearch: "",
  cargoColumns: COLUMN_OPTIONS,
};

export const cargoSlice = createSlice({
  name: "cargo",
  initialState,
  reducers: {
    setActiveCargo(state, action: PayloadAction<string>) {
      state.activeCargo = action.payload;
    },
    setCargoSearch(state, action: PayloadAction<string>) {
      state.cargoSearch = action.payload;
    },
    setCargoColumns(state, action: PayloadAction<ColumnOption[]>) {
      state.cargoColumns = action.payload;
    },
  },
});

export const cargoActions = cargoSlice.actions;
export const cargoReducer = cargoSlice.reducer;
