import styled from "styled-components";
import { Avatar } from "../../../Avatar/Avatar";
import { formatDate } from "../../../../helpers/date";

interface Props {
  userName: string;
  userAvatar: string | undefined;
  createdAt: string;
}

export const UserInfo = ({ userName, userAvatar, createdAt }: Props) => (
  <StyledUserInfo>
    <Avatar userAvatar={userAvatar} userName={userName} />
    <div className="info">
      <div className="username">{userName}</div>
      <div className="date">{formatDate(createdAt, true)}</div>
    </div>
  </StyledUserInfo>
);

const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;
  .info {
    margin-left: 13px;
    color: #575756;
  }
  .username {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .date {
    font-size: 9px;
    font-weight: 400;
  }
`;
