import styled from "styled-components";
import { Switch } from "../../../components/Switch";

interface Props {
  viewType: string;
  onChangeViewType: (type: string) => void;
}

export const SwitchView = ({ viewType, onChangeViewType }: Props) => (
  <StyledSwitchView>
    <Switch
      value={viewType}
      onChange={onChangeViewType}
      options={[
        {
          icon: (
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.8418 0.5H1.15684C0.521708 0.5 0.00683594 1.01487 0.00683594 1.65C0.00683594 2.28513 0.521708 2.8 1.15684 2.8H21.8418C22.477 2.8 22.9918 2.28513 22.9918 1.65C22.9918 1.01487 22.477 0.5 21.8418 0.5Z"
                fill="#B1B1B1"
              />
              <path
                d="M21.8418 7.40002H1.15684C0.521708 7.40002 0.00683594 7.9149 0.00683594 8.55002C0.00683594 9.18515 0.521708 9.70002 1.15684 9.70002H21.8418C22.477 9.70002 22.9918 9.18515 22.9918 8.55002C22.9918 7.9149 22.477 7.40002 21.8418 7.40002Z"
                fill="#B1B1B1"
              />
              <path
                d="M21.8418 14.3H1.15684C0.521708 14.3 0.00683594 14.8149 0.00683594 15.45C0.00683594 16.0852 0.521708 16.6 1.15684 16.6H21.8418C22.477 16.6 22.9918 16.0852 22.9918 15.45C22.9918 14.8149 22.477 14.3 21.8418 14.3Z"
                fill="#B1B1B1"
              />
              <path
                d="M21.8418 21.2H1.15684C0.521708 21.2 0.00683594 21.7148 0.00683594 22.35C0.00683594 22.9851 0.521708 23.5 1.15684 23.5H21.8418C22.477 23.5 22.9918 22.9851 22.9918 22.35C22.9918 21.7148 22.477 21.2 21.8418 21.2Z"
                fill="#B1B1B1"
              />
            </svg>
          ),
          value: "table",
        },
        {
          icon: (
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6499 1.65002V22.35C12.6499 22.655 12.7711 22.9475 12.9868 23.1632C13.2025 23.3789 13.4948 23.5 13.7998 23.5H21.8501C22.1551 23.5 22.4474 23.3789 22.6631 23.1632C22.8788 22.9475 23 22.655 23 22.35V1.65002C23 1.34503 22.8788 1.05246 22.6631 0.836792C22.4474 0.621125 22.1551 0.5 21.8501 0.5H13.7998C13.4948 0.5 13.2025 0.621125 12.9868 0.836792C12.7711 1.05246 12.6499 1.34503 12.6499 1.65002ZM20.7002 21.2H14.9502V2.80005H20.7002V21.2Z"
                fill="#B1B1B1"
              />
              <path
                d="M0 1.65002V12C0 12.305 0.121247 12.5976 0.336914 12.8132C0.552581 13.0289 0.844903 13.15 1.1499 13.15H9.2002C9.50519 13.15 9.79752 13.0289 10.0132 12.8132C10.2289 12.5976 10.3501 12.305 10.3501 12V1.65002C10.3501 1.34503 10.2289 1.05246 10.0132 0.836792C9.79752 0.621125 9.50519 0.5 9.2002 0.5H1.1499C0.844903 0.5 0.552581 0.621125 0.336914 0.836792C0.121247 1.05246 0 1.34503 0 1.65002ZM8.0498 10.85H2.2998V2.80005H8.0498V10.85Z"
                fill="#B1B1B1"
              />
            </svg>
          ),
          value: "canban",
        },
      ]}
    />
  </StyledSwitchView>
);

const StyledSwitchView = styled.div`
  margin: 0 20px;
  @media (max-width: 700px) {
    margin: 0;
  }
`;
