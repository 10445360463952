import { useEffect, useState } from "react";
import styled from "styled-components";
import { EmptyTable } from "../../../components/EmptyTable/EmptyTable";
import { Table } from "../../../components/Table/Table";
import { useAppSelect } from "../../../hooks/redux";
import { useGetUsersRolesQuery } from "../../../store/admin/admin.api";
import { useLazyGetNotificationsQuery } from "../../../store/notification/notification.api";
import { NotificationRow } from "./NotificationRow";
import { SelectedItem, TableData } from "../../../models/models";
import { Footer } from "./Footer/Footer";

const COLUMNS = [
  { title: "cargo.hs", field: "id" },
  { title: "Клиент", field: "id" },
  { title: "notifications.message", field: "notification" },
  { title: "notifications.fromWho", field: "user_id" },
  { title: "notifications.time", field: "created_at" },
];

export const NotificationsTable = () => {
  const { data: usersRoles } = useGetUsersRolesQuery(null);
  const { user } = useAppSelect((state) => state.auth);
  const [getNotifications, { data }] = useLazyGetNotificationsQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<SelectedItem[]>([]);
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });
  const [tableData, setTableData] = useState<TableData>({
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  // CHANGE SELECTED
  const handleRemoveSelected = (cargoId: number) => {
    setSelected(selected.filter((c) => c.id !== cargoId));
  };
  const handleSelect = (title: string, cargoId: number) => {
    const isSelected = !!selected.find((c) => c.id === cargoId);
    isSelected
      ? handleRemoveSelected(cargoId)
      : setSelected([...selected, { title, id: cargoId }]);
  };

  const handleSelectAll = (cargos: { title: string; id: number }[]) =>
    setSelected(cargos);

  const handleGetNotification = (tableData: TableData) => {
    setLoading(true);
    getNotifications({
      role_id: user?.role_id,
      page: tableData?.currentPage,
      perPage: tableData?.perPage,
      sortBy: sort?.field?.length === 0 ? undefined : sort?.field,
      sortDesc: sort?.desc,
    }).then((resp: any) => {
      setLoading(false);
      setTableData({
        ...tableData,
        lastPage: resp?.data?.response?.last_page,
        total: resp?.data?.response?.total,
      });
    });
  };

  const handleChangeTableData = (data: TableData) => {
    setTableData(data);
    handleGetNotification(data);
    setSelected([]);
  };

  useEffect(() => {
    setIsAllSelected(
      selected.length === data?.response?.data?.length &&
        data?.response?.data?.length > 0
    );
  }, [selected, data?.response?.data]);

  useEffect(() => {
    if (!data && user) {
      handleGetNotification(tableData);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user) {
      handleGetNotification(tableData);
    }
    // eslint-disable-next-line
  }, [sort]);

  const handleUpdateDate = () => {
    if (!document.hidden) {
      handleGetNotification(tableData);
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", handleUpdateDate);
    return () =>
      window.removeEventListener("visibilitychange", handleUpdateDate);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledNotificationsTable>
      <Table
        loading={loading}
        columns={COLUMNS.map((column: { title: string; field: string }) => ({
          title: column.title,
          active: sort.field === column.field,
          down: sort.field === column.field && sort.desc,
          onSort: () => handleSort(column.field),
        }))}
        pageSize={tableData.perPage}
        onChangePageSize={(perPage: number) =>
          handleChangeTableData({ ...tableData, perPage })
        }
        onSelectAllCargos={() =>
          handleSelectAll(
            isAllSelected
              ? []
              : data?.response?.data.map((notification: any) => ({
                  title: notification?.ns,
                  id: notification.id,
                }))
          )
        }
        isAllSelected={isAllSelected}
        hideDelete
        classes="table-wrapper"
      >
        {data?.response?.data?.length > 0
          ? data?.response?.data.map((notification: any, i: number) => (
              <NotificationRow
                key={i}
                status={notification.status}
                clientName={notification?.client_name ?? "Клиент"}
                ns={notification?.ns}
                notification={notification?.notification}
                userName={notification.user}
                userRole={
                  notification.role_id
                    ? usersRoles?.find(
                        (role: any) =>
                          Number(role.value) === notification.role_id
                      )?.title
                    : ""
                }
                createdAt={notification.created_at}
                isAlert={notification.alert_status === 1}
                isSelected={
                  !!selected.find((n: SelectedItem) => n.id === notification.id)
                }
                onSelect={() =>
                  handleSelect(notification?.ns, notification?.id)
                }
              />
            ))
          : null}
      </Table>
      {data?.response?.data?.length === 0 ? (
        <EmptyTable title="Нет уведомлений" />
      ) : null}
      <div className="footer">
        <div></div>
        <Footer
          selected={selected}
          onClearSelected={() => handleSelectAll([])}
          tableData={tableData}
          onChangeTableData={handleChangeTableData}
          loading={loading}
          onRefreshData={() => handleGetNotification(tableData)}
        />
      </div>
    </StyledNotificationsTable>
  );
};

const StyledNotificationsTable = styled.div`
  .table-wrapper {
    height: calc(100vh - 120px - 30px - 80px - 70px);
    width: 100%;
    overflow: auto;
    border-radius: 20px;
    @media (max-width: 1000px) {
      height: 200px;
      margin-bottom: 100px;
    }
    th:nth-child(1),
    th:nth-child(2) {
      width: 50px;
    }
  }
  .paginator {
    margin-top: 20px;
    margin-left: auto;
    @media (max-width: 1000px) {
      margin: 20px auto 0;
    }
  }
`;
