import styled from "styled-components";
import showIcon from "../../assets/images/icons/show.svg";

interface Props {
  show: boolean;
  onToggleShow: () => void;
}

export const PasswordToggle = ({ show, onToggleShow }: Props) => (
  <StyledPasswordToggle show={show}>
    <img src={showIcon} alt="" onClick={onToggleShow} />
  </StyledPasswordToggle>
);

interface StyledPasswordToggleProps {
  show: boolean;
}

const StyledPasswordToggle = styled.div<StyledPasswordToggleProps>`
  transition: all 0.3s ease;
  margin-left: 10px;
  opacity: ${(props) => (props.show ? 1 : 0.5)};
`;
