import styled from "styled-components";
import downloadIcon from "../assets/images/icons/download_file.svg";
import removeIcon from "../assets/images/icons/delete_file.svg";
import { handleDownload } from "../helpers/files";

interface Props {
  name: string;
  size: number;
  onRemove?: () => void;
  documentLink?: string;
  view?: boolean;
}

export const File = ({ name, size, onRemove, documentLink, view }: Props) => (
  <StyledFile>
    <div className="name">{name}</div>
    <div className="btns">
      <div className="size">{(size / 1024 / 1024).toFixed(2)}MB</div>
      <img
        src={downloadIcon}
        alt="download file"
        className={`download-btn ${documentLink && "active"}`}
        onClick={() => (documentLink ? handleDownload(documentLink) : null)}
      />
      {onRemove && !view && (
        <img src={removeIcon} alt="remove file" onClick={onRemove} />
      )}
    </div>
  </StyledFile>
);

const StyledFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 9px;
  border-bottom: 1px solid #ececec;
  .name {
    color: #575756;
    margin-right: 10px;
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .size {
    color: #b1b1b1;
  }
  img {
    cursor: pointer;
  }
  .download-btn {
    margin: 0 16px;
    opacity: 0.2;
    cursor: not-allowed;
    &.active {
      cursor: pointer;
      opacity: 1;
    }
  }
`;
