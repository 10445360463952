import axios from "axios";
import cogoToast from "cogo-toast";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { baseUrl } from "../../../api/baseUrl";
import { headers } from "../../../api/headers";
import { useAppSelect } from "../../../hooks/redux";
import {
  useLazyCreateCargoQuery,
  useLazyDeleteCargoQuery,
  useLazySaveCargoDocumentQuery,
  useLazySearchCompaniesQuery,
} from "../../../store/cargo/cargo.api";
import { Loading } from "../Loading";
import { MainInfoTest } from "../MainInfoTest/MainInfoTest";
import { Cars } from "../Cars/Cars";
import { Header } from "../Header";
import {
  handleFormatDocuments,
  handleFormatReceiversData,
  handleFormatReceiversDocument,
  handleShowCargoManageErrors,
} from "../../../helpers/cargo";
import {
  CARGO_INIT,
  CARGO_REQUIRED_FIELS,
  RECEIVER_INIT,
} from "../../../constants/cargo";
import { Comments } from "../../../components/Comments/Comments";

export const Content = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state: any) => state.auth);
  const [createCargo] = useLazyCreateCargoQuery();
  const [saveCargoDocuments] = useLazySaveCargoDocumentQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const created = useRef(false);
  const [formData, setFormData] = useState(CARGO_INIT);
  const [documents, setDocuments] = useState<File[]>([]);
  const documentsData = useRef<File[]>([]);
  const [receiversDocuments, setReceiversDocuments] = useState<any>([]);
  const receiversDocumentsData = useRef<any>([]);
  const [deleteCargo] = useLazyDeleteCargoQuery();
  const [getCompanies] = useLazySearchCompaniesQuery();

  const handleAddReceiver = () => {
    setFormData({
      ...formData,
      receivers: [...formData.receivers, RECEIVER_INIT],
    });
    receiversDocumentsData.current[formData.receivers.length] = [];
  };
  const handleRemoveReceiver = (index: number) => {
    setFormData({
      ...formData,
      receivers: formData.receivers.filter((r: any, i: number) => i !== index),
    });
    const updatedReceiversDocuments: any = [];

    receiversDocumentsData.current.forEach((e: any, i: number) => {
      if (i !== index) {
        updatedReceiversDocuments.push(e);
      }
    });

    receiversDocumentsData.current = updatedReceiversDocuments;
    setReceiversDocuments([...updatedReceiversDocuments]);
  };

  const handleAddReceiverDocuments = (data: File[], receiverIndex: number) => {
    let updatedData = receiversDocumentsData.current;
    if (updatedData[receiverIndex]) {
      updatedData[receiverIndex] = [...updatedData[receiverIndex], ...data];
    } else {
      updatedData[receiverIndex] = data;
    }
    setReceiversDocuments([...updatedData]);
  };

  const handleRemoveReceiverDocument = (
    index: number,
    receiverIndex: number
  ) => {
    const updatedData = receiversDocumentsData.current;
    updatedData[receiverIndex] = updatedData[receiverIndex].filter(
      (d: any, i: number) => i !== index
    );
    setReceiversDocuments([...updatedData]);
  };

  const handleAddDocuments = (data: File[]) => {
    const updatedData = documentsData.current;
    updatedData.push(...data);
    setDocuments(documentsData.current);
  };

  const handleRemoveDocument = (index: number) => {
    documentsData.current = documentsData.current.filter((d, i) => i !== index);
    setDocuments(documentsData.current);
  };

  const handleSuccessCreate = (id: number) => {
    setLoading(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    // cogoToast.success(t("cargo.clickOnMessage"), {
    //   hideAfter: 6,
    //   position: "top-right",
    //   onClick: () => navigate(`/cargo/${id}`),
    //   heading: t("cargo.cargoCreated"),
    // });
    // navigate("/cargos");
  };

  const handleCreateCargo = () => {
    // const isNoEmptyFields = handleCheckEmptyFields(formData, t);
    if (true && !loading) {
      setLoading(true);
      setErrors({});
      const sendData: any = handleFormatReceiversData(formData.receivers);

      const data: any = {
        ...formData,
        receivers: formData.receivers.map((r: any, i: number) => ({
          ...r,
          documents: receiversDocuments[i] ?? [],
        })),
        user_id: user?.id,
        knr_storage_date: new Date(formData?.knr_storage_date).toISOString(),
        cargo_id: undefined,
      };

      for (const key in data) {
        if (key !== "receivers") {
          sendData.append(key, data[key]);
        }
      }

      createCargo(sendData).then(async (response: any) => {
        if (response.status === "fulfilled") {
          const cargo_id = response?.data?.response?.id.toString();
          const receiversId = response?.data?.response.receivers.map(
            (receiver: any) => receiver.id
          );
          let filesToSend: number = documents.length;
          setFormData({ ...formData, cargo_id });
          created.current = true;
          // CALCULATE HOW MANY FILES HAVE TO SEND
          receiversDocuments.forEach((reveiverDocuments: File[]) => {
            filesToSend += reveiverDocuments.length;
          });

          if (filesToSend === 0) {
            return handleSuccessCreate(cargo_id);
          }
          // SENDING DOCUMENTS
          if (documents.length > 0) {
            const documentsFormData = handleFormatDocuments(
              documents,
              cargo_id
            );
            saveCargoDocuments(documentsFormData).then((resp) => {
              if (resp.status === "fulfilled") {
                filesToSend -= documents.length;
                if (filesToSend <= 0) {
                  handleSuccessCreate(cargo_id);
                }
              }
            });
          }
          // SENDING RECEIVERS DOCUMENTS

          await Promise.all(
            receiversId.map(async (receiverId: any, i: number) => {
              if (receiversDocuments[i]?.length > 0) {
                const receveirDocumentsData = handleFormatReceiversDocument(
                  receiverId,
                  cargo_id,
                  receiversDocuments[i]
                );

                return axios.post(
                  `${baseUrl}cargo/save-cargos-receivers-document`,
                  receveirDocumentsData,
                  { headers: headers() }
                );
              }
              return null;
            })
          );

          handleSuccessCreate(cargo_id);
        } else if (response.status === "rejected") {
          handleShowCargoManageErrors(
            response.error.data.errors,
            CARGO_REQUIRED_FIELS,
            t
          );
          setErrors(response.error.data.errors);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      if (formData.cargo_id && !created.current) {
        deleteCargo({ cargo_id: formData.cargo_id });
      }
    };
    // eslint-disable-next-line
  }, [formData.cargo_id]);

  useEffect(() => {
    if (user && user?.role_id === 13) {
      getCompanies({ q: user.name ?? "" }).then((resp: any) => {
        if (resp?.data[0]) {
          setFormData({
            ...formData,
            company_id: resp?.data[0]?.id,
            company_name: resp?.data[0]?.value,
            ns: resp?.data[0]?.cod,
            ns_cod: resp?.data[0]?.ns_cod,
            ns_number: resp?.data[0]?.ns_number,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const handleChangeTab = (tabIndex: number) => setActiveTab(tabIndex);

  useEffect(() => {
    if (user?.role_id === 12) {
      setActiveTab(1);
    }
  }, [user]);

  return (
    <>
      <Header
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        onSubmit={handleCreateCargo}
        type="create"
      />
      <StyledContent>
        <Loading loading={loading} />
        {activeTab === 0 && (
          <MainInfoTest
            formData={formData}
            onUpdateFormData={(data: any) => setFormData(data)}
            documents={documents}
            onAddDocuments={handleAddDocuments}
            onRemoveDocument={handleRemoveDocument}
            errors={errors}
          />
        )}
        {activeTab === 1 && (
          <Cars
            formData={formData}
            onUpdateFormData={(data: any) => setFormData(data)}
            onAddReceiver={handleAddReceiver}
            onRemoveReceiver={handleRemoveReceiver}
            onAddDocument={handleAddReceiverDocuments}
            documents={receiversDocuments}
            onRemoveDocument={handleRemoveReceiverDocument}
          />
        )}
        {activeTab === 2 && (
          <Comments
            cargoId={formData.cargo_id}
            onChangeCargoId={(id: any) =>
              setFormData({ ...formData, cargo_id: id })
            }
          />
        )}
      </StyledContent>
    </>
  );
};

const StyledContent = styled.div``;
