import { useState, useEffect } from "react";
import styled from "styled-components";
import { Info } from "./Info";
import { Status } from "./Status";
import { Oplata } from "./Oplata/Oplata";
import { Footer } from "./Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { headers } from "../../../api/headers";
import { baseUrl } from "../../../api/baseUrl";
import { useParams } from "react-router";
import { handleGetOplataData } from "../../../helpers/cargo";
import cogoToast from "cogo-toast";

interface Props {
  cargoData: any;
  onRefreshCargoData: () => void;
}

export const About = ({ cargoData, onRefreshCargoData }: Props) => {
  const { t } = useTranslation("common");
  const { cargoId } = useParams();
  const [documents, setDocuments] = useState<any>([]);
  const [oplataData, setOplataData] = useState<any>([]);

  useEffect(() => {
    if (cargoData) {
      setOplataData(handleGetOplataData(cargoData, t));
    }
  }, [cargoData]);

  const handleChangeField = (
    index: number,
    fieldName: string,
    value: number
  ) => {
    setOplataData(
      oplataData.map((e: any, i: number) =>
        i === index ? { ...e, [fieldName]: value } : e
      )
    );
  };

  useEffect(() => {
    if (cargoData) {
      let receiversFiles: any = [];
      const cargoDocuments = cargoData.documents ?? [];
      cargoData.receivers.forEach((receiver: any) =>
        receiversFiles.push(...receiver.documents)
      );
      setDocuments([...receiversFiles, ...cargoDocuments]);
    }
  }, [cargoData]);

  const handleSavePrice = () => {
    const FIELDS_DISCOUNT_FIELDS: string[] = [
      "discount_delivery",
      "discount_overload",
      "discount_declaration",
      "discount_additional_arrival",
      "discount_storage",
      "discount_special_equipment",
    ];

    Promise.all(
      oplataData
        .filter((item: any) => item.costName)
        .map((cost: any, i: number) => {
          const data = {
            cargo_id: cargoId,
            row: cost.costName,
            cost: cost.price ?? "0",
            [FIELDS_DISCOUNT_FIELDS[i]]:
              FIELDS_DISCOUNT_FIELDS[i]?.length > 0 ? cost.discount : undefined,
            currency: "USD",
          };
          return axios.post(`${baseUrl}cargo/update-cost`, data, {
            headers: headers(),
          });
        })
    )
      .then((resp: any) => {
        cogoToast.success(t("cargo.cargoPriceChanged"), {
          hideAfter: 3,
          position: "top-right",
        });
      })
      .catch(() =>
        cogoToast.error(t("cargo.cargoPriceChangeError"), {
          hideAfter: 3,
          position: "top-right",
        })
      );
  };

  const handleSaveOplata = () => {
    Promise.all(
      oplataData
        .filter((item: any) => item.paidName)
        .map(({ paidName, paidValue }: any) => {
          return axios.post(`${baseUrl}cargo/update-tariff`, null, {
            headers: headers(),
            params: {
              cargo_id: cargoId,
              row: paidName,
              cost: paidValue ?? "0",
            },
          });
        })
    ).then((values) => {
      cogoToast.success(t("Оплата успешно сохранена"), {
        hideAfter: 3,
        position: "top-right",
      });
    });
  };

  const handleSave = async () => {
    handleSavePrice();
    handleSaveOplata();
  };

  return (
    <>
      <StyledAbout>
        <Info cargoData={cargoData} />
        <div>
          <Status cargoData={cargoData} />
          {cargoData && (
            <Oplata data={oplataData} onChangeField={handleChangeField} />
          )}
        </div>
      </StyledAbout>
      <Footer
        documents={documents}
        onSave={handleSave}
        cargoData={cargoData}
        onRefreshCargoData={onRefreshCargoData}
      />
    </>
  );
};

const StyledAbout = styled.div`
  display: grid;
  grid-template-columns: minmax(450px, 39%) 1fr;
  gap: 22px;
  @media (max-width: 1400px) {
    grid-template-columns: 35% 1fr;
  }
  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;
