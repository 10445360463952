import styled from "styled-components";
import { Button } from "..//Button/Button";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onAction: (type: string, isClose?: boolean) => void;
  options: { title: string; value: string; isClose?: boolean }[];
}

export const Dropdown = ({ open, onAction, options }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledDropdown open={open}>
      {options?.length > 0
        ? options.map((opt, i) => (
            <Button
              key={i}
              title={t(opt.title)}
              onClick={() => onAction(opt.value, opt?.isClose)}
              color="blue"
              classes="dropdown-btn"
            />
          ))
        : null}
    </StyledDropdown>
  );
};

interface StyledDropdownProps {
  open: boolean;
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  position: absolute;
  bottom: 100%;
  padding: 2px;
  background: #ececec;
  border-radius: 10px;
  display: grid;
  gap: 4px;
  width: 187px;
  left: 0;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  .dropdown-btn {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    height: 46px;
  }
`;
