import styled from "styled-components";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Button } from "../../components/Button/Button";
import { BackButton } from "../../components/BackButton/BackButton";

interface Props {
  onAdd: () => void;
}

export const Header = ({ onAdd }: Props) => (
  <StyledHeader>
    <PageHeader title={"KPI трудосилы "} classes="header-title" />
    <div className="btns">
      <BackButton />
      <Button title={"Добавить"} onClick={onAdd} color="blue" classes="btn" />
    </div>
  </StyledHeader>
);

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .header-title {
    margin: 0;
  }
  .btns {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
  }
  .btn {
    max-width: 240px;
    height: 50px;
    background: #4f75f4;
    width: 240px;
    flex-shrink: 0;
    margin-left: 20px;
  }
  @media (max-width: 1000px) {
    .btns {
      width: 100%;
    }
    .btn {
      width: auto;
      max-width: 100%;
    }
  }
`;
