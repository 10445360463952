import styled from "styled-components";
import { Header } from "./Header/Header";
import { Text } from "./Text";
import { Files } from "./Files";

interface Props {
  userName: string;
  userAvatar: string | undefined;
  createdAt: string;
  text: string;
  documents: any[];
  onDelete: () => void;
  onToggleEdit: () => void;
  isOwner: boolean;
  isEdit: boolean;
}

export const Comment = ({
  userName,
  userAvatar,
  createdAt,
  text,
  documents,
  onDelete,
  onToggleEdit,
  isOwner,
  isEdit,
}: Props) => {
  return (
    <StyledComment>
      <Header
        userName={userName}
        userAvatar={userAvatar}
        createdAt={createdAt}
        onDelete={onDelete}
        onToggleEdit={onToggleEdit}
        isOwner={isOwner}
        isEdit={isEdit}
      />
      <div className="content">
        <Text text={text} />
        {documents?.length > 0 && <Files documents={documents} />}
      </div>
    </StyledComment>
  );
};

const StyledComment = styled.div`
  .content {
    padding: 12px 85px 0 60px;
  }
  @media (max-width: 1000px) {
    .content {
      padding: 12px 20px;
    }
  }
`;
