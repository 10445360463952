import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  ns: number;
}

export const TrackNumber = ({ ns }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledTrackNumber>
      {t("cargo.hs")}
      {ns}
    </StyledTrackNumber>
  );
};
const StyledTrackNumber = styled.div`
  margin: 10px 0 35px;
  font-family: "Brygada 1918";
  font-weight: 700;
  font-size: 34px;
  line-height: 130%;
  color: #00b670;
  text-align: center;
`;
