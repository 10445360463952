import styled from "styled-components";
import { Input } from "../../../../components/Input/Input";

interface Props {
  name: string;
  carNumber: string;
  percentageRatio: number;
  unitsDimension: string;
  onChangeField: (field: string, value: string | number) => void;
}

export const ReceiverRow = ({
  name,
  carNumber,
  percentageRatio,
  unitsDimension,
  onChangeField,
}: Props) => (
  <>
    <StyledReceiverRow>{name}</StyledReceiverRow>
    <StyledReceiverRow>{carNumber}</StyledReceiverRow>
    <StyledReceiverRow>
      <Input
        value={percentageRatio?.toString()}
        onChange={(value: string) => onChangeField("percentage_ratio", value)}
        placeholder="Введите значение"
        number
        max={1}
      />
    </StyledReceiverRow>
    <StyledReceiverRow>
      <Input
        value={unitsDimension}
        onChange={(value: string) => onChangeField("units_dimension", value)}
        placeholder="Введите значение"
        number
      />
    </StyledReceiverRow>
  </>
);

const StyledReceiverRow = styled.div`
  border-bottom: 2px solid rgba(228, 230, 232, 0.85);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input-wrapper {
    border: none !important;
    background: #e8eaeb52;
    border-radius: 10px;
    margin: 0 0 5px 10px;
    padding: 0 5px;
  }
`;
