import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  noFooter?: boolean;
}

export const Content = ({ children, noFooter }: Props) => (
  <StyledContent className="modal-content" noFooter={noFooter}>
    {children}
  </StyledContent>
);

interface StyledContentProps {
  noFooter?: boolean;
}

const StyledContent = styled.div<StyledContentProps>`
  padding: ${(props) => (props.noFooter ? "30px" : 0)} 30px;
  max-height: 66vh;
  overflow: auto;
`;
