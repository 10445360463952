import styled from "styled-components";
import { Card } from "./Card/Card";
import cogoToast from "cogo-toast";
import { useAppSelect } from "../../../../hooks/redux";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
  onUpdateFormData: (data: any) => void;
}

export const AdditionalInfo = ({ formData, onUpdateFormData }: Props) => {
  const { user } = useAppSelect((state) => state.auth);
  const { t } = useTranslation("common");

  const handleShowInfo = (message: string) => {
    cogoToast.error(message, {
      hideAfter: 3,
      position: "top-right",
    });
  };

  return (
    <StyledAdditionalInfo className="manage-cargo-info-card">
      <Card
        title={t("cargo.sealNumberCnTitle")}
        placeholder={t("cargo.sealNumberCn")}
        value={formData.seal_id}
        onChange={(value: string[]) =>
          user?.role_id !== 3 && user?.role_id !== 1
            ? handleShowInfo("Это поле может изменять роль Брокер CN")
            : onUpdateFormData({ ...formData, seal_id: value })
        }
      />
      <Card
        title="Накладная CMR"
        placeholder="Накладная CMR"
        value={formData.crm}
        onChange={(value: string[]) =>
          user?.role_id !== 3 && user?.role_id !== 11 && user?.role_id !== 1
            ? handleShowInfo(
                "Это поле может изменять роль Брокер CN и Брокер KZ"
              )
            : onUpdateFormData({ ...formData, crm: value })
        }
      />
      <Card
        title={t("cargo.declaration")}
        placeholder="Декларация"
        value={formData.declaration}
        onChange={(value: string[]) =>
          user?.role_id !== 8 && user?.role_id !== 11 && user?.role_id !== 1
            ? handleShowInfo(
                "Это поле может изменять роль Диспетчер KZ и Брокер KZ "
              )
            : onUpdateFormData({ ...formData, declaration: value })
        }
      />
    </StyledAdditionalInfo>
  );
};

const StyledAdditionalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;
  grid-auto-rows: max-content;
`;
