import QRCode from "react-qr-code";
import { Modal } from "./Modal/Modal";
import { baseSiteUrl } from "../api/baseUrl";
import domtoimage from "dom-to-image";
import styled from "styled-components";

interface Props {
  open: boolean;
  onClose: () => void;
  cargoId?: number | null;
}

export const QrCode = ({ open, onClose, cargoId }: Props) => {
  const handleDownloadQRcode = () => {
    const scale = 8;
    const qrCode: any = document.querySelector(".qr-code");

    domtoimage
      .toPng(qrCode, {
        width: qrCode.clientWidth * scale,
        height: qrCode.clientHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
        },
      })
      .then((dataUrl: string) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.setAttribute("download", `${cargoId}.png`);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <Modal
      title="QR-код"
      open={open}
      onCancel={onClose}
      onSubmit={handleDownloadQRcode}
      submitBtnText="Скачать QR-код"
    >
      <StyledQrCode>
        <div className="qr-code">
          <QRCode value={`${baseSiteUrl}#/info/${cargoId}`} size={180} />
        </div>
      </StyledQrCode>
    </Modal>
  );
};

const StyledQrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;
