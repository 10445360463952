import { useParams } from "react-router";
import styled from "styled-components";
import { useLazyGetCargoActionsQuery } from "../../../store/cargo/cargo.api";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Table } from "../../../components/Table/Table";
import { TableData } from "../../../models/models";
import { EmptyTable } from "../../../components/EmptyTable/EmptyTable";
import { HistoryRow } from "./HistoryRow";

interface Props {
  cargoData: any;
  comments: any;
}
const COLUMNS = [
  { title: "Автор", field: "user" },
  { title: "Дата", field: "date" },
  { title: "Действия", field: "message" },
  { title: "Статус", field: "status" },
];

export const History = ({ cargoData, comments }: Props) => {
  const { cargoId } = useParams();
  const [getCargoActions, { data, isLoading }] = useLazyGetCargoActionsQuery();
  const { t } = useTranslation("common");
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "date",
    desc: true,
  });
  const [tableData, setTableData] = useState<TableData>({
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  useEffect(() => {
    getCargoActions({
      cargo_id: cargoId,
      sortBy: sort.field,
      sortDesc: sort.desc,
    });
    // eslint-disable-next-line
  }, [cargoData, comments, cargoId, sort]);

  return (
    <StyledHistory>
      <Table
        loading={isLoading}
        columns={COLUMNS.map((column: { title: string; field: string }) => ({
          title: column.title,
          active: sort.field === column.field,
          down: sort.field === column.field && sort.desc,
          onSort: () => handleSort(column.field),
        }))}
        pageSize={tableData.perPage}
        hideDelete
        hideManage
        hideCheckbox
        classes="table-wrapper"
      >
        {data?.response?.length > 0
          ? data?.response.map((action: any, i: number) => (
              <HistoryRow
                key={i}
                nickname={action?.user?.name}
                avatar={action?.user?.avatar}
                roleId={action?.user?.role_id}
                date={action?.date}
                statusTitle={action?.message}
                success={action?.status?.id !== 2}
              />
            ))
          : null}
      </Table>
      {data?.response?.length === 0 ? (
        <EmptyTable title="Нет дейстивй" />
      ) : null}
    </StyledHistory>
  );
};

const StyledHistory = styled.div`
  font-family: Inter, sans-serif;
  height: calc(100vh - 131px - 30px - 148px);
  overflow: auto;
  border-radius: 20px;
  @media (max-width: 1000px) {
    height: max-content !important;
    margin-bottom: 100px;
  }
`;
