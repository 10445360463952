import styled from "styled-components";
import { Tabs } from "../../../components/Tabs/Tabs";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { Buttons } from "./Buttons";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { useEffect, useRef, useState } from "react";
import { handleAddZero } from "../../../helpers/date";

interface Props {
  activeTabPeriod: number;
  onChangeActiveTabPeriod: (index: number) => void;
  viewType: string;
  onChangeViewType: (type: string) => void;
}

const TABS = [
  { title: "Все время", badge: "100" },
  { title: "Год", badge: "100" },
  { title: "Месяц", badge: "100" },
  { title: "Неделя", badge: "100" },
];

export const Header = ({
  activeTabPeriod,
  onChangeActiveTabPeriod,
  viewType,
  onChangeViewType,
}: Props) => {
  const [selectingDate, setSelectingDate] = useState<boolean>(false);
  const tabRef = useRef<HTMLDivElement | any>(null);
  const [tabWidth, setTabWidth] = useState(240);
  const [valueFrom, setValueFrom] = useState<Date>(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [valueTo, setValueTo] = useState<Date>(new Date());

  const handleChangeValueFrom = (date: Date) => setValueFrom(date);
  const handleChangeValueTo = (date: Date) => setValueTo(date);

  const handleChangeTab = (index: number) => {
    if (index === 4 && tabRef.current) {
      onChangeActiveTabPeriod(index);
      tabRef.current.scrollLeft += 200;
      setTabWidth(tabRef.current?.firstChild?.offsetWidth ?? 240);
      setSelectingDate(true);
    } else {
      onChangeActiveTabPeriod(index);
      setSelectingDate(false);
    }
  };

  const handleScrollLeft = () => {
    if (activeTabPeriod === 4 && tabRef.current) {
      tabRef.current.scrollLeft += 200;
      setTabWidth(tabRef.current?.firstChild?.offsetWidth ?? 240);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleScrollLeft);
    return () => window.removeEventListener("resize", handleScrollLeft);
  }, []);

  const handleFormatDatePickerDate = (): string => {
    const dateFromMonth = handleAddZero(valueFrom.getMonth() + 1);
    const dateFromYear = valueFrom.getFullYear();
    const dateToMonth = handleAddZero(valueTo.getMonth() + 1);
    const dateToYear = valueTo.getFullYear();

    return selectingDate
      ? `С ${dateFromMonth}/${dateFromYear} по ${dateToMonth}/${dateToYear}`
      : `С мм/гг по мм/гг`;
  };

  return (
    <StyledHeader selectingDate={selectingDate} tabWidth={tabWidth}>
      <div className="content">
        <PageHeader title="Главная / Дашборд компании" />
        <Buttons viewType={viewType} onChangeViewType={onChangeViewType} />
      </div>
      <div className="tabs-wrapper">
        {selectingDate && (
          <DatePicker
            classes="date-picker"
            valueFrom={valueFrom}
            onChangeValueFrom={handleChangeValueFrom}
            valueTo={valueTo}
            onChangeValueTo={handleChangeValueTo}
          />
        )}
        <Tabs
          tabs={[
            ...TABS,
            { title: handleFormatDatePickerDate(), badge: "100" },
          ]}
          activeTab={activeTabPeriod}
          onChangeTab={handleChangeTab}
          classes="tabs"
          innerRef={tabRef}
        />
      </div>
    </StyledHeader>
  );
};

interface StyledHeaderProps {
  selectingDate: boolean;
  tabWidth: number;
}

const StyledHeader = styled.div<StyledHeaderProps>`
  margin-bottom: 22px;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
  }
  .tabs {
    ${(props) => props.selectingDate && "overflow: hidden;"}
  }
  .tabs-wrapper {
    position: relative;
    width: 100%;
    .date-picker {
      position: absolute;
      right: 2px;
      background: #fff;
      top: 41px;
      width: ${(props) => props.tabWidth}px;
      padding-top: 8px;
      padding: 16px;
      z-index: 10;
    }
  }
`;
