import styled from "styled-components";

interface Props {
  title: string;
}

export const Header = ({ title }: Props) => (
  <StyledHeader>{title}</StyledHeader>
);

const StyledHeader = styled.div`
  padding: 20px 20px 40px;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #575756;
`;
