import styled from "styled-components";
import { Option, Options } from "./Options";
import { Input } from "../Input/Input";

interface Props {
  options: Option[];
  onSelectOption: (opt: Option) => void;
  open: boolean;
  value?: string;
  placeholder: string;
  searchValue: string;
  onSearch: (value: string) => void;
  line?: boolean;
}

export const Dropdown = ({
  options,
  onSelectOption,
  open,
  value,
  placeholder,
  searchValue,
  onSearch,
  line,
}: Props) => {
  return (
    <>
      <StyledDropdown
        className="dropdown"
        isEmpty={options.length === 0}
        open={open}
      >
        {!line && (
          <Input
            placeholder={placeholder}
            value={searchValue}
            onChange={(value) => onSearch(value)}
            classes="search-input"
          />
        )}
        {line && options.length === 0 ? null : (
          <Options
            options={options}
            onSelectOption={onSelectOption}
            value={value}
          />
        )}
      </StyledDropdown>
    </>
  );
};

interface StyledDropdownProps {
  isEmpty: boolean;
  open: boolean;
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-top: none;
  border-radius: 0 0 10px 10px;
  z-index: 10000;
  &.active {
    display: flex;
  }
  ${(props) => props.open && "display: flex;"}
  .search-input {
    margin: 0 10px;
    max-width: 100%;
    img {
      height: 12px;
    }
    input {
      font-size: 12px !important;
      line-height: 14px;
    }
  }
`;
