import { DetailsForm } from "./DetailsForm";

interface Props {
  formData: any;
  onUpdateFormData: (data: any) => void;
  errors: any;
}

export const Details = ({ formData, onUpdateFormData, errors }: Props) => {
  return (
    <div>
      <div className="manage-cargo-info-card">
        <DetailsForm
          formData={formData}
          onUpdateFormData={onUpdateFormData}
          errors={errors}
        />
      </div>
    </div>
  );
};
