import styled from "styled-components";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useAppSelect } from "../../hooks/redux";
import { BackButton } from "../../components/BackButton/BackButton";

export const Header = () => {
  const { user } = useAppSelect((state) => state.auth);
  const { t } = useTranslation("common");

  return (
    <StyledHeader>
      <PageHeader
        title={`${t("pages.notifications")} ${
          user && user?.notifications > 0 ? `(${user?.notifications})` : ""
        }`}
      />
      <BackButton />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
