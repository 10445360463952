import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useAppSelect } from "../../hooks/redux";
import { CargoTrackNumber } from "./CargoTrackNumber";
import { Notifications } from "./Notifications";
import { Profile } from "./Profile/Profile";
import { Search } from "./Search";
import { Title } from "./Title";
import { Language } from "./Language";

interface Props {
  onEditProfile: () => void;
}

export const MobileContent = ({ onEditProfile }: Props) => {
  const { i18n, t } = useTranslation("common");
  const location = useLocation();
  const { activeCargo } = useAppSelect((state) => state.cargo);
  const { teamInfo, activeTeam } = useAppSelect((state) => state.teams);

  const handleCheckPage = () => {
    switch (location.pathname.split("/")[1]) {
      case "cargos":
        return "pages.cargos";
      case "cargo":
        return `${t("cargo.specification")} ${activeCargo ?? ""}`;
      case "settings":
        return "pages.profile";
      case "notifications":
        return "pages.notifications";
      case "companies":
        return "pages.companies";
      case "teams":
        return i18n.language === "ru"
          ? `${t("pages.teams")} (${teamInfo.free} из ${
              teamInfo.total
            } группы свободны)`
          : `${t("pages.teams")} (${teamInfo.free}支球队中有${
              teamInfo.total
            } 支有空)`;
      case "team-history":
        return `${t("teams.history")} ${activeTeam}`;
      case "brokers":
        return "pages.brokers";
      case "cargos-board":
        return "Доска грузов";
      case "cargos-on-checking":
        return "Грузы на проверке";
      default:
        return "pages.dashboard";
    }
  };

  return (
    <StyledMobileContent>
      <div className="header">
        <Title title={t(handleCheckPage())} />
        <div className="buttons">
          <Language />
          <Notifications />
          <Profile onEditProfile={onEditProfile} />
        </div>
      </div>
      {/* {location.pathname === "/cargos" && <Cargos />} */}
      {location.pathname === "/cargo" && <CargoTrackNumber />}
      <Search classes="search" />
    </StyledMobileContent>
  );
};

const StyledMobileContent = styled.div`
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 18px;
  }
  .buttons {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;
