import styled from "styled-components";

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => {
  return <StyledTitle>{title} </StyledTitle>;
};

const StyledTitle = styled.div`
  color: #575756;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
