import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  title?: string;
}

export const EmptyTable = ({ title = "cargos.noCargos" }: Props) => {
  const { t } = useTranslation("common");

  return <StyledEmptyTable>{t(title)}</StyledEmptyTable>;
};

export const StyledEmptyTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #7e7e8f98;
`;
