import axios from "axios";
import cogoToast from "cogo-toast";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { baseUrl } from "../../../api/baseUrl";
import { headers } from "../../../api/headers";
import { useAppSelect } from "../../../hooks/redux";
import {
  useLazyCreateCargoQuery,
  useLazyGetCargoQuery,
  useLazySaveCargoDocumentQuery,
} from "../../../store/cargo/cargo.api";
import { Loading } from "../Loading";
import { Header } from "../Header";
import { MainInfoTest } from "../MainInfoTest/MainInfoTest";
import { Cars } from "../Cars/Cars";
import {
  CARGO_INIT,
  CARGO_REQUIRED_FIELS,
  RECEIVER_INIT,
} from "../../../constants/cargo";
import {
  handleFormatReceiversData,
  handleGetCargoData,
  handleShowCargoManageErrors,
} from "../../../helpers/cargo";
import { Comments } from "../../../components/Comments/Comments";

export const Content = () => {
  const { t } = useTranslation("common");
  const { cargoId } = useParams();
  const [getCargo] = useLazyGetCargoQuery();
  const { user } = useAppSelect((state) => state.auth);
  const [createCargo] = useLazyCreateCargoQuery();
  const [saveCargoDocuments] = useLazySaveCargoDocumentQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [formData, setFormData] = useState(CARGO_INIT);
  const [documents, setDocuments] = useState<any[]>([]);
  const documentsData = useRef<any[]>([]);
  const [deletedDocuments, setDeletedDocuments] = useState<any>([]);
  const [receiversDocuments, setReceiversDocuments] = useState<any>([]);
  const receiversDocumentsData = useRef<any>([]);
  const [deletedReceiversDocuments, setDeleteReceiversdDocuments] =
    useState<any>([]);
  const [deletedCars, setDeletedCars] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChangeTab = (tabIndex: number) => setActiveTab(tabIndex);

  useEffect(() => {
    if (user?.role_id === 12) {
      setActiveTab(1);
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    getCargo(cargoId).then((response: any) => {
      setLoading(false);
      const cargoData = response.data.response;
      setFormData(handleGetCargoData(cargoData, cargoId));
      setDocuments([...cargoData?.documents] ?? []);
      documentsData.current = [...cargoData?.documents] ?? [];

      const receiversDocumentsResponseData = cargoData?.receivers?.map(
        (receiver: any) => receiver.documents
      );
      setReceiversDocuments(receiversDocumentsResponseData);
      receiversDocumentsData.current = receiversDocumentsResponseData;
    });
    // eslint-disable-next-line
  }, [cargoId]);

  const handleAddReceiver = () => {
    setFormData({
      ...formData,
      receivers: [...formData.receivers, RECEIVER_INIT],
    });
    receiversDocumentsData.current[formData.receivers.length] = [];
  };
  const handleRemoveReceiver = (index: number, id?: number) => {
    setFormData({
      ...formData,
      receivers: formData.receivers.filter((r: any, i: number) => i !== index),
    });
    const updatedReceiversDocuments: any = [];

    receiversDocumentsData.current.forEach((e: any, i: number) => {
      if (i !== index) {
        updatedReceiversDocuments.push(e);
      }
    });

    receiversDocumentsData.current = updatedReceiversDocuments;
    setReceiversDocuments([...updatedReceiversDocuments]);
    id && setDeletedCars([...deletedCars, id]);
  };

  const handleAddReceiverDocuments = (data: File[], receiverIndex: number) => {
    let updatedData = receiversDocumentsData.current;
    if (updatedData[receiverIndex]) {
      updatedData[receiverIndex] = [...updatedData[receiverIndex], ...data];
    } else {
      updatedData[receiverIndex] = data;
    }
    setReceiversDocuments([...updatedData]);
  };

  const handleRemoveReceiverDocument = (
    index: number,
    receiverIndex: number,
    receiverId?: number
  ) => {
    const selectedDocument = receiversDocumentsData.current[receiverIndex].find(
      (d: any, i: number) => i === index
    );
    if (selectedDocument?.document) {
      setDeleteReceiversdDocuments([
        ...deletedReceiversDocuments,
        { document_id: selectedDocument.id, receiver_id: receiverId },
      ]);
    }
    const updatedData = receiversDocumentsData.current;
    updatedData[receiverIndex] = updatedData[receiverIndex].filter(
      (d: any, i: number) => i !== index
    );
    setReceiversDocuments([...updatedData]);
  };

  const handleAddDocuments = (data: any[]) => {
    const updatedData = documentsData.current;
    updatedData.push(...data);
    setDocuments(documentsData.current);
  };

  const handleRemoveDocument = (index: number) => {
    const selectedDocument = documentsData.current.find((d, i) => i === index);
    if (selectedDocument?.document) {
      setDeletedDocuments([...deletedDocuments, selectedDocument.id]);
    }
    documentsData.current = documentsData.current.filter((d, i) => i !== index);
    setDocuments(documentsData.current);
  };

  const handleSuccessCreate = () => {
    cogoToast.success(t("cargo.cargoUpdated"), {
      hideAfter: 3,
      position: "top-right",
    });
  };

  const handleCreateCargo = () => {
    // const isNoEmptyFields = handleCheckEmptyFields(formData, t);
    if (true && !loading) {
      setLoading(true);
      setErrors({});
      const sendData: any = handleFormatReceiversData(formData.receivers);

      const data: any = {
        ...formData,
        receivers: formData.receivers.map((r: any, i: number) => ({
          ...r,
          documents: receiversDocuments[i] ?? [],
        })),
        user_id: user?.id,
        knr_storage_date: new Date(formData?.knr_storage_date).toISOString(),
      };

      for (const key in data) {
        if (key !== "receivers") {
          sendData.append(key, data[key]);
        }
      }

      createCargo(sendData).then(async (response: any) => {
        if (response.status === "fulfilled") {
          const cargo_id = response?.data?.response.id;
          const receiversId = response?.data?.response.receivers.map(
            (receiver: any) => receiver.id
          );
          let filesToSend: number = documents.length;

          // CALCULATE HOW MANY FILES HAVE TO SEND
          receiversDocuments.forEach((reveiverDocuments: File[]) => {
            filesToSend += reveiverDocuments?.filter(
              (d: any) => !d.document
            ).length;
          });

          if (filesToSend === 0) {
            handleSuccessCreate();
            setLoading(false);
          }
          // SENDING DOCUMENTS
          const documentsFormData = new FormData();
          documents
            .filter((d: any) => !d?.document)
            .forEach((d: any, i: number) =>
              documentsFormData.append(`document[${i}]`, d)
            );
          documentsFormData.append("cargo_id", cargo_id);
          saveCargoDocuments(documentsFormData).then((resp) => {
            if (resp.status === "fulfilled") {
              filesToSend -= documents.length;
              if (filesToSend <= 0) {
                handleSuccessCreate();
                setLoading(false);
              }
            }
          });

          // SENDING RECEIVERS DOCUMENTS
          await Promise.all(
            receiversId.map((receiverId: any, i: number) => {
              if (receiversDocuments[i]?.length) {
                const onlyNewDocuments = receiversDocuments[i]?.filter(
                  (d: any) => !d.document
                );
                const receveirDocumentsData = new FormData();
                receveirDocumentsData.append("cargo_id", cargo_id);
                receveirDocumentsData.append("receiver_id", receiverId);
                if (onlyNewDocuments?.length > 0) {
                  onlyNewDocuments.forEach(
                    (document: any, docIndex: number) => {
                      receveirDocumentsData.append(
                        `documents[${docIndex}]`,
                        document
                      );
                    }
                  );

                  return axios.post(
                    `${baseUrl}cargo/save-cargos-receivers-document`,
                    receveirDocumentsData,
                    { headers: headers() }
                  );
                }
              } else {
                return null;
              }
            })
          );

          if (deletedCars?.length > 0) {
            await Promise.all(
              deletedCars.map((id) =>
                axios.delete(`${baseUrl}release/delete-cargo-release`, {
                  headers: headers(),
                  params: { id },
                })
              )
            );
          }

          if (deletedDocuments.length > 0) {
            await Promise.all(
              deletedDocuments.map((document_id: number) => {
                return axios.post(
                  `${baseUrl}cargo/delete-cargo-document`,
                  null,
                  {
                    headers: headers(),
                    params: { document_id },
                  }
                );
              })
            );
          }
          if (deletedReceiversDocuments?.length > 0) {
            await Promise.all(
              deletedReceiversDocuments.map(
                ({ document_id, receiver_id, ...data }: any) => {
                  return axios.post(
                    `${baseUrl}cargo/delete-cargos-receivers-document`,
                    null,
                    { headers: headers(), params: { document_id, receiver_id } }
                  );
                }
              )
            );
          }
          setLoading(false);
        } else if (response.status === "rejected") {
          handleShowCargoManageErrors(
            response.error.data.errors,
            CARGO_REQUIRED_FIELS,
            t
          );
          setErrors(response.error.data.errors);
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      <Header
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        onSubmit={handleCreateCargo}
        type="edit"
      />
      <div>
        <Loading loading={loading} />
        {activeTab === 0 && (
          <MainInfoTest
            formData={formData}
            onUpdateFormData={(data: any) => setFormData(data)}
            documents={documents}
            onAddDocuments={handleAddDocuments}
            onRemoveDocument={handleRemoveDocument}
            errors={errors}
          />
        )}
        {activeTab === 1 && (
          <Cars
            formData={formData}
            onUpdateFormData={(data: any) => setFormData(data)}
            onAddReceiver={handleAddReceiver}
            onRemoveReceiver={handleRemoveReceiver}
            onAddDocument={handleAddReceiverDocuments}
            documents={receiversDocuments}
            onRemoveDocument={handleRemoveReceiverDocument}
          />
        )}
        {activeTab === 2 && (
          <Comments
            cargoId={cargoId}
            onChangeCargoId={(id: any) =>
              setFormData({ ...formData, cargo_id: id })
            }
          />
        )}
      </div>
    </>
  );
};
