import styled from "styled-components";
import { Header } from "./Header";
import { HistoryTable } from "./HistoryTable";

export const TeamHistory = () => {
  return (
    <StyledTeamHistory>
      <Header />
      <HistoryTable />
    </StyledTeamHistory>
  );
};

const StyledTeamHistory = styled.div``;
