import cogoToast from "cogo-toast";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Confirm,
  ConfirmInterface,
  CONFIRM_INIT,
} from "../../components/Confirm/Confirm";
import { Table } from "../../components/Table/Table";
import { useActions } from "../../hooks/actions";
import { useLazyDeleteTeamsQuery } from "../../store/teams/teams.api";
import { SuccessEndOverload } from "./SuccessEndOverload";
import { TeamRow } from "./TeamRow";
import { TableData } from "../../models/models";
import { useAppSelect } from "../../hooks/redux";

const COLUMNS = [
  { title: "teams.blok", field: "block" },
  { title: "teams.team", field: "name" },
  { title: "teams.peopleCount", field: "people_number" },
  { title: "teams.specTech", field: "special_equipment" },
  { title: "teams.total", field: "peregruzok" },
  { title: "cargo.status", field: "status" },
  { title: "teams.totalTime", field: "peregruzok" },
];

interface Props {
  teams: any;
  onRefreshData: () => void;
  selected: { title: string; id: number }[];
  sort: { field: string; desc: boolean };
  onSort: (title: string) => void;
  loading: boolean;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  isAllSelected: boolean;
  onSelectAll: (data: { title: string; id: number }[]) => void;
  onSelect: (title: string, id: number) => void;
  onEdit: (data: any) => void;
}

export const TeamsTable = ({
  teams,
  onRefreshData,
  selected,
  sort,
  onSort,
  loading,
  tableData,
  onChangeTableData,
  isAllSelected,
  onSelectAll,
  onSelect,
  onEdit,
}: Props) => {
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const { setTemsInfo } = useActions();
  const [openEndOveloadModal, setOpenEndOverloadModal] =
    useState<boolean>(false);
  const [endOveloadModalData, setEndOveloadModalData] = useState<{
    act: string;
    cargoId: number | null;
  }>({ act: "", cargoId: null });
  const [deleteTeams] = useLazyDeleteTeamsQuery();
  const { user } = useAppSelect((state: any) => state.auth);
  const isAccess =
    user?.role_id === 1 || user?.role_id === 8 || user?.role_id === 4;

  const handleCloseEndOveloadModal = () => {
    setOpenEndOverloadModal(false);
    setEndOveloadModalData({ act: "", cargoId: null });
  };

  useEffect(() => {
    if (teams) {
      setTemsInfo({
        total: teams?.length,
        free: teams.filter((team: any) => team.status === "free").length,
      });
    }
    // eslint-disable-next-line
  }, [teams]);

  const handleDeleteCompanies = () => {
    deleteTeams(selected.map((s) => s.id)).then((resp: any) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно удалено", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
        setComfirmData(CONFIRM_INIT);
        onSelectAll([]);
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleConfirmDelete = () => {
    if (selected.length > 0) {
      setComfirmData({
        open: true,
        title: "Вы точно хотите удалить группу(ы)?",
        onCancel: () => setComfirmData(CONFIRM_INIT),
        onConfirm: () => handleDeleteCompanies(),
      });
    }
  };

  return (
    <>
      <Confirm
        {...confirmData}
        items={selected}
        onRemoveItem={onSelect}
        closeOnEmptyItems
      />
      <SuccessEndOverload
        open={openEndOveloadModal}
        onClose={handleCloseEndOveloadModal}
        {...endOveloadModalData}
      />
      <StyledTeamsTable>
        <Table
          columns={COLUMNS.map((column: { title: string; field: string }) => ({
            title: column.title,
            active: sort.field === column.field,
            down: sort.field === column.field && sort.desc,
            onSort: () => onSort(column.field),
          }))}
          loading={loading}
          pageSize={tableData.perPage}
          onChangePageSize={(perPage: number) =>
            onChangeTableData({ ...tableData, perPage })
          }
          onSelectAllCargos={() =>
            onSelectAll(
              isAllSelected
                ? []
                : teams.map((team: any) => ({
                    title: team?.name,
                    id: team.id,
                  }))
            )
          }
          isAllSelected={isAllSelected}
          onRemove={handleConfirmDelete}
          hideManage
          hideCheckbox={!isAccess}
          hideDelete={!isAccess}
        >
          {teams &&
            teams.map((team: any, i: number) => (
              <TeamRow
                key={i}
                id={team?.id}
                block={team?.block}
                name={team?.name}
                peopleNumber={Number(team?.people_number)}
                specialEquipment={team?.special_equipment}
                peregruzok={team?.peregruzok}
                status={team?.status}
                dateStart={team?.date_start}
                cargoNs={team?.cargo_ns}
                onRefreshTable={onRefreshData}
                isSelected={
                  !!selected.find(
                    (s: { title: string; id: number }) => s.id === team.id
                  )
                }
                onSelect={() => onSelect(team.name, team.id)}
                onEdit={() => onEdit(team)}
                hideCheckbox={
                  !(
                    user?.role_id === 1 ||
                    user?.role_id === 8 ||
                    user?.role_id === 4
                  )
                }
              />
            ))}
        </Table>
      </StyledTeamsTable>
    </>
  );
};

const StyledTeamsTable = styled.div`
  height: calc(100vh - 120px - 30px - 70px - 80px);
  width: 100%;
  overflow: auto;
  border-radius: 20px;
  @media (max-width: 1200px) {
    height: calc(100vh - 120px - 30px - 70px - 70px - 72px);
  }
  @media (max-width: 1000px) {
    height: 300px;
  }
`;
