import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tabs } from "../../components/Tabs/Tabs";
import { useActions } from "../../hooks/actions";
import { useAppSelect } from "../../hooks/redux";
import { useGetCargoStatusesQuery } from "../../store/cargo/cargo.api";
import { CargosTable } from "./CargosTable";
import { Header } from "./Header";
import { Empty } from "./Empty";
import { statusesTitle } from "../../constants/cargo";

export const ClientsCargos = () => {
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state) => state.auth);
  const { cargoSearch } = useAppSelect((state) => state.cargo);
  const { data: statuses, refetch } = useGetCargoStatusesQuery(null);
  const { setCargoSearch } = useActions();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeStatus, setActiveStatus] = useState<string | number | undefined>(
    undefined
  );
  const [totalCargos, setTotalCargos] = useState<number>(0);
  const [selectedCargos, setSelectedCargos] = useState<number[]>([]);
  const [isUpdateTable, setIsUpdateTable] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const [allCurrentCargosIds, setAllCurrentCargosIds] = useState<number[]>([]);

  const handleSelectClient = (clientId: string) => setSelectedClient(clientId);

  const handleTurnOffUpdateTable = () => setIsUpdateTable(false);

  const handleSetCurrectCargosIds = (cargoIds: number[]) =>
    setAllCurrentCargosIds(cargoIds);

  const handleChangeTab = (tabIndex: number, tabId?: number | undefined) => {
    console.log(tabId);
    const selectedTabStatus = statuses?.response.find(
      (s: any) => s.id === tabId
    )?.kanban_status;
    setActiveTab(tabIndex);
    setActiveStatus(selectedTabStatus ?? 0);
    // refetch && refetch();
  };

  const handleChangeTotalCargos = (value: number) => setTotalCargos(value);

  useEffect(() => {
    statuses && refetch();
    return () => {
      setCargoSearch("");
    };
    // eslint-disable-next-line
  }, []);

  const handleRemoveSelectedCargo = (cargoId: number) =>
    setSelectedCargos(selectedCargos.filter((c) => c !== cargoId));
  const handleSelectCargo = (cargoId: number) =>
    selectedCargos.includes(cargoId)
      ? handleRemoveSelectedCargo(cargoId)
      : setSelectedCargos([...selectedCargos, cargoId]);
  const handleClearSelectedCargos = () => {
    setSelectedCargos([]);
    setIsUpdateTable(true);
    refetch();
  };
  const handleSelectAllStatusCargos = (isClean: boolean) =>
    setSelectedCargos(isClean ? [] : allCurrentCargosIds);

  useEffect(() => {
    setActiveTab(0);
    setActiveStatus(0);
  }, [cargoSearch]);

  return (
    <StyledClientsCargos>
      <Header
        selectedCargos={selectedCargos}
        onClearSelectedCargos={handleClearSelectedCargos}
        activeStatus={activeStatus}
        selectedClient={selectedClient}
        onSelectClient={handleSelectClient}
      />
      <>
        {selectedClient ? (
          <>
            <Tabs
              tabs={
                statuses
                  ? [
                      { title: t("pages.allCargos") },
                      ...statuses?.response?.map((status: any) => ({
                        title: t(`cargo.${statusesTitle[status.id]}`),
                        id: status.id,
                      })),
                    ]
                  : []
              }
              activeTab={activeTab}
              onChangeTab={handleChangeTab}
            />
            <CargosTable
              activeStatus={activeStatus}
              sortBy={"default"}
              onChangeTotalCargos={handleChangeTotalCargos}
              selectedCargos={selectedCargos}
              onSelectCargo={handleSelectCargo}
              isUpdateTable={isUpdateTable}
              turnOffUpdateTable={handleTurnOffUpdateTable}
              selectedClient={selectedClient}
              onSetCurrectCargosIds={handleSetCurrectCargosIds}
              onSelectAllStatusCargos={handleSelectAllStatusCargos}
              allCurrentCargosIds={allCurrentCargosIds}
            />
          </>
        ) : (
          <Empty />
        )}
      </>
    </StyledClientsCargos>
  );
};

const StyledClientsCargos = styled.div`
  padding-right: 16px;
  @media (max-width: 1000px) {
    padding-right: 0;
  }
`;
