import styled from "styled-components";
import prevBtn from "../../assets/images/icons/pagination-btn-prev.svg";
import nextBtn from "../../assets/images/icons/pagination-btn-next.svg";
import { Button } from "./Button";
import { TableData } from "../../models/models";

interface Props {
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  loading: boolean;
  classes?: string;
}

export const Paginator = ({
  tableData,
  onChangeTableData,
  loading,
  classes,
}: Props) => (
  <StyledPaginator className={classes}>
    <Button
      icon={prevBtn}
      onClick={() =>
        onChangeTableData({
          ...tableData,
          currentPage:
            tableData.currentPage === 1 ? 1 : tableData.currentPage - 1,
        })
      }
      disabled={tableData.currentPage === 1 || loading}
    />
    <div className="info">
      {tableData.currentPage * tableData.perPage - tableData.perPage === 0
        ? 1
        : tableData.currentPage * tableData.perPage - tableData.perPage}
      -
      {tableData.currentPage === tableData.lastPage
        ? tableData.total
        : tableData.currentPage * tableData.perPage}
      <span>из</span> {tableData.total}
    </div>
    <Button
      icon={nextBtn}
      onClick={() =>
        onChangeTableData({
          ...tableData,
          currentPage:
            tableData.lastPage === tableData.currentPage
              ? tableData.currentPage
              : tableData.currentPage + 1,
        })
      }
      disabled={tableData.lastPage === tableData.currentPage || loading}
    />
  </StyledPaginator>
);

const StyledPaginator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  background: #ececec;
  border-radius: 10px;
  padding: 2px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #575756;
  height: 50px;
  .info {
    width: max-content;
    span {
      margin: 0 0 0 2px;
    }
  }
`;
