import styled from "styled-components";
import logo from "../../assets/images/icons/logo.png";

export const Logo = () => <StyledLogo src={logo} alt="" />;

const StyledLogo = styled.img`
  width: 246px;
  margin: 0 auto 85px;
  display: block;
`;
