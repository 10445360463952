import cogoToast from "cogo-toast";
import { useAppSelect } from "../hooks/redux";
import { COST_ERROR_TITLES, RECEIVER_INIT, Receiver } from "../constants/cargo";
import { getDashDate } from "./date";

export const handleCheckOveloadTimeColor = (minutes: number, hours: number) => {
  if (minutes < 30 && hours === 0) {
    return "#25c855";
  } else if ((minutes > 30 && hours === 0) || (hours === 1 && minutes < 30)) {
    return "#3535e8";
  } else if ((hours === 1 && minutes > 30) || (hours === 2 && minutes < 30)) {
    return "#ffb328";
  } else {
    return "#ff2b5e";
  }
};

export const handleCheckEmptyFields = (data: any, t: any) => {
  let emptyFields: number = 0;
  let emptyReceiverFields: number = 0;

  Object.entries(data).forEach((field: any) => {
    if (field[0] === "receivers") {
      let receiversWithEmptyFields: number[] = [];
      Object.entries(field[1]).forEach((receiverData: any, i: number) => {
        Object.entries(receiverData[1]).forEach(
          (receiverData: any, i: number) => {
            if (!receiverData[1] && receiverData[1]?.length === 0) {
              emptyReceiverFields += 1;
            }
          }
        );
        emptyReceiverFields > 0 && receiversWithEmptyFields.push(1 + i);
      });

      receiversWithEmptyFields.forEach((receiverIndex: number) =>
        cogoToast.error(
          `${t("cargo.fillAllReceiverFields")} ${receiverIndex} !`,
          { hideAfter: 3, position: "top-right" }
        )
      );
    } else if (
      field[0] !== "cargo_id" &&
      field[0] !== "ns_cod" &&
      field[0] !== "ns_number" &&
      field[0] !== "seal_storage" &&
      field[0] !== "seal_custom_house_kz" &&
      field[0] !== "tir_id" &&
      field[0] !== "crm"
    ) {
      if (!field[1] || field[1]?.length === 0) {
        emptyFields += 1;
      }
    }
  });

  if (emptyFields > 0) {
    cogoToast.error(t("cargo.fillAllFields"), {
      hideAfter: 3,
      position: "top-right",
    });
    return false;
  } else if (emptyReceiverFields > 0) {
    return false;
  } else {
    return true;
  }
};

export const handleGetStorageDaysCount = (startDate: string): string => {
  const now = Date.parse(new Date().toString());
  const started = Date.parse(new Date(startDate).toString());
  const oneDay = 1000 * 60 * 60 * 24;
  const dayLeft = (now - started) / oneDay;
  return Math.floor(dayLeft < 0 ? dayLeft * -1 : dayLeft).toFixed(0);
};

export const handleCheckIsStorage = (
  startDate: string | null,
  endDate: string | null
) => {
  if (endDate && startDate) {
    const endDateParsed = Date.parse(new Date(endDate).toString());
    const startDateParsed = Date.parse(new Date(startDate).toString());

    if (startDateParsed > endDateParsed) {
      return true;
    }
  } else if (startDate && !endDate) {
    return true;
  }

  return false;
};

export const handleGetUserAccessStatus = (
  role_id: number | null | undefined,
  columnId: string
): boolean => {
  if (role_id) {
    switch (role_id) {
      case 1:
        //admin
        return ["request", "storage_kz", "customs", "completed"].includes(
          columnId
        );
      case 11:
        // broker kz
        return ["request", "storage_kz", "customs"].includes(columnId);
      case 8:
        // Диспетчер Казакстан
        return ["request", "storage_kz", "customs"].includes(columnId);
      case 12:
        // Таможня КЗ
        return ["customs", "completed"].includes(columnId);
      default:
        return false;
    }
  } else {
    return false;
  }
};

export const handleCheckCargoTimers = (
  startDate: string | null,
  endDate: string | null
) => {
  if (endDate && startDate) {
    const endDateParsed = Date.parse(new Date(endDate).toString());
    const startDateParsed = Date.parse(new Date(startDate).toString());

    if (startDateParsed > endDateParsed) {
      return true;
    }
  } else if (startDate && !endDate) {
    return true;
  }

  return false;
};

export const handleFormatEndOverloadTime = (
  startOveloadDate: string | null,
  endOveloadDate: string | null
) => {
  if (endOveloadDate && startOveloadDate) {
    const endOverloadDateParsed = Date.parse(
      new Date(endOveloadDate).toString()
    );
    const startOverloadDateParsed = Date.parse(
      new Date(startOveloadDate).toString()
    );

    const timePassed: any = endOverloadDateParsed - startOverloadDateParsed;
    const seconds = Math.floor((timePassed / 1000) % 60);
    const minutes = Math.floor((timePassed / 1000 / 60) % 60);
    const hours = Math.floor((timePassed / (1000 * 60 * 60)) % 24);

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  return "";
};

export const handleCheckTariffEmptyFields = (data: any, t: any) => {
  if (data[0].discount?.toString()?.length === 0) {
    cogoToast.error(t("cargo.fillDicsount"), {
      hideAfter: 3,
      position: "top-right",
    });
  } else {
    const errors: string[] = [];
    data.forEach((cost: any) => {
      let isEmptyField = false;
      Object.entries(cost).forEach((costField: any) => {
        const filedName = costField[0];
        const fieldValue = costField[1];
        if (filedName !== "quantity" && fieldValue?.toString()?.length === 0) {
          isEmptyField = true;
        }
      });

      if (isEmptyField) {
        errors.push(t(COST_ERROR_TITLES[cost.type]));
      }
    });

    if (errors.length === 0) {
      return true;
    } else {
      cogoToast.error(`${t("cargo.fillAllFields")} ${errors.join(", ")}`, {
        hideAfter: 3,
        position: "top-right",
      });
    }
  }
};

export const getCurrencySign = (currency: string) =>
  currency === "USD" ? "$" : currency === "YUAN" ? "¥" : "";

export const handleFormatReceiversData = (receivers: Receiver[]): FormData => {
  const formData: any = new FormData();

  receivers.forEach((receiver: any, i: number) => {
    Object.entries(receiver).forEach((receiverData: any) => {
      if (receiverData[0] === "date_departure") {
        formData.append(
          `receivers[${i}][${receiverData[0]}]`,
          new Date(receiverData[1]).toISOString()
        );
      } else if (receiverData[0] !== "documents") {
        formData.append(`receivers[${i}][${receiverData[0]}]`, receiverData[1]);
      }
    });
  });

  return formData;
};

export const handleFormatReceiversDocument = (
  receiverId: any,
  cargoId: any,
  receiversDocuments: File[]
): FormData => {
  const formData = new FormData();
  formData.append("cargo_id", cargoId);
  formData.append("receiver_id", receiverId);

  receiversDocuments.forEach((document: File, docIndex: number) => {
    formData.append(`documents[${docIndex}]`, document);
  });

  return formData;
};

export const handleFormatDocuments = (
  documents: any,
  cargoId: any
): FormData => {
  const formData = new FormData();
  documents.forEach((d: any, i: number) =>
    formData.append(`document[${i}]`, d)
  );
  formData.append("cargo_id", cargoId);
  return formData;
};

export const handleShowCargoManageErrors = (
  errors: any,
  requiredFields: any,
  t: any
) => {
  cogoToast.error(
    `${Object.entries(errors).map((error) => {
      const errorLabel = requiredFields[error[0]];
      if (errorLabel) {
        return ` ${t(`${errorLabel}`)}`;
      }
      return "";
    })}
        `,
    {
      hideAfter: 5,
      position: "top-right",
      heading: t("common.fillFields"),
    }
  );
};

export const handleGetCargoData = (
  cargoData: any,
  cargoId: string | undefined
) => {
  return {
    company_name: cargoData?.company_name,
    company_id: cargoData?.company_id,
    ns: cargoData?.ns,
    ns_cod: cargoData?.hs_cod,
    ns_number: cargoData?.ns_number,
    seal_id:
      cargoData?.seal_id?.length > 0 ? cargoData?.seal_id.split(",") : [""],
    tir_id: cargoData?.tir_id,
    seal_storage: cargoData?.seal_storage,
    seal_custom_house_kz: cargoData?.seal_custom_house_kz,
    seal_cn: cargoData?.seal_cn,
    loading_form: cargoData?.loading_form,
    shipping_address: cargoData?.shipping_address,
    shipping_country: cargoData?.shipping_country?.toString() ?? "",
    unloading_address: cargoData?.unloading_address,
    unloading_country: cargoData?.unloading_country?.toString() ?? "",
    customs_clearance_address: cargoData?.customs_clearance_address,
    customs_clearance_country:
      cargoData?.customs_clearance_country?.toString() ?? "",
    category: cargoData?.category ?? "",
    knr_storage_date: getDashDate(cargoData?.knr_storage_date ?? ""),
    containers_number: cargoData?.containers_number,
    places_number: cargoData?.places_number,
    product_volume: cargoData?.product_volume,
    weight: cargoData?.weight,
    declarations_number: cargoData?.declarations_number,
    cargo_id: cargoId,
    crm: cargoData?.crm?.length > 0 ? cargoData?.crm.split(",") : [""],
    declaration:
      cargoData?.declaration?.length > 0
        ? cargoData?.declaration.split(",")
        : [""],
    driver: cargoData?.driver,
    insurance: cargoData?.insurance,
    broker_id: cargoData?.broker_id,
    receivers: cargoData?.receivers ?? [RECEIVER_INIT],
    divided_status: cargoData?.divided_status,
  };
};

export const handleGetOplataData = (cargoData: any, t: any) => [
  {
    costName: "сost_delivery",
    price: cargoData?.cost?.сost_delivery?.toString() ?? "0",
    discount: cargoData?.cost?.discount_delivery?.toString() ?? "0",
    type: "delivery",
    title: t("cargoPrice.delivery"),
    paidName: "tariff_delivery",
    paidValue: cargoData?.tariffs?.tariff_delivery?.toString() ?? "0",
    total:
      cargoData?.tariffs?.tariff_additional_arrival_balance +
      cargoData?.tariffs?.tariff_delivery,
  },
  {
    costName: "cost_overload",
    price: cargoData?.cost?.cost_overload?.toString() ?? "0",
    discount: cargoData?.cost?.discount_overload?.toString() ?? "0",
    type: "overload",
    title: t("cargoPrice.overload"),
    paidName: "tariff_overload",
    paidValue: cargoData?.tariffs?.tariff_overload?.toString() ?? "0",
    total:
      cargoData?.tariffs?.tariff_overload_balance +
      cargoData?.tariffs?.tariff_overload,
  },
  {
    costName: "cost_declaration",
    price: cargoData?.cost?.cost_declaration?.toString() ?? "0",
    discount: cargoData?.cost?.discount_declaration?.toString() ?? "0",
    type: "declaration",
    title: t("cargoPrice.declaration"),
    paidName: "tariff_declaration",
    paidValue: cargoData?.tariffs?.tariff_declaration?.toString() ?? "0",
    total:
      cargoData?.tariffs?.tariff_declaration_balance +
      cargoData?.tariffs?.tariff_declaration,
  },
  {
    costName: "cost_additional_arrival",
    price: cargoData?.cost?.cost_additional_arrival?.toString() ?? "0",
    discount: cargoData?.cost?.discount_additional_arrival?.toString() ?? "0",
    type: "additional_arrival",
    title: "Доп.заед",
    paidName: "tariff_additional_arrival",
    paidValue: cargoData?.tariffs?.tariff_additional_arrival?.toString() ?? "0",
    total: cargoData?.arrival_count * cargoData?.cost?.cost_additional_arrival,
  },
  {
    costName: "cost_storage",
    price: cargoData?.cost?.cost_storage?.toString() ?? "0",
    discount: cargoData?.cost?.discount_storage?.toString() ?? "0",
    type: "storage",
    title: t("cargoPrice.storage"),
    paidName: "tariff_storage",
    paidValue: cargoData?.tariffs?.tariff_storage?.toString() ?? "0",
    total:
      cargoData?.tariffs?.tariff_storage_balance +
      cargoData?.tariffs?.tariff_storage,
  },
  {
    costName: "сost_special_equipment",
    price: cargoData?.cost?.сost_special_equipment?.toString() ?? "0",
    discount: cargoData?.cost?.discount_special_equipment?.toString() ?? "0",
    type: "special_equipment",
    title: t("cargoPrice.specTech"),
    paidName: "tariff_special_equipment",
    paidValue: cargoData?.tariffs?.tariff_special_equipment?.toString() ?? "0",
    total:
      cargoData?.tariffs?.tariff_special_equipment_balance +
      cargoData?.tariffs?.tariff_special_equipment,
  },
];
