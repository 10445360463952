import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { NewPassword } from "./NewPassword";
import { SuccesModal } from "../SuccesModal/SuccesModal";

interface Props {
  type: "login" | "forgotPassword" | "newPassword" | "success";
  userEmail?: string;
  onChangeType: (
    value: "login" | "forgotPassword" | "newPassword" | "success",
    email?: string
  ) => void;
}

export const AuthCard = ({ type, onChangeType, userEmail }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledAuthCard>
      <Card
        title={type === "success" ? "" : t(`auth.${type}`)}
        classes={type === "success" ? "success-card" : ""}
      >
        {type === "forgotPassword" ? (
          <ForgotPassword
            onChangeType={onChangeType}
            onBackToLogin={() => onChangeType("login")}
          />
        ) : type === "newPassword" ? (
          <NewPassword onChangeType={onChangeType} userEmail={userEmail} />
        ) : type === "success" ? (
          <SuccesModal
            onClick={() => onChangeType("login")}
            subtitle="Пароль успешно восстановлен"
          />
        ) : (
          <Login onChangeType={onChangeType} />
        )}
      </Card>
    </StyledAuthCard>
  );
};

const StyledAuthCard = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .success-card {
    max-width: 540px;
  }
  .email-input {
    margin-bottom: 14px;
  }
  input {
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
  }
  @media (max-width: 420px) {
    justify-content: flex-end;
    width: 100%;
    .email-input {
      margin-bottom: 20px;
    }
    .success-card {
      margin-bottom: 223px;
      border-radius: 30px;
      button {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px 32px;
        width: max-content;
      }
    }
  }
`;
