import styled from "styled-components";

export interface Option {
  title: string;
  value: string;
}

interface Props {
  options: Option[];
  onSelectOption: (opt: Option) => void;
  value?: string;
}

export const Options = ({ options, onSelectOption, value }: Props) => (
  <StyledOptions>
    {options.length > 0
      ? options.map((opt, i) => (
          <span
            key={i}
            onClick={() => onSelectOption(opt)}
            onTouchStart={() => onSelectOption(opt)}
            className={`${value === opt.value && "selected"}`}
          >
            {opt.title}
          </span>
        ))
      : null}
  </StyledOptions>
);

const StyledOptions = styled.div`
  max-height: 180px;
  overflow: auto;
  padding: 28px 40px 10px 10px;
  span {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    height: 30px;
    padding: 7px 12px;
    border-radius: 10px;
    cursor: pointer;
    &:hover,
    &.selected {
      background: #f5f5f5;
    }
    img {
      margin-right: 10px;
    }
  }
  .empty {
    opacity: 0.5;
  }
`;
