import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const cargo = createApi({
  reducerPath: "cargo/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    getCargoId: build.query({
      query: () => ({
        url: "cargo/get-cargo-id",
        headers: headers(),
      }),
      transformErrorResponse: handleError,
    }),
    createCargo: build.query<any, any>({
      query: (data) => ({
        url: "cargo/cargo-create",
        headers: { ...headers() },
        method: "POST",
        body: data,
      }),
      transformErrorResponse: handleError,
    }),
    saveCargoQrCode: build.query<any, any>({
      query: (data) => ({
        url: "cargo/save-cargo-qr-code",
        headers: { ...headers() },
        method: "POST",
        body: data,
      }),
      transformErrorResponse: handleError,
    }),
    getCountries: build.query({
      query: () => ({
        url: "cargo/get-countries",
        headers: headers(),
      }),
      transformResponse: (response: any) => {
        const formatedRoles: { title: string; value: string }[] =
          response.response.map((country: any) => ({
            title: country.country,
            value: country.id.toString(),
          }));
        return (response = formatedRoles);
      },
      transformErrorResponse: handleError,
    }),
    searchAddress: build.query<any, { q: string; row: string }>({
      query: ({ q, row }) => ({
        url: "cargo/search-address",
        headers: headers(),
        params: { q, row },
      }),
      transformResponse: (response: any) => {
        const formatedRoles: { title: string; value: string }[] =
          response.response.map((address: any) => ({
            title: address.address,
            value: address.address,
          }));
        return (response = formatedRoles);
      },
      transformErrorResponse: handleError,
    }),
    searchCompanies: build.query<any, { q: string }>({
      query: ({ q }) => ({
        url: "cargo/search-clients",
        headers: headers(),
        params: { q },
      }),
      transformResponse: (response: any) => {
        const formatedRoles: { title: string; value: string; cod: string }[] =
          response.response.map((company: any) => ({
            title: company.company,
            id: company.id,
            value: company.company,
            cod: company.cod,
            ns_cod: company?.ns_cod,
            ns_number: company?.ns_number,
          }));
        return (response = formatedRoles);
      },
      transformErrorResponse: handleError,
    }),
    saveCargoDocument: build.query<any, any>({
      query: (data) => ({
        url: "cargo/save-cargo-document",
        headers: { ...headers() },
        method: "POST",
        body: data,
      }),
      transformErrorResponse: handleError,
    }),
    deleteCargoDocument: build.query<any, string>({
      query: (document_id) => ({
        url: "cargo/delete-cargo-document",
        headers: { ...headers() },
        method: "POST",
        params: { document_id },
      }),
      transformErrorResponse: handleError,
    }),
    saveCargoReceiverDocument: build.query<any, any>({
      query: (data) => ({
        url: "cargo/save-cargos-receivers-document",
        headers: { ...headers() },
        method: "POST",
        body: data,
      }),
      transformErrorResponse: handleError,
    }),
    getCargoStatuses: build.query<any, string | undefined | null>({
      query: (client_id) => ({
        url: "cargo/get-statuses",
        headers: headers(),
        params: { client_id },
      }),
      transformErrorResponse: handleError,
    }),
    getClientCargoStatuses: build.query<any, { client_id?: string }>({
      query: ({ client_id }) => ({
        url: "cargo/get-statuses",
        headers: headers(),
        params: { client_id: client_id },
      }),
      transformErrorResponse: handleError,
    }),
    getCargoCategories: build.query<any, any>({
      query: () => ({
        url: "cargo/get-cargo-categories",
        headers: headers(),
      }),
      transformResponse: (response: any) => {
        const formatedRoles: { title: string; value: string }[] =
          response.response.map((category: any) => ({
            title: category.title,
            value: category.id?.toString(),
          }));
        return (response = formatedRoles);
      },
      transformErrorResponse: handleError,
    }),
    getCargos: build.query<
      any,
      {
        page?: number;
        status?: string | number;
        allCargos?: boolean;
        q?: string;
        client_id?: string | undefined;
        perPage?: number;
        sortBy?: string;
        desc?: boolean;
      }
    >({
      query: ({
        page = 1,
        status,
        allCargos,
        sortBy,
        q,
        client_id,
        perPage = 15,
        desc,
      }) => ({
        url: `cargo/get-all-cargo?perPage=${perPage}&page=${page}${
          status ? `&status=${status}` : ""
        }${sortBy ? `&sortBy=${sortBy}` : ""}&sortDesc=${desc}${
          client_id ? `&client_id=${client_id}` : ""
        }${q && q?.length > 0 ? `&q=${q}` : ""}`,
        headers: headers(),
      }),
      transformErrorResponse: handleError,
    }),
    getCargo: build.query<any, string | undefined>({
      query: (cargo_id) => ({
        url: `cargo/get-cargo-by-id`,
        headers: headers(),
        params: { cargo_id },
      }),
      transformErrorResponse: handleError,
    }),
    saveComment: build.query<
      any,
      {
        cargo_id: string | number | undefined;
        user_id: number | null | undefined;
        comment: string;
      }
    >({
      query: ({ cargo_id, user_id, comment }) => ({
        url: "cargo/save-comment",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, user_id, comment },
      }),
      transformErrorResponse: handleError,
    }),
    updateComment: build.query<
      any,
      {
        comment_id: number;
        comment: string;
      }
    >({
      query: ({ comment_id, comment }) => ({
        url: "cargo/update-comment",
        headers: { ...headers() },
        method: "POST",
        params: { comment_id, comment },
      }),
      transformErrorResponse: handleError,
    }),
    deleteComment: build.query<any, number>({
      query: (comment_id) => ({
        url: "cargo/delete-comment",
        headers: { ...headers() },
        method: "DELETE",
        params: { comment_id },
      }),
      transformErrorResponse: handleError,
    }),
    saveCommentDocuments: build.query<any, any>({
      query: (data) => ({
        url: "cargo/save-comment-documents",
        headers: { ...headers() },
        method: "POST",
        body: data,
      }),
      transformErrorResponse: handleError,
    }),
    changeCommentStatus: build.query<any, string | undefined>({
      query: (cargo_id) => ({
        url: "cargo/change-comment-status",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id },
      }),
      transformErrorResponse: handleError,
    }),
    getCargoComments: build.query<any, string | undefined | number | null>({
      query: (cargo_id) => ({
        url: "cargo/get-cargo-comments",
        headers: { ...headers() },
        params: { cargo_id },
      }),
      transformErrorResponse: handleError,
    }),
    getExportCargo: build.query<
      any,
      {
        date_from?: string;
        date_to?: string;
        type?: string;
        my_columns: number;
      }
    >({
      query: ({ date_from, date_to, type, my_columns }) => ({
        url: "cargo/get-cargo-export",
        headers: { ...headers() },
        params: { date_from, date_to, type, my_columns },
      }),
      transformErrorResponse: handleError,
    }),
    getCargoAllDocuments: build.query<any, string | undefined>({
      query: (cargo_id) => ({
        url: "cargo/get-cargo-all-documents",
        headers: { ...headers() },
        params: { cargo_id },
      }),
      transformErrorResponse: handleError,
    }),
    getCargoActions: build.query<
      any,
      {
        cargo_id: string | number | undefined;
        sortBy?: string;
        sortDesc?: boolean;
      }
    >({
      query: ({ cargo_id, sortBy, sortDesc }) => ({
        url: "cargo/get-users-actions",
        headers: { ...headers() },
        params: { cargo_id, sortBy, sortDesc },
      }),
      transformErrorResponse: handleError,
    }),
    changeCargoStatus: build.query<
      any,
      {
        cargo_id: string | number | undefined;
        user_id: number | null | undefined;
        location_id: string;
      }
    >({
      query: ({ cargo_id, user_id, location_id }) => ({
        url: "cargo/change-status",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, user_id, location_id },
      }),
      transformErrorResponse: handleError,
    }),
    changeCargoVerifyStatus: build.query<
      any,
      { cargo_id: string | number | undefined; status: string }
    >({
      query: ({ cargo_id, status }) => ({
        url: "cargo/change-verify-status",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, status },
      }),
      transformErrorResponse: handleError,
    }),
    changeCargoPriority: build.query<
      any,
      { cargo_id: string | number | undefined; priority: number }
    >({
      query: ({ cargo_id, priority }) => ({
        url: "cargo/priority-cargo",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, priority },
      }),
    }),
    changeCargosPriority: build.query<
      any,
      { cargos: number[]; priority: number }
    >({
      query: ({ cargos, priority }) => ({
        url: `/cargo/priority-cargos${cargos.map(
          (cargo, i) => `${i === 0 ? "?" : "&"}cargos[${i}]=${cargo}`
        )}`,
        headers: { ...headers() },
        method: "POST",
        params: { priority },
      }),
    }),
    changeCargoPaidStatus: build.query<
      any,
      { cargo_id: string | number | undefined; status: number }
    >({
      query: ({ cargo_id, status }) => ({
        url: "cargo/cargo-paid-status",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, status },
      }),
      transformErrorResponse: handleError,
    }),
    сhangeCargoStorageStatus: build.query<
      any,
      { cargo_id: string | number | undefined; type: "start" | "end" }
    >({
      query: ({ cargo_id, type }) => ({
        url: "cargo/start-cargo-storage",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, type },
      }),
    }),
    deleteCargo: build.query<any, { cargo_id: string | number | undefined }>({
      query: ({ cargo_id }) => ({
        url: "cargo/cargo-delete",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id },
      }),
      transformErrorResponse: handleError,
    }),
    deleteCargos: build.query<any, { cargos: number[] }>({
      query: ({ cargos }) => ({
        url: `/cargo/cargos-delete${cargos.map(
          (cargo, i) => `${i === 0 ? "?" : "&"}cargos[${i}]=${cargo}`
        )}`,
        headers: { ...headers() },
        method: "POST",
      }),
    }),
    getMyCargos: build.query<
      any,
      { page: number; sortBy?: string; sortDesc: boolean }
    >({
      query: ({ page = 1, sortBy, sortDesc }) => ({
        url: "user/get-my-cargo",
        headers: { ...headers() },
        params: { page, sortBy, sortDesc },
      }),
      transformErrorResponse: handleError,
    }),
    getAllCargoOverload: build.query({
      query: () => ({
        url: "cargo/get-all-cargo-overload",
        headers: { ...headers() },
      }),
      transformErrorResponse: handleError,
    }),
    cargosChangeStorage: build.query<
      any,
      { cargo_id: { id: number }[]; type: "start" | "end" }
    >({
      query: ({ cargo_id, type }) => ({
        url: `/cargo/start-cargos-storage`,
        headers: { ...headers() },
        method: "POST",
        body: { cargo_id, type },
      }),
      transformErrorResponse: handleError,
    }),
    cargosStartOverload: build.query<
      any,
      { cargo_id: { id: number }[]; command_history_id: number }
    >({
      query: ({ cargo_id, command_history_id }) => ({
        url: `/cargo/start-cargos-overload`,
        headers: { ...headers() },
        method: "POST",
        body: { cargo_id, command_history_id },
      }),
    }),
    cargosEndOverload: build.query<any, { cargo_id: { id: number }[] }>({
      query: ({ cargo_id }) => ({
        url: `/cargo/end-cargos-overload`,
        headers: { ...headers() },
        method: "POST",
        body: { cargo_id },
      }),
    }),
    cargosNextStatus: build.query<
      any,
      { cargo_id: { id: number }[]; status: string }
    >({
      query: ({ cargo_id, status }) => ({
        url: `/cargo/change-cargos-status`,
        headers: { ...headers() },
        method: "POST",
        body: { cargo_id, status },
      }),
    }),
    cargosChangeSendCustomsStatus: build.query<
      any,
      { cargos: number[]; status: string }
    >({
      query: ({ cargos, status }) => ({
        url: `/cargo/check-send-customs`,
        headers: { ...headers() },
        method: "POST",
        body: { cargos, status },
      }),
    }),
    cargoClientApprove: build.query<any, { user_id: string; cargo_id: number }>(
      {
        query: ({ user_id, cargo_id }) => ({
          url: `/cargo/client-approve-cargo`,
          headers: { ...headers() },
          method: "POST",
          params: { user_id, cargo_id },
        }),
        transformErrorResponse: handleError,
      }
    ),
    cargoBrokerChangeStatus: build.query<
      any,
      { user_id: number; cargo_id: number | string; status: string }
    >({
      query: ({ user_id, cargo_id, status }) => ({
        url: `/cargo/broker-change-cargo-status`,
        headers: { ...headers() },
        method: "POST",
        params: { user_id, cargo_id, status },
      }),
    }),
    brokerCargosOnCheking: build.query<
      any,
      { broker_id: number; sortBy?: string; sortDesc: boolean }
    >({
      query: ({ broker_id, sortBy, sortDesc }) => ({
        url: `/cargo/broker-get-client-cargos`,
        headers: { ...headers() },
        method: "GET",
        params: { broker_id, sortBy, sortDesc },
      }),
      transformErrorResponse: handleError,
    }),
    confirmCargo: build.query<
      any,
      { cargo_id: number | string | undefined; status: string }
    >({
      query: ({ cargo_id, status }) => ({
        url: `/cargo/сonfirm-cargo`,
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, status },
      }),
    }),
    changeArrivedStatus: build.query<
      any,
      { cargo_id: string | undefined; status: string }
    >({
      query: ({ cargo_id, status }) => ({
        url: "cargo/update-arrived-status",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, status },
      }),
    }),
    cargoReleaseList: build.query<any, string | undefined>({
      query: (cargo_id) => ({
        url: `/release/cargo-release`,
        headers: { ...headers() },
        method: "GET",
        params: { cargo_id },
      }),
    }),
    updateCargoReleaseList: build.query<
      any,
      {
        cargo_id: string | undefined;
        cars: {
          id: number;
          percentage_ratio: number;
          units_dimension: string;
        }[];
      }
    >({
      query: ({ cargo_id, cars }) => ({
        url: `/release/update-cargo-release`,
        headers: { ...headers() },
        method: "POST",
        body: { cargo_id, cars },
      }),
    }),
    getCargoExstraStorage: build.query<any, string | undefined>({
      query: (client_id) => ({
        url: `/cargo/get-cargo-exstra-storage`,
        headers: { ...headers() },
        method: "GET",
        params: { client_id },
      }),
      transformErrorResponse: handleError,
    }),
    getKpis: build.query<any, { sortBy?: string; sortDesc?: boolean }>({
      query: ({ sortBy, sortDesc }) => ({
        url: `/cargo/get-kpis`,
        headers: { ...headers() },
        method: "GET",
        params: { sortBy, sortDesc },
      }),
      transformResponse: (resp: any) => {
        return resp.response ?? [];
      },
    }),
    storeKpi: build.query<
      any,
      { date_create: string; hours_num: string; workers: string }
    >({
      query: ({ date_create, hours_num, workers }) => ({
        url: "cargo/store-kpi",
        headers: { ...headers() },
        method: "POST",
        params: { date_create, hours_num, workers },
      }),
    }),
    updateKpi: build.query<
      any,
      { id: number; date_create: string; hours_num: string; workers: string }
    >({
      query: ({ id, date_create, hours_num, workers }) => ({
        url: "cargo/update-kpi",
        headers: { ...headers() },
        method: "POST",
        params: { id, date_create, hours_num, workers },
      }),
    }),
    changeCargoPrePaidStatus: build.query<
      any,
      { cargo_id: string | number | undefined; status: number }
    >({
      query: ({ cargo_id, status }) => ({
        url: "cargo/cargo-pre-paid-status",
        headers: { ...headers() },
        method: "POST",
        params: { cargo_id, status },
      }),
    }),
    changeCargosVerifyStatus: build.query<
      any,
      { status: number; cargo_id: number[]; user_id?: string }
    >({
      query: ({ status, cargo_id, user_id }) => ({
        url: "cargo/change-cargos-verify-status",
        headers: { ...headers() },
        method: "POST",
        body: { cargo_id, status, user_id },
      }),
    }),
  }),
});

export const {
  useLazyGetCargoIdQuery,
  useLazyCreateCargoQuery,
  useLazySaveCargoDocumentQuery,
  useLazySaveCargoReceiverDocumentQuery,
  useGetCargoStatusesQuery,
  useLazyGetCargoStatusesQuery,
  useLazyGetCargosQuery,
  useLazyGetCargoQuery,
  useGetCountriesQuery,
  useLazySaveCommentQuery,
  useLazySaveCommentDocumentsQuery,
  useLazyGetCargoCommentsQuery,
  useLazyGetExportCargoQuery,
  useLazyGetCargoAllDocumentsQuery,
  useLazyGetCargoActionsQuery,
  useLazyChangeCargoStatusQuery,
  useLazyChangeCargoVerifyStatusQuery,
  useLazySearchAddressQuery,
  useLazySearchCompaniesQuery,
  useLazyGetMyCargosQuery,
  useLazyChangeCargoPriorityQuery,
  useLazyDeleteCargoQuery,
  useLazyChangeCargoPaidStatusQuery,
  useLazySaveCargoQrCodeQuery,
  useGetCargoCategoriesQuery,
  useGetAllCargoOverloadQuery,
  useLazyСhangeCargoStorageStatusQuery,
  useLazyChangeCargosPriorityQuery,
  useLazyDeleteCargosQuery,
  useLazyCargosChangeStorageQuery,
  useLazyCargosStartOverloadQuery,
  useLazyCargosEndOverloadQuery,
  useLazyCargosNextStatusQuery,
  useLazyCargosChangeSendCustomsStatusQuery,
  useLazyCargoClientApproveQuery,
  useLazyCargoBrokerChangeStatusQuery,
  useLazyBrokerCargosOnChekingQuery,
  useLazyChangeCommentStatusQuery,
  useLazyConfirmCargoQuery,
  useLazyChangeArrivedStatusQuery,
  useLazyCargoReleaseListQuery,
  useLazyUpdateCargoReleaseListQuery,
  useLazyGetCargoExstraStorageQuery,
  useLazyDeleteCommentQuery,
  useLazyUpdateCommentQuery,
  useLazyStoreKpiQuery,
  useLazyUpdateKpiQuery,
  useGetKpisQuery,
  useLazyGetKpisQuery,
  useLazyChangeCargoPrePaidStatusQuery,
  useLazyChangeCargosVerifyStatusQuery,
} = cargo;
