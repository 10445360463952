import styled from "styled-components";
import { Header } from "./Header";
import { Options } from "./Options";

interface Props {
  open: boolean;
  onClose: () => void;
  pageSize: number;
  onChangePageSize: (value: number) => void;
  manageColumns?: boolean;
}

export const Dropdown = ({
  open,
  onClose,
  pageSize,
  onChangePageSize,
  manageColumns,
}: Props) => (
  <StyledDropdown open={open}>
    <Header onClose={onClose} />
    <Options
      pageSize={pageSize}
      onChangePageSize={onChangePageSize}
      manageColumns={manageColumns}
    />
  </StyledDropdown>
);

interface StyledDropdownProps {
  open: boolean;
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  width: 210px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  position: absolute;
  left: 0;
  top: -90%;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 50;
  ${(props) =>
    props.open &&
    `
    opacity: 1;
    visibility: visible;
  `}
`;
