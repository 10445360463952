import styled from "styled-components";
import { FooterLinks } from "./FooterLinks";
import { MainLinks } from "./MainLinks";
import { Toggle } from "./Toggle";
import { useState } from "react";
import { SupportModal } from "./SupportModal";

interface Props {
  open: boolean;
  onToggleOpen: () => void;
  onEditProfile: () => void;
}

export const Sidebar = ({ open, onToggleOpen, onEditProfile }: Props) => {
  const [supportOpen, setSupportOpen] = useState<boolean>(false);

  const handleSupportOpen = () => setSupportOpen(true);
  return (
    <>
      <SupportModal open={supportOpen} onClose={() => setSupportOpen(false)} />
      <StyledSidebar open={open}>
        <MainLinks
          open={open}
          onSupportOpen={handleSupportOpen}
          onEditProfile={onEditProfile}
        />
        <Toggle open={open} onClick={onToggleOpen} />
        <FooterLinks
          open={open}
          onSupportOpen={handleSupportOpen}
          onEditProfile={onEditProfile}
        />
      </StyledSidebar>
    </>
  );
};

interface StyledSidebarProps {
  open: boolean;
}

const StyledSidebar = styled.div<StyledSidebarProps>`
  grid-column: 1/2;
  grid-row: 2/3;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: ${(props) => (props.open ? "40px 45px 40px 35px" : "40px 35px ")};
  overflow: auto;
  transition: all 0.5s;
  width: ${(props) => (props.open ? "220px" : "110px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1000px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    padding: 20px 20px;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    z-index: 100;
    box-shadow: 0px -1px 0px #f0f1f2;
    width: 100vw;
  }
  @media (min-width: 1000px) {
    height: calc(100vh - 131px - 30px);
    overflow: auto;
    overflow-x: hidden;
  }
  @media (max-width: 500px) {
    padding: 10px 20px;
  }
`;
