import styled from "styled-components";

interface Props {
  title?: string;
  active?: boolean;
}

export const StatusPoint = ({ title, active }: Props) => (
  <StyledStatusPoint active={active}>
    <div />
    {title && <span>{title}</span>}
  </StyledStatusPoint>
);

interface StyledStatusPointProps {
  active?: boolean;
}

const StyledStatusPoint = styled.div<StyledStatusPointProps>`
  position: relative;
  div {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: ${(props) => (props.active ? "#4F75F5" : "#F4F6F9")};
  }
  span {
    color: #575756;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    top: calc(100% + 7px);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
`;
