import styled from "styled-components";

interface Props {
  active?: boolean;
  down?: boolean;
}

export const Arrow = ({ active, down }: Props) => (
  <StyledArrow active={active} down={down}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
    >
      <path
        d="M4.18115 0.723999L6.3042 4.40002C6.38106 4.53315 6.4216 4.68417 6.42163 4.83789C6.42166 4.99161 6.38125 5.14261 6.30444 5.27576C6.22763 5.40891 6.11721 5.51951 5.98413 5.59644C5.85105 5.67337 5.69986 5.71388 5.54614 5.71399H1.30029C1.14658 5.71388 0.995629 5.67337 0.862549 5.59644C0.729469 5.51951 0.618802 5.40891 0.541992 5.27576C0.465183 5.14261 0.424777 4.99161 0.424805 4.83789C0.424833 4.68417 0.465378 4.53315 0.542236 4.40002L2.66431 0.723999C2.74121 0.590895 2.85172 0.480405 2.98486 0.403564C3.118 0.326724 3.26913 0.286255 3.42285 0.286255C3.57657 0.286255 3.72746 0.326724 3.8606 0.403564C3.99374 0.480405 4.10425 0.590895 4.18115 0.723999Z"
        fill="#D9D9D9"
      />
    </svg>
  </StyledArrow>
);

const StyledArrow = styled.div<Props>`
  display: inline-block;
  margin-left: 2px;
  transition: all 0.3s;
  line-height: 0;
  ${(props) =>
    props.active &&
    `
    path {
      fill: #575756;
    }
  `}
  ${(props) =>
    props.down &&
    `
    transform: rotate(180deg);
  `}
`;
