import dashboardIcon from "../../assets/images/icons/dashboard-sidebar.svg";
import settingIcon from "../../assets/images/icons/settings.svg";
import supportIcon from "../../assets/images/icons/support.svg";
import clientsIcon from "../../assets/images/icons/clients.svg";
import brokersIcon from "../../assets/images/icons/brokers.svg";
import myCargosIcon from "../../assets/images/icons/myCargos.svg";
import teamsIcon from "../../assets/images/icons/teams.svg";
import employeesIcon from "../../assets/images/icons/employees.svg";

export interface SidebarLinkInterface {
  name: string;
  link: string;
  icon?: string;
  isNotMobile?: boolean | undefined;
  support?: boolean;
  profile?: boolean;
}

export const ADMIN_LINKS: SidebarLinkInterface[] = [
  { name: "pages.dashboard", link: "#", icon: dashboardIcon },
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
  {
    name: "pages.companies",
    link: "/#/companies",
    icon: clientsIcon,
    isNotMobile: true,
  },
  {
    name: "pages.teams",
    link: "/#/teams",
    icon: teamsIcon,
  },
  {
    name: "pages.brokers",
    link: "/#/brokers",
    icon: brokersIcon,
    isNotMobile: true,
  },
  {
    name: "pages.employees",
    link: "/#/employees",
    icon: employeesIcon,
    isNotMobile: true,
  },
];

export const BROKER_LINKS: SidebarLinkInterface[] = [
  { name: "pages.dashboard", link: "#", icon: dashboardIcon },
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
];

export const DISPATHER_KZ_LINKS: SidebarLinkInterface[] = [
  { name: "pages.dashboard", link: "#", icon: dashboardIcon },
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
  { name: "pages.teams", link: "/#/teams", icon: teamsIcon },
];

export const USER_LINKS: SidebarLinkInterface[] = [
  { name: "pages.dashboard", link: "#", icon: dashboardIcon },
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
];

export const STORAGE_LINKS: SidebarLinkInterface[] = [
  { name: "pages.dashboard", link: "#", icon: dashboardIcon },
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
  { name: "pages.teams", link: "/#/teams", icon: teamsIcon },
];

export const СLIENT_LINKS: SidebarLinkInterface[] = [
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
];

export const BROKER_KZ_LINKS: SidebarLinkInterface[] = [
  { name: "pages.dashboard", link: "#", icon: dashboardIcon },
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
  {
    name: "pages.companies",
    link: "/#/companies",
    icon: clientsIcon,
    isNotMobile: true,
  },
];

export const OPERATOR_LINKS: SidebarLinkInterface[] = [
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
];

export const TRANSIT_MANAGER_LINKS: SidebarLinkInterface[] = [
  {
    name: "pages.cargos",
    link: "/#/cargos",
    icon: myCargosIcon,
  },
];

export const handleGetUserLinksByRole = (
  userRole: number | null | undefined
) => {
  switch (userRole) {
    case 1:
      return ADMIN_LINKS;
    case 3:
      return BROKER_LINKS;
    case 8:
      return DISPATHER_KZ_LINKS;
    case 11:
      return BROKER_KZ_LINKS;
    case 13:
      return СLIENT_LINKS;
    case 4:
      return STORAGE_LINKS;
    case 14:
      return OPERATOR_LINKS;
    case 15:
      return TRANSIT_MANAGER_LINKS;
    default:
      return USER_LINKS;
  }
};

export const FOOTER_LINKS: SidebarLinkInterface[] = [
  {
    name: "pages.profile",
    link: "/#/settings",
    icon: settingIcon,
    profile: true,
  },
  { name: "Поддержка", link: "", icon: supportIcon, support: true },
];
