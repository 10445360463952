import styled from "styled-components";
import { useAppSelect } from "../../../../hooks/redux";
import { useGetUsersRolesQuery } from "../../../../store/admin/admin.api";
import { Avatar } from "../../../Avatar/Avatar";

export const Info = () => {
  const { user } = useAppSelect((state) => state.auth);
  const { data: usersRoles } = useGetUsersRolesQuery(null);
  const userRole = usersRoles?.find(
    (role: any) => Number(role.value) === user?.role_id
  )?.title;

  return (
    <StyledInfo>
      <Avatar userAvatar={user?.avatar} userName={user?.name ?? ""} />
      <div className="profile-info">
        <div className="name">{user?.name ?? ""}</div>
        <div className="role">{userRole ?? ""}</div>
      </div>
    </StyledInfo>
  );
};

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .profile-info {
    margin-left: 12px;
    width: 90%;
  }
  .name {
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    color: #575756;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .role {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    text-transform: lowercase;
  }
`;
