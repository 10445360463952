import { useState, useEffect } from "react";
import { Tabs } from "../../components/Tabs/Tabs";
import { Header } from "./Header/Header";
import styled from "styled-components";
import { About } from "./About/About";
import { Comments } from "../../components/Comments/Comments";
import { useParams } from "react-router";
import { Cars } from "./Cars/Cars";
import { useActions } from "../../hooks/actions";
import {
  useLazyChangeCommentStatusQuery,
  useLazyGetCargoCommentsQuery,
  useLazyGetCargoQuery,
} from "../../store/cargo/cargo.api";
import { useAppSelect } from "../../hooks/redux";
import { History } from "./History/History";
import { NotFound } from "./NotFound";
import { Preloader } from "../../components/Preloader/Preloader";

const FULL_CARGO_TABS = [
  { title: "cargo.about" },
  { title: "cargos.receiversData" },
  { title: "cargo.history" },
  { title: "cargos.comments" },
];

export const CargoNew = () => {
  const { cargoId } = useParams();
  const { setActiveCargo } = useActions();
  const [getCargo, { data: cargoData, isLoading }] = useLazyGetCargoQuery();
  const [getCargoComments, { data: cargoComments }] =
    useLazyGetCargoCommentsQuery();
  const [changeCommentStatus] = useLazyChangeCommentStatusQuery();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isReceivers, setIsReceivers] = useState<boolean>(false);
  const { user } = useAppSelect((state) => state.auth);

  const handleChangeTab = (tabIndex: number) => setActiveTab(tabIndex);
  const handleRefreshCargoData = () => getCargo(cargoId);

  useEffect(() => {
    if (cargoId) {
      changeCommentStatus(cargoId);
    }
    // eslint-disable-next-line
  }, [cargoId]);

  useEffect(() => {
    getCargo(cargoId);
    getCargoComments(cargoId);
    // eslint-disable-next-line
  }, [getCargo, cargoId]);

  useEffect(() => {
    setIsReceivers(
      cargoData?.response?.receivers &&
        cargoData?.response?.receivers?.length > 0
    );
    setActiveCargo(cargoData?.response?.ns ?? null);
  }, [setActiveCargo, cargoData]);

  const handleUpdateDate = () => {
    if (!document.hidden) {
      handleRefreshCargoData();
    }
  };

  //   useEffect(() => {
  //     window.addEventListener("visibilitychange", handleUpdateDate);
  //     return () =>
  //       window.removeEventListener("visibilitychange", handleUpdateDate);
  //     // eslint-disable-next-line
  //   }, []);

  return (
    <StyledCargoWrapper>
      {isLoading ? (
        <Preloader classes="loading-spinner" />
      ) : cargoData ? (
        <StyledCargo>
          <Header
            cargoData={cargoData}
            onRefreshCargoData={() => getCargo(cargoId)}
          />
          <Tabs
            tabs={FULL_CARGO_TABS}
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
            classes="tabs"
          />
          {activeTab === 0 ? (
            <About
              cargoData={cargoData?.response}
              onRefreshCargoData={() => getCargo(cargoId)}
            />
          ) : activeTab === 1 ? (
            <Cars
              receivers={cargoData?.response?.receivers}
              cargoData={cargoData?.response}
            />
          ) : activeTab === 2 ? (
            <History cargoData={cargoData} comments={cargoComments} />
          ) : activeTab === 3 ? (
            <Comments cargoId={cargoId} />
          ) : null}
        </StyledCargo>
      ) : (
        <NotFound />
      )}
    </StyledCargoWrapper>
  );
};

const StyledCargoWrapper = styled.div`
  height: 100%;
  .loading-spinner {
    height: 100%;
  }
`;

const StyledCargo = styled.div`
  .tabs {
    margin-bottom: 22px;
  }
  .content-card {
    height: calc(100vh - 131px - 30px - 144px - 72px);
    margin-bottom: 22px;
  }
  @media (max-width: 1500px) {
    .content-card {
      height: calc(100vh - 131px - 30px - 144px - 72px);
    }
  }
`;
