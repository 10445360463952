import React from "react";
import styled from "styled-components";
import { Logo } from "./Logo";

interface Props {
  title: string;
  children: React.ReactNode;
  classes?: string;
}

export const Card = ({ title, children, classes }: Props) => (
  <StyledCard className={classes}>
    <Logo />
    <div className="content-wrapper">{children}</div>
  </StyledCard>
);

const StyledCard = styled.div`
  max-width: 417px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: 38px 0 30px;
  .content-wrapper {
    padding: 0px 30px;
  }
  @media (max-width: 420px) {
    border-radius: 30px 30px 0px 0px;
    .content-wrapper {
      padding: 0 16px 10px;
    }
  }
`;
