import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { SelectedCargos } from "./SelectedCargos";
import { AdditionalButton } from "./AdditionalButton";
import { DownloadButton } from "./DownloadButton";
import { useAppSelect } from "../../../hooks/redux";
import { useTranslation } from "react-i18next";

interface Props {
  viewType: string;
  selectedCargos: { title: string; id: number }[];
  onClearSelectedCargos: () => void;
  onAction: (type: string) => void;
  activeTabIndex: number;
}

const DEFAULT_ACTIONS = [
  { title: "cargos.importantCargos", type: "priority" },
  { title: "cargos.tookAwayPriotitate", type: "not_priority" },
];

const CLIENTS_MY_CARGOS_ACTIONS = [
  { title: "На проверку", type: "on_checking_clients_cargos" },
];

const BROKER_CARGOS_ON_CHECKING_ACTIONS = [
  { title: "common.confirm", type: "broker_cheking_accept" },
  { title: "Отклонить", type: "broker_cheking_decline" },
];

const APPROVE_MY_CARGO_ACTIONS = [
  { title: "common.confirm", type: "approve_my_cargos" },
];

export const Actions = ({
  viewType,
  selectedCargos,
  onClearSelectedCargos,
  onAction,
  activeTabIndex,
}: Props) => {
  const { user } = useAppSelect((state: any) => state.auth);
  const { t } = useTranslation("common");

  const isFullAccess = user?.role_id !== 13;
  const isClientMyCargos = activeTabIndex === 0 && user?.role_id === 13;
  const isBroketCargosOnCheking = activeTabIndex === 0 && user?.role_id === 3;
  const isApproveMyCargos =
    (activeTabIndex === 0 && user?.role_id === 1) ||
    (activeTabIndex === 1 && user?.role_id === 3);
  const actionsBtns = isClientMyCargos
    ? CLIENTS_MY_CARGOS_ACTIONS
    : isApproveMyCargos
    ? APPROVE_MY_CARGO_ACTIONS
    : isBroketCargosOnCheking
    ? BROKER_CARGOS_ON_CHECKING_ACTIONS
    : isFullAccess
    ? DEFAULT_ACTIONS
    : [];

  return (
    <StyledActions viewType={viewType}>
      {actionsBtns.map((action, i) => (
        <Button
          title={t(action.title)}
          onClick={() => onAction(action.type)}
          color="blue"
          classes="btn"
          disabled={selectedCargos.length === 0}
        />
      ))}
      {isFullAccess && (
        <>
          <DownloadButton />
          <AdditionalButton
            disabled={selectedCargos.length === 0}
            onAction={onAction}
          />
        </>
      )}
      {selectedCargos.length > 0 && (
        <SelectedCargos
          selectedCargos={selectedCargos}
          onClearSelectedCargos={onClearSelectedCargos}
        />
      )}
    </StyledActions>
  );
};
interface StyledActionsProps {
  viewType: string;
}

const StyledActions = styled.div<StyledActionsProps>`
  padding: 2px;
  border-radius: 10px;
  background: #ececec;
  display: flex;
  align-items: center;
  gap: 4px;
  .btn {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    height: 50px;
    width: 126px;
    padding: 0;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 14px !important;
    flex-shrink: 0;
  }
  @media (max-width: 820px) {
    flex-direction: column;
    width: 100%;
    .btn {
      width: 100%;
    }
  }
`;
