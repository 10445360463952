import { useState, useEffect } from "react";
import styled from "styled-components";
import arrowIcon from "../../assets/images/icons/s-arrow.svg";
import { Dropdown } from "./Dropdown";
import { Input } from "../Input/Input";

export interface Option {
  title: string;
  value: string;
}

interface Props {
  label?: string | undefined;
  value?: string;
  search?: boolean;
  placeholder?: string;
  options: Option[];
  onSelectOption: (opt: Option) => void;
  classes?: string;
  wrapperClasses?: string;
  outline?: boolean | undefined;
  line?: boolean | undefined;
  errorMessage?: string | undefined;
  disabled?: boolean;
  noArrow?: boolean;
  noFilterSearch?: boolean;
  changeValueOnSearch?: boolean;
  error?: boolean;
}

export const Select = ({
  label,
  value = "",
  placeholder = "Поиск",
  options = [],
  onSelectOption,
  classes,
  wrapperClasses,
  outline,
  line,
  errorMessage,
  search,
  disabled,
  noArrow,
  noFilterSearch,
  changeValueOnSearch,
  error,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    !open && setSearchValue("");
  }, [open]);

  const handleChangeSearch = (value: string) => {
    if (!disabled) {
      setSearchValue(value);
      setOpen(value.length > 0);
    }
    onSelectOption({
      title: changeValueOnSearch ? value : "",
      value: changeValueOnSearch ? value : "",
    });
  };

  const handleSelect = (opt: Option) => {
    onSelectOption(opt);
    setOpen(false);
  };

  useEffect(() => {
    const searchInputValue = changeValueOnSearch ? value : searchValue;
    setSearchValue(
      changeValueOnSearch
        ? value
        : options.find((opt) => opt.value === value)?.title ?? searchInputValue
    );
    // eslint-disable-next-line
  }, [value, options]);

  return (
    <StyledSelectWrapper outline={outline} className={wrapperClasses}>
      <StyledSelect
        className={classes}
        line={line}
        error={error}
        open={open}
        // onClick={handleFocusBtn}
      >
        <div className="header" onClick={() => (line ? null : setOpen(!open))}>
          {label && (
            <div className="label">
              {value
                ? options.find((opt) => opt.value === value)?.title
                : label}
            </div>
          )}
          {line && (
            <Input
              placeholder={placeholder}
              value={searchValue}
              onChange={handleChangeSearch}
              classes="search-main-input"
            />
          )}
          {!noArrow && <img src={arrowIcon} alt="" className="select-arrow" />}
        </div>
        {!disabled && (
          <Dropdown
            open={open}
            value={value}
            options={options?.filter((opt: Option) =>
              search && !noFilterSearch
                ? opt.title.toLowerCase().includes(searchValue.toLowerCase())
                : true
            )}
            onSelectOption={handleSelect}
            placeholder={placeholder ?? ""}
            searchValue={searchValue}
            onSearch={handleChangeSearch}
            line={line}
          />
        )}
      </StyledSelect>
    </StyledSelectWrapper>
  );
};

interface StyledSelectWrapperProps {
  outline?: boolean | undefined;
}

const StyledSelectWrapper = styled.div<StyledSelectWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 240px;
  width: 100%;
  ${(props) =>
    props.outline &&
    `
    margin-bottom: 24px;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    padding: 8px 16px;
    button {
      max-width: 100%;
      background: none;
      font-family: 'Poppins', sans-serif !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 147% !important;
      color: #3A3A3C !important;
      padding: 0;
    }
    .dropdown {
      width: 100%;
      top: 140%;
    }
  `}
`;

interface StyledSelectProps {
  line?: boolean | undefined;
  error?: boolean;
  open: boolean;
}

const StyledSelect = styled.button<StyledSelectProps>`
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  height: 50px;
  padding: 10px 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .label {
    color: #575756;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    img {
      transition: all 0.3s;
      ${(props) => props.open && " transform: rotate(180deg);"}
    }
  }
  .search-main-input {
    width: 100%;
  }
  ${(props) =>
    props.line &&
    `
    border: none;
    padding: 0;
  `}
`;
