import cogoToast from "cogo-toast";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Header } from "./Header";
import { HsCode } from "./HsCode";
import { useLazyEndOverloadQuery } from "../../../../store/teams/teams.api";
import { useLazyСhangeCargoStorageStatusQuery } from "../../../../store/cargo/cargo.api";
import {
  CONFIRM_INIT,
  Confirm,
  ConfirmInterface,
} from "../../../../components/Confirm/Confirm";

interface Props {
  createdAt: string;
  hs: string;
  overload: string;
  storage: string;
  draggable: boolean;
  onEndStorage: (laneId: string, cardId: number, lastData: any) => void;
  onEndOverload: (laneId: string, cardId: number, lastData: any) => void;
  laneId: string;
  id: number;
  data: any;
  end_overload_date: string | null;
  start_overload_date: string | null;
  start_storage_date: string | null;
  end_storage_date: string | null;
  onSelect: (title: string, id: number) => void;
  selectedCargos: { title: string; id: number }[];
  priority: number;
  product_volume: number;
  status_operator_financier: string;
  status_transit_manager: string;
  onRefreshData: () => void;
  history_id: any;
}

export const Card = ({
  createdAt,
  hs,
  draggable,
  onEndStorage,
  onEndOverload,
  laneId,
  id,
  data,
  end_overload_date,
  start_overload_date,
  start_storage_date,
  end_storage_date,
  onSelect,
  selectedCargos,
  priority,
  product_volume,
  status_operator_financier,
  status_transit_manager,
  history_id,
  onRefreshData,
}: Props) => {
  const { t } = useTranslation("common");
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [endOverload] = useLazyEndOverloadQuery();
  const [changeStorageStatus] = useLazyСhangeCargoStorageStatusQuery();

  const handleEndOverload = () => {
    setComfirmData({
      open: true,
      title: t("teams.confirmEndOveloadWithoutTeam"),
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => {
        setComfirmData(CONFIRM_INIT);
        endOverload({ history_id }).then((resp: any) => {
          if (resp.status === "fulfilled") {
            cogoToast.success(t("teams.overloadEnded"), {
              hideAfter: 3,
              position: "top-right",
            });
            onEndOverload(laneId, id, data);
          } else {
            cogoToast.error(t("teams.overloadEndError"), {
              hideAfter: 3,
              position: "top-right",
            });
          }
        });
      },
    });
  };

  const handleEndStorage = () => {
    setComfirmData({
      open: true,
      title: `${t("cargo.endStorage")} ${hs}`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => {
        setComfirmData(CONFIRM_INIT);
        changeStorageStatus({ cargo_id: id, type: "end" }).then((resp: any) => {
          if (resp.status === "fulfilled") {
            cogoToast.success(t("cargo.storageEnded"), {
              hideAfter: 3,
              position: "top-right",
            });
            onEndStorage(laneId, id, data);
          } else {
            cogoToast.error(t("cargo.storageEndError"), {
              hideAfter: 3,
              position: "top-right",
            });
          }
        });
      },
    });
  };

  const handleClick = (e: any) => {
    const elementClassList = e.target.classList;
    let isClickable: boolean = false;
    elementClassList.forEach((className: any) => {
      if (className === "clickable") {
        isClickable = true;
      }
    });

    isClickable && onSelect(hs, id);
  };

  return (
    <StyledCard
      draggable={draggable}
      onDragStart={(e) => e.preventDefault()}
      onClick={handleClick}
      isSelected={
        !!selectedCargos.find((c: { title: string; id: number }) => c.id === id)
      }
      isPrioriy={priority === 1}
      className="clickable"
    >
      <Confirm {...confirmData} />
      <Header
        createdAt={createdAt}
        productVolume={product_volume}
        startOverloadDate={start_overload_date}
        endOverloadDate={end_overload_date}
        startStorageDate={start_storage_date}
        endStorageDate={end_storage_date}
      />
      <HsCode
        cargoId={id}
        hsCode={hs}
        isPrioriy={priority === 1}
        laneId={laneId}
        statusOperatorFinancier={status_operator_financier}
        statusTransitManager={status_transit_manager}
        onRefreshData={onRefreshData}
      />
    </StyledCard>
  );
};

interface StyledCardProps {
  isSelected: boolean;
  isPrioriy: boolean;
}

const StyledCard = styled.div<StyledCardProps>`
  background: ${({ isSelected, isPrioriy }) =>
    isSelected ? "#ECECEC" : isPrioriy ? "#94E18C" : "#F5F5F5"};
  border-radius: 20px;
  padding: 8px 17px 16px 18px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
`;
