import styled from "styled-components";
import sidebarToggleIcon from "../../assets/images/icons/sidebar-toggle.svg";

interface Props {
  open: boolean;
  onClick: () => void;
}

export const Toggle = ({ open, onClick }: Props) => (
  <StyledToggle src={sidebarToggleIcon} onClick={onClick} open={open} />
);

interface StyledToggleProps {
  open: boolean;
}

const StyledToggle = styled.img<StyledToggleProps>`
  display: block;
  transition: all 0.3s;
  margin: ${(props) => (props.open ? "40px 0px 40px auto" : "40px auto 40px")};
  transform: rotate(${(props) => (props.open ? "0" : "180")}deg);
  cursor: pointer;
  @media (max-width: 1000px) {
    display: none;
  }
`;
