import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  data: null,
};

export const canbanSlice = createSlice({
  name: "canban",
  initialState,
  reducers: {},
});

export const canbanActions = canbanSlice.actions;
export const canbanReducer = canbanSlice.reducer;
