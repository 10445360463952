import { useGetCargoCategoriesQuery } from "../../../store/cargo/cargo.api";
import { Option } from "../../../components/Select/Select";
import {
  handleCheckIsStorage,
  handleGetStorageDaysCount,
} from "../../../helpers/cargo";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import editIcon from "../../../assets/images/icons/edit-icon.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  colums: any;
  row: any;
  isSelected: boolean;
  onSelectCargo: () => void;
  status?: string;
}

export const Row = ({
  colums,
  row,
  isSelected,
  onSelectCargo,
  status,
}: Props) => {
  const { t } = useTranslation("common");
  const { data: categories } = useGetCargoCategoriesQuery(null);

  return (
    <tr
      className={`${isSelected && "selected"} ${
        row.priority === 1 && "priority"
      }`}
    >
      <td>
        <Checkbox checked={isSelected} onChange={onSelectCargo} />
      </td>
      <td>
        <Link to={`/edit-cargo/${row.id}`}>
          <img src={editIcon} alt="" />
        </Link>
      </td>
      <td></td>
      {colums[0].show && (
        <td className="row-item">
          <Link to={`/cargo/${row.id}`}>
            <div className="hsCode">
              <div className="bold">{row?.ns}</div>
              <div>{status}</div>
            </div>
          </Link>
        </td>
      )}
      {colums[1].show && (
        <td className="row-item">
          <div className="bold">{row?.company_name}</div>
        </td>
      )}
      {colums[2].show && (
        <td className="row-item">
          <div className="bold">
            {
              categories?.find(
                (opt: Option) => opt.value === row?.category?.toString()
              )?.title
            }
          </div>
        </td>
      )}
      {colums[3]?.show && <td className="row-item">{row?.created_at}</td>}
      {colums[4].show && <td className="row-item">{row?.seal_id}</td>}
      {colums[5].show && (
        <td className="row-item">
          <div
            className={` ${
              handleCheckIsStorage(
                row?.start_storage_date,
                row?.end_storage_date
              ) &&
              Number(handleGetStorageDaysCount(row?.start_storage_date)) > 7 &&
              "red"
            }`}
          >
            {handleCheckIsStorage(
              row?.start_storage_date,
              row?.end_storage_date
            )
              ? handleGetStorageDaysCount(row?.start_storage_date).toString()
              : "-"}
          </div>
        </td>
      )}
      {colums[6].show && (
        <td className="row-item">{row?.declarations_number}</td>
      )}
      {colums[7].show && (
        <td className="row-item">
          <div>{row?.status_id}</div>
          {row.priority === 1 && row.check_send_customs === 0 && (
            <div className="green">{t("cargos.importantCargos")}</div>
          )}
          {row.check_send_customs === 1 && (
            <div className="orange">Можно отправлять на таможню</div>
          )}
        </td>
      )}
      {colums[8].show && (
        <td className="row-item">
          {row?.product_volume} {t("cargo.kub")}
        </td>
      )}
      {colums[9].show && (
        <td className="row-item">
          {row?.weight} {t("cargo.kg")}
        </td>
      )}
    </tr>
  );
};
