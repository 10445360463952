import cogoToast from "cogo-toast";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import {
  useLazyCreateBrokerQuery,
  useLazyEditBrokerQuery,
} from "../../store/brokers/brokers.api";
import { Modal } from "../../components/Modal/Modal";
import { emailValidation } from "../../helpers/validation";

interface Props {
  open: boolean;
  editingBroker?: any;
  onClose: () => void;
  onRefreshData: () => void;
}

export const BrokersModal = ({
  open,
  editingBroker,
  onClose,
  onRefreshData,
}: Props) => {
  const [title, setTitle] = useState<string>("");
  const [cod, setCod] = useState<string>("");
  const [contractNumber, setContractNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordAgain, setPasswordAgain] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [createBroker] = useLazyCreateBrokerQuery();
  const [editBroker] = useLazyEditBrokerQuery();
  const { t } = useTranslation("common");
  const [errors, setErrors] = useState<{ email: null | string }>({
    email: null,
  });

  const handleChangeEmail = (value: string) => {
    setEmail(value);
    setErrors({
      email: emailValidation(value) ? "Введите корректный email" : null,
    });
  };

  const handleResetValues = () => {
    setTitle("");
    setCod("");
    setContractNumber("");
    setEmail("");
    setPassword("");
    setPasswordAgain("");
  };

  const handleCloseModal = () => {
    handleResetValues();
    onClose();
  };

  const handleCreateCompany = () => {
    setLoading(true);
    createBroker({
      title,
      cod,
      contract_number: contractNumber,
      email,
      password,
    }).then((resp) => {
      setLoading(false);
      console.log(resp);
      if (resp.status === "fulfilled") {
        handleCloseModal();
        cogoToast.success(t("brokers.createSuccess"), {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
      } else {
        cogoToast.error(t("brokers.createError"), {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  useEffect(() => {
    if (editingBroker) {
      setTitle(editingBroker?.title ?? "");
      setCod(editingBroker?.cod ?? "");
      setContractNumber(editingBroker?.contract_number ?? "");
      setEmail(editingBroker?.email ?? "");
      setPassword(editingBroker?.password ?? "");
    }
  }, [editingBroker]);

  const handleEditCompany = () => {
    setLoading(true);
    editBroker({
      client_id: editingBroker?.id,
      title,
      cod,
      contract_number: contractNumber,
      email,
    }).then((resp) => {
      setLoading(false);
      if (resp.status === "fulfilled") {
        handleCloseModal();
        cogoToast.success(t("brokers.savedSuccess"), {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
      } else {
        cogoToast.error(t("brokers.savedError"), {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleCloseModal}
      onSubmit={() =>
        !!editingBroker ? handleEditCompany() : handleCreateCompany()
      }
      title={t(!!editingBroker ? "brokers.editing" : "Добавление брокера")}
      cancelBtnText={t("common.cancel")}
      submitBtnText={t(!!editingBroker ? "common.save" : "Добавить")}
      disabledSubmit={
        loading ||
        cod.length === 0 ||
        (!editingBroker && password !== passwordAgain) ||
        (!editingBroker && email.length === 0) ||
        (!editingBroker && password.length === 0) ||
        (!editingBroker && passwordAgain.length === 0) ||
        !!errors.email
      }
    >
      <StyledBrokersModal>
        <Input
          placeholder={t("companies.name")}
          value={title}
          onChange={(value: string) => setTitle(value)}
          classes="input"
        />
        <Input
          placeholder={t("companies.code")}
          value={cod}
          onChange={(value: string) => setCod(value)}
          classes="input"
          disabled={!!editingBroker}
        />
        <div className="input-group">
          <Input
            placeholder={t("companies.contractNumber")}
            value={contractNumber}
            onChange={(value: string) => setContractNumber(value)}
            classes="input"
          />
          <Input
            placeholder={t("settings.email")}
            value={email}
            onChange={handleChangeEmail}
            classes="input"
            disabled={!!editingBroker}
            error={!!errors.email}
            errorMessage={errors.email ?? null}
          />
        </div>
        {!editingBroker && (
          <div className="input-group">
            <Input
              placeholder={t("auth.password")}
              value={password}
              onChange={(value: string) => setPassword(value)}
              classes="input"
              password
            />
            <Input
              placeholder={"Повторите пароль"}
              value={passwordAgain}
              onChange={(value: string) => setPasswordAgain(value)}
              classes="input"
              password
            />
          </div>
        )}
      </StyledBrokersModal>
    </Modal>
  );
};

const StyledBrokersModal = styled.div`
  .input:not(:first-child) {
    margin-bottom: 15px;
  }
  .input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
`;
