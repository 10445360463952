import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Confirm,
  ConfirmInterface,
  CONFIRM_INIT,
} from "../../components/Confirm/Confirm";
import { Table } from "../../components/Table/Table";
import { useLazyDeleteCompaniesQuery } from "../../store/companies/companies.api";
import { CompanyRow } from "./CompanyRow";
import { TableData } from "../../models/models";

const COLUMNS = [
  { title: "companies.name", field: "id" },
  { title: "companies.code", field: "cod" },
  { title: "companies.contractNumber", field: "contract_number" },
  { title: "Ответственный", field: "responsible" },
  { title: "BIN", field: "bin" },
  { title: "INN", field: "inn" },
  { title: "cargo.comment", field: "comment" },
];

interface Props {
  data: any;
  loading: boolean;
  onEditCompany: (company: any) => void;
  onRefreshData: () => void;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  sort: { field: string; desc: boolean };
  onSort: (field: string) => void;
}

export const CompaniesTable = ({
  data,
  loading,
  onEditCompany,
  onRefreshData,
  tableData,
  onChangeTableData,
  sort,
  onSort,
}: Props) => {
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [deleteCompanies] = useLazyDeleteCompaniesQuery();

  const [selected, setSelected] = useState<{ title: string; id: number }[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsAllSelected(selected.length === data?.length && data?.length > 0);
  }, [selected, data]);

  // CHANGE SELECTED
  const handleRemoveSelected = (cargoId: number) => {
    setSelected(selected.filter((c) => c.id !== cargoId));
  };
  const handleSelect = (title: string, cargoId: number) => {
    const isSelected = !!selected.find((c) => c.id === cargoId);
    isSelected
      ? handleRemoveSelected(cargoId)
      : setSelected([...selected, { title, id: cargoId }]);
  };

  const handleSelectAll = (cargos: { title: string; id: number }[]) =>
    setSelected(cargos);

  const handleDeleteCompanies = () => {
    deleteCompanies(selected.map((s) => s.id)).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно удалено", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
        setComfirmData(CONFIRM_INIT);
        setSelected([]);
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleConfirmDelete = () => {
    if (selected.length > 0) {
      setComfirmData({
        open: true,
        title: "Вы точно хотите удалить клиента(ов)?",
        onCancel: () => setComfirmData(CONFIRM_INIT),
        onConfirm: () => handleDeleteCompanies(),
      });
    }
  };
  return (
    <>
      <Confirm
        {...confirmData}
        items={selected}
        onRemoveItem={handleSelect}
        closeOnEmptyItems
      />
      <StyledCompaniesTable>
        <Table
          loading={loading}
          columns={COLUMNS.map((column: { title: string; field: string }) => ({
            title: column.title,
            active: sort.field === column.field,
            down: sort.field === column.field && sort.desc,
            onSort: () => onSort(column.field),
          }))}
          pageSize={tableData.perPage}
          onChangePageSize={(perPage: number) =>
            onChangeTableData({ ...tableData, perPage })
          }
          onSelectAllCargos={() =>
            handleSelectAll(
              isAllSelected
                ? []
                : data.map((company: any) => ({
                    title: company?.title,
                    id: company.id,
                  }))
            )
          }
          isAllSelected={isAllSelected}
          onRemove={handleConfirmDelete}
          hideManage
        >
          {data &&
            data.map((company: any, i: number) => (
              <CompanyRow
                key={i}
                number={1 + i}
                title={company.title}
                cod={company.cod}
                responsible={company?.responsible ?? ""}
                bin={company?.bin ?? ""}
                inn={company?.inn ?? ""}
                comment={company?.comment ?? ""}
                contractNumber={company?.contract_number}
                onEdit={() => onEditCompany(company)}
                isSelected={!!selected.find((c: any) => c.id === company.id)}
                onSelect={() => handleSelect(company.title, company.id)}
              />
            ))}
        </Table>
      </StyledCompaniesTable>
      {/* <Paginator
        tableData={tableData}
        onChangeTableData={onChangeTableData}
        loading={loading}
      /> */}
    </>
  );
};

const StyledCompaniesTable = styled.div`
  overflow: auto;
  min-height: 500px;
  max-height: 100vh;
  height: calc(100vh - 120px - 30px - 80px);
  width: 100%;
  overflow: auto;
  border-radius: 20px;
  @media (max-width: 1000px) {
    height: 300px;
    margin-bottom: 100px;
  }
`;
