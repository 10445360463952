import { Row } from "./Row";

interface Props {
  data: any;
  columns: any;
  selectedCargos: { title: string; id: number }[];
  onSelectCargo: (title: string, cargoId: number) => void;
}

export const Cargos = ({
  data,
  columns,
  selectedCargos,
  onSelectCargo,
}: Props) => (
  <>
    {data.map((row: any, i: number) => (
      <Row
        key={i}
        colums={columns}
        isSelected={!!selectedCargos.find((c) => c.id === row.id)}
        row={row}
        onSelectCargo={() => onSelectCargo(row?.ns, row.id)}
      />
    ))}
  </>
);
