import styled from "styled-components";

interface Props {
  title: string;
}

export const InfoDivider = ({ title }: Props) => (
  <StyledInfoDivider>
    <span>{title}</span>
    <div />
  </StyledInfoDivider>
);

const StyledInfoDivider = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  margin: 16px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #aeaeb2;
  margin: 5px 0;
  div {
    height: 1px;
    width: 100%;
    background: #e5e5ea;
    margin-left: 16px;
  }
  span {
    width: 100%;
  }
`;
