import styled from "styled-components";

interface Props {
  message: string;
  error?: boolean | undefined;
  success?: boolean | undefined;
}

export const Message = ({ message, error, success }: Props) => (
  <StyledMessage error={error} success={success}>
    {message}
  </StyledMessage>
);

interface StyledMessageProps {
  error?: boolean | undefined;
  success?: boolean | undefined;
}

const StyledMessage = styled.div<StyledMessageProps>`
  margin: 10px 0;
  color: ${(props) =>
    props.error ? "#f32a2a" : props.success ? "#3e7eff" : "#171721"};
  text-align: center;
  font-size: 12px;
`;
