import styled from "styled-components";

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => <StyledTitle>{title}</StyledTitle>;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 25px;
  color: #b1b1b1;
  font-size: 12px;
  font-weight: 400;
`;
