import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Option, Select } from "../../components/Select/Select";

interface Props {
  selectedEmployee: string | undefined;
  onSelectEmployee: (value: undefined | string) => void;
  employees: Option[];
  onInvite: () => void;
}

export const Header = ({
  selectedEmployee,
  onSelectEmployee,
  employees,
  onInvite,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledHeader>
      <PageHeader title={t("pages.employees")} classes="header-title" />
      <div className="btns">
        <Select
          options={employees}
          value={selectedEmployee}
          onSelectOption={(opt: Option) =>
            onSelectEmployee(
              opt.value === selectedEmployee ? undefined : opt.value
            )
          }
          classes="select-company"
          label="Выбор сотрудника"
          placeholder="Найти сотрудника"
        />
        <Button
          title={t("employees.invite")}
          onClick={onInvite}
          color="blue"
          classes="btn"
        />
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .header-title {
    margin: 0;
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .select-company {
    margin-right: 20px;
    width: 240px;
  }
  .btn {
    max-width: max-content;
    width: max-content;
    height: 50px;
    background: #4f75f4;
    flex-shrink: 0;
  }
  @media (max-width: 1000px) {
    .btns {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .btn,
    .select-company {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 650px) {
    .btns {
      grid-template-columns: 1fr;
    }
  }
`;
