import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { Item } from "./Item";
import { useEffect } from "react";

export const CONFIRM_INIT = {
  open: false,
  title: "",
  onCancel: () => null,
  onConfirm: () => null,
};

export interface ConfirmInterface {
  open: boolean;
  title?: string;
  onCancel: () => void;
  onConfirm: () => void;
  items?: { title: string; id: number }[];
  onRemoveItem?: (title: string, id: number) => void;
  closeOnEmptyItems?: boolean;
}

export const Confirm = ({
  open,
  title,
  onCancel,
  onConfirm,
  items,
  onRemoveItem,
  closeOnEmptyItems,
}: ConfirmInterface) => {
  const { t } = useTranslation("common");

  useEffect(() => {
    if (closeOnEmptyItems && items) {
      items.length === 0 && onCancel();
    }
  }, [items]);

  return (
    <Modal
      open={open}
      title={title ?? ""}
      onCancel={onCancel}
      onSubmit={onConfirm}
      cancelBtnText={t("common.cancel")}
      submitBtnText={t("common.yes")}
    >
      <div>
        {items
          ? items?.map((item, i) => (
              <Item
                key={i}
                title={item.title}
                onRemove={() =>
                  onRemoveItem ? onRemoveItem(item.title, item.id) : null
                }
              />
            ))
          : null}
      </div>
    </Modal>
  );
};
