import styled from "styled-components";

interface Props {
  onClick: () => void;
  disabled?: boolean;
  icon: string;
}

export const Button = ({ onClick, disabled, icon }: Props) => (
  <StyledButton onClick={onClick} disabled={disabled}>
    <img src={icon} alt="" />
  </StyledButton>
);

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.3s;
  height: 46px;
  width: 46px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`;
