import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Option } from "../../components/Button/Button";
import { InfoDivider } from "../../components/InfoDivider/InfoDivider";
import { ToggleCard } from "../../components/ToggleCard/ToggleCard";
import { formatDate } from "../../helpers/date";
import {
  useGetCargoCategoriesQuery,
  useGetCountriesQuery,
} from "../../store/cargo/cargo.api";
import { InfoCard } from "./InfoCard";

interface Props {
  cargoData: any;
}

export const Info = ({ cargoData }: Props) => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: categories } = useGetCargoCategoriesQuery(null);
  const { t } = useTranslation("common");

  const formatedAboutData = [
    {
      title: t("cargos.cargo"),
      infoItems: [
        {
          classes: "info-block",
          data: [
            { title: t("cargo.hsCode"), value: cargoData?.ns },
            { title: t("cargo.sealNumber"), value: cargoData?.seal_id },
            {
              title: t("cargo.declarationCount"),
              value: cargoData?.declarations_number,
            },
            { title: t("cargo.placeCount"), value: cargoData?.places_number },
            {
              title: t("cargo.containersCount"),
              value: cargoData?.containers_number,
            },
          ],
        },
        {
          classes: "info-block",
          data: [
            {
              title: t("cargo.weight"),
              value: cargoData?.weight && `${cargoData?.weight} т`,
            },
            {
              title: t("cargo.valumeKub"),
              value:
                cargoData?.product_volume &&
                `${cargoData?.product_volume} ${t("cargo.kub")}`,
            },
            {
              title: t("cargo.cargoCategory"),
              value:
                categories?.find(
                  (opt: Option) => opt.value === cargoData?.category?.toString()
                )?.title ?? "",
            },
            {
              title: "Получатели",
              value: cargoData?.receivers
                ?.map((r: any) => r?.receiver)
                ?.join("/"),
            },
          ],
        },
        {
          classes: "info-block",
          data: [
            {
              title: t("cargo.dateKnr1"),
              value: formatDate(cargoData?.knr_storage_date, true, t),
            },
            {
              title: t("cargo.startKranDate"),
              value: cargoData?.start_kran_storage_date
                ? formatDate(cargoData?.start_kran_storage_date, true, t)
                : "-",
            },
            {
              title: t("cargo.endKranDate"),
              value: cargoData?.end_kran_storage_date
                ? formatDate(cargoData?.start_kran_storage_date, true, t)
                : "-",
            },
            {
              title: t("cargo.arriveDateKz1"),
              value: cargoData?.date_arrival_storage
                ? formatDate(cargoData?.date_arrival_storage, true, t)
                : "-",
            },
            {
              title: t("cargo.leftDateKz1"),
              value: cargoData?.date_arrival_сustom
                ? formatDate(cargoData?.date_arrival_сustom, true, t)
                : "-",
            },
            // { title: "Дата подачи на КЗ: 10?.10?.2022", value: "10?.10?.2022" },
            // { title: "Дата отправки документов: 10?.10?.2022", value: "10?.10?.2022" },
            // { title: "Дата завершения проверки документов: 10?.10?.2022", value: "10?.10?.2022" },
            // { title: "Дата выезда из таможник: 10?.10?.2022", value: "10?.10?.2022" }
          ],
        },
      ],
    },
    {
      title: t("cargoPrice.delivery"),
      infoItems: [
        {
          classes: "",
          data: [
            {
              title: t("cargo.shippingAddressSearch"),
              value: `${cargoData?.shipping_address} ${
                countries?.find(
                  (country: Option) =>
                    country.value === cargoData.unloading_country?.toString()
                )?.title ?? ""
              }`,
            },
            {
              title: t("cargo.unloadingAddress"),
              value: `${cargoData?.unloading_address} ${
                countries?.find(
                  (country: Option) =>
                    country.value === cargoData.unloading_country?.toString()
                )?.title ?? ""
              }`,
            },
            {
              title: t("cargo.customsClearanceAddress"),
              value: `${cargoData?.customs_clearance_address} ${
                countries?.find(
                  (country: Option) =>
                    country.value ===
                    cargoData.customs_clearance_country?.toString()
                )?.title ?? ""
              }`,
            },
            { title: t("cargo.tirNumber"), value: cargoData?.tir_id },
            // { title: "ФИО водителя:", value: "Джейдон Санчо Нито" },
            // { title: "Номер водителя:", value: "x03021bbd" },
            // { title: "Номер машины:", value: "920112300222" },
            // { title: "ИИН", value: "321323" },
            // { title: "Номер удв", value: "321323" },
            // { title: "Номер Тех паспорта", value: "321323" },
          ],
        },
      ],
    },
  ];

  return (
    <StyledInfo>
      <div className="info-item">
        <div className="bold">{t("cargo.category")}</div>
        <div>
          {categories?.find(
            (opt: Option) => opt.value === cargoData?.category?.toString()
          )?.title ?? ""}
        </div>
      </div>
      {cargoData?.tir_id && (
        <div className="info-item flex">
          <div className="bold">{t("cargo.tirNumber")}</div>
          <div className="badge">{cargoData?.tir_id}</div>
        </div>
      )}
      {cargoData?.seal_id && (
        <div className="info-item flex">
          <div className="bold">{t("cargo.sealNumber")}</div>
          <div className="badge">{cargoData?.seal_id}</div>
        </div>
      )}

      <ToggleCard title={t("cargo.about")}>
        <>
          {formatedAboutData.map((infoItem, i) => (
            <React.Fragment key={i}>
              {i !== 0 && <InfoDivider title={infoItem.title} />}
              {infoItem.infoItems.map((infoItem, j: number) => (
                <InfoCard
                  key={j}
                  data={infoItem.data}
                  classes={infoItem.classes}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      </ToggleCard>
    </StyledInfo>
  );
};

const StyledInfo = styled.div`
  font-family: "Archivo", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #414249;
  .bold {
    font-weight: 600;
    font-size: 13px;
    color: #09101d;
  }
  .info-item {
    margin-bottom: 24px;
  }
  .info-item.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .badge {
    padding: 2px 4px;
    background: #11bb8d;
    border-radius: 12px;
    font-weight: 600;
    font-size: 10px;
    color: #ffffff;
    width: max-content;
  }
  .subtitle {
    text-align: right;
  }
`;
