import styled from "styled-components";
import minusIcon from "../../assets/images/icons/input-minus.svg";
import plusIcon from "../../assets/images/icons/input-plus.svg";

interface Props {
  counterValue: number;
  onChangeCounterValue: (value: number) => void;
}

export const Counter = ({ counterValue, onChangeCounterValue }: Props) => {
  const handleChangeCounterInput = (value: string) => {
    if (value?.length === 0) {
      onChangeCounterValue(0);
    } else if (value?.length > 1 && value[0] === "0") {
      onChangeCounterValue(Number(value?.substring(1)));
    } else {
      onChangeCounterValue(Number(value));
    }
  };

  return (
    <StyledCounter>
      <img
        src={minusIcon}
        alt=""
        onClick={() =>
          onChangeCounterValue(counterValue === 0 ? 0 : counterValue - 1)
        }
      />
      <input
        type="number"
        value={counterValue.toString()}
        onChange={(e) => handleChangeCounterInput(e.target.value)}
      />
      <img
        src={plusIcon}
        alt=""
        onClick={() => onChangeCounterValue(counterValue + 1)}
      />
    </StyledCounter>
  );
};

const StyledCounter = styled.div`
  input {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #b1b1b1;
    text-align: center;
    width: 45px;
    cursor: inherit !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  img {
    margin: 0 !important;
    cursor: pointer;
  }
`;
