import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "../../../components/Input/Input";
import {
  useLazyStoreKpiQuery,
  useLazyUpdateKpiQuery,
} from "../../../store/cargo/cargo.api";
import { getDashDate } from "../../../helpers/date";
import { Modal } from "../../../components/Modal/Modal";

interface Props {
  open: boolean;
  onClose: (isUpdateData?: boolean) => void;
  selectedKpi: any | null;
}

const HOURS_NUM_INIT = "";
const WORKERS_INIT = "";
const DATE_CREATE_INIT = getDashDate();

export const KpiModal = ({ open, onClose, selectedKpi }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hoursNum, setHoursNum] = useState<string>(HOURS_NUM_INIT);
  const [workers, setWorkers] = useState<string>(WORKERS_INIT);
  const [dateCreate, setDateCreate] = useState<string>(DATE_CREATE_INIT);
  const [storeKpi] = useLazyStoreKpiQuery();
  const [updateKpi] = useLazyUpdateKpiQuery();

  const handleCloseModal = (isSucces?: boolean) => {
    setHoursNum(HOURS_NUM_INIT);
    setWorkers(WORKERS_INIT);
    setDateCreate(DATE_CREATE_INIT);
    onClose(isSucces);
    setLoading(false);
  };

  const handleAdd = () => {
    setLoading(true);
    console.log("here");
    const data = {
      date_create: dateCreate,
      hours_num: hoursNum,
      workers,
    };
    storeKpi(data).then((response) => {
      setLoading(false);
      if (response.status === "fulfilled") {
        cogoToast.success("KPI успешно добавлено", {
          hideAfter: 3,
          position: "top-right",
        });
        handleCloseModal(true);
      } else {
        cogoToast.error("Ошибка добавления KPI");
      }
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: selectedKpi.id,
      date_create: dateCreate,
      hours_num: hoursNum,
      workers,
    };
    updateKpi(data).then((response) => {
      setLoading(false);
      if (response.status === "fulfilled") {
        cogoToast.success("KPI успешно изменено", {
          hideAfter: 3,
          position: "top-right",
        });
        handleCloseModal(true);
      } else {
        cogoToast.error("Ошибка изменения KPI");
      }
    });
  };

  useEffect(() => {
    if (selectedKpi) {
      console.log(selectedKpi?.date_create);
      setHoursNum(selectedKpi?.hours_num ?? HOURS_NUM_INIT);
      setWorkers(selectedKpi?.workers ?? WORKERS_INIT);
      setDateCreate(
        selectedKpi?.date_create
          ? getDashDate(selectedKpi?.date_create)
          : DATE_CREATE_INIT
      );
    }
  }, [selectedKpi]);

  return (
    <Modal
      open={open}
      title="Вводные для KPI трудосилы"
      onCancel={handleCloseModal}
      onSubmit={!!selectedKpi ? handleUpdate : handleAdd}
      cancelBtnText="Отмена"
      submitBtnText={!!selectedKpi ? "Сохранить" : "Добавить"}
      disabledSubmit={loading}
    >
      <StyledKpiModal>
        <div className="kpi-inputs">
          <Input
            value={hoursNum}
            onChange={(value) => setHoursNum(value)}
            placeholder="К-во часов"
            number
          />
          <Input
            value={workers}
            onChange={(value) => setWorkers(value)}
            placeholder="К-во работников"
            number
          />
        </div>
        <Input
          value={dateCreate}
          onChange={(value) => setDateCreate(value)}
          placeholder="Дата"
          calendar
        />
      </StyledKpiModal>
    </Modal>
  );
};

const StyledKpiModal = styled.div`
  .kpi-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
`;
