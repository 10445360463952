// @ts-expect-error
import Board from "react-trello";
import { Card } from "./Card/Card";
import { useEffect, useState } from "react";
import { Header } from "./Header";
import styled from "styled-components";
import {
  useGetKanbanCargosQuery,
  useLazyChangeCargosPositionQuery,
  useLazyChangeCargoStatusQuery,
} from "../../store/canban/canban.api";
import cogoToast from "cogo-toast";
import { Loading } from "./Loading";
import { useAppSelect } from "../../hooks/redux";
import { formatKanbanData, handleUpdateDataAfterDraging } from "./boardHelpers";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

export const CargosBoard = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const { user } = useAppSelect((state) => state.auth);
  const [changeCargoStatus] = useLazyChangeCargoStatusQuery();
  const [changeCargosPosition] = useLazyChangeCargosPositionQuery();
  // const {
  //   data: canbanCargos,
  //   isLoading,
  //   refetch,
  // } = useGetKanbanCargosQuery(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCargos, setSelectedCargos] = useState<number[]>([]);
  const [search, setSearch] = useState<string>("");
  const isFirstLoad = useRef(true);

  const handleSearch = (value: string) => setSearch(value);

  // const handleRemoveSelectedCargo = (cargoId: number) =>
  //   setSelectedCargos(selectedCargos.filter((c) => c !== cargoId));
  // const handleSelectCargo = (cargoId: number) =>
  //   selectedCargos.includes(cargoId)
  //     ? handleRemoveSelectedCargo(cargoId)
  //     : setSelectedCargos([...selectedCargos, cargoId]);
  // const handleClearSelectedCargos = () => {
  //   setSelectedCargos([]);
  //   setTimeout(refetch, 500);
  // };

  // function handleEndOverload(laneId: string, cardId: number, lastData: any) {
  //   setData(
  //     lastData.map((column: any) =>
  //       column.id === laneId
  //         ? {
  //             ...column,
  //             cards: column.cards.map((c: any) =>
  //               c.id === cardId
  //                 ? { ...c, start_overload_date: null, draggable: true }
  //                 : c
  //             ),
  //           }
  //         : column
  //     )
  //   );
  // }

  // function handleEndStorage(laneId: string, cardId: number, lastData: any) {
  //   setData(
  //     lastData.map((column: any) =>
  //       column.id === laneId
  //         ? {
  //             ...column,
  //             cards: column.cards.map((c: any) =>
  //               c.id === cardId
  //                 ? { ...c, start_storage_date: null, draggable: true }
  //                 : c
  //             ),
  //           }
  //         : column
  //     )
  //   );
  // }

  // const handleErrorDragCargo = (lastData: any) => {
  //   setLoading(false);
  //   cogoToast.error("Ошибка перемещения груза", {
  //     hideAfter: 3,
  //     position: "top-right",
  //   });
  //   setData(lastData.map((column: any) => column));
  // };

  // const handleSaveCargosPosition = (columnId: string, updateData: any) => {
  //   if (search.length === 0) {
  //     const selectedColumn = updateData.find(
  //       (column: any) => column.id === columnId
  //     );
  //     const cargos = selectedColumn.cards.map((card: any, i: number) => ({
  //       id: card.id,
  //       position: i,
  //     }));
  //     changeCargosPosition({ status: columnId, cargos })
  //       .then((resp) => {
  //         if (resp.status === "fulfilled") {
  //           cogoToast.success("Груз успешно перемещен", {
  //             hideAfter: 3,
  //             position: "top-right",
  //           });
  //           refetch();
  //           setLoading(false);
  //         } else {
  //           setLoading(false);
  //         }
  //       })
  //       .catch(() => handleErrorDragCargo(updateData));
  //   } else {
  //     cogoToast.success("Груз успешно перемещен", {
  //       hideAfter: 3,
  //       position: "top-right",
  //     });
  //     refetch();
  //   }
  //   setLoading(false);
  // };

  // const handleUpdateData = (
  //   lastData: any,
  //   fromColumn: any,
  //   toColumn: any,
  //   id: number,
  //   index: number,
  //   card: any
  // ) => {
  //   const updateData = handleUpdateDataAfterDraging(
  //     lastData,
  //     fromColumn,
  //     toColumn,
  //     id,
  //     index,
  //     card
  //   );
  //   setData(updateData);
  //   handleSaveCargosPosition(toColumn, updateData);
  // };

  // const handleDragEnd = (
  //   id: number,
  //   fromColumn: string,
  //   toColumn: string,
  //   index: number,
  //   card: any
  // ) => {
  //   let lastData: any = data;
  //   if (card.position === index && toColumn === fromColumn) {
  //   } else if (card.draggable) {
  //     setLoading(true);
  //     changeCargoStatus({ cargo_id: id, status: toColumn, position: index })
  //       .then((resp) => {
  //         if (resp.status === "fulfilled") {
  //           handleUpdateData(lastData, fromColumn, toColumn, id, index, card);
  //         } else {
  //           setLoading(false);
  //           handleErrorDragCargo(lastData);
  //         }
  //       })
  //       .catch(() => handleErrorDragCargo(lastData));
  //   } else {
  //     setData(lastData.map((column: any) => column));
  //   }
  // };

  // const handleFormatCanbanCargosData = (cargosData: any) =>
  //   setData(formatKanbanData(cargosData, user, t));

  // useEffect(() => {
  //   if (canbanCargos?.response && user && isFirstLoad.current) {
  //     isFirstLoad.current = false;
  //     handleFormatCanbanCargosData(canbanCargos?.response);
  //   }
  // }, [canbanCargos, user]);

  // useEffect(() => {
  //   if (canbanCargos?.response && user) {
  //     isFirstLoad.current = false;
  //     handleFormatCanbanCargosData(canbanCargos?.response);
  //   }
  // }, [canbanCargos]);

  return (
    <StyledCargosBoard>
      {/* <Header
        selectedCargos={selectedCargos}
        onClearSelectedCargos={handleClearSelectedCargos}
        search={search}
        onSearch={handleSearch}
      />
      <div className="board-wrapper">
        <Loading loading={loading || isLoading} />
        <Board
          data={{
            lanes: data.map((column: any) => ({
              ...column,
              cards: column.cards.filter((c: any) =>
                c.hs.toLowerCase().includes(search.toLowerCase())
              ),
            })),
          }}
          handleDragEnd={handleDragEnd}
          components={{
            Card: (props: any) => (
              <Card
                {...props}
                onEndOverload={handleEndOverload}
                onEndStorage={handleEndStorage}
                data={data}
                onSelect={handleSelectCargo}
                selectedCargos={selectedCargos}
                onRefreshData={refetch}
              />
            ),
          }}
        />
      </div> */}
    </StyledCargosBoard>
  );
};

const StyledCargosBoard = styled.div`
  .react-trello-board {
    background: none;
  }
  .board-wrapper {
    position: relative;
  }
`;
