import { createSlice } from "@reduxjs/toolkit";

interface NotificationState {
  data: any;
}

const initialState: NotificationState = {
  data: null,
};

export const notificationSlice = createSlice({
  name: "notification.slice",
  initialState,
  reducers: {},
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
