import styled from "styled-components";
import { useState } from "react";
import { Documents } from "../../../components/Documents/Documents";
import { useNavigate, useParams } from "react-router";
import { QrCode } from "../../../components/QrCode";
import { Button } from "../../../components/Button/Button";
import { ArriveStatus } from "./ArriveStatus";

interface Props {
  cargoData: any;
  documents: File[];
  onSave: () => void;
  onRefreshCargoData: () => void;
}

export const Footer = ({
  documents,
  onSave,
  cargoData,
  onRefreshCargoData,
}: Props) => {
  const { cargoId } = useParams();
  const navigate = useNavigate();
  const [documnetsModal, setDocumentsModal] = useState<boolean>(false);
  const [qrcodeModal, setQrcodeModal] = useState<boolean>(false);

  const handleOpenQrCode = () => setQrcodeModal(true);

  return (
    <StyledFooter>
      <Documents
        open={documnetsModal}
        onClose={() => setDocumentsModal(false)}
        documents={documents}
        view
      />
      <QrCode
        open={qrcodeModal}
        onClose={() => setQrcodeModal(false)}
        cargoId={Number(cargoId) ?? 0}
      />
      <Button
        title="Документы"
        onClick={() => setDocumentsModal(true)}
        classes="footer-btn"
        color="blue"
      />
      <Button
        title="QR-код"
        onClick={handleOpenQrCode}
        classes="footer-btn"
        color="blue"
      />
      <Button
        title="Редактировать"
        onClick={() => navigate(`/edit-cargo/${cargoId}`)}
        classes="footer-btn"
        color="blue"
      />
      <Button
        title="Сохранить"
        onClick={onSave}
        classes="footer-btn"
        color="blue"
      />
      <ArriveStatus
        status={cargoData?.kanban_status}
        cargoArrived={cargoData?.arrived_status === 1}
        onRefreshCargoData={onRefreshCargoData}
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 2px;
  width: max-content;
  border-radius: 10px;
  .footer-btn {
    height: 46px;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    width: 122px;
    &:first-child {
      margin-right: 2px;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
    .footer-btn {
      width: 100%;
      margin-bottom: 4px;
      &:first-child {
        margin-right: 0px;
      }
    }
  }
`;
