import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal/Modal";
import { useState, useEffect } from "react";
import {
  useGetUsersRolesQuery,
  useLazyCreateUserQuery,
  useLazyEditUserQuery,
} from "../../store/admin/admin.api";
import { Input } from "../../components/Input/Input";
import cogoToast from "cogo-toast";
import { Select } from "../../components/Select/Select";
import styled from "styled-components";
import { emailValidation } from "../../helpers/validation";

interface Props {
  open: boolean;
  onClose: () => void;
  editEmployee: any | null;
  onRefreshData: () => void;
}

interface Errors {
  name: null | string;
  email: null | string;
  telegram?: null | string;
  phone?: null | string;
}

export const EmployeeModal = ({
  open,
  onClose,
  editEmployee,
  onRefreshData,
}: Props) => {
  const { t } = useTranslation("common");
  const { data: usersRoles } = useGetUsersRolesQuery(null);
  const [editUser] = useLazyEditUserQuery();
  const [createUser] = useLazyCreateUserQuery();
  const [name, setName] = useState<string>("");
  const [telegram, setTelegram] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<string>("1");
  const [errors, setErrors] = useState<Errors>({
    name: null,
    email: null,
    telegram: null,
    phone: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeName = (value: string) => {
    setName(value);
    setErrors({ ...errors, name: null });
  };

  const handleChangePhone = (value: string) => {
    setPhone(value);
    setErrors({ ...errors, phone: null });
  };

  const handleValidateEmail = () => {
    setErrors({
      ...errors,
      email: emailValidation(email) ? "Введите корректный email" : null,
    });
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
    handleValidateEmail();
  };

  const handleSubmit = () => {
    setErrors({ name: null, email: null });

    if (name.length > 0 && email.length > 0) {
      setLoading(true);
      editUser({
        name,
        role_id: role,
        user_id: editEmployee.id,
        phone,
        telegram,
      }).then((response: any) => {
        setLoading(false);
        if (response.status === "fulfilled") {
          onClose();
          onRefreshData();
          cogoToast.success(t("employees.employeeChanged"), {
            hideAfter: 3,
            position: "top-right",
          });
        } else {
          const responseErrors = response.error?.data?.errors;
          setErrors(responseErrors);
        }
      });
    } else {
      setErrors({
        name: name.length === 0 ? t("commom.fillField") : null,
        email:
          !errors.email && email.length === 0 ? t("commom.fillField") : null,
      });
    }
  };

  const handleInvite = () => {
    setErrors({ name: null, email: null });

    if (name.length > 0 && email.length > 0) {
      setLoading(true);
      createUser({ email, name, role_id: role }).then((response: any) => {
        setLoading(false);
        if (response.status === "fulfilled") {
          onClose();
          setName("");
          setEmail("");
          cogoToast.success(t("employees.employeeInvited"), {
            hideAfter: 3,
            position: "top-right",
          });
        } else {
          const responseErrors = response.error?.data?.errors;
          setErrors(responseErrors);
        }
      });
    } else {
      setErrors({
        name: name.length === 0 ? t("common.fillField") : null,
        email:
          !errors.email && email.length === 0 ? t("common.fillField") : null,
      });
    }
  };

  useEffect(() => {
    setName(editEmployee?.name ?? "");
    setTelegram(editEmployee?.telegram ?? "");
    setEmail(editEmployee?.email ?? "");
    setPhone(editEmployee?.phone ?? "");
    setRole(editEmployee?.role_id?.toString() ?? "1");
  }, [editEmployee]);

  return (
    <StyledEmployeeModal>
      <Modal
        title={t(
          !!editEmployee ? "employees.editingEmployee" : "employees.inviting"
        )}
        open={open}
        onCancel={onClose}
        onSubmit={() => (!!editEmployee ? handleSubmit() : handleInvite())}
        cancelBtnText={t("common.cancel")}
        submitBtnText={t(
          !!editEmployee ? "common.save" : "employees.inviteBtn"
        )}
        disabledSubmit={
          loading ||
          (!editEmployee && email.length === 0) ||
          (!editEmployee && !!errors.email)
        }
      >
        <div className="content">
          <div className="input-group">
            <Input
              placeholder={t("settings.nameAndSurname")}
              value={name}
              onChange={handleChangeName}
              error={!!errors.name}
              errorMessage={errors.name}
            />
            <Input
              placeholder={t("settings.telegram")}
              value={telegram}
              onChange={() => null}
              error={!!errors.telegram}
              errorMessage={errors.telegram}
              classes="input"
              disabled
            />
          </div>
          <div className="input-group">
            <Input
              placeholder={t("settings.email")}
              value={email}
              onChange={(value) =>
                !!editEmployee ? null : handleChangeEmail(value)
              }
              error={!!errors.email}
              errorMessage={errors.email}
              disabled={!!editEmployee}
            />
            <Input
              placeholder={t("settings.phone")}
              value={phone}
              onChange={handleChangePhone}
              error={!!errors?.phone}
              errorMessage={errors?.phone}
              classes="input"
            />
          </div>
          <Select
            label={"Выбор роли"}
            value={role}
            options={usersRoles ?? []}
            onSelectOption={(opt) => setRole(opt.value)}
            classes="select"
            placeholder="Найти роль"
          />
        </div>
      </Modal>
    </StyledEmployeeModal>
  );
};

const StyledEmployeeModal = styled.div`
  .input {
    margin-bottom: 15px;
  }
  .input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .modal-content {
    overflow: inherit !important;
  }
`;
