import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const auth = createApi({
  reducerPath: "auth/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    login: build.query<any, { login: string; password: string }>({
      query: ({ login, password }) => ({
        url: "auth/login",
        method: "POST",
        params: { login, password },
      }),
      transformErrorResponse: handleError,
    }),
    getUser: build.query({
      query: () => ({
        url: "auth/user",
        headers: headers(),
      }),
    }),
    forgotPassword: build.query<any, string>({
      query: (email) => ({
        url: "auth/forgot-password",
        method: "POST",
        params: { email },
      }),
      transformErrorResponse: handleError,
    }),
    checkVefificationCode: build.query<any, { email: string; kod: string }>({
      query: ({ email, kod }) => ({
        url: "auth/forgot-password-verification-code",
        method: "POST",
        params: { email, kod },
      }),
      transformErrorResponse: handleError,
    }),
    resetPassword: build.query<
      any,
      { email: string; password: string; password_confirmation: string }
    >({
      query: ({ email, password, password_confirmation }) => ({
        url: "auth/reset-password",
        method: "POST",
        params: { email, password, password_confirmation },
      }),
      transformErrorResponse: handleError,
    }),
    changePassword: build.query<
      any,
      { old_password: string; password: string; password_confirmation: string }
    >({
      query: ({ old_password, password, password_confirmation }) => ({
        url: "auth/password-change",
        method: "POST",
        headers: headers(),
        params: { old_password, password, password_confirmation },
      }),
    }),
    changeLanguage: build.query<
      any,
      { user_id: number | string | undefined | null; lang: string }
    >({
      query: ({ user_id, lang }) => ({
        url: "user/language-update",
        method: "POST",
        headers: headers(),
        params: { user_id, lang },
      }),
      transformErrorResponse: handleError,
    }),
  }),
});

export const {
  useLazyLoginQuery,
  useGetUserQuery,
  useLazyForgotPasswordQuery,
  useLazyCheckVefificationCodeQuery,
  useLazyResetPasswordQuery,
  useLazyChangePasswordQuery,
  useLazyChangeLanguageQuery,
} = auth;
