import styled from "styled-components";
import { File } from "../../File";

interface Props {
  documents: any[];
}

export const Files = ({ documents }: Props) => (
  <StyledFiles>
    {documents.map((doc, i) => (
      <File
        key={i}
        name={doc.name}
        size={doc.size}
        documentLink={doc.document}
      />
    ))}
  </StyledFiles>
);

const StyledFiles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
  margin-bottom: 9px;
  gap: 9px 138px;
  div {
    border-bottom: none;
  }
  @media (max-width: 1400px) {
    gap: 9px 40px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
