import styled from "styled-components";
import { Details } from "./Details/Details";
import { AboutCargo } from "./AboutCargo";
import { AdditionalInfo } from "./AdditionalInfo/AdditionalInfo";
import { Footer } from "./Footer/Footer";

interface Props {
  formData: any;
  onUpdateFormData: (data: any) => void;
  documents: File[];
  onAddDocuments: (data: File[]) => void;
  onRemoveDocument: (index: number) => void;
  errors: any;
}

export const MainInfoTest = ({
  formData,
  onUpdateFormData,
  documents,
  onAddDocuments,
  onRemoveDocument,
  errors,
}: Props) => {
  return (
    <>
      <StyledMainInfo>
        <Details
          formData={formData}
          onUpdateFormData={onUpdateFormData}
          errors={errors}
        />
        <AboutCargo formData={formData} onUpdateFormData={onUpdateFormData} />
        <AdditionalInfo
          formData={formData}
          onUpdateFormData={onUpdateFormData}
        />
      </StyledMainInfo>
      <Footer
        documents={documents}
        onAddDocuments={onAddDocuments}
        onRemoveDocument={onRemoveDocument}
        formData={formData}
        onUpdateFormData={onUpdateFormData}
      />
    </>
  );
};

const StyledMainInfo = styled.div`
  display: grid;
  grid-template-columns: minmax(500px, 1fr) minmax(370px, 23%) minmax(
      370px,
      23%
    );
  gap: 20px;
  margin-bottom: 22px;
  .manage-cargo-info-card {
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    padding: 30px;
    background: #fff;
    overflow: auto;
    height: calc(100vh - 131px - 30px - 144px - 72px);
  }
  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
    overflow: auto;
    height: calc(100vh - 131px - 30px - 144px - 72px);
    .manage-cargo-info-card {
      height: max-content;
    }
  }
  @media (max-width: 1000px) {
    height: max-content;
  }
`;
