import styled from "styled-components";
import { Actions } from "./Actions";
import { Paginator } from "../../../components/Paginator/Paginator";
import {
  CONFIRM_INIT,
  Confirm,
  ConfirmInterface,
} from "../../../components/Confirm/Confirm";
import { useState } from "react";
import cogoToast from "cogo-toast";
import { TableData } from "../../../models/models";
import { useLazyTeamsStartOverloadQuery } from "../../../store/teams/teams.api";
import { AppointCargo } from "../AppointCargo";
import axios from "axios";
import { baseUrl } from "../../../api/baseUrl";
import { headers } from "../../../api/headers";

interface Props {
  selected: { title: string; id: number }[];
  onClearSelected: () => void;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  loading: boolean;
  onSelectCargo: (title: string, cargoId: number) => void;
  onRefreshData: () => void;
  teams: any[];
}

export const Footer = ({
  selected,
  onClearSelected,
  tableData,
  onChangeTableData,
  loading,
  onSelectCargo,
  onRefreshData,
  teams,
}: Props) => {
  const [startCargosOverload] = useLazyTeamsStartOverloadQuery();
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [startOverloadModal, setStartOverloadModal] = useState<boolean>(false);

  const handleStartOveload = (cargo_id: string) => {
    startCargosOverload(
      selected.map((s: { title: string; id: number }) => ({
        command_id: s.id,
        cargo_id,
      }))
    ).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Перегрузку успешно начато", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
        onClearSelected();
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleChangeCargosOveload = (
    type: "start" | "end",
    cargoId?: string
  ) => {
    setComfirmData(CONFIRM_INIT);
    if (type === "start") {
      cargoId && handleStartOveload(cargoId);
    } else {
      Promise.all(
        selected.map((s: { title: string; id: number }) => {
          const { history_id } = teams.find((t: any) => t.id === s.id);
          if (history_id) {
            return axios.post(`${baseUrl}command/end-cargo-overload`, null, {
              headers: headers(),
              params: { history_id },
            });
          }
          return null;
        })
      )
        .then((resp: any) => {
          cogoToast.success("Успешно завершено перегрузку", {
            hideAfter: 3,
            position: "top-right",
          });
          onRefreshData();
          onClearSelected();
        })
        .catch(() => {
          cogoToast.error("Ошибка", {
            hideAfter: 3,
            position: "top-right",
          });
        });
    }
  };

  const handleToggleOveload = (type: "start" | "end", cargoId?: string) => {
    setComfirmData({
      open: true,
      title: `Вы точно хотите ${
        type === "start" ? "начать" : "завершить"
      } перегрузку для выбраных групп`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => handleChangeCargosOveload(type, cargoId),
    });
  };

  const handleAdditionalActions = (type: string) => {
    switch (type) {
      case "end_overload":
        return handleToggleOveload("end");
      case "start_overload":
        return setStartOverloadModal(true);
    }
  };

  return (
    <>
      <AppointCargo
        open={startOverloadModal}
        onClose={() => setStartOverloadModal(false)}
        onSubmit={(cargoId: string) => handleToggleOveload("start", cargoId)}
      />
      <Confirm
        {...confirmData}
        items={selected}
        onRemoveItem={onSelectCargo}
        closeOnEmptyItems
      />
      <StyledFooter>
        <Actions
          selected={selected}
          onClearSelected={onClearSelected}
          onAction={handleAdditionalActions}
        />
        <Paginator
          tableData={tableData}
          onChangeTableData={onChangeTableData}
          loading={loading}
          classes="pagination"
        />
      </StyledFooter>
    </>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: 50px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 20px;
    justify-items: center;
  }
`;
