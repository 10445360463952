import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Option, Select } from "../../components/Select/Select";

interface Props {
  selectedBroker: string | undefined;
  onSelectBroker: (value: undefined | string) => void;
  brokers: Option[];
  onCreate: () => void;
}

export const Header = ({
  selectedBroker,
  onSelectBroker,
  brokers,
  onCreate,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledHeader>
      <PageHeader title={t("pages.brokers")} classes="header-title" />
      <div className="btns">
        <Select
          options={brokers}
          value={selectedBroker}
          onSelectOption={(opt: Option) =>
            onSelectBroker(opt.value === selectedBroker ? undefined : opt.value)
          }
          classes="select-company"
          label="Выбор брокера"
          placeholder="Найти брокера"
        />
        <Button
          title={t("brokers.add")}
          onClick={onCreate}
          color="blue"
          classes="btn"
        />
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .header-title {
    margin: 0;
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .select-company {
    margin-right: 20px;
    width: 240px;
  }
  .btn {
    max-width: 240px;
    height: 50px;
    background: #4f75f4;
    width: 240px;
    flex-shrink: 0;
  }
  @media (max-width: 1000px) {
    .btns {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .btn,
    .select-company {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 650px) {
    .btns {
      grid-template-columns: 1fr;
    }
  }
`;
