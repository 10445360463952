import { RefObject } from "react";
import styled from "styled-components";

interface Props {
  value: string;
  onChange: (value: string) => void;
  innerRef: RefObject<HTMLTextAreaElement>;
}

export const AddComment = ({ value, onChange, innerRef }: Props) => {
  return (
    <StyledAddComment>
      <textarea
        placeholder="Напишите комментарий..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        ref={innerRef}
      />
    </StyledAddComment>
  );
};

const StyledAddComment = styled.div`
  height: 100px;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: 2px;
  background: #fff;
  padding: 35px 30px 20px;
  margin-bottom: 20px;
  textarea {
    width: 100%;
    resize: none;
    color: #575756;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 0 13px 10px 10px;
    height: 48px;
    &::placeholder {
      color: #b1b1b1;
      font-family: Roboto, sans-serif;
    }
    &:placeholder-shown {
      height: 20px;
      border-bottom: 1px solid #ececec;
    }
  }
`;
