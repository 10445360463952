import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../Button/Button";
import { useActions } from "../../hooks/actions";
import { useAppSelect } from "../../hooks/redux";
import { useLazyEditMyColumnsQuery } from "../../store/admin/admin.api";
import { ColumnOption, COLUMN_OPTIONS } from "../../store/cargo/cargo.slice";
import { Dropdown } from "./Dropdown";

interface Props {
  classes?: string | undefined;
}

export const ColumnFilter = ({ classes }: Props) => {
  const btnRef = useRef<any>(null);
  const { setCargoColumns } = useActions();
  const { cargoColumns } = useAppSelect((state) => state.cargo);
  const { user } = useAppSelect((state) => state.auth);
  const [saveMyColumns] = useLazyEditMyColumnsQuery();
  const { t } = useTranslation("common");

  const handleFocusBtn = () => btnRef.current.focus();

  const handleToggleCheckbox = (option: ColumnOption, optionIndex: number) => {
    const updatedColumns = cargoColumns.map((column: ColumnOption, i: number) =>
      i === optionIndex ? { ...column, show: !column.show } : column
    );
    setCargoColumns(updatedColumns);
    saveMyColumns({
      user_id: user?.id,
      columns: JSON.stringify(updatedColumns),
    });
  };

  useEffect(() => {
    if (user?.columns) {
      const parsedColumns = JSON.parse(
        user?.columns ?? JSON.stringify(COLUMN_OPTIONS)
      );
      setCargoColumns(parsedColumns);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleReset = () => {
    setCargoColumns(COLUMN_OPTIONS);
    saveMyColumns({
      user_id: user?.id,
      columns: JSON.stringify(COLUMN_OPTIONS),
    });
  };

  return (
    <StyledColumnFilter
      className={`${classes}`}
      onClick={handleFocusBtn}
      ref={btnRef}
    >
      <Button title={t("cargos.myColumns")} color="blue" />
      <Dropdown
        options={cargoColumns}
        onSelect={handleToggleCheckbox}
        onReset={handleReset}
      />
    </StyledColumnFilter>
  );
};

const StyledColumnFilter = styled.button`
  position: relative;
  z-index: 3;
  &:focus > .column-filter-dropdown {
    opacity: 1;
    visibility: visible;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
  }
`;
