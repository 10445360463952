import { getDashDate } from "../../../helpers/date";
import editIcon from "../../../assets/images/icons/edit-icon.svg";
interface Props {
  dateCreate: string;
  hoursSum: number;
  workers: number;
  onEdit: () => void;
}

export const Row = ({ dateCreate, hoursSum, workers, onEdit }: Props) => {
  return (
    <tr>
      <td>
        <img
          src={editIcon}
          alt=""
          onClick={onEdit}
          className="cursor-pointer"
        />
      </td>
      <td>{getDashDate(dateCreate)}</td>
      <td>{hoursSum}</td>
      <td>{workers}</td>
    </tr>
  );
};
