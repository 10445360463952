import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { SwitchView } from "./SwitchView";

interface Props {
  viewType: string;
  onChangeViewType: (type: string) => void;
}

export const Buttons = ({ viewType, onChangeViewType }: Props) => (
  <StyledButtons>
    <SwitchView value={viewType} onChange={onChangeViewType} />
    <Button
      title="Добавить виджет"
      onClick={() => null}
      classes="add-btn"
      color="dark-blue"
    />
  </StyledButtons>
);

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  .add-btn {
    height: 50px;
    width: 240px;
    margin-left: 22px;
  }
`;
