import styled from "styled-components";
import { Header } from "./Header";
import { Loading } from "./Loading";

interface Props {
  columns: {
    title: string;
    classes?: string;
    onSort?: () => void;
    active: boolean;
    down: boolean;
    noSort?: boolean;
  }[];
  children: React.ReactNode;
  loading?: boolean;
  classes?: string;
  pageSize: number;
  onChangePageSize?: (value: number) => void;
  onSelectAllCargos?: () => void;
  isAllSelected?: boolean;
  onRemove?: () => void;
  manageColumns?: boolean;
  hideManage?: boolean;
  hideCheckbox?: boolean;
  hideDelete?: boolean;
}

export const Table = ({
  columns,
  children,
  loading,
  classes,
  pageSize,
  onChangePageSize,
  onSelectAllCargos,
  isAllSelected,
  onRemove,
  manageColumns,
  hideManage,
  hideCheckbox,
  hideDelete,
}: Props) => {
  return (
    <StyledTable className={classes} loading={loading}>
      <table>
        <Header
          columns={columns}
          pageSize={pageSize}
          onChangePageSize={onChangePageSize}
          onSelectAllCargos={onSelectAllCargos}
          isAllSelected={isAllSelected}
          onRemove={onRemove}
          manageColumns={manageColumns}
          hideManage={hideManage}
          hideCheckbox={hideCheckbox}
          hideDelete={hideDelete}
        />
        <Loading loading={loading} />
        {loading ? null : children}
      </table>
    </StyledTable>
  );
};

interface StyledTableProps {
  loading?: boolean;
}

const StyledTable = styled.div<StyledTableProps>`
  padding: 2px;
  background: #fff;
  border-radius: 20px;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #575756;
  ${(props) => props.loading && "height: 80vh;"}
  position: relative;
  table {
    width: 100%;
    border-spacing: 0;
    overflow: auto;
    border-radius: 10px;
  }
  td {
    text-align: center;
    height: 50px;
    padding: 18px;
    border-bottom: 1px solid #f5f5f5;
    background: #ffff;
    a {
      color: #575756;
    }
  }
  .selected {
    td {
      background: #f5f5f5;
    }
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  .priority {
    td {
      background: #94e18c;
    }
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10p 0;
    }
  }
  .bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
  @media (min-width: 1800px) {
    .bold,
    td,
    th {
      font-size: 17px;
    }
  }
`;
