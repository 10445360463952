import styled from "styled-components";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Content } from "./Content";

interface Props {
  title: string;
  cancelBtnText?: string;
  onCancel: () => void;
  submitBtnText?: string;
  onSubmit: () => void;
  children: React.ReactNode;
  open: boolean;
  disabledSubmit?: boolean;
  classes?: string;
  noFooter?: boolean;
}

export const Modal = ({
  title,
  cancelBtnText,
  onCancel,
  submitBtnText,
  onSubmit,
  children,
  open,
  disabledSubmit,
  classes,
  noFooter,
}: Props) => {
  const handleClickOverlay = (e: any) => {
    const isClickInOverlay = e.target.classList.contains("overlay");
    isClickInOverlay && onCancel();
  };

  return (
    <StyledModal open={open} onClick={handleClickOverlay} className="overlay">
      <div className={`modal ${classes}`}>
        <Header title={title} />
        <Content children={children} noFooter={noFooter} />
        {!noFooter && (
          <Footer
            cancelBtnText={cancelBtnText}
            onCancel={onCancel}
            submitBtnText={submitBtnText}
            onSubmit={onSubmit}
            disabledSubmit={disabledSubmit}
          />
        )}
      </div>
    </StyledModal>
  );
};

interface StyledModalProps {
  open: boolean;
}

const StyledModal = styled.div<StyledModalProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(87, 87, 86, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  .modal {
    max-width: 500px;
    width: 90%;
    background: #ffff;
    border-radius: 20px;
    border: 1px solid #f5f5f5;
  }
`;
