import styled from "styled-components";
import { Counter } from "./Counter";
import { Title } from "./Title";

interface Props {
  title: string;
  price: number;
  discount?: string;
  paidValue?: string;
  onChangeField: (fieldName: string, value: number) => void;
  remainder: number;
}

export const Row = ({
  title,
  price,
  onChangeField,
  discount,
  paidValue,
  remainder,
}: Props) => (
  <>
    <Title title={title} />
    <Counter
      value={price}
      onChange={(value: number) => onChangeField("price", value)}
    />
    {discount !== undefined ? (
      <Counter
        value={Number(discount)}
        onChange={(value: number) => onChangeField("discount", value)}
      />
    ) : (
      <div />
    )}
    {paidValue !== undefined ? (
      <Counter
        value={Number(paidValue)}
        onChange={(value: number) => onChangeField("paidValue", value)}
      />
    ) : (
      <div />
    )}
    <StyledRow>{remainder.toFixed(2)}</StyledRow>
  </>
);

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 25px;
`;
