import styled from "styled-components";

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => <StyledTitle>{title}</StyledTitle>;

const StyledTitle = styled.div`
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #575756;
  margin-left: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
  @media (min-width: 1800px) {
    font-size: 30px;
  }
`;
