import { useState, useEffect } from "react";
import styled from "styled-components";
import { useLazyGetCompaniesQuery } from "../../store/companies/companies.api";
import { CompaniesTable } from "./CompaniesTable";
import { CompanyModal } from "./CompanyModal";
import { Header } from "./Header";
import { TableData } from "../../models/models";

export const Companies = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [editingCompany, setEditingCompany] = useState<null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [getCompanies, { data }] = useLazyGetCompaniesQuery();
  const [tableData, setTableData] = useState<TableData>({
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });
  const [selectedCompany, setSelctedCompany] = useState<undefined | string>(
    undefined
  );
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });

  const handleGetCompanies = () => {
    setLoading(true);
    getCompanies({
      sortBy: sort?.field?.length > 0 ? sort.field : undefined,
      sortDesc: sort?.desc,
    }).then((resp: any) => {
      setLoading(false);
      // console.log(resp?.data.response);
    });
  };

  useEffect(() => {
    handleGetCompanies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleGetCompanies();
    // eslint-disable-next-line
  }, [sort]);

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  const handleChangeSelectedCompany = (value: undefined | string) =>
    setSelctedCompany(value);

  const handleChangeTableData = (data: TableData) => setTableData(data);

  const handleCloseModal = () => {
    setModal(false);
    setEditingCompany(null);
  };
  const handleCreateCompany = () => setModal(true);
  const handleEditCompany = (company: any) => {
    setEditingCompany(company);
    setModal(true);
  };

  return (
    <StyledCompanies>
      <CompanyModal
        open={modal}
        onClose={handleCloseModal}
        onRefreshData={handleGetCompanies}
        editingCompany={editingCompany}
      />
      <Header
        onCreateCompany={handleCreateCompany}
        companies={
          data?.response?.map((c: any) => ({
            title: c.title,
            value: c.id?.toString(),
          })) ?? []
        }
        selectedCompany={selectedCompany}
        onSelectCompany={handleChangeSelectedCompany}
      />
      <CompaniesTable
        data={
          data
            ? data?.response?.filter((c: any) =>
                selectedCompany ? c.id?.toString() === selectedCompany : true
              )
            : []
        }
        loading={loading}
        onEditCompany={handleEditCompany}
        onRefreshData={handleGetCompanies}
        tableData={tableData}
        onChangeTableData={handleChangeTableData}
        sort={sort}
        onSort={handleSort}
      />
    </StyledCompanies>
  );
};

const StyledCompanies = styled.div``;
