import styled from "styled-components";
import prevIcon from "../../../assets/images/icons/year-back.svg";
import nextIcon from "../../../assets/images/icons/year-next.svg";

interface Props {
  value: Date;
  onChangeYear: (year: number) => void;
}
export const YearSelect = ({ value, onChangeYear }: Props) => {
  const handleGetYear = (d: Date): number => {
    const date = new Date(d);
    const year = date.getFullYear();

    return year;
  };

  const handleChangeYear = (type: "prev" | "next") => {
    let year = handleGetYear(value);

    if (type === "prev") {
      year -= 1;
    } else {
      year += 1;
    }

    onChangeYear(year);
  };

  return (
    <StyledYearSelect>
      <img src={prevIcon} alt="" onClick={() => handleChangeYear("prev")} />
      <div className="year">{handleGetYear(value)}</div>
      <img src={nextIcon} alt="" onClick={() => handleChangeYear("next")} />
    </StyledYearSelect>
  );
};

const StyledYearSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
  img {
    cursor: pointer;
  }
  .year {
    margin: 0 8px;
    color: #575756;
    font-size: 12px;
    font-weight: 700;
  }
`;
