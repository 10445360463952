import styled from "styled-components";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { Select } from "../../../components/Select/Select";
import { SwitchView } from "./SwitchView";
import { Button } from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyGetCompaniesQuery } from "../../../store/companies/companies.api";
import { useAppSelect } from "../../../hooks/redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface Props {
  viewType: string;
  onChangeViewType: (type: string) => void;
  selectedClient: string | undefined;
  onChangeSelectedClient: (value: string | undefined) => void;
}

export const Header = ({
  viewType,
  onChangeViewType,
  selectedClient,
  onChangeSelectedClient,
}: Props) => {
  const { t } = useTranslation("common");
  const [getCompanies] = useLazyGetCompaniesQuery();
  const [clients, setClients] = useState([]);
  const { user } = useAppSelect((state) => state.auth);
  const isAvaibleCreateBtn =
    user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 13;

  useEffect(() => {
    getCompanies({}).then((resp: any) =>
      setClients(
        resp.data.response.map((c: any) => ({
          title: c.title,
          value: c.id.toString(),
        }))
      )
    );
  }, []);

  return (
    <StyledHeader>
      <PageHeader title={t("pages.cargos")} />
      <div className="btns">
        <Select
          label="Выбор клиента"
          options={clients}
          value={selectedClient}
          onSelectOption={(opt) =>
            onChangeSelectedClient(
              opt.value === selectedClient ? undefined : opt.value
            )
          }
          wrapperClasses="select-client"
          placeholder="Найти клиента по имени"
        />
        <SwitchView viewType={viewType} onChangeViewType={onChangeViewType} />
        {isAvaibleCreateBtn && (
          <Link to="/create-cargo" className="create-link">
            <Button
              title={t("pages.createCargo")}
              onClick={() => null}
              color="blue"
              classes="create-btn"
            />
          </Link>
        )}
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btns {
    display: flex;
    align-items: center;
    .create-btn {
      width: 240px;
      height: 50px;
      flex-shrink: 0;
    }
  }
  @media (max-width: 700px) {
    .btns {
      display: grid;
      grid-template-columns: 1fr 110px;
      width: 100%;
      gap: 20px;
    }
    .create-link {
      grid-row: 1/2;
      grid-column: 1/3;
      .create-btn {
        width: 100%;
      }
    }
    .select-client {
      min-width: 100px !important;
    }
  }
`;
