import { useState, KeyboardEvent } from "react";
import styled from "styled-components";
import { Buttons } from "./Buttons";
import { Label } from "../Label/Label";
import { Counter } from "./Counter";
import { Message } from "./Message";
import { Hint } from "../Hint/Hint";

interface Props {
  label?: string;
  value?: string | number;
  name?: string;
  placeholder?: string;
  onChange: (value: any) => void;
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLElement>) => void;
  error?: boolean;
  errorMessage?: string | undefined | null;
  successMessage?: string | undefined | null;
  password?: boolean;
  classes?: string;
  action?: string | undefined;
  actionOnClick?: () => void | undefined;
  disabled?: boolean | undefined;
  outline?: boolean | undefined;
  mobile?: boolean | undefined;
  counter?: boolean | undefined;
  counterValue?: number;
  onChangeCounterValue?: (value: number) => void;
  counterMeasure?: string | undefined;
  number?: boolean | undefined;
  autofocus?: boolean | undefined;
  labelClasses?: undefined | string;
  max?: number;
  noClear?: boolean | undefined;
  onFocus?: () => void;
  calendar?: boolean;
  noInput?: boolean;
}

export const Input = ({
  label,
  value,
  name,
  placeholder,
  onChange,
  onBlur,
  onKeyDown,
  error,
  errorMessage,
  successMessage,
  password,
  classes,
  action,
  actionOnClick,
  disabled,
  outline,
  mobile,
  counter,
  counterValue,
  onChangeCounterValue,
  counterMeasure,
  number,
  autofocus,
  labelClasses,
  max,
  noClear,
  onFocus,
  calendar,
  noInput,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const handleToggleShow = () => setShow(!show);

  return (
    <StyledInputWrapper className={classes} outline={outline} error={error}>
      {label && <Label label={label} labelClasses={labelClasses} />}
      <StyledInput
        error={error}
        disabled={disabled || counter}
        className="input-wrapper"
        mobile={mobile}
      >
        {!noInput && (
          <input
            type={
              password && !show
                ? "password"
                : calendar
                ? "date"
                : number
                ? "number"
                : "text"
            }
            value={name ? undefined : counter ? "" : value ? value : ""}
            onChange={(e) => (disabled ? null : onChange(e.target.value))}
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : null)}
            onBlur={onBlur}
            disabled={disabled || counter}
            placeholder={placeholder}
            autoFocus={!!autofocus}
            max={max ?? undefined}
            onFocus={() => (onFocus ? onFocus() : null)}
          />
        )}
        {counter && onChangeCounterValue && (
          <Counter
            counterValue={counterValue ?? 0}
            onChangeCounterValue={onChangeCounterValue}
          />
        )}
        {value && typeof value === "string" && (
          <Buttons
            value={value}
            onChange={onChange}
            password={password}
            show={show}
            onToggleShow={handleToggleShow}
            action={action}
            actionOnClick={actionOnClick}
            disabled={disabled}
            noClear={noClear}
          />
        )}
      </StyledInput>
      {error && errorMessage && <Message message={errorMessage} error />}
      {!error && successMessage && <Message message={successMessage} success />}
    </StyledInputWrapper>
  );
};

interface StyledInputWrapperProps {
  outline?: boolean | undefined;
  error?: boolean | undefined;
}

const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  @media (min-width: 400px) {
    ${(props) =>
      props.outline &&
      `
      padding: 8px 16px 8px;
      border: 1px solid ${props.error ? "#FBB0BF" : "#E5E5EA"};
      border-radius: 8px;
      margin-bottom: 24px;
      input {
        padding: 0px !important;
        border: none;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 147% !important;
        color: #3A3A3C !important;
      }
      .input-wrapper {
        border: none;
        position: relative;
      }
  `}
  }
`;

interface StyledInputProps {
  error?: boolean;
  disabled?: boolean | undefined;
  mobile?: boolean | undefined;
}

const StyledInput = styled.div<StyledInputProps>`
  display: flex;
  align-items: center;
  transition: all 0.3s;
  border-bottom: 1px solid ${(props) => (props.error ? "#FBB0BF" : "#ECECEC")};
  input {
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    width: 100%;
    padding: 10px;
    ${(props) => props.disabled && "cursor: no-drop;"}
    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #b1b1b1;
    }
  }
  img {
    cursor: pointer;
  }
  div {
    display: flex;
    align-items: center;
    img:nth-child(2) {
      margin-left: 16px;
    }
  }
  ${(props) =>
    props.mobile &&
    `
    border-radius: 2px !important;
    background: #FAFAFB !important;
    border-radius: 15px !important;
    border-bottom: none !important;
    padding-right: 10px;
    ${props.error && "border: 2px solid #FBB0BF !important;"}  
    input {
      font-family: 'Archivo', sans-serif !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      line-height: 140% !important;
      color: #09101D !important;
      padding: 12px 16px !important;
    }
  `}
`;
