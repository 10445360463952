import styled from "styled-components";
import { Button } from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";

interface Props {
  onAddReceiver: () => void;
  onOpenDocuments: () => void;
}

export const Footer = ({ onAddReceiver, onOpenDocuments }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledFooter>
      <Button
        title={t("cargo.addReceiver")}
        onClick={onAddReceiver}
        classes="footer-btn"
        color="blue"
      />
      <Button
        title="Документы"
        onClick={onOpenDocuments}
        classes="footer-btn"
        color="blue"
      />
      <Button
        title="Сохранить данные авто"
        onClick={() => null}
        classes="footer-btn"
        color="blue"
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 2px;
  width: max-content;
  border-radius: 10px;
  .footer-btn {
    height: 46px;
    padding: 13px 0 18px;
    width: 164px;
    font-size: 12px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    &:first-child {
      margin-right: 2px;
    }
  }
`;
