import { useAppSelect } from "../../../hooks/redux";
import { Content } from "./Content";

export const Edit = () => {
  const { user } = useAppSelect((state: any) => state.auth);

  return (
    <div>
      {user ? (
        <>
          <Content />
        </>
      ) : null}
    </div>
  );
};
