import styled from "styled-components";
import { useAppSelect } from "../../hooks/redux";
import { FOOTER_LINKS, handleGetUserLinksByRole } from "./links";
import { SidebarLink } from "./SidebarLink";

interface Props {
  open: boolean;
  onSupportOpen: () => void;
  onEditProfile: () => void;
}

export const MainLinks = ({ open, onSupportOpen, onEditProfile }: Props) => {
  const { user } = useAppSelect((state) => state.auth);
  const links = handleGetUserLinksByRole(user?.role_id);

  return (
    <StyledMainLinks>
      {links.map((link, i) => (
        <SidebarLink
          key={i}
          name={link.name}
          link={link.link}
          icon={link.icon}
          isNotMobile={link?.isNotMobile}
          open={open}
          classes={i === links.length - 1 ? "last-link" : ""}
        />
      ))}

      {FOOTER_LINKS.map((link, i) => (
        <SidebarLink
          key={i}
          name={link.name}
          link={link.link}
          icon={link.icon}
          isNotMobile={link?.isNotMobile}
          open={open}
          classes="footer-link"
          onClick={() =>
            link.support
              ? onSupportOpen()
              : link.profile
              ? onEditProfile()
              : null
          }
        />
      ))}
    </StyledMainLinks>
  );
};

const StyledMainLinks = styled.div`
  @media (min-width: 1000px) {
    .last-link {
      margin-bottom: 0 !important;
    }
    .footer-link {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;
