import styled from "styled-components";
import { User } from "./User";
import { formatDate, formatTime } from "../../../helpers/date";
import { useTranslation } from "react-i18next";

interface Props {
  nickname: string;
  avatar: string | null;
  roleId: number;
  date: Date;
  statusTitle: string;
  success?: boolean;
}

export const HistoryRow = ({
  nickname,
  avatar,
  roleId,
  date,
  statusTitle,
  success,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledHistoryRow>
      <td>
        <User avatar={avatar} nickname={nickname} />
      </td>
      <td>
        <div className="date">{formatDate(date, true)}</div>
      </td>
      <td className="action-text">{statusTitle}</td>
      <td> {t(success ? "common.success" : "common.error")}</td>
    </StyledHistoryRow>
  );
};

const StyledHistoryRow = styled.tr`
  .action-text {
    text-align: left;
    color: #575756;
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .date {
    line-height: 1.2;
  }
`;
