import { ActionButton } from "./ActionButton";
import cleanIcon from "../../assets/images/icons/clean.svg";
import { PasswordToggle } from "./PasswordToggle";
import styled from "styled-components";

interface Props {
  value: string;
  onChange: (value: string) => void;
  password?: boolean;
  action?: string | undefined;
  actionOnClick?: () => void | undefined;
  show: boolean;
  onToggleShow: () => void;
  disabled?: boolean | undefined;
  noClear?: boolean | undefined;
}

export const Buttons = ({
  value,
  onChange,
  password,
  show,
  onToggleShow,
  action,
  actionOnClick,
  disabled,
  noClear,
}: Props) => (
  <StyledButtons>
    {password ? (
      <PasswordToggle onToggleShow={onToggleShow} show={show} />
    ) : null}
    {value?.length > 0 && !action && !disabled && !noClear && (
      <img
        src={cleanIcon}
        alt=""
        onClick={() => onChange("")}
        className="clean-btn"
      />
    )}
    {action && action?.length > 0 && (
      <ActionButton
        label={action}
        onClick={() => (actionOnClick ? actionOnClick() : undefined)}
        disabled={value.length === 0}
      />
    )}
  </StyledButtons>
);

const StyledButtons = styled.div`
  .img {
    width: 14px;
  }
`;
