import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { useLazyGetBrokersQuery } from "../../../store/brokers/brokers.api";
import { Select } from "../../../components/Select/Select";
import { Option } from "../../../components/Select/Options";
import styled from "styled-components";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedBroker: string) => void;
}

export const ClientOnCheckModal = ({ open, onClose, onSubmit }: Props) => {
  const [getBrokers] = useLazyGetBrokersQuery();
  const [brokers, setBrokers] = useState<any>([]);
  const [selectedBroker, setSelectedBroker] = useState<string | undefined>(
    undefined
  );

  const handleClose = () => {
    setSelectedBroker(undefined);
    onClose();
  };

  useEffect(() => {
    if (open) {
      getBrokers({}).then((resp: any) =>
        setBrokers(
          resp.data.response
            ? resp.data.response.map((broker: any) => ({
                title: broker.title ?? "",
                value: broker.id.toString(),
              }))
            : []
        )
      );
    }
  }, [open]);

  const handleSubmit = () => {
    if (selectedBroker) {
      onSubmit(selectedBroker);
      handleClose();
    }
  };

  return (
    <StyledClientOnCheckModal>
      <Modal
        open={open}
        title="Отправка груза на проверку"
        onCancel={handleClose}
        onSubmit={handleSubmit}
        cancelBtnText="Отмена"
        submitBtnText="Отправить"
        disabledSubmit={selectedBroker?.length === 0}
      >
        <Select
          options={brokers}
          label="Выберите брокера"
          onSelectOption={(opt: Option) => setSelectedBroker(opt.value)}
          value={selectedBroker}
          wrapperClasses="select-broker"
        />
      </Modal>
    </StyledClientOnCheckModal>
  );
};

const StyledClientOnCheckModal = styled.div`
  .modal-content {
    overflow: unset;
  }
`;
