import cogoToast from "cogo-toast";
import { CostForm } from "../../../CostForm/CostForm";
import { Modal } from "../../../Modal/Modal";
import { baseUrl } from "../../../../api/baseUrl";
import {
  handleCheckEmptyFields,
  handleCheckTariffEmptyFields,
} from "../../../../helpers/cargo";
import { useEffect, useRef, useState } from "react";
import { useGetDefaultCostsQuery } from "../../../../store/admin/admin.api";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { headers } from "../../../../api/headers";
import { Total } from "./Total";
import styled from "styled-components";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const EditCargoPrice = ({ open, onClose }: Props) => {
  const { t } = useTranslation("common");
  const { data } = useGetDefaultCostsQuery(null);
  const [costData, setCostData] = useState<any>([]);
  const initCostData = useRef([]);

  useEffect(() => {
    setCostData(data?.response ?? []);
    initCostData.current = data?.response ?? [];
  }, [data]);

  const handleChangePriceData = (id: number, data: any) => {
    setCostData(
      costData.map((cost: any) => (cost.id === id ? data : { ...cost }))
    );
  };

  const handleSave = () => {
    const isAllFieldsNotEmpty = handleCheckTariffEmptyFields(costData, t);
    if (isAllFieldsNotEmpty) {
      let saved = 0;
      costData.forEach((cost: any) => {
        axios
          .post(`${baseUrl}admin/update-default-cost`, cost, {
            headers: headers(),
          })
          .then((resp: any) => {
            if (resp.data.code === 200) {
              saved += 1;
              if (saved === costData.length) {
                cogoToast.success(t("cargo.cargoPriceChanged"), {
                  hideAfter: 3,
                  position: "top-right",
                });
                initCostData.current = costData;
                onClose();
              }
            }
          })
          .catch(() =>
            cogoToast.error(t("cargo.cargoPriceChangeError"), {
              hideAfter: 3,
              position: "top-right",
            })
          );
      });
    }
  };

  return (
    <Modal
      open={open}
      title="Настройка стоимости услуг"
      cancelBtnText={t("common.cancel")}
      submitBtnText={t("common.save")}
      onCancel={onClose}
      onSubmit={handleSave}
    >
      <div>
        <CostForm
          title={t("cargoPrice.delivery")}
          data={costData?.find((cost: any) => cost.type === "delivery")}
          onChangeData={handleChangePriceData}
        />
        <CostForm
          title={t("cargoPrice.overload")}
          data={costData?.find((cost: any) => cost.type === "overload")}
          onChangeData={handleChangePriceData}
        />
        <CostForm
          title={t("cargoPrice.declaration")}
          data={costData?.find((cost: any) => cost.type === "declaration")}
          onChangeData={handleChangePriceData}
          noDiscount
        />
        <CostForm
          title={"Доп. заезд"}
          data={costData?.find(
            (cost: any) => cost.type === "additional_arrival"
          )}
          onChangeData={handleChangePriceData}
          noDiscount
        />
        <CostForm
          title={t("cargoPrice.storage")}
          data={costData?.find((cost: any) => cost.type === "saving")}
          onChangeData={handleChangePriceData}
        />
        <CostForm
          title={t("cargoPrice.specTech")}
          data={costData?.find(
            (cost: any) => cost.type === "special_equipment"
          )}
          onChangeData={handleChangePriceData}
        />
        <Total costData={costData} />
      </div>
    </Modal>
  );
};

const StyledEditCargoPrice = styled.div``;
