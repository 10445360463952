import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const companies = createApi({
  reducerPath: "companies/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    getCompanies: build.query<
      any,
      { sortBy?: string | undefined; sortDesc?: boolean | undefined }
    >({
      query: ({ sortBy, sortDesc }) => ({
        url: "client/get-clients",
        headers: headers(),
        params: { sortBy, sortDesc },
      }),
      transformErrorResponse: handleError,
    }),
    createCompany: build.query<
      any,
      {
        title: string;
        cod: string;
        contract_number: string;
        email: string;
        password: string;
        cost_delivery: string;
        cost_declaration: string;
        cost_storage: string;
        cost_overload: string;
        responsible: string;
        bin: string;
        inn: string;
        comment: string;
      }
    >({
      query: ({
        title,
        cod,
        contract_number,
        email,
        password,
        cost_delivery,
        cost_declaration,
        cost_storage,
        cost_overload,
        responsible,
        bin,
        inn,
        comment,
      }) => ({
        url: "client/create-client",
        headers: headers(),
        method: "POST",
        params: {
          title,
          cod,
          contract_number,
          email,
          password,
          cost_delivery,
          cost_declaration,
          cost_storage,
          cost_overload,
          responsible,
          bin,
          inn,
          comment,
        },
      }),
    }),
    editCompany: build.query<
      any,
      {
        client_id: number;
        title: string;
        cod: string;
        contract_number: string;
        email: string;
        cost_delivery: string;
        cost_declaration: string;
        cost_storage: string;
        cost_overload: string;
        responsible: string;
        bin: string;
        inn: string;
        comment: string;
      }
    >({
      query: ({
        client_id,
        title,
        cod,
        contract_number,
        email,
        cost_delivery,
        cost_declaration,
        cost_storage,
        cost_overload,
        responsible,
        bin,
        inn,
        comment,
      }) => ({
        url: "client/update-client",
        headers: headers(),
        method: "POST",
        params: {
          client_id,
          title,
          cod,
          contract_number,
          email,
          cost_delivery,
          cost_declaration,
          cost_storage,
          cost_overload,
          responsible,
          bin,
          inn,
          comment,
        },
      }),
    }),
    deleteCompany: build.query<any, number>({
      query: (id) => ({
        url: "client/delete-client",
        headers: headers(),
        method: "DELETE",
        params: { id },
      }),
    }),
    deleteCompanies: build.query<any, number[]>({
      query: (clients) => ({
        url: "client/delete-clients",
        headers: headers(),
        method: "DELETE",
        body: { clients },
      }),
    }),
  }),
});

export const {
  useLazyGetCompaniesQuery,
  useLazyCreateCompanyQuery,
  useLazyEditCompanyQuery,
  useLazyDeleteCompanyQuery,
  useLazyDeleteCompaniesQuery,
} = companies;
