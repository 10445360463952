import styled from "styled-components";
import { useLazyGetCompaniesQuery } from "../../store/companies/companies.api";
import { useEffect, useState } from "react";
import { Select } from "../../components/Select/Select";

interface Props {
  selectedClient: string | undefined;
  onSelectClient: (clientId: string) => void;
}

export const Search = ({ selectedClient, onSelectClient }: Props) => {
  const [getCompanies] = useLazyGetCompaniesQuery();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getCompanies({}).then((resp: any) =>
      setClients(
        resp.data.response.map((c: any) => ({
          title: c.title,
          value: c.id.toString(),
        }))
      )
    );
  }, []);

  return (
    <StyledSearch>
      <Select
        options={clients}
        onSelectOption={(opt) => onSelectClient(opt.value)}
        value={selectedClient}
        search
        outline
        wrapperClasses="search-clients"
        placeholder="Найти клиента..."
      />
    </StyledSearch>
  );
};

const StyledSearch = styled.div`
  .search-clients {
    margin-bottom: 0 !important;
    background: #f2f3f7 !important;
    max-width: 400px;
  }
  @media (max-width: 1000px) {
    .search-clients {
      max-width: 100%;
    }
  }
`;
