import styled from "styled-components";
import { Link } from "react-router-dom";
import logoIcon from "../../assets/images/icons/logo.png";

export const Logo = () => (
  <StyledLogo>
    <Link to="/">
      <img src={logoIcon} alt="" />
    </Link>
  </StyledLogo>
);

const StyledLogo = styled.div`
  a {
    text-decoration: none;
    img {
      width: 246px;
    }
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;
