import styled from "styled-components";
import { useState, useEffect } from "react";
import { Documents } from "../../../components/Documents/Documents";
import { Button } from "../../../components/Button/Button";

interface Props {
  cargoData: any;
}

export const Footer = ({ cargoData }: Props) => {
  const [documnetsModal, setDocumentsModal] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>([]);

  useEffect(() => {
    if (cargoData) {
      let receiversFiles: any = [];
      cargoData.receivers.forEach((receiver: any) =>
        receiversFiles.push(...receiver.documents)
      );
      setDocuments(receiversFiles);
    }
  }, [cargoData]);

  return (
    <StyledFooter>
      <Documents
        open={documnetsModal}
        onClose={() => setDocumentsModal(false)}
        documents={documents}
        view
      />
      <Button
        title="Документы"
        onClick={() => setDocumentsModal(true)}
        classes="footer-btn"
        color="blue"
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 2px;
  width: max-content;
  border-radius: 10px;
  .footer-btn {
    height: 46px;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    width: 236px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    .footer-btn {
      width: 100%;
    }
  }
`;
