import styled from "styled-components";
import { Title } from "./Title";
import plusIcon from "../../../../../assets/images/icons/input-plus.svg";

interface Props {
  title: string;
  value: string[];
  onChange: (value: string[]) => void;
}

export const Header = ({ title, value, onChange }: Props) => {
  const handleAddValue = () => onChange([...value, ""]);

  return (
    <StyledHeader>
      <Title title={title} />
      <img src={plusIcon} alt="" onClick={handleAddValue} />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
  img {
    cursor: pointer;
  }
`;
