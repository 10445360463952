import styled from "styled-components";

interface Props {
  procent: number;
}

export const Progres = ({ procent = 0 }: Props) => (
  <StyledProgres procent={procent > 100 ? 100 : procent}>
    {((procent >= 10 && procent <= 48) || (procent >= 60 && procent <= 97)) && (
      <span>{procent.toFixed(0)}%</span>
    )}
  </StyledProgres>
);

const StyledProgres = styled.div<Props>`
  position: absolute;
  height: 3px;
  background: #94e18c;
  border-radius: 3px;
  width: ${(props) => props.procent}%;
  span {
    position: absolute;
    right: 0;
    top: calc(100% + 17px);
    color: #575756;
    font-family: Inter, sans-serif;
    font-size: 9px;
    font-weight: 400;
  }
`;
