import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Checkbox } from "../Checkbox/Checkbox";
import { ManageColumns } from "./ManageColumns/ManageColumns";
import deleteIcon from "../../assets/images/icons/delete-icon.svg";
import { Arrow } from "./Arrow";
interface Props {
  columns: {
    title: string;
    classes?: string;
    onSort?: () => void;
    selectCheckbox?: boolean;
    active: boolean;
    down: boolean;
    noSort?: boolean;
  }[];
  pageSize: number;
  onChangePageSize?: (value: number) => void;
  onSelectAllCargos?: () => void;
  isAllSelected?: boolean;
  onRemove?: () => void;
  manageColumns?: boolean;
  hideManage?: boolean;
  hideCheckbox?: boolean;
  hideDelete?: boolean;
}

export const Header = ({
  columns,
  pageSize,
  onChangePageSize,
  onSelectAllCargos,
  isAllSelected,
  onRemove,
  manageColumns,
  hideManage,
  hideCheckbox,
  hideDelete,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledHeader className="no-select">
      <tr>
        {!hideCheckbox && (
          <th>
            <Checkbox
              onChange={() => (onSelectAllCargos ? onSelectAllCargos() : null)}
              checked={!!isAllSelected}
            />
          </th>
        )}
        {!hideManage && (
          <th>
            <ManageColumns
              pageSize={pageSize}
              onChangePageSize={(value) =>
                onChangePageSize ? onChangePageSize(value) : null
              }
              manageColumns={manageColumns}
            />
          </th>
        )}
        {!hideDelete && (
          <th>
            <img
              src={deleteIcon}
              alt=""
              className="delete-btn"
              onClick={onRemove}
            />
          </th>
        )}
        {columns.map((column, i) => (
          <th
            key={i}
            className={column?.classes}
            onClick={() => (column?.onSort ? column.onSort() : null)}
          >
            <div>
              <div className="title">
                {t(column.title)}
                {!column.noSort && (
                  <Arrow active={column?.active} down={column.down} />
                )}
              </div>
              <div />
            </div>
          </th>
        ))}
      </tr>
    </StyledHeader>
  );
};

const StyledHeader = styled.thead`
  background: #ececec;
  height: 50px;
  tr {
    border-collapse: collapse;
  }
  th {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    background: #ececec;
    padding: 12px;
    position: sticky;
    top: -3px;
    z-index: 10;
    &:first-child {
      border-radius: 20px 0 0 20px;
      padding: 18px 12px 18px 18px;
    }
    &:last-child {
      border-radius: 0 20px 20px 0;
    }
    .title {
      display: flex;
      align-items: baseline;
      justify-content: center;
      width: max-content;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .delete-btn {
    cursor: pointer;
    margin: 0 auto;
  }
`;
