import styled from "styled-components";
import { Actions } from "./Actions";
import { Paginator } from "../../../components/Paginator/Paginator";
import {
  useLazyCargosChangeStorageQuery,
  useLazyCargosEndOverloadQuery,
  useLazyCargosNextStatusQuery,
  useLazyCargosStartOverloadQuery,
  useLazyChangeCargosPriorityQuery,
  useLazyChangeCargosVerifyStatusQuery,
  useLazyDeleteCargosQuery,
} from "../../../store/cargo/cargo.api";
import {
  CONFIRM_INIT,
  Confirm,
  ConfirmInterface,
} from "../../../components/Confirm/Confirm";
import { useState } from "react";
import cogoToast from "cogo-toast";
import { TableData } from "../../../models/models";
import { ClientOnCheckModal } from "./ClientOnCheckModal";

interface Props {
  viewType: string;
  selectedCargos: { title: string; id: number }[];
  onClearSelectedCargos: () => void;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  loading: boolean;
  onSelectCargo: (title: string, cargoId: number) => void;
  activeTabIndex: number;
}

export const Footer = ({
  viewType,
  selectedCargos,
  onClearSelectedCargos,
  tableData,
  onChangeTableData,
  loading,
  onSelectCargo,
  activeTabIndex,
}: Props) => {
  const [changePriorities] = useLazyChangeCargosPriorityQuery();
  const [deleteCargos] = useLazyDeleteCargosQuery();
  const [changeCargosStorage] = useLazyCargosChangeStorageQuery();
  const [startCargosOverload] = useLazyCargosStartOverloadQuery();
  const [endCargosOverload] = useLazyCargosEndOverloadQuery();
  const [cargosNextStatus] = useLazyCargosNextStatusQuery();
  const [changeCargosVerifyStatus] = useLazyChangeCargosVerifyStatusQuery();
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [clientOnCheckModal, setClientOnCheckModal] = useState<boolean>(false);

  const handleToogleCargoPrioritate = (priority: number) => {
    changePriorities({
      cargos: selectedCargos.map((c) => c.id),
      priority,
    })
      .then(() => {
        onClearSelectedCargos();
        cogoToast.success("Успех", {
          hideAfter: 3,
          position: "top-right",
        });
      })
      .catch(() => {
        onClearSelectedCargos();
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      });
  };

  const handleChangeCargosStorage = (type: "start" | "end") => {
    changeCargosStorage({
      cargo_id: selectedCargos.map((c) => ({ id: c.id })),
      type,
    })
      .then(() => {
        onClearSelectedCargos();
        cogoToast.success("Успех", {
          hideAfter: 3,
          position: "top-right",
        });
      })
      .catch(() => {
        onClearSelectedCargos();
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      });
  };

  const handleChangeCargosOveload = (type: "start" | "end") => {
    if (type === "start") {
      startCargosOverload({
        cargo_id: selectedCargos.map((c) => ({ id: c.id })),
        command_history_id: 1,
      })
        .then(() => {
          onClearSelectedCargos();
          cogoToast.success("Успех", {
            hideAfter: 3,
            position: "top-right",
          });
        })
        .catch(() => {
          onClearSelectedCargos();
          cogoToast.error("Ошибка", {
            hideAfter: 3,
            position: "top-right",
          });
        });
    } else {
      endCargosOverload({
        cargo_id: selectedCargos.map((c) => ({ id: c.id })),
      })
        .then(() => {
          onClearSelectedCargos();
          cogoToast.success("Успех", {
            hideAfter: 3,
            position: "top-right",
          });
        })
        .catch(() => {
          onClearSelectedCargos();
          cogoToast.error("Ошибка", {
            hideAfter: 3,
            position: "top-right",
          });
        });
    }
  };

  const handleCargosNextStatus = () => {
    cargosNextStatus({
      cargo_id: selectedCargos.map((cargo) => ({ id: cargo.id })),
      status: "request",
    })
      .then(() => {
        onClearSelectedCargos();
        setComfirmData(CONFIRM_INIT);
        cogoToast.success("Успех", {
          hideAfter: 3,
          position: "top-right",
        });
      })
      .catch(() => {
        onClearSelectedCargos();
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      });
  };

  const handleToggleStorage = (type: "start" | "end") => {
    setComfirmData({
      open: true,
      title: `Вы точно хотите ${
        type === "start" ? "начать" : "завершить"
      } хранение для выбраных грузов`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => handleChangeCargosStorage(type),
    });
  };

  const handleToggleOveload = (type: "start" | "end") => {
    setComfirmData({
      open: true,
      title: `Вы точно хотите ${
        type === "start" ? "начать" : "завершить"
      } перегрузку для выбраных грузов`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => handleChangeCargosOveload(type),
    });
  };

  const handleChangeCargosVerifyStatus = (status: number, user_id?: string) => {
    changeCargosVerifyStatus({
      status,
      cargo_id: selectedCargos.map((s) => s.id),
      user_id,
    })
      .then(() => {
        onClearSelectedCargos();
        setComfirmData(CONFIRM_INIT);
        cogoToast.success("Успех", {
          hideAfter: 3,
          position: "top-right",
        });
      })
      .catch(() => {
        onClearSelectedCargos();
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      });
  };
  const handleToggleClientOnCheking = () => {
    setComfirmData({
      open: true,
      title: `Вы точно хотите отправить на проверку выбранные грузы`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => {
        setComfirmData(CONFIRM_INIT);
        setClientOnCheckModal(true);
      },
    });
  };

  const handleToggleBrokerCheking = (status: number) => {
    setComfirmData({
      open: true,
      title: `Вы точно хотите ${
        status === 1 ? "подтвердить" : "отклонить"
      } выбранные грузы`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => handleChangeCargosVerifyStatus(status),
    });
  };

  const handleApproveMyCargos = () => {
    setComfirmData({
      open: true,
      title: `Вы точно хотите подтвердить выбранные грузы`,
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: handleCargosNextStatus,
    });
  };

  const handleAdditionalActions = (type: string) => {
    switch (type) {
      case "end_overload":
        return handleToggleOveload("end");
      case "start_storage":
        return handleToggleStorage("start");
      case "end_storage":
        return handleToggleStorage("end");
      case "priority":
        return handleToogleCargoPrioritate(1);
      case "not_priority":
        return handleToogleCargoPrioritate(0);
      case "on_checking_clients_cargos":
        return handleToggleClientOnCheking();
      case "broker_cheking_accept":
        return handleToggleBrokerCheking(2);
      case "broker_cheking_decline":
        return handleToggleBrokerCheking(3);
      case "approve_my_cargos":
        return handleApproveMyCargos();
    }
  };

  return (
    <>
      <Confirm
        {...confirmData}
        items={selectedCargos}
        onRemoveItem={onSelectCargo}
        closeOnEmptyItems
      />
      <ClientOnCheckModal
        open={clientOnCheckModal}
        onClose={() => setClientOnCheckModal(false)}
        onSubmit={(selectedBroker) =>
          handleChangeCargosVerifyStatus(1, selectedBroker)
        }
      />
      <StyledFooter>
        <Actions
          selectedCargos={selectedCargos}
          onClearSelectedCargos={onClearSelectedCargos}
          onAction={handleAdditionalActions}
          viewType={viewType}
          activeTabIndex={activeTabIndex}
        />
        {viewType === "table" && (
          <Paginator
            tableData={tableData}
            onChangeTableData={onChangeTableData}
            loading={loading}
            classes="pagination"
          />
        )}
      </StyledFooter>
    </>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: 50px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 20px;
    justify-items: center;
  }
`;
