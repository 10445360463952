import { getDashDate } from "../helpers/date";

export const COST_ERROR_TITLES: any = {
  delivery: "cargo.fieldDelivery",
  declaration: "cargo.fieldDelaration",
  saving: "cargo.fieldStorage",
  overload: "cargo.fieldOverload",
};

export const statusesTitle: string[] = [
  "",
  "knr1",
  "knr2",
  "status3",
  "status4",
  "status5",
  "status6",
  "",
  "yama",
];

export interface Receiver {
  receiver: string;
  company: string;
  phone: string;
  car_number: string;
  driver_number: string;
  iin: string;
  udv_number: string;
  technical_passport_number: string;
  trap: string;
  track: number;
  date_departure: string | Date;
  volume: string | number;
  arrival_status: number;
}

export const RECEIVER_INIT: Receiver = {
  receiver: "",
  company: "",
  phone: "",
  car_number: "",
  driver_number: "",
  iin: "",
  udv_number: "",
  technical_passport_number: "",
  trap: "trailer",
  track: 0,
  date_departure: getDashDate(),
  volume: 0,
  arrival_status: 0,
};

export const CARGO_INIT: any = {
  company_name: "",
  company_id: null,
  ns: "",
  seal_id: [""],
  seal_storage: "",
  seal_custom_house_kz: "",
  tir_id: "",
  seal_cn: "",
  shipping_address: "",
  shipping_country: "",
  unloading_address: "",
  unloading_country: "",
  customs_clearance_address: "",
  customs_clearance_country: "",
  category: "",
  ns_cod: undefined,
  ns_number: undefined,
  loading_form: "on_pallet",
  knr_storage_date: getDashDate(),
  containers_number: 1,
  places_number: 1,
  product_volume: 1,
  weight: 1,
  declarations_number: 1,
  cargo_id: undefined,
  crm: [""],
  declaration: [""],
  driver: "",
  insurance: "",
  broker_id: "",
  receivers: [RECEIVER_INIT],
  divided_status: 0,
};

export const CARGO_REQUIRED_FIELS: any = {
  company_name: "cargo.companyName",
  ns: "cargo.hs",
  seal_id: "cargo.sealNumber",
  tir_id: "cargo.tirNumber",
  shipping_address: "cargo.shippingAddressSearch",
  shipping_country: "cargo.sendCountry",
  unloading_address: "cargo.unloadingCountry",
  unloading_country: "cargo.unloadingCountry",
  customs_clearance_address: "cargo.customsClearanceAddress",
  customs_clearance_country: "cargo.customCountry",
};
