import styled from "styled-components";
import { StatusBar } from "../../../components/StatusBar/StatusBar";
import { useTranslation } from "react-i18next";

interface Props {
  cargoData?: any;
}

export const Status = ({ cargoData }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledStatus className="card-wrapper">
      <div className="title">СТАТУС ПЕРЕДВИЖЕНИЯ</div>
      <StatusBar
        data={[
          {
            title: t("cargo.status1"),
            active:
              cargoData?.kanban_status === "request" ||
              cargoData?.kanban_status === "storage_kz" ||
              cargoData?.kanban_status === "customs" ||
              cargoData?.kanban_status === "completed",
          },
          {
            title: t("cargo.status4"),
            active:
              cargoData?.kanban_status === "storage_kz" ||
              cargoData?.kanban_status === "customs" ||
              cargoData?.kanban_status === "completed",
          },
          {
            title: t("cargo.status5"),
            active:
              cargoData?.kanban_status === "customs" ||
              cargoData?.kanban_status === "completed",
          },
          {
            title: t("cargo.status6"),
            active: cargoData?.kanban_status === "completed",
          },
        ]}
      />
    </StyledStatus>
  );
};

const StyledStatus = styled.div`
  padding: 19px 22px 20px;
  height: 100px;
  overflow: hidden;
  .title {
    text-align: center;
    margin-bottom: 8px;
    color: #575756;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
`;
