import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const canban = createApi({
  reducerPath: "canban/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    getKanbanCargos: build.query<
      any,
      {
        client_id: string | undefined;
        status: string | undefined;
        sort: number;
      }
    >({
      query: ({ client_id, status, sort }) => ({
        url: "cargo/cargos-kanban",
        headers: headers(),
        params: { client_id, status, sort },
      }),
      transformErrorResponse: handleError,
    }),
    changeCargoStatus: build.query<
      any,
      { cargo_id: number; status: string; position: number }
    >({
      query: ({ cargo_id, status, position }) => ({
        url: "cargo/change-cargo-kanban-status",
        headers: headers(),
        method: "POST",
        params: { cargo_id, status, position },
      }),
    }),
    changeCargosPosition: build.query<
      any,
      { status: string; cargos: { id: number; position: number }[] }
    >({
      query: ({ status, cargos }) => ({
        url: "cargo/change-cargo-kanban-position",
        headers: headers(),
        method: "POST",
        body: { status, cargos },
      }),
    }),
  }),
});

export const {
  useGetKanbanCargosQuery,
  useLazyGetKanbanCargosQuery,
  useLazyChangeCargoStatusQuery,
  useLazyChangeCargosPositionQuery,
} = canban;
