import { useState } from "react";
import styled from "styled-components";
import { ColumnOption } from "../../store/cargo/cargo.slice";
import { Header } from "./Header";
import { Options } from "./Options";
import { ShowMore } from "./ShowMore";

interface Props {
  options: ColumnOption[];
  onSelect: (opt: ColumnOption, optionIndex: number) => void;
  onReset: () => void;
}

export const Dropdown = ({ options, onSelect, onReset }: Props) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleToggleShowAll = () => setShowAll(true);

  return (
    <StyledDropdown className="column-filter-dropdown">
      <Header onReset={onReset} />
      <Options options={options} showAll={showAll} onSelect={onSelect} />
      {!showAll && <ShowMore onClick={handleToggleShowAll} />}
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div`
  position: absolute;
  top: 110%;
  right: 0;
  padding: 24px;
  width: 280px;
  background: #ffffff;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
`;
