import styled from "styled-components";
import clearIcon from "../../../assets/images/icons/clear-cargos-btn.svg";

interface Props {
  selectedCargos: { title: string; id: number }[];
  onClearSelectedCargos: () => void;
}

export const SelectedCargos = ({
  selectedCargos,
  onClearSelectedCargos,
}: Props) => (
  <StyledSelectedCargos>
    <div className="message">Выбрано {selectedCargos.length}</div>
    <button onClick={onClearSelectedCargos}>
      <img src={clearIcon} alt="" />
    </button>
  </StyledSelectedCargos>
);

const StyledSelectedCargos = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #575756;
  .message {
    width: 186px;
    padding: 0 13px;
  }
  button {
    height: 46px;
    width: 46px;
    border-radius: 10px;
    background: #7392f7;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 820px) {
    width: 100%;
    justify-content: space-between;
    .message {
      width: 126px;
      padding: 0 13px;
    }
  }
`;
