import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import styled from "styled-components";
import arrowLeft from "../../assets/images/icons/back-arrow.svg";

interface Props {
  classes?: string;
}

export const BackButton = ({ classes }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");

  const handleNavigateBack = () => {
    const isSuccessTelegramConnected =
      location?.pathname?.split("/")[2] === "success-telegram";
    if (isSuccessTelegramConnected) {
      navigate("/");
    } else {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        navigate("/");
      }
    }
  };

  return (
    <StyledBackButton className={classes} onClick={handleNavigateBack}>
      <img src={arrowLeft} alt="" />
    </StyledBackButton>
  );
};

const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background: #4f75f4;
  cursor: pointer;
`;
