import { Content } from "./Content";
import { AddComment } from "./AddComment";
import { Footer } from "./Footer";
import { useEffect, useRef, useState } from "react";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppSelect } from "../../hooks/redux";
import {
  useLazyGetCargoCommentsQuery,
  useLazyGetCargoIdQuery,
  useLazySaveCommentDocumentsQuery,
  useLazySaveCommentQuery,
  useLazyUpdateCommentQuery,
} from "../../store/cargo/cargo.api";
import { Documents } from "../Documents/Documents";

interface Props {
  cargoId?: number | null | string | undefined;
  onChangeCargoId?: (cargo_id: number) => void;
}

export const Comments = ({ cargoId, onChangeCargoId }: Props) => {
  const { t } = useTranslation("common");
  const [message, setMessage] = useState<string>("");
  const { cargoId: cargo_id } = useParams();
  const [documentsModal, setDocumentsModal] = useState<boolean>(false);
  const { user } = useAppSelect((state) => state.auth);
  const [saveComment] = useLazySaveCommentQuery();
  const [saveCommentDocuments] = useLazySaveCommentDocumentsQuery();
  const [updateComment] = useLazyUpdateCommentQuery();
  const [editComment, setEditComment] = useState<null | any>(null);
  const [documents, setDocuments] = useState<File[]>([]);
  const documentsData = useRef<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [getCargoComments, { data: cargoComments }] =
    useLazyGetCargoCommentsQuery();
  const [getCargoId] = useLazyGetCargoIdQuery();
  const addCommentRef = useRef<HTMLTextAreaElement>(null);

  const handleGenerateCargoId = () => {
    getCargoId(null).then((response: any) => {
      const cargoId = response?.data?.response?.cargo_id;
      if (cargoId && onChangeCargoId) {
        onChangeCargoId(cargoId);
      }
    });
  };

  useEffect(() => {
    if (!cargoId) {
      handleGenerateCargoId();
    }
    // eslint-disable-next-line
  }, [cargoId]);

  const handleChangeMessage = (value: string) => setMessage(value);

  const handleAddDocuments = (data: File[]) => {
    const updatedData = documentsData.current;
    updatedData.push(...data);
    setDocuments(documentsData.current);
  };

  const handleRemoveDocument = (index: number) => {
    documentsData.current = documentsData.current.filter((d, i) => i !== index);
    setDocuments(documentsData.current);
  };

  const handleReset = () => {
    setMessage("");
    setDocuments([]);
    documentsData.current = [];
    getCargoComments(cargoId);
  };

  const handleSendFiles = (commentId: string) => {
    const data = new FormData();
    documents.forEach((d: any, i: number) => data.append(`document[${i}]`, d));
    data.append("comment_id", commentId);
    saveCommentDocuments(data).then((response) => {
      if (response.status === "fulfilled") {
        handleReset();
        cogoToast.success(t("cargo.commentAdded"), {
          hideAfter: 3,
          position: "top-right",
        });
        setLoading(false);
      }
    });
  };

  const handleSendMessage = () => {
    setLoading(true);
    saveComment({
      cargo_id: cargoId ?? cargo_id,
      user_id: user?.id,
      comment: message,
    }).then((response: any) => {
      if (response.status === "fulfilled") {
        const commentId = response?.data?.response?.id;
        if (commentId && documents.length > 0) {
          handleSendFiles(commentId.toString());
        } else {
          handleReset();
          cogoToast.success(t("cargo.commentAdded"), {
            hideAfter: 3,
            position: "top-right",
          });
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (cargoId) {
      getCargoComments(cargoId);
    }
    // eslint-disable-next-line
  }, []);

  const handleToggleEditComment = (comment?: any) => {
    setEditComment(comment ?? null);
    setMessage(comment?.comment ?? "");
    comment?.comment && addCommentRef.current?.focus();
  };

  const handleUpdateMessage = () => {
    updateComment({ comment_id: editComment.id, comment: message }).then(
      (response: any) => {
        setLoading(false);
        if (response.status === "fulfilled") {
          handleReset();
          setEditComment(null);
          cogoToast.success("Комментарий успешно сохранен", {
            hideAfter: 3,
            position: "top-right",
          });
        } else {
          cogoToast.error("Ошибка сохранения комментария", {
            hideAfter: 3,
            position: "top-right",
          });
        }
      }
    );
  };
  return (
    <div>
      <Documents
        open={documentsModal}
        onClose={() => setDocumentsModal(false)}
        documents={documents}
        onRemoveDocument={handleRemoveDocument}
        onAddDocuments={handleAddDocuments}
        dropzone
      />
      <Content
        data={cargoComments?.response}
        onRefreshData={() => getCargoComments(cargoId)}
        onToggleEditComment={handleToggleEditComment}
        editComment={editComment}
      />
      <AddComment
        value={message}
        onChange={handleChangeMessage}
        innerRef={addCommentRef}
      />
      <Footer
        onOpenDocuments={() => setDocumentsModal(true)}
        onSendMessage={editComment ? handleUpdateMessage : handleSendMessage}
        sendDisabled={message?.length === 0 || loading}
        isEdit={!!editComment}
      />
    </div>
  );
};
