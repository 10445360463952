import { useEffect, useState } from "react";
import { Header } from "./Header/Header";
import { Tabs } from "../../components/Tabs/Tabs";
import { useLazyGetCargoStatusesQuery } from "../../store/cargo/cargo.api";
import { handleGetTabsByRole } from "../Cargos/cargosTabs";
import { useTranslation } from "react-i18next";
import { useAppSelect } from "../../hooks/redux";
import styled from "styled-components";
import { CargosTable } from "./CargosTable/CargosTable";
import { Footer } from "./Footer/Footer";
import { CargosCanban } from "./CargosCanban/CargosCanban";
import { TableData } from "../../models/models";

interface Props {
  sidebarOpen: boolean;
}

const TABLE_DATA_INIT = {
  perPage: 25,
  currentPage: 1,
  lastPage: 1,
  total: 0,
};

export const CargosNew = ({ sidebarOpen }: Props) => {
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state) => state.auth);
  const [viewType, setViewType] = useState<string>("table");
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeStatus, setActiveStatus] = useState<string | undefined>(
    undefined
  );
  const [totalCargos, setTotalCargos] = useState<number>(0);
  const [statuses, setStatuses] = useState<any>([]);
  const [getStatuses] = useLazyGetCargoStatusesQuery();
  // SELECTED CARGOS
  const [selectedCargos, setSelectedCargos] = useState<
    { title: string; id: number }[]
  >([]);
  const [tableData, setTableData] = useState<TableData>(TABLE_DATA_INIT);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const { cargoSearch } = useAppSelect((state) => state.cargo);
  const isMyCargos =
    (activeTab === 0 && user?.role_id === 13) ||
    (activeTab === 0 && user?.role_id === 1) ||
    (activeTab === 1 && user?.role_id === 3);
  const isBroketCargosOnCheking = activeTab === 0 && user?.role_id === 3;

  // GET STATUSES
  const handleGetStatuses = (clientId?: string | null | undefined) => {
    getStatuses(clientId).then((resp) =>
      setStatuses(resp?.data?.response ?? [])
    );
  };

  useEffect(() => {
    handleGetStatuses();
    // eslint-disable-next-line
  }, []);

  const handleChangeViewType = (type: string) => setViewType(type);
  const handleChangeTotalCargos = (value: number) => setTotalCargos(value);
  const handleChangeSelectedClient = (value: string | undefined) => {
    setSelectedClient(value);
    handleGetStatuses(value);
  };
  const handleChangeTableData = (data: TableData) => setTableData(data);

  const handleResetTableData = () => setTableData(TABLE_DATA_INIT);

  const handleChangeLoading = (value: boolean) => setLoading(value);

  const handleChangeTab = (tabIndex: number, tabId?: number | undefined) => {
    const selectedTabStatus = statuses.find(
      (s: any) => s.id === tabId
    )?.kanban_status;
    setActiveTab(tabIndex);
    setActiveStatus(selectedTabStatus ?? 0);
    handleResetTableData();
    setSelectedCargos([]);
    // refetch && refetch();
  };

  // CHANGE SELECTED CARGOS
  // select cargos
  const handleRemoveSelectedCargo = (cargoId: number) =>
    setSelectedCargos(selectedCargos.filter((c) => c.id !== cargoId));
  const handleSelectCargo = (title: string, cargoId: number) => {
    const isSelected = !!selectedCargos.find((c) => c.id === cargoId);
    isSelected
      ? handleRemoveSelectedCargo(cargoId)
      : setSelectedCargos([...selectedCargos, { title, id: cargoId }]);
  };
  const handleSelectAllStatusCargos = (
    cargos: { title: string; id: number }[]
  ) => setSelectedCargos(cargos);

  useEffect(() => {
    if (cargoSearch?.length > 0) {
      const isBroker = user?.role_id === 3;
      const isRoleHaveMyCargos =
        user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 13;
      if (isBroker) {
        handleChangeTab(2);
      } else if (isRoleHaveMyCargos) {
        handleChangeTab(1);
      }
    }
  }, [cargoSearch]);

  return (
    <StyledCargos>
      <Header
        viewType={viewType}
        onChangeViewType={handleChangeViewType}
        selectedClient={selectedClient}
        onChangeSelectedClient={handleChangeSelectedClient}
      />
      {viewType === "table" && (
        <Tabs
          activeTab={activeTab}
          onChangeTab={handleChangeTab}
          tabs={
            statuses
              ? handleGetTabsByRole({
                  role_id: user?.role_id,
                  t,
                  totalCargos,
                  statuses,
                })
              : []
          }
          classes="cargos-tabs"
        />
      )}

      {viewType === "table" ? (
        <CargosTable
          activeStatus={activeStatus}
          onChangeTotalCargos={handleChangeTotalCargos}
          selectedCargos={selectedCargos}
          onSelectCargo={handleSelectCargo}
          onSelectAllCargos={handleSelectAllStatusCargos}
          tableData={tableData}
          onChangeTableData={handleChangeTableData}
          loading={loading}
          onChangeLoading={handleChangeLoading}
          selectedClient={selectedClient}
          activeTabIndex={activeTab}
        />
      ) : (
        <CargosCanban
          selectedCargos={selectedCargos}
          onSelectCargo={handleSelectCargo}
          selectedClient={selectedClient}
          sidebarOpen={sidebarOpen}
        />
      )}
      <Footer
        viewType={viewType}
        selectedCargos={selectedCargos}
        onClearSelectedCargos={() => handleSelectAllStatusCargos([])}
        tableData={tableData}
        onChangeTableData={handleChangeTableData}
        loading={loading}
        onSelectCargo={handleSelectCargo}
        activeTabIndex={activeTab}
      />
    </StyledCargos>
  );
};

const StyledCargos = styled.div`
  .cargos-tabs {
    margin-bottom: 20px;
  }
`;
