import styled from "styled-components";

interface Props {
  checked: boolean;
  onChange: () => void;
}

export const RadioButton = ({ checked, onChange }: Props) => (
  <StyledRadioButton checked={checked} onClick={onChange}>
    {checked && <div />}
  </StyledRadioButton>
);

interface StyledRadioButtonProps {
  checked: boolean;
}

const StyledRadioButton = styled.div<StyledRadioButtonProps>`
  height: 14px;
  width: 14px;
  border-radius: 100%;
  border: 1px solid #d9d9d9;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    height: 4px;
    width: 4px;
    border-radius: 100%;
    background: #ffffff;
  }
  ${(props) =>
    props.checked &&
    `
    background: #7392F7;
  `}
`;
