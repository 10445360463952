export const handleCheckFileType = (fileName: string) => {
  const fileFormat = fileName?.split(".")[3];
  switch (fileFormat) {
    case "jpg":
    case "png":
    case "jpeg":
      return "image";
    case "mp3":
    case "mov":
    case "wmv":
    case "flv":
      return "video";
    default:
      return "file";
  }
};

export const handleDownload = (fileLink: string) => {
  var link = document.createElement("a");
  link.setAttribute("download", "file");
  link.href = fileLink;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
};
