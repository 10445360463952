import { Checkbox } from "../../components/Checkbox/Checkbox";
import editIcon from "../../assets/images/icons/edit-icon.svg";

interface Props {
  number: number;
  title: string;
  cod: string;
  contractNumber: string | null;
  responsible: string;
  bin: string;
  inn: string;
  comment: string;
  onEdit: () => void;
  isSelected: boolean;
  onSelect: () => void;
}

export const CompanyRow = ({
  number,
  title,
  cod,
  contractNumber,
  responsible,
  bin,
  inn,
  comment,
  onEdit,
  isSelected,
  onSelect,
}: Props) => (
  <tr className={`${isSelected && "selected"}`}>
    <td>
      <Checkbox checked={isSelected} onChange={onSelect} />
    </td>
    <td>
      <img src={editIcon} alt="" onClick={onEdit} className="cursor-pointer" />
    </td>
    {/* <td>
      <div className="bold">{number}</div>
    </td> */}
    <td>
      <div className="bold">{title}</div>
    </td>
    <td>
      <div className="bold">{cod}</div>
    </td>
    <td>
      <div>{contractNumber}</div>
    </td>
    <td>
      <div>{responsible}</div>
    </td>
    <td>
      <div>{bin}</div>
    </td>
    <td>
      <div>{inn}</div>
    </td>
    <td>
      <div>{comment}</div>
    </td>
  </tr>
);
