import styled from "styled-components";
import { handleCheckOveloadTimeColor } from "../../helpers/cargo";

interface Props {
  time: string;
}

export const TimerTime = ({ time }: Props) => {
  const color = handleCheckOveloadTimeColor(
    Number(time.split(":")[1] ?? 0),
    Number(time.split(":")[0] ?? 0)
  );

  return <StyledTimerTime color={color}>{time}</StyledTimerTime>;
};

interface StyledTimerTimeProps {
  color: string;
}

const StyledTimerTime = styled.div<StyledTimerTimeProps>`
  color: ${(props) => props.color ?? "#2E2E3A"};
`;
