import styled from "styled-components";

interface Props {
  location: string;
}

export const Message = ({ location }: Props) => (
  <StyledMessage>
    {/* <div className="title">Globalink</div> */}
    <div className="title">Ваш груз находиться на {location}</div>
  </StyledMessage>
);

const StyledMessage = styled.div`
  font-family: "Archivo", sans-serif;
  margin-bottom: 95px;
  text-align: center;
  .title {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;
    color: #09101d;
  }
  .subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #414249;
  }
`;
