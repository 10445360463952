import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const notification = createApi({
  reducerPath: "notification/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    getNotifications: build.query<
      any,
      {
        page?: number;
        role_id: number | null | undefined;
        perPage: number;
        sortBy?: string | undefined;
        sortDesc?: boolean | undefined;
      }
    >({
      query: ({ page, role_id, perPage, sortBy, sortDesc }) => ({
        url: `notification/get-notification`,
        params: { page: page ?? 1, role_id, perPage, sortBy, sortDesc },
        headers: headers(),
      }),
      transformErrorResponse: handleError,
    }),
    changeNotificationStatus: build.query<any, number>({
      query: (notification_id) => ({
        url: "notification/change-notification-status",
        headers: { ...headers() },
        method: "POST",
        params: { notification_id },
        transformErrorResponse: handleError,
      }),
    }),
    changeNotificationsStatus: build.query<any, number[]>({
      query: (notificatiions) => ({
        url: "notification/change-notifications-status",
        headers: { ...headers() },
        method: "POST",
        body: { notificatiions },
      }),
      transformErrorResponse: handleError,
    }),
  }),
});

export const {
  useLazyGetNotificationsQuery,
  useLazyChangeNotificationStatusQuery,
  useLazyChangeNotificationsStatusQuery,
} = notification;
