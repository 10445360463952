import styled from "styled-components";
import { BackButton } from "../../components/BackButton/BackButton";
import { Tabs } from "../../components/Tabs/Tabs";
import { useAppSelect } from "../../hooks/redux";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Button } from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
interface Props {
  activeTab: number;
  onChangeTab: (tabIndex: number) => void;
  onSubmit: () => void;
  type: "edit" | "create";
}

export const Header = ({ activeTab, onChangeTab, onSubmit, type }: Props) => {
  const { user } = useAppSelect((state) => state.auth);
  const { t } = useTranslation("common");
  const typeTitle = `${type === "edit" ? "Редактирование" : "Создание"} груза`;
  const title = `Грузы / ${typeTitle}`;
  const TABS = [
    { title: typeTitle },
    { title: "cargos.receiversData" },
    { title: "cargos.comments" },
  ];
  const BROKER_KZ_TABS = [{ title: typeTitle }, { title: "cargos.comments" }];
  const TAMOZNIA_TABS = [{ title: typeTitle }, { title: "cargos.comments" }];
  const tabs =
    user?.role_id === 11
      ? BROKER_KZ_TABS
      : user?.role_id === 12
      ? TAMOZNIA_TABS
      : TABS;

  return (
    <StyledHeader>
      <div className="header">
        <PageHeader title={title} />
        <div className="btns">
          <BackButton classes="back-btn" />
          <Button
            title={t("common.save")}
            onClick={onSubmit}
            color="blue"
            classes="save-btn"
          />
        </div>
      </div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        classes="tabs"
        defaultActive={user?.role_id === 12 ? 1 : 0}
      />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  padding: 0 0 22px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
  }
  .tabs {
    margin-top: 22px;
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .back-btn {
    margin-right: 20px;
  }
  .save-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 13px 80px;
  }
`;
