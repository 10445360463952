import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DetailCard } from "./DetailCard";

interface Props {
  cargoData: any;
  fullPrice: number;
  currencySign: string;
}

export const Detail = ({ cargoData, fullPrice, currencySign }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledDetail>
      <DetailCard
        title={t("cargoPrice.delivery")}
        value={`${cargoData?.cost?.сost_delivery ?? 0}${currencySign}`}
      />
      <DetailCard
        title={t("cargo.declaration")}
        value={`${cargoData?.cost?.cost_declaration ?? 0}${currencySign}`}
      />
      <DetailCard
        title={t("cargoPrice.storage")}
        value={`${cargoData?.cost?.cost_storage ?? 0}${currencySign}`}
      />
      <DetailCard
        title={t("cargoPrice.overload")}
        value={`${cargoData?.cost?.cost_overload ?? 0}${currencySign}`}
      />
      <DetailCard
        title={t("cargoPrice.total")}
        value={`${fullPrice}${currencySign}`}
        classes="total"
      />
    </StyledDetail>
  );
};

const StyledDetail = styled.div`
  .extra .value {
    color: #ff0000;
  }
  .total div {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #23262b !important;
  }
`;
