import styled from "styled-components";
import { Table } from "../../../components/Table/Table";
import { Row } from "./Row";

const COLUMNS = [
  { title: "", field: "", classes: "edit-kpi-column", noSort: true },
  { title: "Дата", field: "id" },
  { title: "К-во часов", field: "hours_num" },
  { title: "К-во работников", field: "id" },
];

interface Props {
  loading: boolean;
  data: any;
  onEdit: (data: any) => void;
  sort: { field: string; desc: boolean };
  onSort: (field: string) => void;
}

export const KpiTable = ({ loading, data, onEdit, sort, onSort }: Props) => {
  return (
    <StyledKpiTable>
      <Table
        loading={loading}
        columns={COLUMNS.map(
          (column: {
            title: string;
            field: string;
            noSort?: boolean;
            classes?: string;
          }) => ({
            title: column.title,
            active: sort.field === column.field,
            down: sort.field === column.field && sort.desc,
            noSort: column.noSort,
            onSort: () => onSort(column.field),
            classes: column.classes,
          })
        )}
        pageSize={25}
        hideDelete
        hideCheckbox
        hideManage
      >
        {data &&
          data?.map((e: any, i: number) => (
            <Row
              key={i}
              dateCreate={e?.date_create}
              hoursSum={e?.hours_num}
              workers={e?.workers}
              onEdit={() => onEdit(e)}
            />
          ))}
      </Table>
    </StyledKpiTable>
  );
};

const StyledKpiTable = styled.div`
  th {
    div {
      justify-content: center;
    }
  }
  .edit-kpi-column {
    width: 80px;
  }
`;
