import { Title } from "./Title";

interface Props {
  title: string;
  subtitle?: string;
  classes?: string;
}

export const PageHeader = ({ title, classes }: Props) => (
  <div className={classes}>
    <Title title={title} />
  </div>
);
