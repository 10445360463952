import styled from "styled-components";
import { Actions } from "./Actions";
import { Paginator } from "../../../../components/Paginator/Paginator";
import { TableData } from "../../../../models/models";
import { useLazyChangeNotificationsStatusQuery } from "../../../../store/notification/notification.api";
import cogoToast from "cogo-toast";
import { useAppSelect } from "../../../../hooks/redux";
import { useActions } from "../../../../hooks/actions";

interface Props {
  selected: { title: string; id: number }[];
  onClearSelected: () => void;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  loading: boolean;
  onRefreshData: () => void;
}

export const Footer = ({
  selected,
  onClearSelected,
  tableData,
  onChangeTableData,
  loading,
  onRefreshData,
}: Props) => {
  const { user } = useAppSelect((state) => state.auth);
  const { changeNotificationCount } = useActions();

  const [changeNotificationsStatus] = useLazyChangeNotificationsStatusQuery();

  const handleUpdateNotificationCount = () => {
    const currentCount: number = user?.notifications ?? 0;
    const selectedCount: number = selected.length > 0 ? selected.length : 0;
    const updatedCount: number = currentCount - selectedCount;
    changeNotificationCount(updatedCount);
  };

  const handleReadSelectedNotifications = () => {
    changeNotificationsStatus(selected.map((s) => s.id)).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно прочитано", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
        handleUpdateNotificationCount();
        onClearSelected();
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleAdditionalActions = (type: string) => {
    switch (type) {
      case "read":
        return handleReadSelectedNotifications();
    }
  };

  return (
    <StyledFooter>
      <Actions
        selected={selected}
        onClear={onClearSelected}
        onAction={handleAdditionalActions}
      />
      <Paginator
        tableData={tableData}
        onChangeTableData={onChangeTableData}
        loading={loading}
        classes="pagination"
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: 50px;
  @media (max-width: 1000px) {
    margin-bottom: 300px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 20px;
    justify-items: center;
  }
`;
