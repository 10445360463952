import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getCurrencySign } from "../../../../helpers/cargo";

interface Props {
  costData: any;
}

export const Total = ({ costData }: Props) => {
  const { t } = useTranslation("common");
  const fullCost: number =
    costData?.length > 0
      ? costData
          ?.map((cost: any) => Number(cost.price))
          ?.reduce(
            (previousValue: number, currentValue: any) =>
              currentValue + previousValue
          )
      : 0;
  const discount: number =
    costData?.length > 0
      ? costData
          ?.map(
            (cost: any) => (Number(cost.price) / 100) * Number(cost.discount)
          )
          ?.reduce(
            (previousValue: number, currentValue: any) =>
              currentValue + previousValue
          )
      : 0;

  return (
    <StyledTotal>
      <div>
        {t("cargoPrice.total")}: {(fullCost - discount).toFixed(2)}
        {getCurrencySign(costData[0]?.currency)}
      </div>
      <span>
        {t("cargo.discount")}: {discount}
      </span>
    </StyledTotal>
  );
};

const StyledTotal = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 132%;
  letter-spacing: 0.02em;
  color: #000000;
  text-align: right;
  span {
    font-size: 15px;
    display: block;
  }
`;
