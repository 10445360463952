import styled from "styled-components";
import { Language } from "./Language";
import { Notifications } from "./Notifications";
import { Profile } from "./Profile/Profile";
import { Search } from "./Search";

interface Props {
  onEditProfile: () => void;
}

export const Content = ({ onEditProfile }: Props) => {
  return (
    <StyledContent>
      <Search />
      <div className="buttons">
        <Language />
        <Notifications />
        <Profile onEditProfile={onEditProfile} />
      </div>
    </StyledContent>
  );
};

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  .buttons {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;
