import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TeamInfo {
  total: number;
  free: number;
}

interface TeamState {
  teamInfo: TeamInfo;
  activeTeam: string;
}

const initialState: TeamState = {
  teamInfo: { total: 0, free: 0 },
  activeTeam: "",
};

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setTemsInfo(state, action: PayloadAction<TeamInfo>) {
      state.teamInfo = action.payload;
    },
    setActiveTeam(state, action: PayloadAction<string>) {
      state.activeTeam = action.payload;
    },
  },
});

export const teamsActions = teamsSlice.actions;
export const teamsReducer = teamsSlice.reducer;
