import styled from "styled-components";

interface Props {
  title: string;
  value: string;
  bold?: boolean;
}

export const InfoItem = ({ title, value, bold }: Props) => {
  const handleCheckValueExist = (value: string) =>
    value?.length > 0 ? value : "-";

  return (
    <StyledInfoItem>
      <span className={`value ${bold && "bold"}`}>
        {handleCheckValueExist(value)}
      </span>
      <span className="title">{title}</span>
    </StyledInfoItem>
  );
};

const StyledInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 15px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  .title {
    color: #b1b1b1;
  }
  .value {
    color: #575756;
  }
  .bold {
    font-weight: 700;
  }
`;
