import styled from "styled-components";

interface Props {
  active?: boolean;
}

export const StatusLine = ({ active }: Props) => (
  <StyledStatusLine active={active} />
);

const StyledStatusLine = styled.div<Props>`
  height: 3px;
  width: 100%;
  max-width: 180px;
  border-radius: 10px;
  margin: 0 -5px;
  background: ${(props) => (props.active ? "#4F75F5" : "#F4F6F9")};
  flex-shrink: 0;
  @media (max-width: 1400px) {
    max-width: 150px;
  }
  @media (max-width: 1200px) {
    max-width: 130px;
  }
  @media (max-width: 800px) {
    max-width: 100px;
  }
  @media (max-width: 600px) {
    max-width: 80px;
  }
  @media (max-width: 500px) {
    max-width: 50px;
  }
  @media (max-width: 400px) {
    max-width: 60px;
  }
  @media (min-width: 350px) {
    flex-shrink: 0;
  }
`;
