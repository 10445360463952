import { useState } from "react";
import styled from "styled-components";
import background from "../assets/images/auth-background.jfif";
import { AuthCard } from "../components/AuthCard/AuthCard";

export const Auth = () => {
  const [type, setType] = useState<
    "login" | "forgotPassword" | "newPassword" | "success"
  >("login");
  const [userEmail, setUserEmail] = useState<string>("");

  const handleOnChangeType = (
    value: "login" | "forgotPassword" | "newPassword" | "success",
    email?: string
  ) => {
    setType(value);
    value === "newPassword" && email && setUserEmail(email);
  };

  return (
    <StyledAuth background={background}>
      <AuthCard
        type={type}
        userEmail={userEmail}
        onChangeType={handleOnChangeType}
      />
    </StyledAuth>
  );
};

interface StyledAuthProps {
  background: string;
}

const StyledAuth = styled.div<StyledAuthProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f8f9fc;
  font-family: "Public Sans", sans-serif;
  @media (max-width: 420px) {
    align-items: end;
  }
`;
