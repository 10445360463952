import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Timer } from "../../components/Timer/Timer";
import { TimerTime } from "../TimerTime/TimerTime";

interface Props {
  startOveloadDate: string | null;
  endOveloadDate: string | null;
  classes?: string | undefined;
  noTitle?: boolean | undefined;
  title?: string;
  endTitle?: string;
}

export const OverloadStatus = ({
  startOveloadDate,
  endOveloadDate,
  classes,
  noTitle,
  title,
  endTitle,
}: Props) => {
  const { t } = useTranslation("common");
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(true);

  useEffect(() => {
    if (endOveloadDate && startOveloadDate) {
      const endOverloadDateParsed = Date.parse(
        new Date(endOveloadDate).toString()
      );
      const startOverloadDateParsed = Date.parse(
        new Date(startOveloadDate).toString()
      );

      if (startOverloadDateParsed < endOverloadDateParsed) {
        setIsEnded(true);
      }
    } else if (startOveloadDate && !endOveloadDate) {
    } else {
      setIsShow(false);
    }
  }, [endOveloadDate, startOveloadDate]);

  const handleFormatEndTime = () => {
    if (endOveloadDate && startOveloadDate) {
      const endOverloadDateParsed = Date.parse(
        new Date(endOveloadDate).toString()
      );
      const startOverloadDateParsed = Date.parse(
        new Date(startOveloadDate).toString()
      );

      const timePassed: any = endOverloadDateParsed - startOverloadDateParsed;
      const seconds = Math.floor((timePassed / 1000) % 60);
      const minutes = Math.floor((timePassed / 1000 / 60) % 60);
      const hours = Math.floor((timePassed / (1000 * 60 * 60)) % 24);

      return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    return "";
  };

  return (
    <>
      {isShow ? (
        <StyledOverloadStatus
          href="/#/teams"
          target="_blank"
          className={`${classes}`}
        >
          {isEnded ? (
            <>
              {!noTitle && (
                <span> {endTitle ?? t("cargo.overloadingEnded")}</span>
              )}
              <TimerTime time={handleFormatEndTime()} />
            </>
          ) : (
            <>
              {!noTitle && <span> {title ?? t("cargo.overloading")}</span>}
              {startOveloadDate && <Timer dateStart={startOveloadDate} />}
            </>
          )}
        </StyledOverloadStatus>
      ) : null}
    </>
  );
};

const StyledOverloadStatus = styled.a`
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  color: #2e2e3a;
  font-weight: bold;
  span {
    margin-right: 5px;
  }
`;
