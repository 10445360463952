import { DropdownButton } from "../../../components/DropdownButton/DropdownButton";

interface Props {
  disabled: boolean;
  onAction: (type: string) => void;
}

export const AdditionalButton = ({ disabled, onAction }: Props) => (
  <DropdownButton
    title="Дополнительно"
    disabled={disabled}
    onAction={onAction}
    options={[
      { title: "cargo.toStartStorage", value: "start_storage" },
      { title: "cargo.endStorage", value: "end_storage" },
      { title: "Завершить перегрузку", value: "end_overload" },
    ]}
  />
);
