import styled from "styled-components";
import spinnerIcon from "../../assets/images/icons/spinner.svg";

interface Props {
  classes?: string;
}

export const Preloader = ({ classes }: Props) => (
  <StyledPreloader className={classes}>
    <img src={spinnerIcon} alt="" />
  </StyledPreloader>
);

const StyledPreloader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 100px;
  }
`;
