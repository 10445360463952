import { useRef, useEffect } from "react";
import styled from "styled-components";
import { Input } from "../../../../components/Input/Input";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import { File } from "../../../../components/File";
import cogoToast from "cogo-toast";
import { useAppSelect } from "../../../../hooks/redux";

interface Props {
  data: any;
  onChangeField: (
    field: string,
    value: string | File[] | number | Date
  ) => void;
  divideStatus: number;
  onChangeDivideStatus: () => void;
  documents: any[];
  onRemoveDocument: (index: number) => void;
  activeCarIndex: number;
  totalValueKub: number;
  totalCarsValueKub: number;
}

export const ActiveCar = ({
  data,
  onChangeField,
  divideStatus,
  onChangeDivideStatus,
  documents,
  onRemoveDocument,
  activeCarIndex,
  totalValueKub,
  totalCarsValueKub,
}: Props) => {
  const { t } = useTranslation("common");
  const containerRef = useRef<any>();
  const { user } = useAppSelect((state) => state.auth);

  const handleShowInfo = (message: string) => {
    cogoToast.error(message, {
      hideAfter: 3,
      position: "top-right",
    });
  };

  const handleScrollToBottom = () => {
    containerRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [activeCarIndex]);

  const handleChangeVolume = (value: number): void => {
    if (Number(value) + totalCarsValueKub - data.volume <= totalValueKub) {
      onChangeField("volume", value);
    } else {
      cogoToast.error("Значение выше обьема груза", {
        hideAfter: 3,
        position: "top-right",
      });
    }
  };

  return (
    <StyledActiveCar className="cars-card" ref={containerRef}>
      <Input
        placeholder={t("cargo.driverName")}
        value={data?.company}
        onChange={(value: string) => onChangeField("company", value)}
      />
      <div className="checkboxes">
        <Checkbox
          checked={divideStatus === 1}
          onChange={onChangeDivideStatus}
          label="Разделение"
        />
        <Checkbox
          checked={data?.arrival_status === 1}
          onChange={() =>
            onChangeField("arrival_status", data?.arrival_status === 1 ? 0 : 1)
          }
          label="Дополнительный заезд"
        />
      </div>
      <Input
        placeholder={t("cargo.numberLicence")}
        value={data?.udv_number}
        onChange={(value: string) => onChangeField("udv_number", value)}
      />
      <div className="checkboxes">
        <Checkbox
          checked={data?.track === 1}
          onChange={() => onChangeField("track", data?.track === 1 ? 0 : 1)}
          label={t("cargo.car")}
        />
        <Checkbox
          checked={data?.trap === "trailer"}
          onChange={() =>
            onChangeField(
              "trap",
              data?.trap === "trailer" ? "not_trailer" : "trailer"
            )
          }
          label={t("cargo.withTrap")}
        />
      </div>
      <Input
        placeholder={t("cargo.iin")}
        value={data?.iin}
        onChange={(value: string) => onChangeField("iin", value)}
      />
      <div className="uploaded-files-title">Загруженные документы</div>
      <Input
        placeholder={t("cargo.driverNumber")}
        value={data?.driver_number}
        onChange={(value: string) => onChangeField("driver_number", value)}
      />
      <div className="file-wrapper">
        {documents[0] && (
          <File
            name={documents[0]?.name}
            size={documents[0]?.size}
            documentLink={documents[0]?.document ?? undefined}
            onRemove={() => onRemoveDocument(0)}
          />
        )}
      </div>
      <Input
        placeholder="Дата выезда"
        value={data?.date_departure}
        onChange={(value: string) => onChangeField("date_departure", value)}
        calendar
      />
      <div className="file-wrapper">
        {documents[1] && (
          <File
            name={documents[1]?.name}
            size={documents[1]?.size}
            documentLink={documents[1]?.document ?? undefined}
            onRemove={() => onRemoveDocument(1)}
          />
        )}
      </div>
      <Input
        placeholder={t("cargo.numberTechDoc")}
        value={data?.technical_passport_number}
        onChange={(value: string) =>
          onChangeField("technical_passport_number", value)
        }
      />
      <div className="file-wrapper">
        {documents[2] && (
          <File
            name={documents[2]?.name}
            size={documents[2]?.size}
            documentLink={documents[2]?.document ?? undefined}
            onRemove={() => onRemoveDocument(2)}
          />
        )}
      </div>
      <Input
        placeholder={t("cargo.carNumber")}
        value={data?.car_number}
        onChange={(value: string) =>
          user?.role_id !== 4 && user?.role_id !== 1
            ? handleShowInfo("Это поле может изменять роль Склад")
            : onChangeField("car_number", value)
        }
      />
      <div className="file-wrapper">
        {documents[3] && (
          <File
            name={documents[3]?.name}
            size={documents[3]?.size}
            documentLink={documents[3]?.document ?? undefined}
            onRemove={() => onRemoveDocument(3)}
          />
        )}
      </div>
      <Input
        placeholder={t("cargo.valueKub")}
        value={data?.volume?.toString() ?? "0"}
        onChange={handleChangeVolume}
        number
      />
      <div className="file-wrapper">
        {documents[4] && (
          <File
            name={documents[4]?.name}
            size={documents[4]?.size}
            documentLink={documents[4]?.document ?? undefined}
            onRemove={() => onRemoveDocument(4)}
          />
        )}
      </div>
    </StyledActiveCar>
  );
};

const StyledActiveCar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 19px;
  padding: 35px 30px 30px !important;
  grid-auto-rows: max-content;
  .checkboxes {
    display: flex;
    align-items: center;
    gap: 37px;
  }
  .uploaded-files-title {
    color: #575756;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    align-self: flex-end;
  }
  .file-wrapper {
    border-bottom: 1px solid #ececec;
    padding-bottom: 9px;
    div {
      border: none;
      padding-bottom: 0;
    }
  }
`;
