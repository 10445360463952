import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  useLazyCheckVefificationCodeQuery,
  useLazyForgotPasswordQuery,
} from "../../store/auth/auth.api";
import { Input } from "../Input/Input";
import { Button } from "./Button";
import { NavigateButton } from "./NavigateButton";

interface Props {
  onChangeType: (
    value: "login" | "forgotPassword" | "newPassword" | "success",
    email?: string
  ) => void;
  onBackToLogin: () => void;
}

export const ForgotPassword = ({ onChangeType, onBackToLogin }: Props) => {
  const { t } = useTranslation("common");
  const [getCode, { error }] = useLazyForgotPasswordQuery();
  const [checkCode, { isLoading: isCheckingCode }] =
    useLazyCheckVefificationCodeQuery();
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<null | string>(null);
  const [sendCodeMessage, setSendCodeMessage] = useState<null | string>(null);

  const handleChangeEmail = (value: string) => {
    setEmail(value);
    setSendCodeMessage(null);
  };

  const handleChangeCode = (value: string) => setCode(value);

  const handleGetCode = () => {
    setSendCodeMessage(null);
    getCode(email).then((response) => {
      if (response.status === "fulfilled") {
        setSendCodeMessage(
          "Код для восстановления пароля отправлен вам на пошту"
        );
      }
    });
  };

  const handleCheckCode = async () => {
    checkCode({ email, kod: code }).then((response) => {
      if (response.status === "rejected") {
        setCodeError(t("settings.incorrectCode"));
      } else if (response.status === "fulfilled") {
        setCodeError(null);
        onChangeType("newPassword", email);
      }
    });
  };

  return (
    <StyledForgotPassword>
      <Input
        placeholder={t("auth.email_or_number")}
        value={email}
        onChange={handleChangeEmail}
        classes="email-input"
        action={t("auth.sendCode")}
        actionOnClick={handleGetCode}
        error={!!error}
        successMessage={sendCodeMessage}
        errorMessage={"Этот email отсутствует в нашей базе данных"}
      />
      <Input
        placeholder={t("auth.code")}
        value={code}
        error={!!codeError}
        errorMessage={codeError}
        onChange={handleChangeCode}
        classes="code-input"
      />
      <NavigateButton title={t("auth.back")} onClick={onBackToLogin} />
      <Button
        label={t("auth.continue")}
        onClick={handleCheckCode}
        disabled={
          email.length === 0 ||
          code.length === 0 ||
          isCheckingCode ||
          !sendCodeMessage
        }
      />
    </StyledForgotPassword>
  );
};

const StyledForgotPassword = styled.div`
  .email-input {
    margin-bottom: 6px;
  }
  .code-input {
    margin-bottom: 44px;
  }
  @media (max-width: 420px) {
    .email-input {
      margin-bottom: 20px;
    }
    .code-input {
      margin-bottom: 59px;
    }
  }
`;
