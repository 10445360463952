import { Switch } from "../../../components/Switch";

interface Props {
  value: string;
  onChange: (value: string) => void;
}
export const SwitchView = ({ value, onChange }: Props) => (
  <Switch
    value={value}
    onChange={onChange}
    options={[
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
          >
            <path
              d="M15.6574 12.2871C16.7263 11.4269 17.5017 10.2563 17.8771 8.93665C18.2526 7.61701 18.2091 6.21341 17.7531 4.9194C17.2971 3.6254 16.4511 2.50475 15.3312 1.71205C14.2114 0.919351 12.8732 0.493652 11.5011 0.493652C10.1291 0.493652 8.7909 0.919351 7.67107 1.71205C6.55124 2.50475 5.7052 3.6254 5.2492 4.9194C4.79319 6.21341 4.74973 7.61701 5.12517 8.93665C5.50061 10.2563 6.27602 11.4269 7.3449 12.2871C5.51511 13.0453 3.9262 14.2871 2.74822 15.8794C1.57024 17.4717 0.848072 19.3545 0.658375 21.3261C0.644375 21.4711 0.634149 21.8951 0.626149 22.3261C0.623493 22.4788 0.651577 22.6305 0.70818 22.7723C0.764783 22.9142 0.849174 23.0433 0.956227 23.1522C1.06328 23.2611 1.19038 23.3476 1.33123 23.4067C1.47207 23.4657 1.62382 23.4961 1.77654 23.4961H21.2228C21.3758 23.4961 21.5281 23.4656 21.6691 23.4064C21.8102 23.3472 21.937 23.2604 22.0441 23.1512C22.1512 23.0419 22.2357 22.9124 22.2922 22.7702C22.3486 22.628 22.3763 22.476 22.3732 22.3231C22.3632 21.8451 22.3492 21.3651 22.3312 21.1961C22.1202 19.249 21.3892 17.3945 20.215 15.827C19.0408 14.2596 17.4667 13.0369 15.6574 12.2871ZM11.5011 2.80008C12.3573 2.80008 13.1945 3.05397 13.9064 3.52965C14.6183 4.00532 15.1725 4.68142 15.5002 5.47244C15.8278 6.26346 15.9143 7.13387 15.7472 7.97362C15.5802 8.81336 15.1681 9.58472 14.5627 10.1901C13.9573 10.7956 13.1856 11.2079 12.3459 11.3749C11.5061 11.5419 10.6359 11.4562 9.8449 11.1286C9.05388 10.8009 8.37722 10.246 7.90154 9.53413C7.42586 8.82223 7.17205 7.98527 7.17205 7.12907C7.17337 5.98153 7.63024 4.88135 8.44158 4.06982C9.25292 3.25829 10.3536 2.80167 11.5011 2.80008ZM2.98748 21.2001C3.26693 19.1403 4.28329 17.2517 5.8488 15.8842C7.41432 14.5167 9.4225 13.7632 11.5011 13.7632C13.5798 13.7632 15.588 14.5167 17.1535 15.8842C18.719 17.2517 19.7354 19.1403 20.0148 21.2001H2.98748Z"
              fill="#B1B1B1"
            />
          </svg>
        ),
        value: "test1",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="46"
            viewBox="0 0 51 46"
            fill="none"
          >
            <path
              d="M32.0107 30.131C31.1058 30.131 30.222 29.8626 29.4696 29.3599C28.7173 28.8572 28.1304 28.1427 27.7841 27.3067C27.4378 26.4708 27.3468 25.5509 27.5234 24.6634C27.6999 23.776 28.1365 22.9608 28.7763 22.3209C29.4161 21.6811 30.2306 21.2454 31.1181 21.0689C32.0055 20.8923 32.9257 20.9829 33.7616 21.3292C34.5976 21.6755 35.3126 22.2619 35.8153 23.0142C36.3181 23.7666 36.5859 24.6511 36.5859 25.556C36.584 26.7688 36.1016 27.9314 35.2441 28.7889C34.3865 29.6465 33.2235 30.1291 32.0107 30.131ZM32.0107 23.281C31.5607 23.281 31.1211 23.4144 30.747 23.6644C30.3729 23.9144 30.0813 24.2696 29.9091 24.6854C29.7369 25.1011 29.6914 25.5585 29.7792 25.9998C29.867 26.4411 30.0841 26.8465 30.4023 27.1646C30.7204 27.4828 31.126 27.6995 31.5673 27.7872C32.0086 27.875 32.4661 27.83 32.8818 27.6578C33.2975 27.4856 33.6523 27.194 33.9023 26.8199C34.1522 26.4458 34.286 26.0059 34.286 25.556C34.2853 24.9528 34.0455 24.3746 33.6191 23.9482C33.1926 23.5217 32.6138 23.2818 32.0107 23.281Z"
              fill="#B1B1B1"
            />
            <path
              d="M38.0713 36.2189H24.7715C24.7715 36.2189 24.8013 34.2929 24.8623 33.9189C25.1357 32.2203 26.0048 30.6745 27.3145 29.5588C28.6241 28.4431 30.2883 27.8303 32.0088 27.8303C33.7293 27.8303 35.3934 28.4431 36.7031 29.5588C38.0128 30.6745 38.8828 32.2203 39.1562 33.9189C39.1823 34.0849 39.2037 34.5589 39.2188 35.0329C39.2236 35.1867 39.1981 35.34 39.1426 35.4836C39.0871 35.6271 39.0026 35.7581 38.8955 35.8687C38.7884 35.9793 38.6602 36.0673 38.5186 36.1274C38.3769 36.1876 38.2252 36.2187 38.0713 36.2189ZM27.208 33.9189H36.8145C36.5558 32.8397 35.9407 31.8791 35.0693 31.1919C34.198 30.5047 33.1205 30.1309 32.0107 30.1309C30.901 30.1309 29.8235 30.5047 28.9521 31.1919C28.0808 31.8791 27.4666 32.8397 27.208 33.9189Z"
              fill="#B1B1B1"
            />
            <path
              d="M18.9892 30.131C18.0843 30.131 17.1995 29.8626 16.4472 29.3599C15.6948 28.8572 15.1089 28.1427 14.7626 27.3067C14.4163 26.4708 14.3253 25.5509 14.5019 24.6634C14.6784 23.776 15.114 22.9608 15.7538 22.3209C16.3936 21.6811 17.2091 21.2454 18.0966 21.0689C18.9841 20.8923 19.9042 20.9829 20.7402 21.3292C21.5761 21.6755 22.2902 22.2619 22.7929 23.0142C23.2956 23.7666 23.5644 24.6511 23.5644 25.556C23.563 26.7689 23.0803 27.9318 22.2226 28.7895C21.3649 29.6472 20.2021 30.1296 18.9892 30.131ZM18.9892 23.281C18.5392 23.2808 18.0988 23.414 17.7245 23.6639C17.3503 23.9137 17.059 24.269 16.8866 24.6847C16.7143 25.1003 16.6691 25.5578 16.7568 25.9992C16.8444 26.4406 17.0607 26.846 17.3788 27.1643C17.6969 27.4825 18.1025 27.6993 18.5439 27.7872C18.9852 27.875 19.4426 27.83 19.8583 27.6579C20.2741 27.4857 20.6298 27.1941 20.8798 26.82C21.1298 26.4458 21.2626 26.006 21.2626 25.556C21.2618 24.953 21.0219 24.375 20.5956 23.9485C20.1694 23.5221 19.5921 23.282 18.9892 23.281Z"
              fill="#B1B1B1"
            />
            <path
              d="M26.2262 36.2189H12.9264C12.7723 36.2188 12.6199 36.1878 12.4781 36.1277C12.3363 36.0676 12.2083 35.9797 12.1012 35.8691C11.994 35.7584 11.9097 35.6274 11.8541 35.4838C11.7985 35.3402 11.7731 35.1868 11.7779 35.0329C11.7929 34.5589 11.8134 34.0849 11.8404 33.9189C12.1138 32.2203 12.9839 30.6745 14.2935 29.5588C15.6032 28.4431 17.2674 27.8303 18.9879 27.8303C20.7084 27.8303 22.3725 28.4431 23.6822 29.5588C24.9919 30.6745 25.8609 32.2203 26.1344 33.9189C26.1994 34.2929 26.2262 36.2189 26.2262 36.2189ZM14.1851 33.9189H23.7916C23.533 32.8397 22.9188 31.8791 22.0475 31.1919C21.1761 30.5047 20.0986 30.1309 18.9889 30.1309C17.8791 30.1309 16.8016 30.5047 15.9303 31.1919C15.0589 31.8791 14.4438 32.8397 14.1851 33.9189Z"
              fill="#B1B1B1"
            />
            <path
              d="M28.085 17.6749L25.9228 16.5379L23.7598 17.6749C23.5699 17.7745 23.3565 17.8191 23.1426 17.8036C22.9287 17.788 22.7233 17.713 22.5498 17.5869C22.3763 17.4609 22.241 17.2888 22.1602 17.0902C22.0793 16.8915 22.0557 16.6743 22.0918 16.4629L22.5049 14.0549L20.7559 12.3489C20.6024 12.1991 20.4937 12.0094 20.4424 11.8012C20.391 11.5929 20.3996 11.3745 20.4658 11.1705C20.5321 10.9666 20.6535 10.7852 20.8174 10.6469C20.9813 10.5086 21.1804 10.4189 21.3926 10.3879L23.8105 10.0369L24.8916 7.84587C24.9868 7.65362 25.1341 7.4918 25.3164 7.37866C25.4987 7.26552 25.7093 7.20557 25.9238 7.20557C26.1383 7.20557 26.348 7.26552 26.5303 7.37866C26.7125 7.4918 26.8599 7.65362 26.9551 7.84587L28.0352 10.0369L30.4521 10.3879C30.6644 10.4187 30.8633 10.5083 31.0273 10.6465C31.1914 10.7848 31.3135 10.9662 31.3799 11.1702C31.4462 11.3741 31.4546 11.5926 31.4033 11.8009C31.352 12.0092 31.2434 12.199 31.0898 12.3489L29.3408 14.0549L29.7539 16.4629C29.7902 16.6744 29.7664 16.8919 29.6855 17.0906C29.6046 17.2894 29.4695 17.4616 29.2959 17.5877C29.1222 17.7138 28.9172 17.7888 28.7031 17.8042C28.4891 17.8196 28.2749 17.7748 28.085 17.6749Z"
              fill="#B1B1B1"
            />
          </svg>
        ),
        value: "test2",
      },
    ]}
  />
);
