import styled from "styled-components";
import { Button } from "../../../../components/Button/Button";
import { Selected } from "./Selected";

interface Props {
  selected: { title: string; id: number }[];
  onClear: () => void;
  onAction: (type: string) => void;
}

export const Actions = ({ selected, onClear, onAction }: Props) => (
  <StyledActions>
    <Button
      title="Прочитано"
      onClick={() => onAction("read")}
      color="blue"
      classes="btn"
      disabled={selected.length === 0}
    />
    {/* <Button
      title="Непрочитано"
      onClick={() => onAction("not_priority")}
      color="blue"
      classes="btn"
      disabled={selected.length === 0}
    /> */}
    {selected.length > 0 && <Selected selected={selected} onClear={onClear} />}
  </StyledActions>
);

const StyledActions = styled.div`
  padding: 2px;
  border-radius: 10px;
  background: #ececec;
  display: flex;
  align-items: center;
  gap: 4px;
  .btn {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    height: 50px;
    width: 126px;
    padding: 0;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 14px !important;
    flex-shrink: 0;
  }
  @media (max-width: 820px) {
    flex-direction: column;
    width: 100%;
    .btn {
      width: 100%;
    }
  }
`;
