import styled from "styled-components";
import { Arrow } from "./Arrow";

interface Props {
  id: string;
  title: string;
  label: string;
  sort: { field: string; desc: boolean };
  onSort: (columnName: string) => void;
}

export const Header = ({ id, title, label, sort, onSort }: Props) => (
  <StyledHeader className="no-select">
    <span onClick={() => onSort(id)} className="title">
      {title}
      <Arrow active={sort.field === id} down={sort.field === id && sort.desc} />
    </span>
    <span className="info">{label}</span>
  </StyledHeader>
);

const StyledHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  background: #ececec;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #575756;
  .title {
    cursor: pointer;
    transition: all 0.3s;
  }
  .info {
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    color: #575756;
  }
`;
