import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Input } from "../Input/Input";
import { Label } from "../Label/Label";

interface Props {
  title?: string;
  data: any;
  onChangeData: (id: number, data: any) => void;
  quantity?: boolean | undefined;
  noDiscount?: boolean | undefined;
}

export const CostForm = ({
  title,
  data,
  onChangeData,
  quantity,
  noDiscount,
}: Props) => {
  const { t } = useTranslation("common");

  const handleChangeField = (fieldName: string, value: number) => {
    onChangeData(data?.id, { ...data, [fieldName]: value });
  };

  return (
    <StyledCostForm>
      {title && <Label label={title} labelClasses="label" />}
      <div
        className={`input-group ${
          !quantity && noDiscount
            ? "group-one-input"
            : quantity && !noDiscount
            ? "group-three-inputs"
            : "group-two-inputs"
        }`}
      >
        <Input
          placeholder={t("cargoPrice.price")}
          value={data?.price?.toString() ?? "0"}
          onChange={(value) => handleChangeField("price", value)}
          error={!data?.price}
          number
        />
        {quantity && (
          <Input
            placeholder={t("cargo.count")}
            value={data?.quantity?.toString() ?? "0"}
            onChange={(value) => handleChangeField("quantity", value)}
            error={!data?.quantity}
            number
            disabled
          />
        )}
        {!noDiscount && (
          <Input
            placeholder={t("cargoPrice.discount")}
            value={data?.discount?.toString() ?? "0"}
            onChange={(value) => handleChangeField("discount", value)}
            error={!data?.discount?.toString()}
            number
          />
        )}
      </div>
    </StyledCostForm>
  );
};

const StyledCostForm = styled.div`
  margin-bottom: 20px;
  .input-group {
    display: grid;
    gap: 30px;
    align-items: center;
    margin: 8px 0 20px;
    width: 100%;
    img {
      height: 14px;
    }
  }
  input {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .group-one-input {
    grid-template-columns: 1fr;
  }
  .group-three-inputs {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .group-two-inputs {
    grid-template-columns: 1fr 1fr;
  }
  .select {
    border-radius: 0;
    border-bottom: 2px solid rgba(228, 230, 232, 0.85);
    background: #ffff;
    padding-bottom: 14px;
    max-width: 100%;
    .dropdown {
      width: 100%;
    }
  }
  .label {
    font-weight: bold;
  }
  @media (max-width: 650px) {
    .input-group {
      grid-template-columns: 1fr;
    }
    .dropdown {
      width: 90% !important;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
