import styled from "styled-components";

interface Props {
  left: number;
  title: string;
  active?: boolean;
}

export const Point = ({ left, title, active }: Props) => (
  <StyledPoint left={left} active={active}>
    <span>{title}</span>
  </StyledPoint>
);

interface StyledPointProps {
  left: number;
  active?: boolean;
}

const StyledPoint = styled.div<StyledPointProps>`
  position: absolute;
  left: ${(props) => props.left}%;
  transform: translateX(-${(props) => props.left}%);
  width: 14px;
  height: 14px;
  border-radius: 100%;
  top: -7px;
  background: ${(props) => (props.active ? "#94E18C" : "#ECECEC")};
  span {
    position: absolute;
    top: calc(100% + 7px);
    left: 50%;
    transform: translateX(-50%);
    color: #575756;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
