import styled from "styled-components";
import { CheckButton } from "./CheckButton";
import { useLazyConfirmCargoQuery } from "../../store/cargo/cargo.api";
import { useAppSelect } from "../../hooks/redux";
import cogoToast from "cogo-toast";

interface Props {
  cargoStatus: string;
  cargoId: string | number | undefined;
  statusOperatorFinancier: string;
  statusTransitManager: string;
  onRefreshCargoData: () => void;
  small?: boolean;
  className?: string;
}

export const CheckingСargo = ({
  cargoStatus = "",
  cargoId = "",
  statusOperatorFinancier = "",
  statusTransitManager = "",
  onRefreshCargoData,
  small,
  className,
}: Props) => {
  const { user } = useAppSelect((state: any) => state.auth);
  const [confirmCargo] = useLazyConfirmCargoQuery();

  const handleConfirmCargo = (status: string) => {
    confirmCargo({
      cargo_id: cargoId,
      status: status === "new" ? "approve" : "new",
    }).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно проверено", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshCargoData();
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  if (cargoStatus !== "storage_kz") {
    return null;
  }

  return (
    <StyledCheckingСargo className={`no-select ${className}`} small={small}>
      <CheckButton
        checked={statusOperatorFinancier === "approve"}
        onClick={() => handleConfirmCargo(statusOperatorFinancier)}
        anable={user?.role_id === 14 || user?.role_id === 1}
        small={small}
        approveColor="#56A77A"
      />
      <CheckButton
        checked={statusTransitManager === "approve"}
        onClick={() => handleConfirmCargo(statusTransitManager)}
        anable={user?.role_id === 15 || user?.role_id === 1}
        small={small}
        approveColor="#4F75F5"
      />
    </StyledCheckingСargo>
  );
};

interface StyledCheckingСargoProps {
  small?: boolean;
}

const StyledCheckingСargo = styled.div<StyledCheckingСargoProps>`
  display: flex;
  align-items: ${(props) => (props.small ? "end" : "center")};
`;
