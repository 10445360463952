import { TeamsTable } from "./TeamsTable";
import { Header } from "./Header";
import { useEffect, useState } from "react";
import { useLazyGetTeamsQuery } from "../../store/teams/teams.api";
import { Footer } from "./Footer/Footer";
import { TeamModal } from "./TeamModal";

export interface TeamInfo {
  total: number;
  free: number;
}

export const Teams = () => {
  const [getTeams, { data }] = useLazyGetTeamsQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<string | undefined>(
    undefined
  );
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });
  const [selected, setSelected] = useState<{ title: string; id: number }[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [tableData, setTableData] = useState<{
    perPage: number;
    currentPage: number;
    lastPage: number;
    total: number;
  }>({ perPage: 25, currentPage: 1, lastPage: 1, total: 0 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editTeam, setEditTeam] = useState<any | null>(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditTeam(null);
  };
  const handleEditTeam = (data: any) => {
    setOpenModal(true);
    setEditTeam(data);
  };
  // CHANGE SELECTED
  const handleRemoveSelected = (cargoId: number) => {
    setSelected(selected.filter((c) => c.id !== cargoId));
  };
  const handleSelect = (title: string, cargoId: number) => {
    const isSelected = !!selected.find((c) => c.id === cargoId);
    isSelected
      ? handleRemoveSelected(cargoId)
      : setSelected([...selected, { title, id: cargoId }]);
  };

  const handleSelectAll = (cargos: { title: string; id: number }[]) =>
    setSelected(cargos);

  useEffect(() => {
    setIsAllSelected(
      selected.length === data?.response?.length && data?.response?.length > 0
    );
  }, [selected, data]);

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  const handleChangeTableData = (data: {
    perPage: number;
    currentPage: number;
    lastPage: number;
    total: number;
  }) => setTableData(data);

  const handleGetTeams = () => {
    setLoading(true);
    getTeams({
      sortBy: sort?.field?.length > 0 ? sort?.field : undefined,
      sortDesc: sort?.desc,
    }).then(() => setLoading(false));
  };

  useEffect(() => {
    handleGetTeams();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleGetTeams();
    // eslint-disable-next-line
  }, [sort]);

  const handleUpdateDate = () => {
    if (!document.hidden) {
      handleGetTeams();
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", handleUpdateDate);
    return () =>
      window.removeEventListener("visibilitychange", handleUpdateDate);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header
        teams={
          data?.response
            ? data?.response?.map((t: any) => ({ title: t.name, value: t.id }))
            : []
        }
        selectedTeam={selectedTeam}
        onSelectTeam={(team: string | undefined) => setSelectedTeam(team)}
        onCreate={() => setOpenModal(true)}
      />
      <TeamsTable
        teams={
          data?.response
            ? data?.response.filter((t: any) =>
                selectedTeam ? t.id === selectedTeam : true
              )
            : []
        }
        onRefreshData={handleGetTeams}
        selected={selected}
        sort={sort}
        onSort={handleSort}
        loading={loading}
        tableData={tableData}
        onChangeTableData={handleChangeTableData}
        isAllSelected={isAllSelected}
        onSelectAll={handleSelectAll}
        onSelect={handleSelect}
        onEdit={handleEditTeam}
      />
      <Footer
        selected={selected}
        onClearSelected={() => handleSelectAll([])}
        tableData={tableData}
        onChangeTableData={handleChangeTableData}
        loading={loading}
        onSelectCargo={handleSelect}
        onRefreshData={handleGetTeams}
        teams={data?.response ?? []}
      />
      <TeamModal
        open={openModal}
        onClose={handleCloseModal}
        editTeam={editTeam}
        onRefreshData={handleGetTeams}
      />
    </div>
  );
};
