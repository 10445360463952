import { useState } from "react";
import styled from "styled-components";
import { Comment } from "./Comment/Comment";
import { useEffect, useRef } from "react";
import cogoToast from "cogo-toast";
import { useAppSelect } from "../../hooks/redux";
import { useLazyDeleteCommentQuery } from "../../store/cargo/cargo.api";
import { CONFIRM_INIT, Confirm, ConfirmInterface } from "../Confirm/Confirm";

interface Props {
  data: any;
  onRefreshData: () => void;
  onToggleEditComment: (comment?: any) => void;
  editComment: any | null;
}

export const Content = ({
  data,
  onRefreshData,
  onToggleEditComment,
  editComment,
}: Props) => {
  const commentsRef = useRef<any>();
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const { user } = useAppSelect((state: any) => state.auth);
  const [deleteComment] = useLazyDeleteCommentQuery();

  const handleScrollToBottom = () => {
    if (commentsRef.current) {
      commentsRef.current.scroll({
        top: commentsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [data]);

  const handleDeleteComment = (commentId: number) => {
    deleteComment(commentId).then((resp: any) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Комментарий успешно удален", {
          hideAfter: 3,
          position: "top-right",
        });
        setComfirmData(CONFIRM_INIT);
        onRefreshData();
      } else {
        cogoToast.error("Ошибка удаления комментария ", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleConfirmDelete = (commentId: number) => {
    setComfirmData({
      open: true,
      title: "Вы точно хотите удалить комментарий?",
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => handleDeleteComment(commentId),
    });
  };

  return (
    <>
      <Confirm {...confirmData} />
      <StyledContent ref={commentsRef}>
        {data?.length
          ? data.map((comment: any, i: number) => (
              <Comment
                key={i}
                userName={comment?.user?.name ?? ""}
                userAvatar={comment?.user?.avatar}
                createdAt={comment.created_at}
                text={comment.comment}
                documents={comment.documents}
                onDelete={() => handleConfirmDelete(comment.id)}
                onToggleEdit={() =>
                  onToggleEditComment(
                    editComment?.id === comment.id ? null : comment
                  )
                }
                isOwner={user?.id === comment?.user?.id}
                isEdit={editComment?.id === comment.id}
              />
            ))
          : null}
      </StyledContent>
    </>
  );
};

const StyledContent = styled.div`
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: 2px;
  background: #fff;
  overflow: auto;
  height: calc(100vh - 131px - 30px - 144px - 22px - 100px - 22px - 50px);
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    height: 300px;
  }
`;
