import styled from "styled-components";
import wordIcon from "../../assets/images/word-icon.png";

interface Props {
  title: string;
  link: string;
}

export const DocumentLink = ({ title, link }: Props) => (
  <StyledDocumentLink href={link}>
    <img src={wordIcon} alt="" />
    <span>{title}</span>
  </StyledDocumentLink>
);

const StyledDocumentLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
  img {
    height: 46px;
    width: 46px;
    margin-right: 15px;
  }
`;
