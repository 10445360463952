import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Timer } from "../../components/Timer/Timer";
import { Checkbox } from "../../components/Checkbox/Checkbox";
import editIcon from "../../assets/images/icons/edit-icon.svg";
import { Link } from "react-router-dom";

interface Props {
  id: number;
  block: string;
  name: string;
  peopleNumber: number;
  specialEquipment: string;
  peregruzok: number;
  status: string;
  dateStart: string;
  cargoNs: string | null;
  onRefreshTable: () => void;
  onEdit: () => void;
  isSelected: boolean;
  onSelect: () => void;
  hideCheckbox: boolean;
}

export const TeamRow = ({
  id,
  block,
  name,
  peopleNumber,
  specialEquipment,
  peregruzok,
  status,
  dateStart,
  cargoNs,
  onEdit,
  isSelected,
  onSelect,
  hideCheckbox,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledTeamRow className={`${isSelected && "selected"}`}>
      {!hideCheckbox && (
        <>
          <td>
            <Checkbox checked={isSelected} onChange={onSelect} />
          </td>
          <td>
            <img
              src={editIcon}
              alt=""
              onClick={onEdit}
              className="cursor-pointer"
            />
          </td>
        </>
      )}
      <td>{block}</td>
      <td>
        <Link to={`/team-history/${id}`}>
          <div>
            <div className="bold"> {name}</div>
            {status === "closed" && cargoNs && <div>{cargoNs}</div>}
          </div>
        </Link>
      </td>
      <td className="cursor-pointer">{peopleNumber}</td>
      <td className="cursor-pointer">{specialEquipment}</td>
      <td>{peregruzok ?? 0}</td>
      <td>{t(status === "free" ? "teams.free" : "teams.busy")}</td>
      <td>{status === "closed" ? <Timer dateStart={dateStart} /> : "-"}</td>
    </StyledTeamRow>
  );
};

const StyledTeamRow = styled.tr`
  button {
    width: 150px;
  }
  .history-btn {
    width: max-content;
  }
`;
