import styled from "styled-components";

interface Props {
  label: string | undefined;
  onClick: () => void | undefined;
  disabled: boolean;
}

export const ActionButton = ({ label, onClick, disabled }: Props) => (
  <StyledActionButton onClick={onClick} disabled={disabled}>
    {label}
  </StyledActionButton>
);

const StyledActionButton = styled.button`
  background: #7392f7;
  border-radius: 10px;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 7px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: max-content;
  @media (max-width: 420px) {
    font-family: "Archivo", sans-serif;
    font-weight: 300;
    font-size: 10px;
    line-height: 18px;
    border-radius: 8px;
    padding: 6px 15px;
  }
  &:hover {
    opacity: 0.8;
  }
  &:disabled,
  &[disabled] {
    color: #000000;
    background: #d9d9d9;
  }
`;
