import styled from "styled-components";
import { Tab } from "./Tab";
import { RefObject } from "react";

interface Tab {
  title: string;
  badge?: string | number;
  id?: number | undefined;
}

interface Props {
  tabs: Tab[];
  activeTab: number;
  onChangeTab: (tabIndex: number, tabId?: number | undefined) => void;
  classes?: string | undefined;
  defaultActive?: number | undefined;
  innerRef?: RefObject<HTMLDivElement>;
}

export const Tabs = ({
  tabs,
  activeTab,
  onChangeTab,
  classes,
  defaultActive = 0,
  innerRef,
}: Props) => {
  return (
    <StyledTabs className={classes} tabsCount={tabs.length ?? 0} ref={innerRef}>
      {tabs.map((tab: Tab, i: number) => {
        if (tab?.title?.length > 0) {
          return (
            <Tab
              key={i}
              title={tab.title}
              badge={tab?.badge}
              id={tab?.id}
              active={i === activeTab}
              onClick={() => onChangeTab(i, tab?.id)}
              defaultActive={i === defaultActive}
            />
          );
        }
        return null;
      })}
    </StyledTabs>
  );
};

interface StyledTabsProps {
  tabsCount: number;
}

const StyledTabs = styled.div<StyledTabsProps>`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  position: relative;
  height: 50px;
  background: #ececec;
  padding: 2px;
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.tabsCount},
    minmax(150px, 1fr)
  );
  gap: 4px;
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
