import styled from "styled-components";
import { ColumnOption } from "../../store/cargo/cargo.slice";
import { Checkbox } from "./Checkbox";

interface Props {
  options: ColumnOption[];
  showAll: boolean;
  onSelect: (opt: ColumnOption, optionIndex: number) => void;
}

export const Options = ({ options, showAll, onSelect }: Props) => (
  <StyledOptions>
    {options.slice(0, showAll ? options.length : 6).map((opt, i) => (
      <Checkbox
        key={i}
        checked={opt.show}
        label={opt.title}
        onClick={() => onSelect(opt, i)}
      />
    ))}
  </StyledOptions>
);

const StyledOptions = styled.div`
  max-height: 250px;
  overflow: auto;
  margin-right: -24px;
`;
