import styled from "styled-components";

export const Header = () => (
  <>
    <div />
    <StyledHeader>Стоимость $ </StyledHeader>
    <StyledHeader>Скидка % </StyledHeader>
    <StyledHeader>Оплата $ </StyledHeader>
    <StyledHeader>Остаток $ </StyledHeader>
  </>
);

const StyledHeader = styled.div`
  color: #575756;
  font-size: 12px;
  font-weight: 400;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
