import styled from "styled-components";

interface Props {
  title: string;
  value: string;
  classes?: string | undefined;
}

export const DetailCard = ({ title, value, classes }: Props) => (
  <StyledDetailCard className={classes}>
    <div className="title">{title}</div>
    <div className="value">{value}</div>
  </StyledDetailCard>
);

const StyledDetailCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f4f6f9;
  font-family: "Archivo", sans-serif;
  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: #747b84;
  }
  .value {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: #09101d;
  }
`;
