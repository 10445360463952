import { useState } from "react";
import styled from "styled-components";
import { Header } from "./Header/Header";

export const Dashboard = () => {
  const [activeTabPeriod, setActiveTabPeriod] = useState<number>(0);
  const [viewType, setViewType] = useState<string>("test1");

  const handleChangeTabPeriod = (tabIndex: number) =>
    setActiveTabPeriod(tabIndex);

  const handleChangeViewType = (type: string) => setViewType(type);

  return (
    <StyledDashboard>
      <Header
        activeTabPeriod={activeTabPeriod}
        onChangeActiveTabPeriod={handleChangeTabPeriod}
        viewType={viewType}
        onChangeViewType={handleChangeViewType}
      />
      <div className="widgets-empty">
        <div className="block block-1" />
        <div className="block block-2" />
        <div className="block block-3" />
        <div className="block block-4" />
        <div className="block block-5" />
      </div>
    </StyledDashboard>
  );
};

const StyledDashboard = styled.div`
  .widgets-empty {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 20px;
    height: calc(100vh - 100px - 30px - 123px - 30px - 22px - 22px);
    grid-auto-rows: 50%;
  }
  .block {
    background: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 20px;
  }
  .block-1 {
    grid-column: 1/5;
  }
  .block-2 {
    grid-column: 5/11;
  }
  .block-3 {
    grid-column: 1/4;
  }
  .block-4 {
    grid-column: 4/8;
  }
  .block-5 {
    grid-column: 8/11;
  }
`;
