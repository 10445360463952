import styled from "styled-components";

interface Props {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export const Button = ({ label, onClick, disabled }: Props) => (
  <StyledButton onClick={onClick} disabled={disabled}>
    {label}
  </StyledButton>
);

interface StyledButtonProps {
  disabled: boolean | undefined;
}

const StyledButton = styled.button<StyledButtonProps>`
  background: #7392f7;
  border-radius: 10px;
  padding: 17px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
  ${(props) => props.disabled && "opacity: 0.8; cursor: no-drop;"}
  @media(max-width: 420px) {
    font-family: "Archivo", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    padding: 10px 16px;
    background: #4376fe;
    border-radius: 15px;
  }
`;
