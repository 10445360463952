import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReceiverRow } from "./ReceiverRow";
import { Footer } from "./Footer";
import { ContentHeader } from "./ContentHeader";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import cogoToast from "cogo-toast";
import {
  useLazyCargoReleaseListQuery,
  useLazyUpdateCargoReleaseListQuery,
} from "../../../../store/cargo/cargo.api";
import { Modal } from "../../../../components/Modal/Modal";

interface Props {
  open: boolean;
  onClose: () => void;
  onSendToNextStatus: () => void;
  totalVolume: number;
}

interface ReceiverRowIterface {
  id: number;
  percentage_ratio: number;
  units_dimension: string;
}

export const ReleaseModal = ({
  open,
  onClose,
  onSendToNextStatus,
  totalVolume,
}: Props) => {
  const { t } = useTranslation("common");
  const { cargoId } = useParams();
  const [getRealiseList, { data }] = useLazyCargoReleaseListQuery();
  const [updateCargoReleaseList] = useLazyUpdateCargoReleaseListQuery();
  const [formData, setFormData] = useState<ReceiverRowIterface[]>([]);
  const [totalUnitsDismension, setTotalUnitsDismension] = useState<number>(0);

  useEffect(() => {
    open && getRealiseList(cargoId);
  }, [open]);

  const handleGetTotalUnitsDismension = (data: ReceiverRowIterface[]) => {
    if (data?.length > 0) {
      setTotalUnitsDismension(
        data
          .map((e: ReceiverRowIterface) => Number(e.units_dimension))
          ?.reduce(
            (previousValue: number, currentValue: any) =>
              currentValue + previousValue
          )
      );
    }
  };

  const handleGetValues = (data: any) => {
    const values: ReceiverRowIterface[] = [];

    data.forEach((receiver: any): void => {
      values.push({
        id: receiver.id,
        percentage_ratio: receiver.percentage_ratio ?? "",
        units_dimension: receiver.units_dimension ?? "",
      });
    });

    handleGetTotalUnitsDismension(values);
    setFormData(values);
  };

  const handleUpdateFormData = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const updatedData = formData.map(
      (receiver: ReceiverRowIterface, i: number) =>
        i === index ? { ...receiver, [field]: value } : receiver
    );
    handleGetTotalUnitsDismension(updatedData);
    setFormData(updatedData);
  };

  useEffect(() => {
    data?.response && handleGetValues(data.response);
  }, [data]);

  const handleCheckIsAllFieldsField = () => {
    let emptyFieldsCount: number = 0;

    formData.forEach((row: ReceiverRowIterface) => {
      if (row.percentage_ratio === 0) {
        emptyFieldsCount += 1;
      }
      if (row?.units_dimension?.length === 0) {
        emptyFieldsCount += 1;
      }
    });

    emptyFieldsCount === 0 && onSendToNextStatus();
  };

  const handleSubmit = () => {
    updateCargoReleaseList({ cars: formData, cargo_id: cargoId }).then(
      (resp) => {
        if (resp.status === "fulfilled") {
          cogoToast.success("Груз успешно выпущено", {
            hideAfter: 3,
            position: "top-right",
          });
          onClose();
          handleCheckIsAllFieldsField();
        } else {
          cogoToast.error(t("common.error"), {
            hideAfter: 3,
            position: "top-right",
          });
        }
      }
    );
  };

  return (
    <StyledReleaseModal>
      <Modal
        open={open}
        title="Отгрузка груза"
        onSubmit={handleSubmit}
        onCancel={onClose}
        submitBtnText={"Отгрузить"}
        cancelBtnText={t("common.close")}
        classes="modal-wrapper"
      >
        <div className="content">
          <ContentHeader />
          {data?.response
            ? data?.response.map((receiver: any, i: number) => (
                <ReceiverRow
                  key={i}
                  name={receiver?.company ?? "-"}
                  carNumber={receiver?.car_number ?? "-"}
                  percentageRatio={formData[i]?.percentage_ratio}
                  unitsDimension={formData[i]?.units_dimension}
                  onChangeField={(field, value) =>
                    handleUpdateFormData(i, field, value)
                  }
                />
              ))
            : null}

          <div className="total">
            Остаток груза: {totalVolume - totalUnitsDismension} {t("cargo.kub")}
          </div>
        </div>
      </Modal>
    </StyledReleaseModal>
  );
};

const StyledReleaseModal = styled.div`
  .modal-wrapper {
    max-width: 700px;
  }
  .content {
    min-width: 600px;
    max-height: 50vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    gap: 10px 0px;
    overflow: auto;
    input {
      text-align: center;
    }
    .total {
      font-size: 20px;
      font-weight: bold;
      grid-column: 1/5;
      text-align: center;
      margin-top: 10px;
    }
  }
`;
