import styled from "styled-components";
import { Avatar } from "../../../components/Avatar/Avatar";

interface Props {
  nickname: string;
  avatar: string | null;
}

export const User = ({ avatar, nickname }: Props) => (
  <StyledUser>
    <Avatar userAvatar={avatar} userName={nickname} />
    <div className="bold nickname">{nickname}</div>
  </StyledUser>
);

const StyledUser = styled.div`
  display: flex;
  align-items: center;
  .nickname {
    margin-left: 20px;
    font-size: 12px;
  }
`;
