import styled from "styled-components";

interface Props {
  message: string;
}

export const ErrorMessage = ({ message }: Props) => (
  <StyledErrorMessage>{message}</StyledErrorMessage>
);

const StyledErrorMessage = styled.div`
  color: #ec2222;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
`;
