import React, { useState } from "react";
import styled from "styled-components";
import arrowIcon from "../../assets/images/icons/arrow-right.svg";

interface Props {
  title: string;
  children: React.ReactElement;
}

export const ToggleCard = ({ title, children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledToggleCard open={open}>
      <div className="toggle-card-header" onClick={() => setOpen(!open)}>
        <div className="title">{title}</div>
        <img src={arrowIcon} alt="" />
      </div>
      <div className="toggle-card-content">{open && children}</div>
    </StyledToggleCard>
  );
};

interface StyledToggleCardProps {
  open: boolean;
}
const StyledToggleCard = styled.div<StyledToggleCardProps>`
  .toggle-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 20px;
    .title {
      font-family: "Archivo", sans-serif;
      font-weight: 600;
      font-size: 13px;
      line-height: 140%;
      color: #09101d;
    }
    img {
      transition: all 0.3s ease;
      ${(props) => props.open && "transform: rotate(90deg);"}
    }
  }
  .toggle-card-content {
    padding-left: 20px;
  }
`;
