import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const adminSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
});

export const adminActions = adminSlice.actions;
export const adminReducer = adminSlice.reducer;
