import { useState } from "react";
import styled from "styled-components";
import { Button } from "../components/Button/Button";
import { useLazyLoginQuery } from "../store/auth/auth.api";

const roles = [
  { title: "Админ", email: "admin@gmail.com", password: "1" },
  {
    title: "Брокер CN",
    email: "broker_cn@gmail.com",
    password: "1",
    note: "Поля: Название клиента, HS, № Инвойс покупателя,  ТТН страховка, CMR  ",
  },
  {
    title: "Брокер KZ",
    email: "broker_kz@gmail.com",
    password: "1",
    note: "Поля: CMR, Декларация (доп. услуга), Прочее инф. ",
  },
  {
    title: "Диспетчер CN",
    email: "dispatcher@gmail.com",
    password: "1",
    note: "Поля: Обьем товара в куб, Адресс растаможки, Перевозчик",
  },
  {
    title: "Диспетчер Казахстан",
    email: "dispetcher_kz@gmail.com",
    password: "1",
    note: "Поля: Прочее инф., Декларация (доп. услуга)",
  },
  { title: "Таможня КЗ", email: "сustoms_kz@gmail.com", password: "1" },
  {
    title: "Склад",
    email: "storage@gmail.com",
    password: "1",
    note: "Поля: Номер машины,  тип автомобиля,  ПРИЦЕП есть / нет, Вес груза , Обьем товара в куб",
  },
  { title: "Финансы", email: "finance@gmail.com", password: "1" },
  {
    title: "Оператор - Финансист",
    email: "operator_finance@gmail.com",
    password: "1",
  },
  {
    title: "Управляющий транзитом",
    email: "transit_manager@gmail.com",
    password: "1",
  },
  { title: "Клиент", email: "test1@gmail.com", password: "1" },
];

export const TestingRoles = () => {
  const [login] = useLazyLoginQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const handleAuthRole = async (role: any) => {
    setLoading(true);
    const resp = await login({ login: role.email, password: role.password });
    if (resp?.status === "fulfilled") {
      setLoading(false);
      const { access_token } = resp?.data.response;
      localStorage.setItem("token", access_token);
      window.location.replace("/");
    } else {
      setLoading(false);
    }
  };

  return (
    <StyledTestingRoles>
      {roles.map((role: any, i: number) => (
        <>
          <div key={i} className="role-card">
            <div>
              <div>{role.title}</div>
              {role?.note && <div className="note">({role.note})</div>}
            </div>
            <Button
              title="Войти"
              onClick={() => handleAuthRole(role)}
              color="blue"
              disabled={loading}
              classes="btn"
            />
          </div>
        </>
      ))}
      <div></div>
    </StyledTestingRoles>
  );
};

const StyledTestingRoles = styled.div`
  background: #ffff;
  box-shadow: 0px 100px 80px rgb(199 203 217 / 7%),
    0px 41.7776px 33.4221px rgb(199 203 217 / 5%),
    0px 22.3363px 17.869px rgb(199 203 217 / 4%),
    0px 12.5216px 10.0172px rgb(199 203 217 / 4%),
    0px 6.6501px 5.32008px rgb(199 203 217 / 3%),
    0px 2.76726px 2.21381px rgb(199 203 217 / 2%);
  border-radius: 20px;
  padding: 28px 30px;
  .role-card {
    display: grid;
    grid-template-columns: 1fr 150px;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #9a9aaf3a;
    padding: 10px;
    margin-bottom: 10px;
    .note {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #9a9aaf;
      text-transform: capitalize;
    }
    .btn {
      width: 100px;
    }
  }
`;
