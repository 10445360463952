import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const teams = createApi({
  reducerPath: "teams/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    getTeams: build.query<
      any,
      { sortBy?: string | undefined; sortDesc?: boolean | undefined }
    >({
      query: ({ sortBy, sortDesc }) => ({
        url: "command/get-commands",
        headers: headers(),
        params: { sortBy, sortDesc },
      }),
      transformErrorResponse: handleError,
    }),
    updateTeamField: build.query<
      any,
      { command_id: number; fieldName: string; fieldValue: string | number }
    >({
      query: ({ command_id, fieldName, fieldValue }) => ({
        url: "command/update-command",
        headers: headers(),
        method: "POST",
        params: { command_id, [fieldName]: fieldValue },
      }),
      transformErrorResponse: handleError,
    }),
    createTeam: build.query<
      any,
      {
        command_id: number;
        name: string;
        people_number: string;
        special_equipment: string;
        block: string;
      }
    >({
      query: ({
        command_id,
        name,
        people_number,
        special_equipment,
        block,
      }) => ({
        url: "command/create-command",
        headers: headers(),
        method: "POST",
        params: { command_id, name, people_number, special_equipment, block },
      }),
      transformErrorResponse: handleError,
    }),
    updateTeam: build.query<
      any,
      {
        command_id: number;
        name: string;
        people_number: string;
        special_equipment: string;
        block: string;
      }
    >({
      query: ({
        command_id,
        name,
        people_number,
        special_equipment,
        block,
      }) => ({
        url: "command/update-command",
        headers: headers(),
        method: "POST",
        params: { command_id, name, people_number, special_equipment, block },
      }),
    }),
    startOverload: build.query<
      any,
      { command_id: number | undefined; cargo_id: string }
    >({
      query: ({ command_id, cargo_id }) => ({
        url: "command/start-cargo-overload",
        headers: headers(),
        method: "POST",
        params: { command_id, cargo_id },
      }),
    }),
    teamsStartOverload: build.query<
      any,
      { command_id: number; cargo_id: string }[]
    >({
      query: (cargos) => ({
        url: "command/all-start-cargos-overload",
        headers: headers(),
        method: "POST",
        body: { cargos },
      }),
      transformErrorResponse: handleError,
    }),
    endOverload: build.query<any, { history_id: any }>({
      query: ({ history_id }) => ({
        url: "command/end-cargo-overload",
        headers: headers(),
        method: "POST",
        params: { history_id },
      }),
    }),
    getTeamHistory: build.query<
      any,
      { command_id: string; page: number; perPage: number }
    >({
      query: ({ command_id, page, perPage }) => ({
        url: "command/get-command-history",
        headers: headers(),
        params: { command_id, page, perPage },
      }),
      transformErrorResponse: handleError,
    }),
    deleteTeams: build.query<any, number[]>({
      query: (commands) => ({
        url: "command/delete-commands",
        headers: { ...headers() },
        method: "DELETE",
        body: { commands },
      }),
    }),
  }),
});

export const {
  useLazyGetTeamsQuery,
  useLazyUpdateTeamQuery,
  useLazyStartOverloadQuery,
  useLazyEndOverloadQuery,
  useLazyGetTeamHistoryQuery,
  useLazyDeleteTeamsQuery,
  useLazyCreateTeamQuery,
  useLazyTeamsStartOverloadQuery,
} = teams;
