import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  title: string;
  badge?: string | number | undefined;
  id?: number | undefined;
  active: boolean;
  onClick: () => void;
  defaultActive: boolean;
}

export const Tab = ({ title, badge, id, active, onClick }: Props) => {
  const { t } = useTranslation("common");

  useEffect(() => {
    if (active) {
      onClick();
    }
  }, []);

  return (
    <StyledTab active={active} onClick={onClick} className="no-select">
      {t(title)}
      {(badge || badge === 0) && <div>{badge}</div>}
    </StyledTab>
  );
};

interface StyledTabProps {
  active: boolean;
}

const StyledTab = styled.div<StyledTabProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #575756;
  padding: 12px;
  background: ${(props) => (props.active ? "#FFFFFF" : "#F5F5F5")};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  @media (min-width: 1800px) {
    font-size: 16px;
  }
`;
