import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useAppSelect } from "../../hooks/redux";

interface Props {
  name: string;
  link: string;
  icon?: string;
  isNotMobile?: boolean | undefined;
  open: boolean;
  classes?: string | undefined;
  onClick?: () => void;
}

export const SidebarLink = ({
  name,
  link,
  icon,
  isNotMobile,
  open,
  classes,
  onClick,
}: Props) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation("common");
  const location = useLocation();
  const { user } = useAppSelect((state) => state.auth);

  useEffect(() => {
    const pathName = location.pathname;
    const linkWithoutHash = link.split("#")[1];
    const linkFormated = linkWithoutHash?.length === 0 ? "/" : linkWithoutHash;
    setActive(pathName === linkFormated);
  }, [location, user]);

  return (
    <>
      <StyledSidebarLink
        active={active}
        isNotMobile={isNotMobile}
        open={open}
        className={`${classes}`}
        onClick={() => (onClick ? onClick() : null)}
      >
        {onClick ? (
          <div className="link">
            {icon && (
              <div className="icon">
                <img src={icon} alt="" />
              </div>
            )}
            <div className="title">{t(name)}</div>
          </div>
        ) : (
          <a href={link} className="link">
            {icon && (
              <div className="icon">
                <img src={icon} alt="" />
              </div>
            )}
            <div className="title">{t(name)}</div>
          </a>
        )}
      </StyledSidebarLink>
    </>
  );
};

interface StyledSidebarLinkProps {
  isNotMobile: boolean | undefined;
  active: boolean;
  open: boolean;
}

const StyledSidebarLink = styled.div<StyledSidebarLinkProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  .link {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => (props.active ? "#575756" : "#D9D9D9")};
    text-decoration: none;
    align-items: center;
  }
  .icon {
    margin-right: ${(props) => (props.open ? "12" : "0")}px;
    height: 40px;
    width: 40px;
    background: ${(props) => (props.active ? "#575756" : "#D9D9D9")};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all 0.3s;
  }
  &:hover {
    color: #575756;
    .icon {
      background: #575756;
    }
    .link {
      color: #575756;
      opacity: 1;
    }
  }
  @media (min-width: 1000px) {
    .title {
      visibility: ${(props) => (props.open ? "visible" : "hidden")};
      opacity: ${(props) => (props.open ? "1" : "0")};
      position: absolute;
      left: 52px;
    }
  }
  @media (max-width: 1000px) {
    ${(props) => props.isNotMobile && "display: none;"}
    .link {
      flex-direction: column;
      align-items: center;
      div {
        margin-top: 10px;
      }
      .icon {
        margin: 0;
      }
    }
  }
  @media (max-width: 500px) {
    a {
      font-size: 13px;
    }
  }
`;
