import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const admin = createApi({
  reducerPath: "admin/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    createUser: build.query<
      any,
      { email: string; name: string; role_id: string }
    >({
      query: ({ email, name, role_id }) => ({
        url: "admin/user-create",
        method: "POST",
        headers: headers(),
        params: { email, name, role_id },
      }),
      transformErrorResponse: handleError,
    }),
    getUsersRoles: build.query({
      query: () => ({
        url: "admin/get-roles",
        headers: headers(),
      }),
      transformResponse: (response: any) => {
        const formatedRoles: { title: string; value: string }[] =
          response.roles.map((role: any) => ({
            title: role.title,
            value: role.id.toString(),
          }));
        return (response = formatedRoles);
      },
      transformErrorResponse: handleError,
    }),
    getUsers: build.query<
      any,
      { page: number; sortBy: string; sortDesc: boolean; perPage: number }
    >({
      query: ({ page, sortBy, sortDesc, perPage }) => ({
        url: `user/get-users`,
        headers: headers(),
        params: { page, sortBy, sortDesc, perPage },
      }),
    }),
    deleteUser: build.query<any, number>({
      query: (user_id) => ({
        url: "admin/user-delete",
        method: "DELETE",
        headers: headers(),
        params: { user_id },
      }),
      transformErrorResponse: handleError,
    }),
    deleteUsers: build.query<any, number[]>({
      query: (users) => ({
        url: "admin/users-delete",
        method: "DELETE",
        headers: headers(),
        body: { users },
      }),
      transformErrorResponse: handleError,
    }),
    editUser: build.query<
      any,
      {
        user_id: number | null;
        name: string;
        role_id: number | string | null | undefined;
        telegram: string;
        phone: string;
      }
    >({
      query: ({ user_id, name, role_id, telegram, phone }) => ({
        url: "user/user-update",
        method: "POST",
        headers: headers(),
        params: { user_id, name, role_id, telegram, phone },
      }),
      transformErrorResponse: handleError,
    }),
    editMyColumns: build.query<
      any,
      { user_id: number | null | undefined; columns: string }
    >({
      query: ({ user_id, columns }) => ({
        url: "user/user-columns-update",
        method: "POST",
        headers: headers(),
        params: { user_id, columns },
      }),
      transformErrorResponse: handleError,
    }),
    getDefaultCosts: build.query({
      query: () => ({
        url: "admin/get-default-costs",
        headers: headers(),
      }),
      transformErrorResponse: handleError,
    }),
    editDefaultCosts: build.query<
      any,
      {
        id: number;
        price: number;
        discount: number;
        quantity?: number | undefined;
        currency: string;
      }
    >({
      query: ({ id, price, discount, quantity, currency }) => ({
        url: "admin/update-default-cost",
        method: "POST",
        headers: headers(),
        params: { id, price, discount, quantity, currency },
      }),
      transformErrorResponse: handleError,
    }),
  }),
});

export const {
  useLazyCreateUserQuery,
  useGetUsersRolesQuery,
  useLazyGetUsersQuery,
  useLazyDeleteUserQuery,
  useLazyEditUserQuery,
  useLazyEditMyColumnsQuery,
  useGetDefaultCostsQuery,
  useLazyEditDefaultCostsQuery,
  useLazyDeleteUsersQuery,
} = admin;
