import styled from "styled-components";

interface Props {
  text: string;
}

export const Text = ({ text }: Props) => <StyledText>{text}</StyledText>;

const StyledText = styled.div`
  color: #575756;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 28px;
`;
