import styled from "styled-components";
import { Point } from "./Point";
import { Progres } from "./Progres";

interface Props {
  procent: number;
}

export const Status = ({ procent }: Props) => (
  <StyledStatus>
    <Point left={0} title="0%" active={procent >= 0} />
    <Point left={50} title="50%" active={procent >= 50} />
    <Point left={100} title="100%" active={procent >= 100} />
    <Progres procent={procent} />
  </StyledStatus>
);

const StyledStatus = styled.div`
  width: 95%;
  height: 3px;
  background: #ececec;
  margin: 0 auto;
  position: relative;
  margin-bottom: 43px;
  border-radius: 20px;
`;
