import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Select } from "../../../components/Select/Select";
import { Option } from "../../../components/Select/Options";
import { Input } from "../../../components/Input/Input";
import { useAppSelect } from "../../../hooks/redux";
import cogoToast from "cogo-toast";

interface Props {
  formData: any;
  onUpdateFormData: (data: any) => void;
}

export const AboutCargo = ({ formData, onUpdateFormData }: Props) => {
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state) => state.auth);

  const handleShowInfo = () => {
    cogoToast.error("Это поле может изменять роль Склад и Брокер CN ", {
      hideAfter: 3,
      position: "top-right",
    });
  };

  return (
    <div>
      <StyledAboutCargo className="manage-cargo-info-card">
        <Select
          label={t("cargo.formLoading")}
          onSelectOption={(opt: Option) =>
            onUpdateFormData({ ...formData, loading_form: opt.value })
          }
          value={formData?.loading_form}
          options={[
            { title: t("cargo.onPalet"), value: "on_pallet" },
            { title: t("cargo.winthoutPalet"), value: "off_pallet" },
          ]}
        />
        <Input
          placeholder={t("cargo.containersCount")}
          counterValue={formData.containers_number}
          onChangeCounterValue={(value: number) =>
            onUpdateFormData({ ...formData, containers_number: value })
          }
          onChange={() => null}
          counter
        />
        <Input
          placeholder={t("cargo.placeCount")}
          counterValue={formData.places_number}
          onChangeCounterValue={(value: number) =>
            onUpdateFormData({ ...formData, places_number: value })
          }
          onChange={() => null}
          counter
        />
        <Input
          placeholder={t("cargo.valueKub")}
          counterValue={formData.product_volume}
          onChangeCounterValue={(value: number) =>
            user?.role_id === 4 || user?.role_id === 2 || user?.role_id === 1
              ? onUpdateFormData({ ...formData, product_volume: value })
              : handleShowInfo()
          }
          onChange={() => null}
          counter
          counterMeasure={t("cargo.kub")}
          disabled={user?.role_id !== 4 && user?.role_id !== 1}
        />
        <Input
          placeholder={t("cargo.weightCargo")}
          counterValue={formData.weight}
          onChangeCounterValue={(value: number) =>
            user?.role_id === 4 || user?.role_id === 1
              ? onUpdateFormData({ ...formData, weight: value })
              : handleShowInfo()
          }
          onChange={() => null}
          counter
          counterMeasure={t("cargo.kg")}
          disabled={user?.role_id !== 4 && user?.role_id !== 1}
        />
        <Input
          placeholder={t("cargo.driver")}
          value={formData.driver}
          onChange={(value: string) =>
            user?.role_id !== 2 && user?.role_id !== 1
              ? handleShowInfo()
              : onUpdateFormData({ ...formData, driver: value })
          }
        />
        <Input
          placeholder={t("cargo.insurance")}
          value={formData.insurance}
          onChange={(value: string) =>
            onUpdateFormData({ ...formData, insurance: value })
          }
        />
      </StyledAboutCargo>
    </div>
  );
};

const StyledAboutCargo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;
  overflow: auto;
  height: calc(100vh - 131px - 30px - 183px);
  grid-auto-rows: max-content;
`;
