import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { admin } from "./admin/admin.api";
import { adminReducer } from "./admin/admin.slice";
import { auth } from "./auth/auth.api";
import { authReducer } from "./auth/auth.slice";
import { brokers } from "./brokers/brokers.api";
import { brokersReducer } from "./brokers/brokers.slice";
import { canban } from "./canban/canban.api";
import { canbanReducer } from "./canban/canban.slice";
import { cargo } from "./cargo/cargo.api";
import { cargoReducer } from "./cargo/cargo.slice";
import { companies } from "./companies/companies.api";
import { companiesReducer } from "./companies/companies.slice";
import { notification } from "./notification/notification.api";
import { notificationReducer } from "./notification/notification.slice";
import { teams } from "./teams/teams.api";
import { teamsReducer } from "./teams/teams.slice";

export const store = configureStore({
  reducer: {
    [auth.reducerPath]: auth.reducer,
    auth: authReducer,
    [admin.reducerPath]: admin.reducer,
    admin: adminReducer,
    [cargo.reducerPath]: cargo.reducer,
    cargo: cargoReducer,
    [notification.reducerPath]: notification.reducer,
    notification: notificationReducer,
    [teams.reducerPath]: teams.reducer,
    teams: teamsReducer,
    [companies.reducerPath]: companies.reducer,
    companies: companiesReducer,
    brokers: brokersReducer,
    [brokers.reducerPath]: brokers.reducer,
    [canban.reducerPath]: canban.reducer,
    canban: canbanReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      auth.middleware,
      admin.middleware,
      cargo.middleware,
      notification.middleware,
      teams.middleware,
      companies.middleware,
      brokers.middleware,
      canban.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
