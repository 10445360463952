import { statusesTitle } from "../../constants/cargo";

const handleFindItemById = (array: any, id: number): any =>
  array.find((e: any) => e.id === id);

const adminsTabs = ({
  t,
  totalCargos,
  statuses,
}: {
  t: any;
  totalCargos: number;
  statuses: any;
}) => [
  {
    title: t("pages.myCargos"),
    badge: "",
    id: "my_cargos",
  },
  { title: t("pages.allCargos"), badge: totalCargos },
  ...statuses?.map((status: any, index: number) => ({
    title: t(`cargo.${statusesTitle[status.id]}`),
    badge: status?.cargo_count,
    id: status?.id,
  })),
];
const dispatcherKzTabs = ({ t, statuses }: { t: any; statuses: any }) => {
  const knr1 = handleFindItemById(statuses, 1);
  const kz = handleFindItemById(statuses, 4);

  if (!knr1 || !kz) {
    return [];
  }

  return [
    {
      title: t(`cargo.${statusesTitle[knr1.id]}`),
      badge: knr1?.cargo_count,
      id: knr1?.id,
    },
    {
      title: t(`cargo.${statusesTitle[kz.id]}`),
      badge: kz?.cargo_count,
      id: kz?.id,
    },
  ];
};
const brokerKzTabs = ({ t, statuses }: { t: any; statuses: any }) => {
  const request = handleFindItemById(statuses, 1);
  const kz = handleFindItemById(statuses, 4);

  if (!request || !kz) {
    return [];
  }

  return [
    {
      title: t(`cargo.${statusesTitle[request?.id]}`),
      badge: request?.cargo_count,
      id: request?.id,
    },
    {
      title: t(`cargo.${statusesTitle[kz?.id]}`),
      badge: kz?.cargo_count,
      id: kz?.id,
    },
  ];
};
const brokerTabs = ({ t, statuses }: { t: any; statuses: any }) => [
  {
    title: "Грузы на проверке",
    badge: "",
    id: "cargos_on_check",
  },
  {
    title: t("pages.myCargos"),
    badge: "",
    id: "my_cargos",
  },
  ...statuses?.map((status: any, index: number) => ({
    title: t(`cargo.${statusesTitle[status.id]}`),
    badge: status?.cargo_count,
    id: status?.id,
  })),
];

const tamozniaTabs = ({ t, statuses }: { t: any; statuses: any }) => {
  const tamoznia = handleFindItemById(statuses, 5);
  const ended = handleFindItemById(statuses, 6);

  if (!tamoznia || !ended) {
    return [];
  }

  return [
    {
      title: t(`cargo.${statusesTitle[tamoznia.id]}`),
      badge: tamoznia?.cargo_count,
      id: tamoznia?.id,
    },
    {
      title: t(`cargo.${statusesTitle[ended.id]}`),
      badge: ended?.cargo_count,
      id: ended?.id,
    },
  ];
};

const userTabs = ({ t, statuses }: { t: any; statuses: any }) =>
  statuses?.map((status: any, index: number) => ({
    title: t(`cargo.${statusesTitle[status.id]}`),
    badge: status?.cargo_count,
    id: status?.id,
  }));

const clientTabs = ({ t, statuses }: { t: any; statuses: any }) => [
  {
    title: t("pages.myCargos"),
    badge: "",
    id: "my_cargos",
  },
  ...statuses?.map((status: any, index: number) => ({
    title: t(`cargo.${statusesTitle[status.id]}`),
    badge: status?.cargo_count,
    id: status?.id,
  })),
];
export const handleGetTabsByRole = ({
  role_id,
  t,
  totalCargos,
  statuses,
}: {
  t: any;
  totalCargos: number;
  statuses: any;
  role_id?: number | null | undefined;
}) => {
  if (!statuses) {
    return [];
  }

  switch (role_id) {
    case 1:
    case 14:
    case 15:
      return adminsTabs({ t, totalCargos, statuses });
    case 8:
      return dispatcherKzTabs({ t, statuses });
    case 11:
      return brokerKzTabs({ t, statuses });
    case 3:
      return brokerTabs({ t, statuses });
    case 12:
      return tamozniaTabs({ t, statuses });
    case 13:
      return clientTabs({ t, statuses });
    default:
      return userTabs({ t, statuses });
  }
};
