import { useEffect } from "react";
import { baseSiteUrl } from "../../../../api/baseUrl";

export const TelegramAuth = () => {
  useEffect(() => {
    let script = document.createElement("script");
    script.async = true;
    script.id = "telegramBtn";
    script.src = "https://telegram.org/js/telegram-widget.js?21";
    script.setAttribute("data-telegram-login", "crm_one_test_bot");
    script.setAttribute("data-size", "large");
    script.setAttribute("data-request-access", "write");
    document.getElementById("telegramBtn")?.appendChild(script);
    script.setAttribute("data-onauth", `onTelegramAuth(user)`);
    let callback = document.createElement("script");
    callback.id = "telegramLoginCallback";
    callback.innerHTML = `
           function onTelegramAuth(user) {
            fetch('${baseSiteUrl}api/auth/telegram-login', {
              method: 'POST', 
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                'Authorization': 'Bearer ${localStorage.getItem("token")}'
              }, 
              body: JSON.stringify(user)
            })
            .then(resp => resp.json())
            .then(resp => {
              if(resp?.code === 200) {
                window.location.href = window.location.href + "?success-telegram=true";
              }
            })
           }
         `;

    document.getElementById("telegramBtn")?.appendChild(callback);
  }, []);

  return <div id="telegramBtn" />;
};
