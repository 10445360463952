import styled from "styled-components";
import { Title } from "./Title";
import { Status } from "./Status/Status";
import { Table } from "./Table/Table";
import { useEffect, useState } from "react";

interface Props {
  data: any;
  onChangeField: (index: number, fieldName: string, value: number) => void;
}

export interface TotalData {
  total_price: number;
  total_discount: number;
  total_paid: number;
  total_remainder: number;
}

const TOTAL_DATA_INIT = {
  total_price: 0,
  total_discount: 0,
  total_paid: 0,
  total_remainder: 0,
};

export const Oplata = ({ data, onChangeField }: Props) => {
  const [totalData, setTotalData] = useState<TotalData>(TOTAL_DATA_INIT);
  const [remainderData, setRemainderData] = useState<number[]>([]);

  const handleGetSum = (array: any, fieldName?: string): number => {
    const filteredArray = fieldName
      ? array.map((item: any) => Number(item[fieldName] ?? "0"))
      : array;

    return filteredArray.length > 0
      ? Number(
          filteredArray
            .reduce(
              (previousValue: number, currentValue: any) =>
                currentValue + previousValue
            )
            .toFixed(2)
        )
      : 0;
  };

  const handleCalculateRemainder = () => {
    return data.map((item: any) => {
      const discount =
        (Number(item.price) / 100) * Number(item.discount ?? "0");
      const remainder =
        Number(item.price) - discount - Number(item.paidValue ?? "0");
      return remainder;
    });
  };

  const handleGetTotalData = () => {
    return {
      total_price: handleGetSum(data, "price"),
      total_discount: handleGetSum(data, "discount"),
      total_paid: handleGetSum(data, "paidValue"),
      total_remainder: handleGetSum(handleCalculateRemainder()),
    };
  };

  useEffect(() => {
    setRemainderData(handleCalculateRemainder());
    setTotalData(handleGetTotalData());
  }, [data]);

  return (
    <StyledOplata className="card-wrapper">
      <Title />
      <Status
        procent={
          ((totalData.total_price - totalData.total_remainder) /
            totalData.total_price) *
          100
        }
      />
      <Table
        data={data}
        onChangeField={onChangeField}
        totalData={totalData}
        remainderData={remainderData}
      />
    </StyledOplata>
  );
};

const StyledOplata = styled.div`
  height: calc(100vh - 131px - 30px - 144px - 72px - 120px);
  padding: 20px 29px;
  @media (max-width: 1000px) {
    height: max-content !important;
  }
`;
