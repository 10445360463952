import { Checkbox } from "../../../components/Checkbox/Checkbox";
import editIcon from "../../../assets/images/icons/edit-icon.svg";
import { Avatar } from "../../../components/Avatar/Avatar";
import styled from "styled-components";
import { formatDate } from "../../../helpers/date";
import { useGetUsersRolesQuery } from "../../../store/admin/admin.api";

interface Props {
  isSelected: boolean;
  onSelect: () => void;
  onEdit: () => void;
  name: string;
  avatar: string | null;
  roleId: number;
  email: string;
  phone: string;
  lastUpdate: string;
}

export const EmployeeRow = ({
  isSelected,
  onSelect,
  onEdit,
  name,
  avatar,
  roleId,
  email,
  phone,
  lastUpdate,
}: Props) => {
  const { data: usersRoles } = useGetUsersRolesQuery(null);
  const currentRole: string | undefined = usersRoles?.find(
    (role) => role.value === roleId.toString()
  )?.title;

  return (
    <StyledEmployeeRow className={`${isSelected && "selected"}`}>
      <td>
        <Checkbox checked={isSelected} onChange={onSelect} />
      </td>
      <td>
        <img
          src={editIcon}
          alt=""
          onClick={onEdit}
          className="cursor-pointer"
        />
      </td>
      <td></td>
      <td>
        <div className="userName">
          <Avatar userName={name} userAvatar={avatar} classses="avatar" />
          <div className="bold">{name}</div>
        </div>
      </td>
      <td>{currentRole}</td>
      <td>{email}</td>
      <td>{phone}</td>
      <td>{formatDate(lastUpdate)}</td>
    </StyledEmployeeRow>
  );
};

const StyledEmployeeRow = styled.tr`
  .userName {
    display: flex;
    align-items: center;
    width: max-content;
    .avatar {
      margin-right: 20px;
    }
  }
`;
