import styled from "styled-components";
import checkIcon from "../../assets/images/icons/check-checkbox.svg";

interface Props {
  checked: boolean;
  onChange: () => void;
  label?: string;
}

export const Checkbox = ({ checked, onChange, label }: Props) => (
  <StyledCheckbox checked={checked} onClick={onChange} className="no-select">
    <div className="checkbox">
      <img src={checkIcon} alt="" />
    </div>
    {label && <div className="label">{label}</div>}
  </StyledCheckbox>
);

interface StyledCheckboxProps {
  checked: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  .checkbox {
    height: 14px;
    width: 14px;
    border-radius: 4px;
    border: 1px solid #b1b1b1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;
    img {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
    ${(props) =>
      props.checked &&
      `
    background: #7392F7;
    img {
      opacity: 1;
      visibility: visible;
    }
  `}
  }
  .label {
    margin-left: 11px;
    color: #575756;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
`;
