import { useState, useEffect } from "react";
import { BrokersTable } from "./BrokersTable";
import { BrokersModal } from "./BrokersModal";
import { Header } from "./Header";
import { useLazyGetBrokersQuery } from "../../store/brokers/brokers.api";

export const Brokers = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [editingBroker, setEditingBroker] = useState<null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [getBrokers, { data }] = useLazyGetBrokersQuery();
  const [selectedBroker, setSelectedBroker] = useState<undefined | string>(
    undefined
  );
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  const handleSelectBroker = (value: undefined | string) =>
    setSelectedBroker(value);

  const handleCloseModal = () => {
    setModal(false);
    setEditingBroker(null);
  };
  const handleCreateBroker = () => setModal(true);
  const handleEditBroker = (broker: any) => {
    setEditingBroker(broker);
    setModal(true);
  };

  const handleGetBrokers = () => {
    setLoading(true);
    getBrokers({
      sortBy: sort?.field?.length > 0 ? sort?.field : undefined,
      sortDesc: sort.desc,
    }).then(() => setLoading(false));
  };

  useEffect(() => {
    handleGetBrokers();
  }, []);

  useEffect(() => {
    handleGetBrokers();
  }, [sort]);

  return (
    <div>
      <BrokersModal
        open={modal}
        onClose={handleCloseModal}
        onRefreshData={handleGetBrokers}
        editingBroker={editingBroker}
      />
      <Header
        brokers={
          data?.response
            ? data?.response?.map((b: any) => ({
                title: b.title,
                value: b.id.toString(),
              }))
            : []
        }
        selectedBroker={selectedBroker}
        onSelectBroker={handleSelectBroker}
        onCreate={handleCreateBroker}
      />
      <BrokersTable
        data={
          data?.response
            ? data?.response.filter((b: any) =>
                selectedBroker ? b.id?.toString() === selectedBroker : true
              )
            : []
        }
        loading={loading}
        onEditBroker={handleEditBroker}
        onRefreshData={handleGetBrokers}
        sort={sort}
        onSort={handleSort}
      />
    </div>
  );
};
