import { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useActions } from "../../hooks/actions";
import { useLazyLoginQuery } from "../../store/auth/auth.api";
import { Input } from "../Input/Input";
import { Button } from "./Button";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { NavigateButton } from "./NavigateButton";

interface Props {
  onChangeType: (
    value: "login" | "forgotPassword" | "newPassword" | "success"
  ) => void;
}

export const Login = ({ onChangeType }: Props) => {
  const { t } = useTranslation("common");
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginSendResp, { isLoading, isError }] = useLazyLoginQuery();
  const { loginUser } = useActions();
  const handleChangeLogin = (value: string) => setLogin(value);
  const handleChangePassword = (value: string) => setPassword(value);

  const handleLogin = async () => {
    const resp = await loginSendResp({ login, password });
    if (resp?.status === "fulfilled") {
      const { access_token, user } = resp?.data.response;
      loginUser(user);
      localStorage.setItem("token", access_token);
      window.location.reload();
    }
  };

  const handleSubmitOnPressEnter = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter" && login.length > 0 && password.length > 0) {
      handleLogin();
    }
  };

  return (
    <>
      {isError && <ErrorMessage message={"Данные не найдены"} />}
      <Input
        placeholder={t("auth.email_or_number")}
        value={login}
        onChange={handleChangeLogin}
        onKeyDown={handleSubmitOnPressEnter}
        classes="email-input"
      />
      <Input
        placeholder={t("auth.password")}
        value={password}
        onChange={handleChangePassword}
        onKeyDown={handleSubmitOnPressEnter}
        password
      />
      <NavigateButton
        title={t("auth.forgotPassword")}
        onClick={() => onChangeType("forgotPassword")}
      />
      <Button
        label={t("auth.login")}
        onClick={handleLogin}
        disabled={isLoading}
      />
    </>
  );
};
