import { formatDate, formatTime } from "../../../helpers/date";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox/Checkbox";

interface Props {
  status: string;
  clientName: string;
  ns: string;
  notification: string;
  userName: string;
  userRole: string | undefined;
  createdAt: string;
  isAlert: boolean;
  isSelected: boolean;
  onSelect: () => void;
}

export const NotificationRow = ({
  status,
  clientName,
  ns,
  notification,
  userName,
  userRole,
  createdAt,
  isAlert,
  isSelected,
  onSelect,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <tr className={`${isSelected && "selected"}`}>
      <td>
        <Checkbox checked={isSelected} onChange={onSelect} />
      </td>
      <td></td>
      <td>
        <div className="bold">{ns}</div>
      </td>
      <td>
        <div className={`${status === "new" && "bold"}`}>{clientName}</div>
      </td>
      <td>{notification}</td>
      <td>
        <div>{userName}</div>
        <div className="grey">{userRole}</div>
      </td>
      <td>
        <div>{formatDate(createdAt, false, t)}</div>
        <div className="grey">{formatTime(createdAt)}</div>
      </td>
    </tr>
  );
};
