import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const NotFound = () => {
  const { t } = useTranslation("common");

  return <StyledNotFound>{t("cargos.notFound")}</StyledNotFound>;
};

const StyledNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  color: #7e7e8f98;
`;
