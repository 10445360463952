import { useNavigate } from "react-router";
import { useAppSelect } from "../../../hooks/redux";
import { Content } from "./Content";

export const Create = () => {
  const navigate = useNavigate();
  const { user } = useAppSelect((state) => state.auth);
  const isAvaibleCreate =
    user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 13;

  if (!isAvaibleCreate && user) {
    navigate("/cargos");
  }

  return (
    <div>
      {user ? (
        <>
          <Content />
        </>
      ) : null}
    </div>
  );
};
