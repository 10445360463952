import "./App.css";
import { useEffect, useRef } from "react";
import { Auth } from "./pages/Auth";
import { Pages } from "./pages/Pages";
import { Route, Routes, useNavigate } from "react-router";
import { useGetUserQuery } from "./store/auth/auth.api";
import { useActions } from "./hooks/actions";
import { useLocation } from "react-router";
import cogoToast from "cogo-toast";

function App() {
  const { data: userData, error, refetch }: any = useGetUserQuery(null);
  const { loginUser } = useActions();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  const handleCheckIsTelegramLogined = () => {
    cogoToast.info(
      "Чтобы получать сообщения о изменениях в грузах в телеграме \n (кликните чтобы перейти в настройки)",
      {
        hideAfter: 6,
        position: "top-right",
        onClick: () => navigate("/settings"),
        heading: "Подключите Telegram",
      }
    );
  };

  useEffect(() => {
    const errorMessage = error?.data?.message;
    if (errorMessage === "Unauthenticated." && token) {
      localStorage.removeItem("token");
      window.location.replace("/");
    } else if (userData) {
      const isTelegram = userData?.response?.telegram;
      loginUser(userData?.response);
      isFirstRender.current && !isTelegram && handleCheckIsTelegramLogined();
      isFirstRender.current = false;
    }
    // eslint-disable-next-line
  }, [loginUser, userData]);

  const handleUpdateUser = () => {
    if (!document.hidden) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", handleUpdateUser);
    return () =>
      window.removeEventListener("visibilitychange", handleUpdateUser);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [location]);

  if (!token && location.pathname.split("/")[1] !== "info") {
    return <Auth />;
  }

  return (
    <div>
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<Pages />} />
      </Routes>
    </div>
  );
}

export default App;
