import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Option, Select } from "../../components/Select/Select";

interface Props {
  onCreateCompany: () => void;
  companies: Option[];
  selectedCompany: string | undefined;
  onSelectCompany: (value: undefined | string) => void;
}

export const Header = ({
  onCreateCompany,
  companies,
  selectedCompany,
  onSelectCompany,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledHeader>
      <PageHeader title={t("pages.companies")} classes="header-title" />
      <div className="btns">
        <Select
          options={companies}
          value={selectedCompany}
          onSelectOption={(opt: Option) =>
            onSelectCompany(
              opt.value === selectedCompany ? undefined : opt.value
            )
          }
          classes="select-company"
          label="Выбор клиента"
          placeholder="Найти клиента по имени"
        />
        <Button
          title={t("companies.addCompany")}
          onClick={onCreateCompany}
          color="blue"
          classes="btn"
        />
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .header-title {
    margin: 0;
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .select-company {
    margin-right: 20px;
    width: 240px;
  }
  .btn {
    max-width: 240px;
    height: 50px;
    background: #4f75f4;
    width: 240px;
    flex-shrink: 0;
  }
  @media (max-width: 600px) {
    .btns {
      flex-direction: column;
      width: 100%;
      .select-company,
      .btn {
        width: 100%;
        max-width: 100%;
      }
      .btn {
        margin-top: 20px;
      }
    }
  }
`;
