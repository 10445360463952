import React from "react";
import styled from "styled-components";

interface Props {
  data: { title: string; value: string }[];
  classes?: string | undefined;
}

export const InfoCard = ({ data, classes }: Props) => (
  <StyledInfoCard className={classes}>
    {data.map((item, i) => (
      <React.Fragment key={i}>
        <div>{item.title}</div>
        <div className="bold">{item.value}</div>
      </React.Fragment>
    ))}
  </StyledInfoCard>
);

const StyledInfoCard = styled.div`
  display: grid;
  grid-template-columns: 345px 1fr;
  font-family: "Encode Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 116%;
  color: #1c1c1e;
  word-break: break-all;
  .bold {
    font-weight: 600;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    font-weight: bold;
    .bold {
      font-weight: 400 !important;
      margin: 5px 0 15px;
    }
  }
`;
