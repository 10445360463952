import styled from "styled-components";

export const Title = () => <StyledTitle>СТАТУС ОПЛАТЫ</StyledTitle>;

const StyledTitle = styled.div`
  text-align: center;
  margin-bottom: 16px;
  color: #575756;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
