import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { useAppSelect } from "../../../hooks/redux";
import {
  CONFIRM_INIT,
  Confirm,
  ConfirmInterface,
} from "../../../components/Confirm/Confirm";
import cogoToast from "cogo-toast";
import { useParams } from "react-router";
import { useLazyCargoBrokerChangeStatusQuery } from "../../../store/cargo/cargo.api";
import { useLazyChangeCargoStatusQuery } from "../../../store/canban/canban.api";
import { ReleaseModal } from "./ReleaseModal/ReleaseModal";

interface Props {
  cargoData: any;
  onRefreshCargoData: () => void;
}

export const SendButton = ({ cargoData, onRefreshCargoData }: Props) => {
  const { t } = useTranslation("common");
  const { cargoId } = useParams();
  const { user } = useAppSelect((state) => state.auth);
  const [statusChanged, setStatusChanged] = useState<boolean>(false);
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [loading, setLoading] = useState<boolean>(false);
  const [changeStatus] = useLazyCargoBrokerChangeStatusQuery();
  const [changeCargoStatus] = useLazyChangeCargoStatusQuery();
  const [release, setRelease] = useState<boolean>(false);

  const handleGetNextCargoStatus = () => {
    console.log(cargoData);
    if (cargoData?.kanban_status === "my_cargos") {
      return t("cargo.confirm1");
    } else if (cargoData?.kanban_status === "request") {
      return t("cargo.confirm5");
    } else if (cargoData?.kanban_status === "storage_kz") {
      return t("cargo.confirm6");
    } else if (cargoData?.kanban_status === "customs") {
      return t("cargo.confirm8");
    }
  };

  const handleGetStatus = (isNext: boolean): string => {
    const statuses: any = [
      "my_cargos",
      "request",
      "storage_kz",
      "customs",
      "completed",
    ];
    let currentStatusIndex: number = statuses.indexOf(cargoData?.kanban_status);
    return statuses[isNext ? 1 + currentStatusIndex : currentStatusIndex - 1];
  };

  const handleChangeCargoStatus = (
    isNext: boolean,
    isReleaseModal?: boolean
  ) => {
    if (
      cargoData?.kanban_status === "storage_kz" &&
      (cargoData?.status_operator_financier !== "approve" ||
        cargoData?.status_transit_manager !== "approve")
    ) {
      const message = `Нужно подтверждение оператором-финансистом и управляющим транзитом`;
      cogoToast.error(message, {
        hideAfter: 3,
        position: "top-right",
      });
      return null;
    }

    if (
      cargoData?.kanban_status === "storage_kz" &&
      cargoData?.receivers?.length > 1 &&
      !isReleaseModal
    ) {
      setRelease(true);
      return null;
    }

    setComfirmData({
      open: true,
      title: isNext ? handleGetNextCargoStatus() : t("cargo.refuse1"),
      onCancel: () => setComfirmData(CONFIRM_INIT),
      onConfirm: () => {
        setLoading(true);
        changeCargoStatus({
          cargo_id: cargoData?.id,
          status: handleGetStatus(isNext),
          position: 0,
        }).then((response) => {
          setLoading(false);
          setComfirmData(CONFIRM_INIT);
          if (response.status === "fulfilled") {
            cogoToast.success(
              t(isNext ? "cargo.confirmSuccess" : "cargo.resuseSuccess"),
              { hideAfter: 3, position: "top-right" }
            );
            console.log("here");
            onRefreshCargoData();
            setStatusChanged(true);
          } else {
            cogoToast.error(t("cargo.changeStatusError"), {
              hideAfter: 3,
              position: "top-right",
            });
          }
        });
      },
    });
  };

  const handleCheckButtonAccess = (): boolean => {
    switch (user?.role_id) {
      case 1:
        //admin
        return true;
      case 3:
        //broker cn
        return cargoData?.draft_status === 0;
      case 11:
        // broker kz
        return (
          cargoData?.draft_status === 0 ||
          cargoData?.kanban_status === "request" ||
          cargoData?.kanban_status === "storage_kz"
        );
      case 8:
        // Диспетчер Казакстан
        return (
          cargoData?.kanban_status === "storage_kz" &&
          cargoData?.status_operator_financier === "approve" &&
          cargoData?.status_transit_manager === "approve"
        );
      case 12:
        // Таможня КЗ
        return cargoData?.kanban_status === "customs";
      case 13:
        // Клиент
        return (
          cargoData?.draft_status === 0 ||
          cargoData?.kanban_status === "request"
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    setStatusChanged(false);
  }, [cargoData]);

  return (
    <StyledSendButton>
      <Confirm {...confirmData} />
      <ReleaseModal
        open={release}
        onClose={() => setRelease(false)}
        onSendToNextStatus={() => handleChangeCargoStatus(true, true)}
        totalVolume={cargoData?.product_volume ?? 0}
      />
      {handleCheckButtonAccess() &&
        !statusChanged &&
        cargoData?.kanban_status !== "completed" && (
          <>
            <Button
              title={t(
                cargoData?.kanban_status === "customs"
                  ? "cargo.end"
                  : cargoData?.kanban_status === "storage_kz"
                  ? "Отгрузка груза"
                  : "cargo.check"
              )}
              onClick={() => handleChangeCargoStatus(true)}
              classes="btn send-btn"
              disabled={loading}
            />
          </>
        )}
      {cargoData?.kanban_status === "customs" && (
        <Button
          title={t("cargo.refuse")}
          color="blue"
          onClick={() => handleChangeCargoStatus(false)}
          classes="btn refuse-btn"
          disabled={loading}
        />
      )}
    </StyledSendButton>
  );
};

const StyledSendButton = styled.button`
  .btn {
    height: 50px;
    width: 240px;
    color: #fff;
    padding: 0;
    margin-left: 20px;
  }
  .send-btn {
    background: #94e18c;
  }
  @media (max-width: 800px) {
    padding: 0;
    .refuse-btn {
      margin-top: 10px;
    }
    .btn {
      margin-left: 0;
    }
  }
`;
