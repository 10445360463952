import { Row } from "./Row";

interface Props {
  data: any;
  columns: any;
  selectedCargos: { title: string; id: number }[];
  onSelectCargo: (title: string, cargoId: number) => void;
}

const STATUSES = {
  my_cargos: {
    title: "",
    color: "green",
    disable: false,
  },
  my_cargos_approve: {
    title: "На проверке",
    color: "orange",
    disable: true,
  },
  my_cargos_reject: {
    title: "Отклонен",
    color: "red",
    disable: false,
  },
};

export const MyCargos = ({
  data,
  columns,
  selectedCargos,
  onSelectCargo,
}: Props) => (
  <>
    {data.map((row: any, i: number) => (
      <Row
        key={i}
        colums={columns}
        isSelected={!!selectedCargos.find((c) => c.id === row.id)}
        row={row}
        onSelectCargo={() => onSelectCargo(row?.ns, row.id)}
        status={STATUSES[row.kanban_status as keyof typeof STATUSES].title}
      />
    ))}
  </>
);
