import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Option, Select } from "../../components/Select/Select";
import { useAppSelect } from "../../hooks/redux";

interface Props {
  selectedTeam: string | undefined;
  onSelectTeam: (value: undefined | string) => void;
  teams: Option[];
  onCreate: () => void;
}

export const Header = ({
  selectedTeam,
  onSelectTeam,
  teams,
  onCreate,
}: Props) => {
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state: any) => state.auth);
  const isAccess =
    user?.role_id === 1 || user?.role_id === 8 || user?.role_id === 4;

  return (
    <StyledHeader>
      <PageHeader title={t("pages.teams")} classes="header-title" />
      <div className="btns">
        <Select
          options={teams}
          value={selectedTeam}
          onSelectOption={(opt: Option) =>
            onSelectTeam(opt.value === selectedTeam ? undefined : opt.value)
          }
          classes="select-company"
          label="Выбор группы"
          placeholder="Найти группу"
        />
        {isAccess && (
          <Button
            title={"Добавить группу"}
            onClick={onCreate}
            color="blue"
            classes="btn"
          />
        )}
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .header-title {
    margin: 0;
  }
  .btns {
    display: flex;
    align-items: center;
  }
  .select-company {
    margin-right: 20px;
    width: 240px;
  }
  .btn {
    max-width: 240px;
    height: 50px;
    background: #4f75f4;
    width: 240px;
    flex-shrink: 0;
  }
  @media (max-width: 1000px) {
    .btns {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .btn,
    .select-company {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 650px) {
    .btns {
      grid-template-columns: 1fr;
    }
  }
`;
