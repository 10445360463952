import styled from "styled-components";
import editIcon from "../../../../assets/images/icons/edit_btn.svg";
import closeIcon from "../../../../assets/images/icons/close-white.svg";

interface Props {
  onDelete: () => void;
  onToggleEdit: () => void;
  isEdit: boolean;
}

export const Actions = ({ onDelete, onToggleEdit, isEdit }: Props) => (
  <StyledActions>
    <img src={isEdit ? closeIcon : editIcon} alt="" onClick={onToggleEdit} />
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onDelete}
    >
      <path
        d="M13.1846 3.54411H10.9092V1.65411C10.9092 1.547 10.8877 1.44094 10.8467 1.342C10.8057 1.24305 10.7457 1.15315 10.6699 1.07745C10.5941 1.00176 10.5043 0.941763 10.4053 0.900864C10.3063 0.859966 10.1999 0.838981 10.0928 0.839112H3.90918C3.69294 0.839112 3.48503 0.924946 3.33203 1.07776C3.17903 1.23057 3.09304 1.43787 3.09277 1.65411V3.54411H0.817383C0.710356 3.54411 0.603763 3.56518 0.504883 3.60614C0.406002 3.6471 0.315914 3.70714 0.240234 3.78282C0.164555 3.8585 0.105411 3.94835 0.0644531 4.04723C0.0234955 4.14611 0.00195313 4.25207 0.00195312 4.3591V7.4521C0.00195312 7.55913 0.0234955 7.66511 0.0644531 7.76399C0.105411 7.86287 0.164555 7.95272 0.240234 8.0284C0.315914 8.10408 0.406002 8.16411 0.504883 8.20506C0.603763 8.24602 0.710356 8.26711 0.817383 8.26711H1.41699L2.125 16.4171C2.14276 16.6202 2.2364 16.8093 2.38672 16.947C2.53704 17.0847 2.73265 17.1611 2.93652 17.1611H11.0742C11.2781 17.1611 11.4747 17.0847 11.625 16.947C11.7753 16.8093 11.868 16.6202 11.8857 16.4171L12.5938 8.26711H13.1943C13.4105 8.26711 13.6177 8.18124 13.7705 8.0284C13.9234 7.87556 14.0088 7.66825 14.0088 7.4521V4.3591C14.0088 4.25131 13.9878 4.14459 13.9463 4.04512C13.9048 3.94565 13.8442 3.8554 13.7676 3.7796C13.6909 3.7038 13.5999 3.64397 13.5 3.60355C13.4001 3.56312 13.2924 3.54292 13.1846 3.54411ZM4.72266 2.4691H9.27832V3.54411H4.72266V2.4691ZM1.63086 5.1751H12.3691V6.63609H1.63086V5.1751ZM10.3203 15.5311H3.67871L3.04688 8.26711H10.9473L10.3203 15.5311Z"
        fill={isEdit ? "#FFF" : "#B1B1B1"}
      />
    </svg>
  </StyledActions>
);

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    &:first-child {
      margin-right: 15px;
    }
  }
  svg {
    cursor: pointer;
  }
`;
