import styled from "styled-components";
import removeIcon from "../../assets/images/icons/clean.svg";
interface Props {
  title: string;
  onRemove: () => void;
}

export const Item = ({ title, onRemove }: Props) => (
  <StyledItem>
    <div>{title}</div>
    <img src={removeIcon} alt="" onClick={onRemove} />
  </StyledItem>
);

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #575756;
  padding: 25px 10px 10px;
  border-bottom: 1px solid #ececec;
  img {
    cursor: pointer;
  }
`;
