import { useTranslation } from "react-i18next";
import styled from "styled-components";
import likeIcon from "../../assets/images/icons/like.svg";
import { Button } from "../AuthCard/Button";

interface Props {
  onClick: () => void;
  subtitle?: string;
}

export const SuccesModal = ({ onClick, subtitle }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledSuccesModal>
      <img src={likeIcon} alt="" />
      <h5>{t("auth.doneSuccessfully")}</h5>
      <div>{subtitle ?? t("auth.doneSuccessfullyDescr")}</div>
      <Button label={t("auth.enter")} onClick={onClick} />
    </StyledSuccesModal>
  );
};

const StyledSuccesModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Archivo", sans-serif;
  line-height: 140%;
  text-align: center;
  img {
    margin-bottom: 40px;
  }
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #09101d;
    margin: 0 0 10px;
  }
  div {
    font-weight: 600;
    font-size: 14px;
    color: #747b84;
    margin-bottom: 30px;
    max-width: 343px;
  }
  @media (max-width: 420px) {
    div {
      margin-bottom: 18px;
    }
  }
`;
