import {
  handleCheckIsStorage,
  handleGetUserAccessStatus,
} from "../../../helpers/cargo";

export const handleCheckIsDraggable = (
  columnId: string,
  start_overload_date: string | null,
  end_overload_date: string | null,
  start_storage_date: string | null,
  end_storage_date: string | null,
  user: any,
  status: string,
  statusOperatorFinancier: string,
  statusTransitManager: string
) => {
  if (
    status === "storage_kz" &&
    (statusOperatorFinancier !== "approve" ||
      statusTransitManager !== "approve")
  ) {
    return false;
  }
  if (handleCheckIsStorage(start_storage_date, end_storage_date)) {
    return false;
  }

  if (!handleGetUserAccessStatus(user?.role_id, columnId)) {
    return false;
  }

  if (end_overload_date && start_overload_date) {
    const endOverloadDateParsed = Date.parse(
      new Date(end_overload_date).toString()
    );
    const startOverloadDateParsed = Date.parse(
      new Date(start_overload_date).toString()
    );

    if (startOverloadDateParsed > endOverloadDateParsed) {
      return false;
    }
  } else if (start_overload_date && !end_overload_date) {
    return false;
  }

  return true;
};

export const handleUpdateDataAfterDraging = (
  lastData: any,
  fromColumn: any,
  toColumn: any,
  id: number,
  index: number,
  card: any
) => {
  const updateData = lastData.map((column: any) => {
    if (fromColumn === toColumn && column.id === fromColumn) {
      return {
        ...column,
        cards: [
          ...column.cards.filter((c: any) => c.id !== id).splice(0, index),
          { ...card, laneId: toColumn },
          ...column.cards.filter((c: any) => c.id !== id).splice(index),
        ],
      };
    } else {
      if (column.id === fromColumn) {
        return {
          ...column,
          cards: column.cards.filter((c: any) => c.id !== id),
          label: column.label - 1 === 0 ? "0" : column.label - 1,
        };
      }
      if (column.id === toColumn) {
        return {
          ...column,
          cards: [
            ...column.cards.filter((c: any) => c.id !== id).splice(0, index),
            { ...card, laneId: toColumn },
            ...column.cards.filter((c: any) => c.id !== id).splice(index),
          ],
          label: column.label + 1,
        };
      }
    }

    return column;
  });

  return updateData;
};

const handleCountReadyCargosKubs = (column: any, t: any) => {
  const readyCargos = column.cards.filter((c: any) => c.priority === 1);
  const kubsSum = readyCargos.reduce(
    (sum: any, a: any) => sum + a.product_volume,
    0
  );
  return column.id === "request"
    ? `${Math.floor(kubsSum)} ${t("cargo.kub")} / `
    : "";
};

export const formatKanbanData = (cargosData: any, user: any, t: any): any => {
  return cargosData.map((column: any) => ({
    ...column,
    droppable: !!handleGetUserAccessStatus(user?.role_id, column.id),
    label: `${handleCountReadyCargosKubs(column, t)} ${
      column.cards.length
    } грузов`,
    cards: column.cards.map((c: any) => ({
      ...c,
      laneId: column.id,
      draggable: handleCheckIsDraggable(
        column.id,
        c.start_overload_date,
        c.end_overload_date,
        c.start_storage_date,
        c.end_storage_date,
        user,
        column.id,
        c.status_operator_financier,
        c.status_transit_manager
      ),
    })),
  }));
};
