import styled from "styled-components";
import { StatusLine } from "./StatusLine";
import { StatusPoint } from "./StatusPoint";

interface Status {
  title: string;
  active: boolean;
}

interface Props {
  data: Status[];
}

export const StatusBar = ({ data }: Props) => {
  return (
    <StyledStatusBar className="status-bar-wrapper">
      {data.map((statusItem, i) => (
        <>
          <StatusPoint title={statusItem.title} active={statusItem.active} />
          {i < data.length - 1 && <StatusLine active={data[i + 1].active} />}
        </>
      ))}
    </StyledStatusBar>
  );
};

const StyledStatusBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 32px; */
  margin-left: 10px;
  height: 10px;
  width: 100%;
  @media (min-width: 350px) {
    width: 100%;
    padding: 0 20px;
    flex-shrink: 0;
  }
  @media (max-width: 400px) {
    justify-content: start;
  }
`;
