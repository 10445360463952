import styled from "styled-components";
import { useAppSelect } from "../../hooks/redux";

interface Props {
  userName?: string | undefined;
  userAvatar?: string | null;
  classses?: string;
}

export const Avatar = ({ userName, userAvatar, classses }: Props) => {
  return (
    <StyledAvatar className={`no-select ${classses}`} avatar={userAvatar}>
      <div className="avatar" />
      <span>{userName ? userName[0] : null}</span>
    </StyledAvatar>
  );
};

interface StyledAvatarProps {
  avatar?: string | undefined | null;
}

const StyledAvatar = styled.div<StyledAvatarProps>`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  flex-shrink: 0;
  text-transform: capitalize;
  background: #7392f7;
  overflow: hidden;
  position: relative;
  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    ${(props) =>
      props.avatar &&
      `background: url(${props.avatar}) center center/cover no-repeat;`}
  }
`;
