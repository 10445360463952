import { useTranslation } from "react-i18next";
import { Input } from "../../../Input/Input";
import { Modal } from "../../../Modal/Modal";
import { Info } from "./Info";
import { useAppSelect } from "../../../../hooks/redux";
import {
  useGetUsersRolesQuery,
  useLazyEditUserQuery,
} from "../../../../store/admin/admin.api";
import { useState, useEffect } from "react";
import cogoToast from "cogo-toast";
import styled from "styled-components";
import {
  useGetUserQuery,
  useLazyChangePasswordQuery,
} from "../../../../store/auth/auth.api";
import { handleRenderTelegramConnectBtn } from "../../../../helpers/user";
import { TelegramAuth } from "./TelegramAuth";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ERRORS_INIT = {
  old_password: null,
  password: null,
  password_confirmation: null,
};

export const EditProfile = ({ open, onClose }: Props) => {
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state) => state.auth);
  const { refetch }: any = useGetUserQuery(null);
  const { data: usersRoles } = useGetUsersRolesQuery(null);
  const [editUser] = useLazyEditUserQuery();
  const [chagePassword] = useLazyChangePasswordQuery();
  const userRole = usersRoles?.find(
    (role: any) => Number(role.value) === user?.role_id
  )?.title;
  // user data
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [telegram, setTelegram] = useState<string>("");
  // change password
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newAgainPassword, setNewAgainPassword] = useState<string>("");
  const [errors, setErrors] = useState<{
    old_password: string | null;
    password: string | null;
    password_confirmation: string | null;
  }>(ERRORS_INIT);

  useEffect(() => {
    setName(user?.name ?? "");
    setEmail(user?.email ?? "");
    setPhone(user?.phone ?? "");
    setTelegram(user?.telegram ?? "");
  }, [user, userRole, open]);

  const handleResetValues = () => {
    setErrors(ERRORS_INIT);
    setName(user?.name ?? "");
    setEmail(user?.email ?? "");
    setPhone(user?.phone ?? "");
    setTelegram(user?.telegram ?? "");
    setOldPassword("");
    setNewPassword("");
    setNewAgainPassword("");
  };

  const handleCloseEdit = () => {
    handleResetValues();
    onClose();
  };

  const handleSaveUserData = () => {
    editUser({
      name,
      role_id: user?.role_id ?? null,
      user_id: user?.id ?? null,
      phone,
      telegram,
    }).then((response: any) => {
      if (response.status === "fulfilled") {
        cogoToast.success(t("settings.pesonalInfoUpdated"), {
          hideAfter: 3,
          position: "top-right",
        });
        refetch();
      }
    });
  };

  const handleSaveNewPassword = () => {
    setErrors(ERRORS_INIT);
    if (newPassword.length < 8) {
      setErrors({ ...errors, password: t("settings.passwordLength") });
    } else if (newPassword !== newAgainPassword) {
      const errorMessage = t("settings.passwordNotSimilar");
      setErrors({
        ...errors,
        password: errorMessage,
        password_confirmation: errorMessage,
      });
    } else {
      chagePassword({
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: newAgainPassword,
      }).then((response: any) => {
        if (response.status === "fulfilled") {
          setOldPassword("");
          setNewPassword("");
          setNewAgainPassword("");
          cogoToast.success(t("settings.passwordChanged"), {
            hideAfter: 3,
            position: "top-right",
          });
        } else {
          setErrors(response.error.data.errors);
        }
      });
    }
  };

  const handleSave = () => {
    handleSaveUserData();

    if (oldPassword.length > 0 && newPassword.length > 0) {
      handleSaveNewPassword();
    }
  };

  useEffect(() => {
    handleRenderTelegramConnectBtn();
  }, []);

  return (
    <Modal
      title="Профиль / Персональная информация"
      cancelBtnText={t("common.cancel")}
      submitBtnText={t("common.save")}
      onSubmit={handleSave}
      open={open}
      onCancel={handleCloseEdit}
      disabledSubmit={name.length === 0}
    >
      <StyledEditProfile>
        <Info />
        <div className="inputs">
          <Input
            placeholder={t("settings.nameAndSurname")}
            value={name}
            onChange={(value) => setName(value)}
          />
          <div className="input-group">
            <Input
              placeholder={t("settings.email")}
              value={email}
              onChange={(value) => null}
              disabled
            />
            <Input
              placeholder={t("settings.phone")}
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </div>
          <div className="input-group">
            <Input
              placeholder={t("settings.telegram")}
              value={telegram}
              onChange={(value) => setTelegram(value)}
              disabled
            />
            <TelegramAuth />
          </div>
          <Input
            placeholder={t("settings.oldPassword")}
            value={oldPassword}
            onChange={(value) => setOldPassword(value)}
            classes="old-password-input"
            error={!!errors.old_password}
            errorMessage={errors.old_password ?? ""}
            password
          />
          <div className="input-group">
            <Input
              placeholder={t("settings.newPassword")}
              value={newPassword}
              onChange={(value) => setNewPassword(value)}
              error={!!errors.password}
              errorMessage={errors.password ?? ""}
              password
            />
            <Input
              placeholder={t("settings.confirmNewPassword")}
              value={newAgainPassword}
              onChange={(value) => setNewAgainPassword(value)}
              error={!!errors.password_confirmation}
              errorMessage={errors.password_confirmation ?? ""}
              password
            />
          </div>
        </div>
      </StyledEditProfile>
    </Modal>
  );
};

const StyledEditProfile = styled.div`
  .inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    input {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #575756;
    }
    .input-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
`;
