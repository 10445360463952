import styled from "styled-components";
import { Header } from "../components/Header/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { Dashboard } from "./Dashboard/Dashboard";
import { Route, Routes } from "react-router";
import { Cargo } from "./Cargo/Cargo";
import { useLocation } from "react-router";
import { Notifications } from "./Notifications/Notifications";
import { Client } from "./Client/Client";
import { TestingRoles } from "./TestingRoles";
import { Teams } from "./Teams/Teams";
import { Companies } from "./Companies/Companies";
import { TeamHistory } from "./TeamHistory/TeamHistory";
import { useAppSelect } from "../hooks/redux";
import { Brokers } from "./Brokers/Brokers";
import { Create } from "./ManageCargo/Create/Create";
import { Edit } from "./ManageCargo/Edit/Edit";
import { CargosBoard } from "./CargosBoard/CargosBoard";
import { ClientsCargos } from "./ClientsCargos/ClientsCargos";
import { CargosNew } from "./CargosNew/CargosNew";
import { Employees } from "./Employees/Employees";
import { Kpi } from "./Kpi/Kpi";
import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import { EditProfile } from "../components/Header/Profile/EditProfile/EditProfile";
import { CargoNew } from "./CargoNew/CargoNew";

export const Pages = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const pathname = location.pathname;
  const { user } = useAppSelect((state: any) => state.auth);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [openEditProfile, setOpenEditProfile] = useState<boolean>(false);

  const handleToggleEditProfile = (value: boolean) => setOpenEditProfile(value);
  const handleToggleOpenSidevar = () => setOpenSidebar(!openSidebar);

  useEffect(() => {
    const isTelegramSuccessPage = location.search === "?success-telegram=true";
    if (isTelegramSuccessPage) {
      cogoToast.success(t("settings.telegramAuth"), {
        hideAfter: 3,
        position: "top-right",
      });
    }
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <>
      <Routes>
        <Route path="/info/:cargoId" element={<Client />} />
        <Route
          path="*"
          element={
            <StyledPages>
              <EditProfile
                open={openEditProfile}
                onClose={() => handleToggleEditProfile(false)}
              />
              <Header onEditProfile={() => handleToggleEditProfile(true)} />
              <Sidebar
                open={openSidebar}
                onToggleOpen={handleToggleOpenSidevar}
                onEditProfile={() => handleToggleEditProfile(true)}
              />
              <div className="wrapper">
                <Routes>
                  {user?.role_id === 13 ||
                  user?.role_id === 14 ||
                  user?.role_id === 15 ? (
                    <Route
                      path="/"
                      element={<CargosNew sidebarOpen={openSidebar} />}
                    />
                  ) : (
                    <Route path="/" element={<Dashboard />} />
                  )}
                  <Route
                    path="/cargos"
                    element={<CargosNew sidebarOpen={openSidebar} />}
                  />
                  <Route path="/cargos-board" element={<CargosBoard />} />
                  <Route path="/employees" element={<Employees />} />
                  {/* <Route path="/cargo-old/:cargoId" element={<Cargo />} /> */}
                  <Route path="/cargo/:cargoId" element={<CargoNew />} />
                  <Route path="/create-cargo" element={<Create />} />
                  <Route path="/edit-cargo/:cargoId" element={<Edit />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/test-roles" element={<TestingRoles />} />
                  <Route path="/cargos-clients" element={<ClientsCargos />} />
                  {user?.role_id !== 13 && (
                    <>
                      <Route path="/teams" element={<Teams />} />
                      <Route path="/kpi" element={<Kpi />} />
                      <Route
                        path="/team-history/:teamId"
                        element={<TeamHistory />}
                      />
                      <Route path="/companies" element={<Companies />} />
                      <Route path="/brokers" element={<Brokers />} />
                    </>
                  )}
                  {/* {user?.role_id === 13 ? (
                    <Route path="*" element={<Cargos />} />
                  ) : (
                    <Route path="*" element={<Dashboard />} />
                  )} */}
                </Routes>
              </div>
            </StyledPages>
          }
        />
      </Routes>
    </>
  );
};

const StyledPages = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content 1fr;
  min-height: 100vh;
  gap: 20px;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  padding-top: 0px;
  .wrapper {
    width: 100%;
    overflow: auto;
  }
  @media (max-width: 1000px) {
    gap: 20px 0px;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    .wrapper {
      padding-bottom: 280px;
    }
  }
`;
