import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const Title = () => {
  const { t } = useTranslation("common");

  return <StyledTitle>{t("cargos.column")} </StyledTitle>;
};

const StyledTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 147%;
  color: #1c1c1e;
`;
