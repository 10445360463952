import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatDate } from "../../../helpers/date";
import { InfoList } from "../InfoList/InfoList";
import { Footer } from "./Footer";

interface Props {
  receivers: any[];
  cargoData: any;
}

export const Cars = ({ receivers, cargoData }: Props) => {
  const { t } = useTranslation("common");
  const data = receivers.map((receiver, i) => [
    { value: `${t("cargo.receiver")} ${1 + i}`, title: "", bold: true },
    { title: t("cargo.driverName"), value: receiver.company ?? "" },
    { title: t("cargo.carNumber"), value: receiver.car_number ?? "" },
    { title: t("cargo.iin"), value: receiver.iin ?? "" },
    {
      title: t("cargo.numberLicence"),
      value: receiver.udv_number ?? "",
    },
    {
      title: t("cargo.numberTechDoc"),
      value: receiver.technical_passport_number ?? "",
    },
    {
      title: t("cargo.trap"),
      value:
        receiver.trap === "trailer"
          ? t("cargo.withTrap")
          : receiver.trap === "not_trailer"
          ? t("cargo.withoutTrap")
          : t("cargo.halfTrap"),
    },
    {
      title: t("cargo.carType"),
      value: receiver.track ? t("cargo.car") : "",
    },
    {
      title: "Дата выезда",
      value: receiver?.date_departure
        ? formatDate(receiver?.date_departure, true)
        : "",
    },
    {
      title: t("cargo.valueKub"),
      value: receiver.volume ?? "",
    },
  ]);

  return (
    <>
      <StyledCars className="content-card card-wrapper">
        {data.map((car, i) => (
          <InfoList key={i} data={car} />
        ))}
      </StyledCars>
      <Footer cargoData={cargoData} />
    </>
  );
};

const StyledCars = styled.div`
  padding: 33px 28px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  @media (max-width: 1000px) {
    height: max-content !important;
  }
`;
