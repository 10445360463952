import { NotificationsTable } from "./NotificationsTable/NotificationsTable";
import { Header } from "./Header";

export const Notifications = () => {
  return (
    <div>
      <Header />
      <NotificationsTable />
    </div>
  );
};
