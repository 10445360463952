import { useState } from "react";
import manageIcon from "../../../assets/images/icons/manage-columns.svg";
import { Dropdown } from "./Dropdown";
import styled from "styled-components";

interface Props {
  pageSize: number;
  onChangePageSize: (value: number) => void;
  manageColumns?: boolean;
}

export const ManageColumns = ({
  pageSize,
  onChangePageSize,
  manageColumns,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggleOpen = (value: boolean) => setOpen(value);
  return (
    <StyledManageColumns open={open}>
      <img
        src={manageIcon}
        alt=""
        onClick={() => handleToggleOpen(true)}
        className="show-btn"
      />
      <Dropdown
        open={open}
        onClose={() => handleToggleOpen(false)}
        pageSize={pageSize}
        onChangePageSize={onChangePageSize}
        manageColumns={manageColumns}
      />
    </StyledManageColumns>
  );
};

interface StyledManageColumnsProps {
  open: boolean;
}

const StyledManageColumns = styled.div<StyledManageColumnsProps>`
  position: relative;
  margin: 0 auto;
  transition: all 0.3s;
  width: ${(props) => (props.open ? "210px !important" : "50px")};
  .show-btn {
    cursor: pointer;
  }
`;
