import styled from "styled-components";
import { Checkbox } from "../../Checkbox/Checkbox";
import { RadioButton } from "../../RadioButton/RadioButton";
import { PageSize } from "./PageSize";
import { FilterColumns } from "./FilterColumns";

interface Props {
  pageSize: number;
  onChangePageSize: (value: number) => void;
  manageColumns?: boolean;
}

export const Options = ({
  pageSize,
  onChangePageSize,
  manageColumns,
}: Props) => (
  <StyledOptions>
    {manageColumns && <FilterColumns />}
    <PageSize pageSize={pageSize} onChangePageSize={onChangePageSize} />
  </StyledOptions>
);

const StyledOptions = styled.div`
  padding: 0 18px 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #575756;
  max-height: 180px;
  overflow: auto;
  .opt {
    display: flex;
    align-items: center;
    padding: 7px 5px 8px;
    border-radius: 10px;
  }
  .label {
    margin-left: 11px;
  }
`;
