import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  data: null,
};

export const brokersSlice = createSlice({
  name: "brokers",
  initialState,
  reducers: {},
});

export const brokersActions = brokersSlice.actions;
export const brokersReducer = brokersSlice.reducer;
