import styled from "styled-components";
import { Button } from "../../../../components/Button/Button";
import { useState } from "react";
import { QrCode } from "../../../../components/QrCode";
import { useLazyGetCargoIdQuery } from "../../../../store/cargo/cargo.api";
import { Documents } from "../../../../components/Documents/Documents";

interface Props {
  documents: File[];
  onAddDocuments: (data: File[]) => void;
  onRemoveDocument: (index: number) => void;
  formData: any;
  onUpdateFormData: (data: any) => void;
}

export const Footer = ({
  documents,
  onAddDocuments,
  onRemoveDocument,
  formData,
  onUpdateFormData,
}: Props) => {
  const [documnetsModal, setDocumentsModal] = useState<boolean>(false);
  const [qrcodeModal, setQrcodeModal] = useState<boolean>(false);
  const [getCargoId] = useLazyGetCargoIdQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOpenQrCode = () => {
    if (formData.cargo_id) {
      setQrcodeModal(true);
    } else {
      setLoading(true);
      getCargoId(null).then((response: any) => {
        const cargo_id = response?.data?.response?.cargo_id;
        setLoading(false);
        if (cargo_id) {
          onUpdateFormData({ ...formData, cargo_id });
          setQrcodeModal(true);
        }
      });
    }
  };

  return (
    <StyledFooter>
      <Documents
        open={documnetsModal}
        onClose={() => setDocumentsModal(false)}
        documents={documents}
        onAddDocuments={onAddDocuments}
        onRemoveDocument={onRemoveDocument}
      />
      <QrCode
        open={qrcodeModal}
        onClose={() => setQrcodeModal(false)}
        cargoId={formData.cargo_id}
      />
      <Button
        title="Документы"
        onClick={() => setDocumentsModal(true)}
        classes="footer-btn"
        color="blue"
      />
      <Button
        title="QR-код"
        onClick={handleOpenQrCode}
        classes="footer-btn"
        color="blue"
        disabled={loading}
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 2px;
  width: max-content;
  border-radius: 10px;
  .footer-btn {
    height: 46px;
    padding: 13px 90px 18px;
    font-size: 12px;
    font-weight: 700;
    &:first-child {
      margin-right: 2px;
    }
  }
`;
