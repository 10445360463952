import styled from "styled-components";

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => <StyledTitle>{title}</StyledTitle>;

const StyledTitle = styled.div`
  font-family: "Archivo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  color: #09101d;
  word-break: break-all;
  @media (min-width: 1000px) {
    display: none;
  }
`;
