import { useState, useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { EmptyTable } from "../../components/EmptyTable/EmptyTable";
import { Paginator } from "../../components/Paginator/Paginator";
import { Table } from "../../components/Table/Table";
import { useLazyGetTeamHistoryQuery } from "../../store/teams/teams.api";
import { TimerTime } from "../../components/TimerTime/TimerTime";
import { TableData } from "../../models/models";

const COLUMNS = [
  // { title: "#" },
  { title: "HS code" },
  { title: "teams.peopleCount" },
  { title: "teams.specTech" },
  { title: "teams.startTime" },
  { title: "teams.endTime" },
  { title: "teams.overloadTime" },
];

export const HistoryTable = () => {
  const { teamId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [getTeamHistory, { data }] = useLazyGetTeamHistoryQuery();
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });
  const [tableData, setTableData] = useState<TableData>({
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  const handleGetTeamHistory = (lastTableData: TableData) => {
    setLoading(true);
    if (!loading) {
      getTeamHistory({
        command_id: teamId ?? "",
        page: tableData.currentPage,
        perPage: tableData.perPage,
      }).then((resp) => {
        setTableData({
          ...lastTableData,
          lastPage: resp?.data?.response?.last_page,
          total: resp?.data?.response?.total,
        });
        setLoading(false);
      });
    }
  };

  const handleChangeTableData = (data: TableData) => {
    setTableData(data);
    handleGetTeamHistory(data);
  };

  useEffect(() => {
    handleGetTeamHistory(tableData);
    // eslint-disable-next-line
  }, [teamId]);

  console.log(tableData);
  return (
    <StyledHistoryTable>
      <Table
        loading={loading}
        columns={COLUMNS.map((column: { title: string }) => ({
          title: column.title,
          active: sort.field === column.title,
          down: sort.field === column.title && sort.desc,
          onSort: () => handleSort(column.title),
        }))}
        hideCheckbox
        hideDelete
        pageSize={tableData.perPage}
        classes="table-wrapper"
        onChangePageSize={(value) =>
          handleChangeTableData({ ...tableData, perPage: value })
        }
      >
        {data?.response?.data &&
          data?.response?.data?.map((overload: any, i: number) => (
            <tr key={i}>
              <td></td>
              <td>
                <div className="bold">
                  <a
                    href={`/#/cargo/${overload?.cargo_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {overload?.cargo_hs}
                  </a>
                </div>
              </td>
              <td>{overload?.people_number}</td>
              <td>{overload?.special_equipment}</td>
              <td>{overload?.date_start}</td>
              <td>{overload?.date_end}</td>
              <td>{overload.loading_time ?? "-"}</td>
            </tr>
          ))}
      </Table>
      {data && data.response.data.length === 0 && !loading && (
        <EmptyTable title="Нет перегрузок" />
      )}
      <Paginator
        tableData={tableData}
        onChangeTableData={handleChangeTableData}
        loading={loading}
        classes="paginator"
      />
    </StyledHistoryTable>
  );
};

const StyledHistoryTable = styled.div`
  overflow: auto;
  min-height: 500px;
  .paginator {
    margin-top: 20px;
    margin-left: auto;
  }
  .table-wrapper {
    height: calc(100vh - 120px - 30px - 70px - 70px);
    width: 100%;
    overflow: auto;
    border-radius: 20px;
    @media (max-width: 1200px) {
      height: calc(100vh - 120px - 30px - 70px - 70px - 72px);
    }
    @media (max-width: 1000px) {
      height: 300px;
    }
    th:first-child > div {
      width: 0px;
    }
  }
  @media (max-width: 1000px) {
    .paginator {
      margin: 10px auto 0;
    }
  }
`;
