import styled from "styled-components";

export const Empty = () => {
  return <StyledEmpty>Выберите клиента</StyledEmpty>;
};

const StyledEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  color: #7e7e8f98;
  margin-top: 20vh;
`;
