import { useEffect, useState } from "react";
import styled from "styled-components";
import { StatusBar } from "../../components/StatusBar/StatusBar";
import { Message } from "./Message";
import { Total } from "./Total";
import { TrackNumber } from "./TrackNumber";
import { Info } from "./Info";
import { Detail } from "./Detail/Detail";
import { useParams } from "react-router";
import { useLazyGetCargoQuery } from "../../store/cargo/cargo.api";
import { Preloader } from "../../components/Preloader/Preloader";
import { useTranslation } from "react-i18next";
import { getCurrencySign } from "../../helpers/cargo";

export const Client = () => {
  const { cargoId } = useParams();
  const [getCargo, { data: cargoData, isLoading }] = useLazyGetCargoQuery();
  const fullPrice =
    (cargoData?.response?.cost?.сost_delivery ?? 0) +
    (cargoData?.response?.cost?.cost_declaration ?? 0) *
      cargoData?.response?.declarations_number +
    (cargoData?.response?.cost?.cost_storage ?? 0) +
    (cargoData?.response?.cost?.cost_overload ?? 0);
  const [location, setLocation] = useState<null | string | undefined>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    getCargo(cargoId);
    // eslint-disable-next-line
  }, [getCargo, cargoId]);

  const getCargoLocation = () => {
    if (cargoData?.response?.location_id === 1) {
      return "складе КНР 1";
    } else if (cargoData?.response?.location_id === 2) {
      return "кране КНР 2";
    } else if (cargoData?.response?.location_id === 3) {
      return "кране";
    } else if (cargoData?.response?.location_id === 4) {
      return "складе";
    } else if (cargoData?.response?.location_id === 5) {
      return "таможне";
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (cargoData?.response && !location) {
      setLocation(getCargoLocation());
    }
    // eslint-disable-next-line
  }, [cargoData]);

  return (
    <StyledClient>
      {isLoading ? (
        <Preloader classes="loading-spinner" />
      ) : cargoData?.response ? (
        <>
          <TrackNumber ns={cargoData?.response?.ns} />
          <Total
            fullPrice={fullPrice ?? 0}
            currencySign={getCurrencySign(cargoData?.response?.cost?.currency)}
          />
          {location && <Message location={location} />}
          <div className="status-bar">
            <StatusBar
              data={[
                {
                  title: t("cargo.status1"),
                  active:
                    cargoData?.response?.kanban_status === "request" ||
                    cargoData?.response?.kanban_status === "storage_kz" ||
                    cargoData?.response?.kanban_status === "customs" ||
                    cargoData?.response?.kanban_status === "completed",
                },
                {
                  title: t("cargo.status4"),
                  active:
                    cargoData?.response?.kanban_status === "storage_kz" ||
                    cargoData?.response?.kanban_status === "customs" ||
                    cargoData?.response?.kanban_status === "completed",
                },
                {
                  title: t("cargo.status5"),
                  active:
                    cargoData?.response?.kanban_status === "customs" ||
                    cargoData?.response?.kanban_status === "completed",
                },
                {
                  title: t("cargo.status6"),
                  active: cargoData?.response?.kanban_status === "completed",
                },
              ]}
            />
          </div>
          <Info cargoData={cargoData?.response} />
          <Detail
            cargoData={cargoData?.response}
            fullPrice={fullPrice ?? 0}
            currencySign={getCurrencySign(cargoData?.response?.cost?.currency)}
          />
        </>
      ) : (
        <div className="empty">{t("cargos.notFound")}</div>
      )}
    </StyledClient>
  );
};

const StyledClient = styled.div`
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  .status-bar {
    margin: 0 auto;
  }
  .loading-spinner {
    height: 100vh;
  }
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    color: #7e7e8f98;
    height: 100vh;
  }
`;
