import styled from "styled-components";
import { CheckingСargo } from "../../../../components/CheckingСargo/CheckingСargo";

interface Props {
  cargoId: number;
  hsCode: string;
  isPrioriy: boolean;
  laneId: string;
  statusOperatorFinancier: string;
  statusTransitManager: string;
  onRefreshData: () => void;
}

export const HsCode = ({
  cargoId,
  hsCode,
  isPrioriy,
  laneId,
  statusOperatorFinancier,
  statusTransitManager,
  onRefreshData,
}: Props) => {
  return (
    <StyledHsCode isPrioriy={isPrioriy} className="clickable">
      <a
        className="hscode"
        href={`/#/cargo/${cargoId}`}
        target="_blank"
        onDragStart={(e) => e.preventDefault()}
        rel="noreferrer"
      >
        {hsCode?.length > 15 ? `${hsCode.slice(0, 15)}...` : hsCode}
      </a>
      <div>
        <CheckingСargo
          cargoStatus={laneId}
          cargoId={cargoId}
          statusTransitManager={statusTransitManager}
          statusOperatorFinancier={statusOperatorFinancier}
          onRefreshCargoData={onRefreshData}
          small
        />
      </div>
    </StyledHsCode>
  );
};

interface StyledHsCodeProps {
  isPrioriy: boolean;
}

const StyledHsCode = styled.div<StyledHsCodeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    display: inline;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
  }
`;
