import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useAppSelect } from "../../../hooks/redux";

interface Props {
  open: boolean;
  onEditProfile: () => void;
  onEditCargoPrice: () => void;
}

export const Dropdown = ({ open, onEditProfile, onEditCargoPrice }: Props) => {
  const { user } = useAppSelect((state) => state.auth);
  const { t } = useTranslation("common");

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <StyledDropdown open={open} className="header-dropdown">
      <div onClick={onEditProfile} className="header-dropdown-dropdown">
        {t("pages.profile")}
      </div>
      <div onClick={onEditCargoPrice} className="header-dropdown-dropdown">
        {t("cargo.changingPrice")}
      </div>
      <NavLink to="/notifications" className="header-dropdown-dropdown">
        {t("pages.notifications")}
      </NavLink>
      {(user?.role_id === 1 || user?.role_id === 3) && (
        <NavLink
          to="/my-cargos"
          className="mobile-link header-dropdown-dropdown"
        >
          {t("pages.myCargos")}
        </NavLink>
      )}
      {(user?.role_id === 1 || user?.role_id === 8 || user?.role_id === 4) && (
        <NavLink to="/teams" className="mobile-link header-dropdown-dropdown">
          {t("pages.teams")}
        </NavLink>
      )}
      {user?.role_id === 1 && (
        <NavLink to="/brokers" className="mobile-link header-dropdown-dropdown">
          {t("pages.brokers")}
        </NavLink>
      )}
      {(user?.role_id === 1 || user?.role_id === 11) && (
        <NavLink
          to="/companies"
          className="mobile-link header-dropdown-dropdown"
        >
          {t("pages.companies")}
        </NavLink>
      )}
      {(user?.role_id === 1 ||
        user?.role_id === 14 ||
        user?.role_id === 15) && (
        <NavLink
          to="/cargos-clients"
          className="mobile-link header-dropdown-dropdown"
        >
          Клиентские грузы
        </NavLink>
      )}
      <NavLink
        to="/cargos-board"
        className="mobile-link header-dropdown-dropdown"
      >
        Доска грузов
      </NavLink>
      <NavLink to="test-roles" className="header-dropdown-dropdown">
        {t("header.testingRoles")}
      </NavLink>
      <a href="/dashboard">Дашборд</a>
      <div onClick={handleLogout}>{t("header.logout")}</div>
    </StyledDropdown>
  );
};

interface StyledDropdownProps {
  open: boolean;
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  position: absolute;
  width: 100%;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  top: 100%;
  right: 0;
  transition: all 0.2s ease;
  padding: 17px 5px;
  visibility: ${(props) => (props.open ? "visivle" : "hidden")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  z-index: ${(props) => (props.open ? 1000 : -20)};
  max-height: 70vh;
  overflow: auto;
  border: 1px solid #f5f5f5;
  border-top: none;
  padding-left: 40px;
  a,
  div {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    transition: all 0.3s;
    &:hover {
      background: #f5f5f5;
    }
  }
  .mobile-link {
    display: none;
  }

  @media (max-width: 1000px) {
    .mobile-link {
      display: block;
    }
  }
  @media (max-width: 600px) {
    width: 200px;
    right: -10px;
    padding: 0 40px 0 0;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
  }
`;
