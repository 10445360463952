import { useRef } from "react";
import styled from "styled-components";
import { Modal } from "../Modal/Modal";
import { useDropzone } from "react-dropzone";
import { File } from "../File";
import { useTranslation } from "react-i18next";
import downloadIcon from "../../assets/images/icons/download-file.svg";

interface Props {
  open: boolean;
  onClose: () => void;
  documents: File[];
  onAddDocuments?: (data: File[]) => void;
  onRemoveDocument?: (index: number) => void;
  maxFiles?: number;
  dropzone?: boolean;
  view?: boolean;
}

export const Documents = ({
  open,
  onClose,
  documents,
  onAddDocuments,
  onRemoveDocument,
  maxFiles,
  dropzone,
  view,
}: Props) => {
  const { t } = useTranslation("common");
  const inputRef = useRef<any>(null);
  const onDrop = (acceptedFiles: File[]) => {
    onAddDocuments && onAddDocuments(acceptedFiles);
  };
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/pdf": [".pdf"],
      "text/*": [".doc", ".docx", ".xls", ".xlsx"],
      "file/zip": [".zip"],
      "file/7zip": [".7zip"],
    },
    maxFiles: maxFiles ?? undefined,
  });

  const handleUploadFiles = () => inputRef.current.click();

  return (
    <Modal
      title="Документы"
      open={open}
      onCancel={onClose}
      onSubmit={handleUploadFiles}
      cancelBtnText={t("common.cancel")}
      submitBtnText={t("cargo.downloadDocuments")}
      noFooter={view}
    >
      <StyledDocuments>
        <div
          {...getRootProps({ className: `dropzone ${dropzone && "active"}` })}
        >
          <input
            {...getInputProps()}
            type="file"
            multiple
            accept=".doc, .docx, .jpg, .png, .jpeg, .pdf, .xls, .xlsx, .zip, .7zip"
            ref={inputRef}
          />
          <div className="dropzone-info">
            <img src={downloadIcon} alt="" />
            {t(
              isDragActive ? "cargo.dropDocuments" : "cargo.downloadDocuments"
            )}
          </div>
        </div>
        {documents?.length > 0 ? (
          documents.map((file: any, i: number) => (
            <File
              key={i}
              name={file.name}
              size={file.size}
              documentLink={file?.document}
              onRemove={() => (onRemoveDocument ? onRemoveDocument(i) : null)}
              view={view}
            />
          ))
        ) : dropzone ? null : (
          <div className="empty">Нет документов</div>
        )}
      </StyledDocuments>
    </Modal>
  );
};

const StyledDocuments = styled.div`
  max-height: 31vh;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 25px;
  .empty {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #5757569c;
  }
  .dropzone {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 116%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #1c1c1e;
    width: 100%;
    border: 1px dashed #e5e5ea;
    border-radius: 8px;
    padding: 52px 0;
    position: relative;
    cursor: pointer;
    display: none;
    &.active {
      display: flex;
    }
    img {
      margin-right: 16px;
    }
    .text {
      margin-top: 5px;
      text-transform: uppercase;
      opacity: 0.3;
    }
  }
`;
