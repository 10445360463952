import { useState, useEffect } from "react";
import styled from "styled-components";
import { handleCheckOveloadTimeColor } from "../../helpers/cargo";

interface Props {
  dateStart: string;
}

export const Timer = ({ dateStart }: Props) => {
  const [timer, setTimer] = useState<string>("");
  const [timerColor, setTimerColor] = useState<string>("#25c855");

  useEffect(() => {
    let interval = setInterval(() => {
      const now = new Date();
      const timePassed: any =
        Date.parse(now.toString()) - Date.parse(dateStart);
      const seconds = Math.floor((timePassed / 1000) % 60);
      const minutes = Math.floor((timePassed / 1000 / 60) % 60);
      const hours = Math.floor((timePassed / (1000 * 60 * 60)) % 24);

      setTimer(
        `${hours < 10 ? `0${hours}` : hours}:${
          minutes < 10 ? `0${minutes}` : minutes
        }:${seconds < 10 ? `0${seconds}` : seconds}`
      );
      setTimerColor(handleCheckOveloadTimeColor(minutes, hours));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <StyledTimer color={timerColor}>{dateStart && timer}</StyledTimer>;
};

interface StyledTimerProps {
  color: string;
}

const StyledTimer = styled.div<StyledTimerProps>`
  color: ${(props) => props.color};
`;
