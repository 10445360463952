import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const ContentHeader = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <StyledContentHeader>{t("cargo.driverName")}</StyledContentHeader>
      <StyledContentHeader>{t("cargo.carNumber")}</StyledContentHeader>
      <StyledContentHeader>Процент отгрузки</StyledContentHeader>
      <StyledContentHeader>{t("cargo.valueKub")}</StyledContentHeader>
    </>
  );
};

const StyledContentHeader = styled.div`
  font-weight: bold;
`;
