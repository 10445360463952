import styled from "styled-components";
import { SidebarLink } from "./SidebarLink";
import { FOOTER_LINKS } from "./links";

interface Props {
  open: boolean;
  onSupportOpen: () => void;
  onEditProfile: () => void;
}

export const FooterLinks = ({ open, onSupportOpen, onEditProfile }: Props) => (
  <StyledFooterLinks>
    {FOOTER_LINKS.map((link, i) => (
      <SidebarLink
        key={i}
        name={link.name}
        link={link.link}
        icon={link.icon}
        isNotMobile={link?.isNotMobile}
        open={open}
        onClick={() =>
          link.support ? onSupportOpen() : link.profile ? onEditProfile() : null
        }
      />
    ))}
  </StyledFooterLinks>
);

const StyledFooterLinks = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;
