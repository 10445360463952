import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Select } from "../../components/Select/Select";
import { useGetAllCargoOverloadQuery } from "../../store/cargo/cargo.api";
import { Modal } from "../../components/Modal/Modal";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (cargoId: string) => void;
}

export const AppointCargo = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation("common");
  const [selectedCargo, setSelectedCargo] = useState<string | undefined>(
    undefined
  );
  const { data, refetch } = useGetAllCargoOverloadQuery(null);

  const handleSelectOption = (opt: any) => {
    setSelectedCargo(opt.value);
  };

  const handleCloseModal = () => {
    setSelectedCargo(undefined);
    onClose();
  };

  const handleSubmit = () => {
    if (selectedCargo) {
      onSubmit(selectedCargo);
      handleCloseModal();
    }
  };

  useEffect(() => {
    open && refetch();
  }, [open]);

  return (
    <StyledAppointCargo>
      <Modal
        open={open}
        onCancel={onClose}
        onSubmit={handleSubmit}
        title="Назначение груза"
        cancelBtnText="Oтмена"
        submitBtnText="Назначить"
        disabledSubmit={!selectedCargo}
      >
        <div>
          {data?.response && open && (
            <Select
              label={t("cargos.cargo")}
              value={selectedCargo}
              onSelectOption={handleSelectOption}
              options={
                data?.response?.map((cargo: any) => ({
                  title: cargo?.ns,
                  value: cargo.id?.toString(),
                })) ?? []
              }
              classes="cargo-select"
              search
              placeholder={t("teams.choiceCargo")}
            />
          )}
        </div>
      </Modal>
    </StyledAppointCargo>
  );
};

const StyledAppointCargo = styled.div`
  .modal-content {
    overflow: inherit !important;
  }
`;
