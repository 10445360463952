import { useState } from "react";
import { DropdownButton } from "../../../components/DropdownButton/DropdownButton";
import {
  useLazyGetCargoExstraStorageQuery,
  useLazyGetExportCargoQuery,
} from "../../../store/cargo/cargo.api";
import { handleDownload } from "../../../helpers/files";
import { useTranslation } from "react-i18next";

export const DownloadButton = () => {
  const { t } = useTranslation("common");
  const [step, setStep] = useState<number>(1);
  const [downloadExtraStorage] = useLazyGetCargoExstraStorageQuery();
  const [exportCargo] = useLazyGetExportCargoQuery();

  const SELECT_TYPE_OPTIONS = [
    { title: "Фин. отчет", value: "type_finanse" },
    { title: "Отчет доп. хранения", value: "type_extra_store", isClose: true },
  ];
  const SELECT_DURATION = [
    { title: t("cargos.by7Days"), value: "week", isClose: true },
    { title: t("cargos.byMonth"), value: "month", isClose: true },
    { title: t("cargos.byYear"), value: "year", isClose: true },
  ];

  const handleClose = () => setStep(1);

  const handleDownloadExtraStorage = () => {
    downloadExtraStorage(undefined).then((resp: any) => {
      if (resp.data.code === 200) {
        const { file } = resp.data.response;
        handleDownload(file);
      }
    });
  };

  const handleDownloadCargos = (type: string) => {
    exportCargo({ type: type, my_columns: 0 }).then((response: any) => {
      if (response.status === "fulfilled") {
        const fileLink = response.data?.response?.file;
        fileLink && handleDownload(fileLink);
      }
    });
  };
  const handleAction = (action: string) => {
    switch (action) {
      case "type_finanse":
        setStep(2);
        break;
      case "type_extra_store":
        handleDownloadExtraStorage();
        break;
      default:
        handleDownloadCargos(action);
    }
  };

  return (
    <DropdownButton
      title={t("common.download")}
      disabled={false}
      onAction={handleAction}
      options={step === 1 ? SELECT_TYPE_OPTIONS : SELECT_DURATION}
      onClose={handleClose}
    />
  );
};
