import styled from "styled-components";
import { InfoList } from "../InfoList/InfoList";
import { useTranslation } from "react-i18next";
import {
  useGetCargoCategoriesQuery,
  useGetCountriesQuery,
} from "../../../store/cargo/cargo.api";
import { formatDate } from "../../../helpers/date";
import { Option } from "../../../components/Select/Options";

interface Props {
  cargoData: any;
}

export const Info = ({ cargoData }: Props) => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: categories } = useGetCargoCategoriesQuery(null);
  const { t } = useTranslation("common");

  const data = [
    { title: t("cargo.hsCode"), value: cargoData?.ns, bold: true },
    { title: t("cargo.sealNumber"), value: cargoData?.seal_id },
    {
      title: "Количество доп.заездов",
      value: cargoData?.arrival_count.toString(),
    },
    {
      title: t("cargo.declarationCount"),
      value: cargoData?.declarations_number,
    },
    { title: t("cargo.placeCount"), value: cargoData?.places_number },
    {
      title: t("cargo.containersCount"),
      value: cargoData?.containers_number,
    },
    {
      title: t("cargo.weight"),
      value: cargoData?.weight && `${cargoData?.weight} т`,
    },
    {
      title: t("cargo.valumeKub"),
      value:
        cargoData?.product_volume &&
        `${cargoData?.product_volume} ${t("cargo.kub")}`,
    },
    {
      title: t("cargo.cargoCategory"),
      value:
        categories?.find(
          (opt: Option) => opt.value === cargoData?.category?.toString()
        )?.title ?? "",
    },
    {
      title: t("cargo.dateKnr1"),
      value: formatDate(cargoData?.knr_storage_date, true, t),
    },
    {
      title: t("cargo.startKranDate"),
      value: cargoData?.start_kran_storage_date
        ? formatDate(cargoData?.start_kran_storage_date, true, t)
        : "-",
    },
    {
      title: t("cargo.endKranDate"),
      value: cargoData?.end_kran_storage_date
        ? formatDate(cargoData?.start_kran_storage_date, true, t)
        : "-",
    },
    {
      title: t("cargo.arriveDateKz1"),
      value: cargoData?.date_arrival_storage
        ? formatDate(cargoData?.date_arrival_storage, true, t)
        : "-",
    },
    {
      title: t("cargo.leftDateKz1"),
      value: cargoData?.date_arrival_сustom
        ? formatDate(cargoData?.date_arrival_сustom, true, t)
        : "-",
    },
    {
      title: "Клиент",
      value: cargoData?.contact?.title,
      bold: true,
    },
    {
      title: t("companies.contractNumber"),
      value: cargoData?.contact?.contract_number,
    },
    {
      title: "Ответственный",
      value: cargoData?.contact?.responsible,
    },
    {
      title: "BIN",
      value: cargoData?.contact?.bin,
    },
    {
      title: "INN",
      value: cargoData?.contact?.inn,
    },
    {
      title: t("cargo.shippingAddressSearch"),
      value: `${cargoData?.shipping_address} ${
        countries?.find(
          (country: Option) =>
            country.value === cargoData?.unloading_country?.toString()
        )?.title ?? "-"
      }`,
    },
    {
      title: t("cargo.unloadingAddress"),
      value: `${cargoData?.unloading_address} ${
        countries?.find(
          (country: Option) =>
            country.value === cargoData?.unloading_country?.toString()
        )?.title ?? "-"
      }`,
    },
    {
      title: t("cargo.customsClearanceAddress"),
      value: `${cargoData?.customs_clearance_address} ${
        countries?.find(
          (country: Option) =>
            country.value === cargoData?.customs_clearance_country?.toString()
        )?.title ?? "-"
      }`,
    },
    { title: t("cargo.tirNumber"), value: cargoData?.tir_id },
  ];

  return (
    <StyledInfo className="content-card card-wrapper">
      <InfoList data={data} />
    </StyledInfo>
  );
};

const StyledInfo = styled.div`
  padding: 33px 28px;
  @media (max-width: 1000px) {
    height: max-content !important;
  }
`;
