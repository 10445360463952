import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { SelectedCargos } from "./SelectedCargos";
import { useNavigate } from "react-router";
import { useAppSelect } from "../../../hooks/redux";
import { useTranslation } from "react-i18next";

interface Props {
  selected: { title: string; id: number }[];
  onClearSelected: () => void;
  onAction: (type: string) => void;
}

export const Actions = ({ selected, onClearSelected, onAction }: Props) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { user } = useAppSelect((state: any) => state.auth);
  const isAccess =
    user?.role_id === 1 || user?.role_id === 8 || user?.role_id === 4;

  return (
    <StyledActions>
      {isAccess && (
        <>
          <Button
            title={t("teams.choiceCargoToTeam")}
            onClick={() => onAction("start_overload")}
            color="blue"
            classes="btn"
            disabled={selected.length === 0}
          />
          <Button
            title={t("teams.end")}
            onClick={() => onAction("end_overload")}
            color="blue"
            classes="btn"
            disabled={selected.length === 0}
          />
        </>
      )}
      <Button
        title="KPI"
        onClick={() => navigate("/kpi")}
        color="blue"
        classes="btn"
      />
      {selected.length > 0 && (
        <SelectedCargos selected={selected} onClearSelected={onClearSelected} />
      )}
    </StyledActions>
  );
};

const StyledActions = styled.div`
  padding: 2px;
  border-radius: 10px;
  background: #ececec;
  display: flex;
  align-items: center;
  gap: 4px;
  .btn {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    height: 50px;
    width: 126px;
    padding: 0;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 14px !important;
    flex-shrink: 0;
  }
  @media (max-width: 820px) {
    flex-direction: column;
    width: 100%;
    .btn {
      width: 100%;
    }
  }
`;
