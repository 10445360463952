import { useEffect, useState, useRef } from "react";
import { Table } from "../../../components/Table/Table";
import { useAppSelect } from "../../../hooks/redux";
import { ColumnOption } from "../../../store/cargo/cargo.slice";
import styled from "styled-components";
import {
  useGetCargoCategoriesQuery,
  useLazyBrokerCargosOnChekingQuery,
  useLazyDeleteCargosQuery,
  useLazyGetCargosQuery,
  useLazyGetMyCargosQuery,
} from "../../../store/cargo/cargo.api";
import {
  CONFIRM_INIT,
  Confirm,
  ConfirmInterface,
} from "../../../components/Confirm/Confirm";
import { useTranslation } from "react-i18next";
import cogoToast from "cogo-toast";
import { TableData } from "../../../models/models";
import { MyCargos } from "./MyCargos";
import { Cargos } from "./Cargos";

interface Props {
  activeStatus: string | undefined;
  onChangeTotalCargos: (value: number) => void;
  selectedCargos: { title: string; id: number }[];
  onSelectCargo: (title: string, cargoId: number) => void;
  onSelectAllCargos: (cargos: { title: string; id: number }[]) => void;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  loading: boolean;
  onChangeLoading: (value: boolean) => void;
  selectedClient: string | undefined;
  activeTabIndex: number;
}

const SORT_INIT = { field: "", desc: false };

export const CargosTable = ({
  activeStatus,
  onChangeTotalCargos,
  selectedCargos,
  onSelectCargo,
  onSelectAllCargos,
  tableData,
  onChangeTableData,
  loading,
  onChangeLoading,
  selectedClient,
  activeTabIndex,
}: Props) => {
  const { t } = useTranslation("common");
  const { user } = useAppSelect((state) => state.auth);
  const [getCargos, { data }] = useLazyGetCargosQuery();
  const [getMyCargos, { data: myCargosData }] = useLazyGetMyCargosQuery();
  const [getCargosOnChecking, { data: cargosOnChecking }] =
    useLazyBrokerCargosOnChekingQuery();
  const { refetch } = useGetCargoCategoriesQuery(null);
  const { cargoSearch } = useAppSelect((state) => state.cargo);
  const { cargoColumns } = useAppSelect((state) => state.cargo);
  const [sort, setSort] = useState<{ field: string; desc: boolean }>(SORT_INIT);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [deleteCargos] = useLazyDeleteCargosQuery();
  const isMyCargos =
    (activeTabIndex === 0 && user?.role_id === 13) ||
    (activeTabIndex === 0 && user?.role_id === 1) ||
    (activeTabIndex === 1 && user?.role_id === 3);
  const isBroketCargosOnCheking = activeTabIndex === 0 && user?.role_id === 3;
  const tableRef = useRef<HTMLDivElement>(null);

  const handleChangePageSize = (value: number) =>
    onChangeTableData({ ...tableData, perPage: value });

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
    onChangeTableData({ ...tableData, currentPage: 1 });
  };

  // get data
  const handleGetCargos = (notLoading?: boolean) => {
    onChangeLoading(!notLoading);
    refetch();
    if (isMyCargos) {
      getMyCargos({ page: 1, sortBy: sort.field, sortDesc: sort.desc }).then(
        (resp: any) => {
          onChangeLoading(false);
          onChangeTableData({
            ...tableData,
            lastPage: resp?.data?.response?.last_page,
            total: resp?.data?.response?.total,
          });
        }
      );
    } else if (isBroketCargosOnCheking && user?.id) {
      getCargosOnChecking({
        broker_id: user?.id,
        sortBy: sort.field,
        sortDesc: sort.desc,
      }).then((resp: any) => {
        onChangeLoading(false);
        onChangeTableData({
          ...tableData,
          lastPage: resp?.data?.response?.last_page,
          total: resp?.data?.response?.total,
        });
      });
    } else {
      getCargos({
        page: tableData.currentPage,
        status: activeStatus,
        sortBy: sort.field ?? "priority",
        desc: sort.desc,
        q: cargoSearch,
        perPage: tableData.perPage,
        client_id: selectedClient,
      }).then((resp: any) => {
        onChangeLoading(false);
        onChangeTableData({
          ...tableData,
          lastPage: resp?.data?.response?.last_page,
          total: resp?.data?.response?.total,
        });
      });
    }
  };

  useEffect(() => {
    handleGetCargos();
    if (!loading && tableRef.current) {
      tableRef.current?.scroll({ top: 0, behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [
    getCargos,
    activeStatus,
    cargoSearch,
    tableData.perPage,
    tableData.currentPage,
    selectedClient,
    sort,
  ]);

  useEffect(() => {
    handleGetCargos();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMyCargos) {
      setIsAllSelected(
        selectedCargos.length === myCargosData?.response?.data?.length &&
          myCargosData?.response?.data?.length > 0
      );
    } else if (isBroketCargosOnCheking) {
      setIsAllSelected(
        selectedCargos.length === cargosOnChecking?.response.length &&
          cargosOnChecking?.response.length > 0
      );
      if (selectedCargos.length === 0) {
        getMyCargos({ page: 1, sortBy: sort.field, sortDesc: sort.desc });
      }
    } else {
      setIsAllSelected(
        selectedCargos.length === data?.response?.data?.length &&
          data?.response?.data?.length > 0
      );
      if (selectedCargos.length === 0) {
        handleGetCargos(true);
      }
    }

    if (selectedCargos.length === 0) {
      isMyCargos
        ? getMyCargos({ page: 1, sortBy: sort.field, sortDesc: sort.desc })
        : isBroketCargosOnCheking && user?.id
        ? getCargosOnChecking({
            broker_id: user?.id,
            sortBy: sort.field,
            sortDesc: sort.desc,
          })
        : handleGetCargos(true);
    }
    // eslint-disable-next-line
  }, [selectedCargos, data]);

  useEffect(() => {
    if (data && activeStatus?.toString() === "0") {
      onChangeTotalCargos(data?.response?.total ?? 0);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    window.addEventListener("visibilitychange", () => handleGetCargos());
    return () =>
      window.removeEventListener("visibilitychange", () => handleGetCargos());
    // eslint-disable-next-line
  }, [data, activeStatus, sort]);

  const handleDeleteCargos = () => {
    deleteCargos({ cargos: selectedCargos.map((c) => c.id) })
      .then(() => {
        onSelectAllCargos([]);
        cogoToast.success("Успех", {
          hideAfter: 3,
          position: "top-right",
        });
      })
      .catch(() => {
        onSelectAllCargos([]);
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      });
  };

  const handleConfirmDeleteCargos = () => {
    if (selectedCargos.length > 0) {
      setComfirmData({
        open: true,
        title:
          selectedCargos.length === 1
            ? t("cargo.confirmDelete")
            : t("cargo.confirmDeleteCargos"),
        onCancel: () => setComfirmData(CONFIRM_INIT),
        onConfirm: () => handleDeleteCargos(),
      });
    }
  };

  useEffect(() => {
    if (user?.role_id === 13) {
      getMyCargos({ page: 1, sortBy: sort.field, sortDesc: sort.desc });
    } else if (user?.role_id === 3 && user?.id) {
      getCargosOnChecking({
        broker_id: user?.id,
        sortBy: sort.field,
        sortDesc: sort.desc,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSort(SORT_INIT);
  }, [activeTabIndex]);

  return (
    <StyledCargosTable ref={tableRef}>
      <Confirm
        {...confirmData}
        items={selectedCargos}
        onRemoveItem={onSelectCargo}
        closeOnEmptyItems
      />
      <Table
        columns={cargoColumns
          .filter((column: ColumnOption) => column.show)
          .map((column: ColumnOption, i: number) => ({
            title: column.title,
            active: sort.field === column.fieldName,
            down: sort.field === column.fieldName && sort.desc,
            onSort: () => handleSort(column.fieldName),
          }))}
        pageSize={tableData.perPage}
        onChangePageSize={handleChangePageSize}
        loading={loading}
        onSelectAllCargos={() =>
          onSelectAllCargos(
            isAllSelected
              ? []
              : isMyCargos
              ? myCargosData?.response?.data.map((row: any) => ({
                  title: row?.ns,
                  id: row.id,
                }))
              : isBroketCargosOnCheking
              ? cargosOnChecking?.response.map((row: any) => ({
                  title: row?.ns,
                  id: row.cargo_id,
                }))
              : data.response.data.map((row: any) => ({
                  title: row?.ns,
                  id: row.id,
                }))
          )
        }
        isAllSelected={isAllSelected}
        onRemove={handleConfirmDeleteCargos}
        manageColumns
      >
        <tbody>
          {isMyCargos ? (
            <MyCargos
              data={myCargosData?.response?.data ?? []}
              columns={cargoColumns}
              selectedCargos={selectedCargos}
              onSelectCargo={onSelectCargo}
            />
          ) : isBroketCargosOnCheking ? (
            <Cargos
              data={
                cargosOnChecking?.response
                  ? cargosOnChecking?.response?.map((cargo: any) => ({
                      ...cargo,
                      id: cargo.cargo_id,
                    }))
                  : []
              }
              columns={cargoColumns}
              selectedCargos={selectedCargos}
              onSelectCargo={onSelectCargo}
            />
          ) : data?.response?.data ? (
            <Cargos
              data={data.response.data}
              columns={cargoColumns}
              selectedCargos={selectedCargos}
              onSelectCargo={onSelectCargo}
            />
          ) : null}
        </tbody>
      </Table>
    </StyledCargosTable>
  );
};

const StyledCargosTable = styled.div`
  height: calc(100vh - 120px - 30px - 70px - 70px - 82px);
  width: 100%;
  overflow: auto;
  border-radius: 20px;
  @media (max-width: 1200px) {
    height: calc(100vh - 120px - 30px - 70px - 70px - 72px - 72px);
  }
  @media (max-width: 1000px) {
    height: 300px;
  }
`;
