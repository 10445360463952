import styled from "styled-components";
import { Button } from "../Button/Button";

interface Props {
  cancelBtnText?: string;
  onCancel: () => void;
  submitBtnText?: string;
  onSubmit: () => void;
  disabledSubmit?: boolean;
}

export const Footer = ({
  cancelBtnText,
  onCancel,
  submitBtnText,
  onSubmit,
  disabledSubmit,
}: Props) => (
  <StyledFooter isTwoBtns={!!cancelBtnText && !!submitBtnText}>
    {cancelBtnText && (
      <Button title={cancelBtnText} onClick={onCancel} color="blue" />
    )}
    <Button
      title={submitBtnText}
      onClick={onSubmit}
      color="blue"
      disabled={disabledSubmit}
    />
  </StyledFooter>
);

interface StyledFooterProps {
  isTwoBtns?: boolean;
}

const StyledFooter = styled.div<StyledFooterProps>`
  padding: 30px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.isTwoBtns ? "repeat(2, minmax(max-content, 1fr))" : "1fr"};
  gap: 20px;
  button {
    height: 60px;
    width: 100%;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
