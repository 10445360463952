import { useRef } from "react";
import styled from "styled-components";
import { Dropdown } from "../Dropdown/Dropdown";

export interface Option {
  title: string;
  value: string;
  icon?: string | undefined;
}

interface Props {
  title?: string;
  color?: string;
  onClick?: () => void;
  options?: Option[];
  selectedOption?: string;
  onSelectOption?: (opt: Option) => void;
  icon?: string;
  classes?: string | undefined;
  size?: "large";
  outline?: boolean | undefined;
  hideDropdown?: boolean | undefined;
  disabled?: boolean | undefined;
}

export const Button = ({
  title,
  color,
  onClick,
  options,
  icon,
  classes,
  size,
  outline,
  selectedOption,
  onSelectOption,
  hideDropdown,
  disabled,
}: Props) => {
  const buttonRef = useRef<any>(null);
  const COLORS_WITH_WHITE_FONT = [
    "grey",
    "red",
    "light - red",
    "blue",
    "dark-green",
    "dark-blue",
  ];

  const handleClick = () => {
    if (!disabled) {
      options && buttonRef.current.focus();
      onClick && onClick();
    }
  };

  const handleGetColor = () => {
    switch (color) {
      case "grey":
        return "#747B84";
      case "green":
        return "#00FF4B";
      case "dark-green":
        return "#258842";
      case "red":
        return "#FF2B5E";
      case "light-red":
        return "#FF4A4A";
      case "orange":
        return "#FF9D2B";
      case "blue":
        return "#7392F7";
      case "dark-blue":
        return "#4F75F4";
      case "yellow":
        return "#c8ff00";
      case "light-blue":
        return "linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF";
      default:
        return undefined;
    }
  };

  const handleSelectOption = (opt: Option) => {
    if (onSelectOption) {
      onSelectOption(opt);
      buttonRef?.current?.blur();
    }
  };

  return (
    <StyledButton
      onClick={handleClick}
      color={color}
      colorHex={handleGetColor()}
      className={classes}
      size={size}
      outline={outline}
      disabled={disabled}
      ref={buttonRef}
      colorsWithWhiteFont={COLORS_WITH_WHITE_FONT}
    >
      {icon && <img src={icon} alt="" />}
      {selectedOption
        ? options?.find((opt) => opt.value === selectedOption)?.title
        : title}
      {options && !hideDropdown && (
        <Dropdown options={options} onSelectOption={handleSelectOption} />
      )}
    </StyledButton>
  );
};

interface StyledButtonProps {
  color?: string;
  colorHex?: string | undefined;
  size?: "large";
  outline?: boolean | undefined;
  disabled?: boolean | undefined;
  colorsWithWhiteFont: string[];
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 6px 20px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  background: ${(props) => props.colorHex ?? "#FFFFFF"};
  color: ${(props) =>
    props.colorsWithWhiteFont.includes(props.color ?? "")
      ? "#FFFFFF"
      : props.color === "light-blue"
      ? "#5E81F4"
      : "#2E2E3A"};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  ${(props) =>
    props.size === "large" &&
    "padding: 17px 27px;border-radius: 8px; line-height: 10px;"}
  ${(props) =>
    props.outline &&
    `
      color: ${props.colorHex ?? "#2E2E3A"};
      border: 2px solid ${props.colorHex ?? "#2E2E3A"};
      background: none;
      &:hover {
          background: ${props.colorHex ?? "#2E2E3A"};
          color: ${
            props.colorsWithWhiteFont.includes(props.color ?? "")
              ? "#FFFFFF"
              : props.color === "light-blue"
              ? "#5E81F4"
              : "#2E2E3A"
          };
      }
  `}
  ${(props) => props.disabled && "opacity: 0.8; cursor: no-drop;"}
  .dropdown {
    right: 0;
    left: unset;
  }
  &:hover {
    opacity: 0.5;
    ${(props) =>
      props.color === "blue" &&
      `
      opacity: 1;
      background: #4F75F4;
    `}
  }
  &:focus {
    opacity: 1 !important;
  }
  &:focus > .dropdown {
    display: flex;
  }
`;
