import styled from "styled-components";

interface Props {
  label: string;
  labelClasses?: undefined | string;
}

export const Label = ({ label, labelClasses }: Props) => (
  <StyledLabel className={`${labelClasses}`}>{label}</StyledLabel>
);

const StyledLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  @media (max-width: 420px) {
    font-family: "Archivo", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    color: rgba(9, 16, 29, 0.2);
    margin-bottom: 8px;
  }
`;
