import styled from "styled-components";
import manageIcon from "../../../assets/images/icons/manage-columns.svg";

interface Props {
  onClose: () => void;
}

export const Header = ({ onClose }: Props) => (
  <StyledHeader onClick={onClose}>
    <img src={manageIcon} alt="" />
    <div>Колонки и строки</div>
  </StyledHeader>
);

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 15px 12px;
  cursor: pointer;
  div {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #575756;
    margin-left: 11px;
  }
`;
