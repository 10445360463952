import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../api/baseUrl";
import { headers } from "../../api/headers";
import { handleError } from "../../helpers/api";

export const brokers = createApi({
  reducerPath: "brokers/api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    getBrokers: build.query<
      any,
      { sortBy?: string | undefined; sortDesc?: boolean | undefined }
    >({
      query: ({ sortBy, sortDesc }) => ({
        url: "broker/get-brokers",
        headers: headers(),
        params: { sortBy, sortDesc },
      }),
      transformErrorResponse: handleError,
    }),
    createBroker: build.query<
      any,
      {
        title: string;
        cod: string;
        contract_number: string;
        email: string;
        password: string;
      }
    >({
      query: ({ title, cod, contract_number, email, password }) => ({
        url: "broker/create-broker",
        headers: headers(),
        method: "POST",
        params: {
          title,
          cod,
          contract_number,
          email,
          password,
        },
      }),
    }),
    editBroker: build.query<
      any,
      {
        client_id: number;
        title: string;
        cod: string;
        contract_number: string;
        email: string;
      }
    >({
      query: ({ client_id, title, cod, contract_number, email }) => ({
        url: "broker/update-broker",
        headers: headers(),
        method: "POST",
        params: {
          client_id,
          title,
          cod,
          contract_number,
          email,
        },
      }),
    }),
    deleteBroker: build.query<any, number>({
      query: (id) => ({
        url: "broker/delete-broker",
        headers: headers(),
        method: "DELETE",
        params: { id },
      }),
    }),
    deleteBrokers: build.query<any, number[]>({
      query: (brokers) => ({
        url: "broker/delete-brokers",
        headers: headers(),
        method: "DELETE",
        body: { brokers },
      }),
    }),
  }),
});

export const {
  useLazyGetBrokersQuery,
  useLazyCreateBrokerQuery,
  useLazyEditBrokerQuery,
  useLazyDeleteBrokerQuery,
  useLazyDeleteBrokersQuery,
} = brokers;
