import editIcon from "../../assets/images/icons/edit-icon.svg";
import { Checkbox } from "../../components/Checkbox/Checkbox";

interface Props {
  number: number;
  title: string;
  cod: string;
  contractNumber: string | null;
  onEdit: () => void;
  isSelected: boolean;
  onSelect: () => void;
}

export const BrokerRow = ({
  number,
  title,
  cod,
  contractNumber,
  onEdit,
  isSelected,
  onSelect,
}: Props) => (
  <tr className={`${isSelected && "selected"}`}>
    <td>
      <Checkbox checked={isSelected} onChange={onSelect} />
    </td>
    <td>
      <img src={editIcon} alt="" onClick={onEdit} className="cursor-pointer" />
    </td>
    <td>
      <div className="grey">{number}</div>
    </td>
    <td>
      <div className="bold">{title}</div>
    </td>
    <td>
      <div className="bold">{cod}</div>
    </td>
    <td>
      <div className="bold">{contractNumber}</div>
    </td>
  </tr>
);
