import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Input } from "../../../../components/Input/Input";
import { Option, Select } from "../../../../components/Select/Select";
import { useLazyGetBrokersQuery } from "../../../../store/brokers/brokers.api";
import {
  useGetCargoCategoriesQuery,
  useGetCountriesQuery,
  useLazySearchAddressQuery,
  useLazySearchCompaniesQuery,
} from "../../../../store/cargo/cargo.api";
import { useAppSelect } from "../../../../hooks/redux";
import cogoToast from "cogo-toast";

interface Props {
  formData: any;
  onUpdateFormData: (data: any) => void;
  errors: any;
}

export const DetailsForm = ({ formData, onUpdateFormData, errors }: Props) => {
  const { t } = useTranslation("common");
  const { data: countries } = useGetCountriesQuery(null);
  const { data: categories } = useGetCargoCategoriesQuery(null);
  const { user } = useAppSelect((state) => state.auth);
  const [getAddress] = useLazySearchAddressQuery();
  const [getCompanies] = useLazySearchCompaniesQuery();
  const [getBrokers, { data: brokers }] = useLazyGetBrokersQuery();
  const [companySearch, setCompanySearch] = useState<Option[]>([]);
  const [shippingAddressSearch, setShippingAddressSearch] = useState<Option[]>(
    []
  );
  const [unloadingAddressSearch, setUnloadingAddressSearch] = useState<
    Option[]
  >([]);
  const [customsClearanceAddressSearch, setCustomsClearanceAddressSearch] =
    useState<Option[]>([]);

  const handleShowInfo = (message: string) => {
    cogoToast.error(message, {
      hideAfter: 3,
      position: "top-right",
    });
  };

  const handleChangeShippingAddress = (opt: Option) => {
    onUpdateFormData({ ...formData, shipping_address: opt.value });
    getAddress({ q: opt.value, row: "shipping_address" }).then(
      (response: any) => setShippingAddressSearch(response.data ?? [])
    );
  };

  const handleChangeUnloadingAddress = (opt: Option) => {
    onUpdateFormData({ ...formData, unloading_address: opt.value });
    getAddress({ q: opt.value, row: "unloading_address" }).then(
      (response: any) => setUnloadingAddressSearch(response.data ?? [])
    );
  };

  const handleClearanceAddress = (opt: Option) => {
    onUpdateFormData({ ...formData, customs_clearance_address: opt.value });
    getAddress({ q: opt.value, row: "customs_clearance_address" }).then(
      (response: any) => setCustomsClearanceAddressSearch(response.data ?? [])
    );
  };

  const handleChangeCompanySearch = (opt: any) => {
    if (user?.role_id === 13 || (user?.role_id !== 3 && user?.role_id !== 1)) {
      handleShowInfo("Это поле может изменять роль Брокер CN");
    } else {
      onUpdateFormData({
        ...formData,
        company_name: opt.value,
        company_id: opt.id,
        ns: opt.cod,
        ns_cod: opt.ns_cod,
        ns_number: opt.ns_number,
      });
      getCompanies({ q: opt.value }).then((response: any) =>
        setCompanySearch(response.data ?? [])
      );
    }
  };

  useEffect(() => {
    getAddress({ q: "", row: "shipping_address" }).then((response: any) =>
      setShippingAddressSearch(response.data ?? [])
    );
    getAddress({ q: "", row: "unloading_address" }).then((response: any) =>
      setUnloadingAddressSearch(response.data ?? [])
    );
    getAddress({ q: "", row: "customs_clearance_address" }).then(
      (response: any) => setCustomsClearanceAddressSearch(response.data ?? [])
    );
    getCompanies({ q: "" }).then((response: any) =>
      setCompanySearch(response.data ?? [])
    );
    getBrokers({});
    // eslint-disable-next-line
  }, []);

  return (
    <StyledDetailsForm>
      <Select
        placeholder={t("cargo.companyName")}
        value={formData.company_name}
        options={companySearch}
        onSelectOption={handleChangeCompanySearch}
        line
        noArrow
        changeValueOnSearch
        error={!!errors.company_name}
        wrapperClasses="company-input"
        disabled={
          user?.role_id === 13 || (user?.role_id !== 3 && user?.role_id !== 1)
        }
      />
      <Input
        placeholder={t("cargo.hs")}
        value={formData.ns}
        onChange={(value: string) =>
          user?.role_id !== 3 && user?.role_id !== 1
            ? handleShowInfo("Это поле может изменять роль Брокер CN")
            : onUpdateFormData({ ...formData, ns: value })
        }
        error={!!errors.ns}
        classes="hs-input"
        // errorMessage={errors.ns}
      />
      <Input
        placeholder={"Дата поступления Заявки"}
        value={formData.knr_storage_date}
        onChange={(value: Date) =>
          onUpdateFormData({ ...formData, knr_storage_date: value })
        }
        error={!!errors.ns}
        classes="hs-input"
        calendar
        // errorMessage={errors.ns}
      />
      <Input
        placeholder={t("cargo.sealNumberKz")}
        value={formData.seal_custom_house_kz}
        onChange={(value: string) =>
          onUpdateFormData({ ...formData, seal_custom_house_kz: value })
        }
        error={!!errors.seal_custom_house_kz}
        // errorMessage={errors.seal_id}
        // number
      />
      <Input
        placeholder={t("cargo.sealCn")}
        value={formData.seal_cn}
        onChange={(value: string) =>
          onUpdateFormData({ ...formData, seal_cn: value })
        }
        error={!!errors.seal_cn}
        // errorMessage={errors.tir_id}
      />
      <Input
        placeholder={t("cargo.sealNumberStorage")}
        value={formData.seal_storage}
        onChange={(value: string) =>
          user?.role_id !== 3 && user?.role_id !== 1
            ? handleShowInfo("Это поле может изменять роль Брокер CN")
            : onUpdateFormData({ ...formData, seal_storage: value })
        }
        error={!!errors.seal_storage}
        // errorMessage={errors.seal_id}
        // number
      />
      <Input
        placeholder={"Дополнительная информация"}
        value={formData.tir_id}
        onChange={(value: string) =>
          user?.role_id !== 8 && user?.role_id !== 11 && user?.role_id !== 1
            ? handleShowInfo(
                "Это поле может изменять роль Диспетчер KZ и Брокер KZ"
              )
            : onUpdateFormData({ ...formData, tir_id: value })
        }
      />
      <Select
        label={t("cargo.category")}
        value={formData.category}
        options={categories ?? []}
        onSelectOption={(opt: Option) =>
          onUpdateFormData({ ...formData, category: opt.value })
        }
        error={!!errors.category}
        wrapperClasses="form-select"
        placeholder="Поиск"
      />
      <Select
        label={t("cargo.broker")}
        value={formData.broker_id}
        options={
          brokers?.response?.map(
            ({ title, id }: { title: string; id: number }) => ({
              title,
              value: id,
            })
          ) ?? []
        }
        onSelectOption={(opt: Option) =>
          onUpdateFormData({ ...formData, broker_id: opt.value })
        }
        error={!!errors.broker_id}
        wrapperClasses="form-select"
        placeholder="Поиск"
      />
      <Select
        value={formData.shipping_country}
        label="Cтрана отправки"
        options={countries ?? []}
        onSelectOption={(opt: Option) =>
          onUpdateFormData({ ...formData, shipping_country: opt.value })
        }
        error={!!errors.shipping_country}
        search
        wrapperClasses="form-select"
        placeholder="Поиск"
      />
      <Select
        value={formData.shipping_address}
        placeholder={t("cargo.shippingAddressSearch")}
        options={shippingAddressSearch}
        onSelectOption={handleChangeShippingAddress}
        search
        noArrow
        noFilterSearch
        changeValueOnSearch
        error={!!errors.shipping_address}
        line
        wrapperClasses="form-select"
      />
      <Select
        value={formData.unloading_country}
        label="Cтрана разгрузки"
        options={countries ?? []}
        onSelectOption={(opt: Option) =>
          onUpdateFormData({ ...formData, unloading_country: opt.value })
        }
        error={!!errors?.unloading_country}
        search
        wrapperClasses="form-select"
        placeholder="Поиск"
      />
      <Select
        value={formData.unloading_address}
        placeholder={t("cargo.unloadingAddress")}
        options={unloadingAddressSearch}
        onSelectOption={handleChangeUnloadingAddress}
        search
        noArrow
        noFilterSearch
        changeValueOnSearch
        error={!!errors.unloading_address}
        line
        wrapperClasses="form-select"
      />
      <Select
        value={formData.customs_clearance_country}
        label="Страна растаможки"
        options={countries ?? []}
        onSelectOption={(opt: Option) =>
          user?.role_id !== 2 && user?.role_id !== 1
            ? handleShowInfo("Это поле может изменять роль Диспетчер CN")
            : onUpdateFormData({
                ...formData,
                customs_clearance_country: opt.value,
              })
        }
        search
        error={!!errors.customs_clearance_country}
        wrapperClasses="form-select"
        placeholder="Поиск"
      />
      <Select
        value={formData.customs_clearance_address}
        placeholder={t("cargo.customsClearanceAddress")}
        options={customsClearanceAddressSearch}
        onSelectOption={handleClearanceAddress}
        search
        noArrow
        noFilterSearch
        changeValueOnSearch
        error={!!errors.customs_clearance_address}
        line
        wrapperClasses="form-select"
      />
    </StyledDetailsForm>
  );
};

const StyledDetailsForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
  .company-input {
    grid-column: 1/3;
    min-width: 100px !important;
  }
  .date-input {
    margin-bottom: 13px;
  }
  .hs-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .form-select {
    min-width: auto;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    .company-input {
      grid-column: 1/2;
    }
    .calendar {
      margin: 0 auto;
    }
  }
`;
