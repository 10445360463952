import styled from "styled-components";
import { TotalData } from "../Oplata";

export const Total = ({
  total_price,
  total_discount,
  total_paid,
  total_remainder,
}: TotalData) => (
  <>
    <StyledTotal className="first">Итого </StyledTotal>
    <StyledTotal>{total_price}</StyledTotal>
    <StyledTotal>{total_discount}</StyledTotal>
    <StyledTotal>{total_paid}</StyledTotal>
    <StyledTotal>{total_remainder} </StyledTotal>
  </>
);

const StyledTotal = styled.div`
  color: #575756;
  font-size: 12px;
  font-weight: 700;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.first {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
`;
