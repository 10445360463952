import styled from "styled-components";
import { Input } from "../../components/Input/Input";
import { Modal } from "../../components/Modal/Modal";
import { useState, useEffect } from "react";
import {
  useLazyCreateTeamQuery,
  useLazyUpdateTeamQuery,
} from "../../store/teams/teams.api";
import cogoToast from "cogo-toast";

interface Props {
  open: boolean;
  onClose: () => void;
  editTeam: null | any;
  onRefreshData: () => void;
}

export const TeamModal = ({
  open,
  onClose,
  editTeam,
  onRefreshData,
}: Props) => {
  const [name, setName] = useState<string>("");
  const [block, setBlock] = useState<string>("");
  const [peopleNumber, setPeopleNumber] = useState<string>("0");
  const [specEquipment, setSpecEquipment] = useState<string>("");
  const [updateTeam] = useLazyUpdateTeamQuery();
  const [createTeam] = useLazyCreateTeamQuery();

  const handleResetValues = () => {
    setName("");
    setBlock("");
    setPeopleNumber("0");
    setSpecEquipment("");
  };

  useEffect(() => {
    if (open) {
      setName(editTeam?.name ?? "");
      setBlock(editTeam?.block ?? "");
      setPeopleNumber(editTeam?.people_number ?? "");
      setSpecEquipment(editTeam?.special_equipment ?? "");
    }
  }, [editTeam, open]);

  const handleCloseModal = () => {
    handleResetValues();
    onClose();
  };

  const handleCreateTeam = () => {
    createTeam({
      command_id: editTeam?.id,
      name,
      block,
      people_number: peopleNumber,
      special_equipment: specEquipment,
    }).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно добавлено", {
          hideAfter: 3,
          position: "top-right",
        });
        handleCloseModal();
        onRefreshData();
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleEditTeam = () => {
    updateTeam({
      command_id: editTeam?.id,
      name,
      block,
      people_number: peopleNumber,
      special_equipment: specEquipment,
    }).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно сохранено", {
          hideAfter: 3,
          position: "top-right",
        });
        handleCloseModal();
        onRefreshData();
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  return (
    <Modal
      open={open}
      title={editTeam ? "Редактирование группы" : "Добавление группу"}
      onCancel={handleCloseModal}
      onSubmit={editTeam ? handleEditTeam : handleCreateTeam}
      cancelBtnText="Отмена"
      submitBtnText={editTeam ? "Сохранить изменения" : "Добавить"}
      disabledSubmit={
        name?.length === 0 ||
        block?.length === 0 ||
        specEquipment.length === 0 ||
        peopleNumber.length === 0
      }
    >
      <StyledTeamModal>
        <Input
          placeholder="Название группы"
          value={name}
          onChange={(value) => setName(value)}
          classes="input"
        />
        <Input
          placeholder="Блок"
          value={block}
          onChange={(value) => setBlock(value)}
          classes="input"
        />
        <Input
          placeholder="Количество людей"
          value={""}
          onChange={(value) => null}
          classes="input"
          counter
          counterValue={Number(peopleNumber)}
          onChangeCounterValue={(value) => setPeopleNumber(value.toString())}
        />
        <Input
          placeholder="Спец техника"
          value={specEquipment}
          onChange={(value) => setSpecEquipment(value)}
          classes="input"
        />
      </StyledTeamModal>
    </Modal>
  );
};

const StyledTeamModal = styled.div`
  .input:not(:last-child) {
    margin-bottom: 15px;
  }
`;
