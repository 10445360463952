import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  handleCheckCargoTimers,
  handleGetStorageDaysCount,
} from "../../../../helpers/cargo";
import { OverloadStatus } from "../../../../components/OverloadStatus/OveloadStatus";

interface Props {
  createdAt: string;
  productVolume: number;
  startOverloadDate: string | null;
  endOverloadDate: string | null;
  startStorageDate: string | null;
  endStorageDate: string | null;
}

export const Header = ({
  createdAt,
  productVolume,
  startOverloadDate,
  endOverloadDate,
  startStorageDate,
  endStorageDate,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StlyedHeader className="clickable">
      <div className="clickable info">
        {createdAt}
        {handleCheckCargoTimers(startOverloadDate, endOverloadDate) ? (
          <OverloadStatus
            startOveloadDate={startOverloadDate}
            endOveloadDate={endOverloadDate}
            title={`| ${t("cargo.overloading")} -`}
            endTitle={`| ${t("cargo.overloadingEnded")} -`}
            classes="timer"
          />
        ) : handleCheckCargoTimers(startStorageDate, endStorageDate) ? (
          <>
            | {t("cargo.storaging")}
            {` - ${handleGetStorageDaysCount(
              startStorageDate ?? ""
            ).toString()}`}
          </>
        ) : null}
      </div>
      <div className="kub clickable">
        {productVolume} {t("cargo.kub")}
      </div>
    </StlyedHeader>
  );
};

const StlyedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #575756;
  cursor: pointer;
  margin-bottom: 15px;
  .kub {
    margin-left: 10px;
  }
  .info {
    display: flex;
    align-items: center;
  }
  .timer {
    font-weight: 400 !important;
    font-size: 9px !important;
    line-height: 11px !important;
    color: #575756 !important;
    margin-left: 2px;
  }
`;
