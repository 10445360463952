import cogoToast from "cogo-toast";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Confirm,
  ConfirmInterface,
  CONFIRM_INIT,
} from "../../components/Confirm/Confirm";
import { Table } from "../../components/Table/Table";
import { useLazyDeleteBrokersQuery } from "../../store/brokers/brokers.api";
import { BrokerRow } from "./BrokerRow";

const COLUMNS = [
  { title: "#", field: "id" },
  { title: "companies.name", field: "name" },
  { title: "companies.code", field: "cod" },
  { title: "companies.contractNumber", field: "contract_number" },
];

interface Props {
  data: any;
  loading: boolean;
  onEditBroker: (broker: any) => void;
  onRefreshData: () => void;
  sort: { field: string; desc: boolean };
  onSort: (field: string) => void;
}

export const BrokersTable = ({
  data,
  loading,
  onEditBroker,
  onRefreshData,
  sort,
  onSort,
}: Props) => {
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [deleteBrokers] = useLazyDeleteBrokersQuery();
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState<{ title: string; id: number }[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  // CHANGE SELECTED
  const handleRemoveSelected = (cargoId: number) => {
    setSelected(selected.filter((c) => c.id !== cargoId));
  };
  const handleSelect = (title: string, cargoId: number) => {
    const isSelected = !!selected.find((c) => c.id === cargoId);
    isSelected
      ? handleRemoveSelected(cargoId)
      : setSelected([...selected, { title, id: cargoId }]);
  };

  useEffect(() => {
    setIsAllSelected(selected.length === data?.length && data?.length > 0);
  }, [selected, data]);

  const handleDeleteCompanies = () => {
    deleteBrokers(selected.map((s) => s.id)).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Успешно удалено", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
        setComfirmData(CONFIRM_INIT);
        setSelected([]);
      } else {
        cogoToast.error("Ошибка", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleConfirmDelete = () => {
    if (selected.length > 0) {
      setComfirmData({
        open: true,
        title: "Вы точно хотите удалить брокера(ов)",
        onCancel: () => setComfirmData(CONFIRM_INIT),
        onConfirm: () => handleDeleteCompanies(),
      });
    }
  };

  return (
    <>
      <Confirm
        {...confirmData}
        items={selected}
        onRemoveItem={handleSelect}
        closeOnEmptyItems
      />
      <StyledBrokersTable>
        <Table
          loading={loading}
          columns={COLUMNS.map((column: { title: string; field: string }) => ({
            title: column.title,
            active: sort.field === column.field,
            down: sort.field === column.field && sort.desc,
            onSort: () => onSort(column.field),
          }))}
          pageSize={25}
          hideManage
          isAllSelected={isAllSelected}
          onSelectAllCargos={() =>
            setSelected(
              isAllSelected
                ? []
                : data?.map((b: any) => ({ title: b.title, id: b.id }))
            )
          }
          onRemove={handleConfirmDelete}
          classes="table-wrapper"
        >
          {data &&
            data.map((broker: any, i: number) => (
              <BrokerRow
                key={i}
                number={1 + i}
                title={broker.title}
                cod={broker.cod}
                contractNumber={broker?.contract_number}
                onEdit={() => onEditBroker(broker)}
                isSelected={
                  !!selected.find(
                    (s: { title: string; id: number }) => s.id === broker.id
                  )
                }
                onSelect={() => handleSelect(broker.title, broker.id)}
              />
            ))}
        </Table>
      </StyledBrokersTable>
    </>
  );
};

const StyledBrokersTable = styled.div`
  overflow: auto;
  height: calc(100vh - 120px - 30px - 80px);
  width: 100%;
  overflow: auto;
  border-radius: 20px;
  @media (max-width: 1000px) {
    height: max-content;
  }
  .table-wrapper {
    height: 100%;
  }
  th:nth-child(1),
  th:nth-child(2) {
    width: 50px;
  }
`;
