import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  data: null,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
});

export const companiesActions = companiesSlice.actions;
export const companiesReducer = companiesSlice.reducer;
