import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import styled from "styled-components";
import { BackButton } from "../../components/BackButton/BackButton";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useActions } from "../../hooks/actions";
import { useLazyGetTeamsQuery } from "../../store/teams/teams.api";

export const Header = () => {
  const { t } = useTranslation("common");
  const { teamId } = useParams();
  const [getTeams, { data }] = useLazyGetTeamsQuery();
  const { setActiveTeam } = useActions();

  useEffect(() => {
    getTeams({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setActiveTeam(
        data.response
          ?.find((team: any) => team.id.toString() === teamId)
          ?.name?.substring(8) ?? ""
      );
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <StyledHeader>
      <PageHeader title={t("teams.history")} />
      <BackButton classes="back-btn" />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
