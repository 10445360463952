import styled from "styled-components";
import { Header } from "./Header";
import { Inputs } from "./Inputs";

interface Props {
  title: string;
  placeholder: string;
  value: string[];
  onChange: (value: string[]) => void;
}

export const Card = ({ title, placeholder, value, onChange }: Props) => {
  return (
    <StyledCard>
      <Header title={title} value={value} onChange={onChange} />
      <Inputs value={value} onChange={onChange} placeholder={placeholder} />
    </StyledCard>
  );
};

const StyledCard = styled.div`
  height: max-content;
`;
