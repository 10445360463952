import { useTranslation } from "react-i18next";
import cogoToast from "cogo-toast";
import { useAppSelect } from "../../../hooks/redux";
import { useLazyChangeArrivedStatusQuery } from "../../../store/cargo/cargo.api";
import { useParams } from "react-router";
import { Button } from "../../../components/Button/Button";

interface Props {
  cargoArrived: boolean;
  status: string;
  onRefreshCargoData: () => void;
}

export const ArriveStatus = ({
  status,
  cargoArrived,
  onRefreshCargoData,
}: Props) => {
  const { t } = useTranslation("common");
  const { cargoId } = useParams();
  const [changeArriveStatus] = useLazyChangeArrivedStatusQuery();
  const { user } = useAppSelect((state) => state.auth);

  const handleChange = () => {
    changeArriveStatus({
      cargo_id: cargoId,
      status: cargoArrived ? "0" : "1",
    }).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Статус прибытия успешно изменен", {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshCargoData();
      } else {
        cogoToast.success("Ошибка изменения статуса прибытия", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  return (
    <>
      {status === "storage_kz" &&
        (user?.role_id === 1 || user?.role_id === 8) && (
          <Button
            title={cargoArrived ? "Груз прибыл" : "Груз не прибыл"}
            color="blue"
            onClick={handleChange}
            classes="footer-btn"
          />
        )}
    </>
  );
};
