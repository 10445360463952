import styled from "styled-components";

interface Props {
  title: string;
  onClick: () => void;
}
export const NavigateButton = ({ title, onClick }: Props) => {
  return <StyledNavigateButton onClick={onClick}>{title}</StyledNavigateButton>;
};

const StyledNavigateButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4f75f4;
  margin: 84px auto 43px 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 420px) {
    font-family: "Archivo", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #5e81f4;
    text-align: left;
    margin: 15px 0 23px;
  }
`;
