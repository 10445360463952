export const handleAddZero = (value: number) =>
  value < 10 ? `0${value}` : value;
export const mounts = [
  "months.january",
  "months.february",
  "months.march",
  "months.april",
  "months.may",
  "months.june",
  "months.july",
  "months.august",
  "months.september",
  "months.october",
  "months.november",
  "months.december",
];

export const mounts_Infinitive = [
  "monthsInfinitive.january",
  "monthsInfinitive.february",
  "monthsInfinitive.march",
  "monthsInfinitive.april",
  "monthsInfinitive.may",
  "monthsInfinitive.june",
  "monthsInfinitive.july",
  "monthsInfinitive.august",
  "monthsInfinitive.september",
  "monthsInfinitive.october",
  "monthsInfinitive.november",
  "monthsInfinitive.december",
];

export const formatDateCreateCargo = (date: Date) => {
  const day = handleAddZero(date.getDate());
  const month = handleAddZero(date.getMonth());
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatDate = (d?: string | Date, poitType?: boolean, t?: any) => {
  const date = new Date(d ?? new Date());
  const day = handleAddZero(date.getDate());
  const monthNumber = handleAddZero(date.getMonth() + 1);
  const month = t ? t(mounts[date.getMonth()]) : "";
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = handleAddZero(date.getMinutes());

  if (poitType) {
    return `${day}.${monthNumber}.${year} ${hours}:${minutes} `;
  }
  return `${day} ${month} ${year}`;
};

export const formatTime = (d: string | Date) => {
  const date = new Date(d);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const getDashDate = (d?: string) => {
  const date = d ? new Date(d) : new Date();
  const day = handleAddZero(date.getDate());
  const month = handleAddZero(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};
