import styled from "styled-components";

export const CargoTrackNumber = () => (
  <StyledCargoTrackNumber>HS3298319083</StyledCargoTrackNumber>
);

const StyledCargoTrackNumber = styled.div`
  margin-bottom: 10px;
  font-family: "Brygada 1918";
  font-weight: 700;
  font-size: 34px;
  line-height: 130%;
  color: #00b670;
  text-align: center;
`;
