import styled from "styled-components";
import { Option } from "../Button/Button";

interface Props {
  options: Option[];
  onSelectOption: (opt: Option) => void;
  innerRef?: any;
}

export const Dropdown = ({ options, onSelectOption, innerRef }: Props) => (
  <>
    <StyledDropdown
      className="dropdown"
      ref={innerRef}
      isEmpty={options.length === 0}
    >
      {options.length > 0
        ? options.map((opt, i) => (
            <span
              key={i}
              onClick={() => onSelectOption(opt)}
              onTouchStart={() => onSelectOption(opt)}
            >
              {opt.icon && <img src={opt.icon} alt="" />}
              {opt.title}
            </span>
          ))
        : null}
    </StyledDropdown>
  </>
);

interface StyledDropdownProps {
  isEmpty: boolean;
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  position: absolute;
  top: 110%;
  left: 0;
  display: none;
  flex-direction: column;
  padding: ${(props) => !props.isEmpty && "4px"};
  /* padding: 4px; */
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 16, 61, 0.16);
  border-radius: 8px;
  color: #2e2e3a;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
  &.active {
    display: flex;
  }
  span {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    padding: 10px 14px;
    text-align: left;
    transition: all 0.3s ease;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2e2e3a;
    z-index: 200;
    &:hover {
      background: #f2f3f7;
    }
    img {
      margin-right: 10px;
    }
  }
  .empty {
    opacity: 0.5;
  }
`;
