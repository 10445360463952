import styled from "styled-components";
import { useEffect, useState } from "react";
import { Paginator } from "../../components/Paginator/Paginator";
import { Table } from "../../components/Table/Table";
import {
  useGetCargoCategoriesQuery,
  useLazyGetCargosQuery,
} from "../../store/cargo/cargo.api";
import { EmptyTable } from "../../components/EmptyTable/EmptyTable";
import { useAppSelect } from "../../hooks/redux";
import { ColumnOption } from "../../store/cargo/cargo.slice";
import { Option } from "../../components/Select/Select";
import { useTranslation } from "react-i18next";
import editIcon from "../../assets/images/icons/edit.svg";
import showIcon from "../../assets/images/icons/show.svg";
import { OverloadStatus } from "../../components/OverloadStatus/OveloadStatus";
import { formatDate } from "../../helpers/date";
import {
  handleCheckIsStorage,
  handleGetStorageDaysCount,
} from "../../helpers/cargo";
import { Button } from "../../components/Button/Button";

interface Props {
  activeStatus: string | number | undefined;
  sortBy: string;
  onChangeTotalCargos: (value: number) => void;
  selectedCargos: number[];
  onSelectCargo: (cargoId: number) => void;
  isUpdateTable: boolean;
  turnOffUpdateTable: () => void;
  selectedClient: string | undefined;
  onSetCurrectCargosIds: (cargosIds: number[]) => void;
  onSelectAllStatusCargos: (isClean: boolean) => void;
  allCurrentCargosIds: number[];
}

export const CargosTable = ({
  activeStatus,
  sortBy,
  onChangeTotalCargos,
  selectedCargos,
  onSelectCargo,
  isUpdateTable,
  turnOffUpdateTable,
  selectedClient,
  onSetCurrectCargosIds,
  onSelectAllStatusCargos,
  allCurrentCargosIds,
}: Props) => {
  const [getCargos, { data }] = useLazyGetCargosQuery();
  const { data: categories, refetch } = useGetCargoCategoriesQuery(null);
  const { user } = useAppSelect((state) => state.auth);
  const { cargoSearch } = useAppSelect((state) => state.cargo);
  const { cargoColumns } = useAppSelect((state) => state.cargo);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    setLoading(true);
    getCargos({
      page: 1,
      status: activeStatus,
      sortBy: sortBy === "default" ? "priority" : sortBy,
      client_id: selectedClient,
    }).then((resp) => setLoading(false));
  }, [getCargos, activeStatus, sortBy, cargoSearch, selectedClient]);

  useEffect(() => {
    setLoading(true);
    getCargos({
      page: 1,
      status: activeStatus,
      sortBy: sortBy === "default" ? "priority" : sortBy,
      q: cargoSearch,
      client_id: selectedClient,
    }).then((resp) => setLoading(false));
  }, []);

  const handleChangePage = (page: number) => {
    setLoading(true);
    getCargos({
      page,
      status: activeStatus,
      sortBy: sortBy === "default" ? "priority" : sortBy,
      q: cargoSearch,
      client_id: selectedClient,
    }).then((resp) => setLoading(false));
  };

  useEffect(() => {
    data && onSetCurrectCargosIds(data.response.data.map((c: any) => c.id));
    if (data && activeStatus?.toString() === "0") {
      onChangeTotalCargos(data?.response?.total ?? 0);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleUpdateDate = () => {
    if (!document.hidden) {
      refetch();
      getCargos({
        page: data?.response?.current_page ?? 1,
        status: activeStatus,
        sortBy: sortBy === "default" ? "priority" : sortBy,
        client_id: selectedClient,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", handleUpdateDate);
    return () =>
      window.removeEventListener("visibilitychange", handleUpdateDate);
  }, [data, activeStatus, selectedClient]);

  useEffect(() => {
    if (isUpdateTable) {
      handleChangePage(data?.response?.current_page ?? 1);
      turnOffUpdateTable();
    }
  }, [isUpdateTable]);

  return (
    <StlyedCargosTable>
      {/* <Table
        loading={loading}
        columns={cargoColumns
          .filter((column: ColumnOption) => column.show)
          .map((column: ColumnOption, i: number) => ({
            title: column.title,
            selectCheckbox: i === 0,
            onSelect: () =>
              onSelectAllStatusCargos(
                selectedCargos.length === allCurrentCargosIds.length
              ),
            selectCheckboxValue:
              selectedCargos.length === allCurrentCargosIds.length,
          }))}
      >
        {data &&
          data.response.data.map((row: any, i: number) => (
            <tr
              key={i}
              className={`hover 
                ${row.priority === 1 && "priority"}
                ${row.check_send_customs === 1 && "customReady"}
                ${selectedCargos.includes(row.id) && "selected"}
                `}
            >
              {cargoColumns[0].show && (
                <td className="row-item">
                  <div className="hsCode">
                    <input
                      type="checkbox"
                      onChange={() => onSelectCargo(row.id)}
                      checked={selectedCargos.includes(row.id)}
                    />
                    <div>
                      <div className="bold">{row?.ns}</div>
                      <OverloadStatus
                        startOveloadDate={row?.start_overload_date}
                        endOveloadDate={row?.end_overload_date}
                        classes="overload-header-status"
                      />
                    </div>
                  </div>
                </td>
              )}
              {cargoColumns[1].show && (
                <td className="row-item">
                  <div className="bold">{row?.company_name}</div>
                </td>
              )}
              {cargoColumns[2].show && (
                <td className="row-item">
                  <div className="bold">
                    {categories?.find(
                      (opt: Option) => opt.value === row?.category?.toString()
                    )?.title ?? ""}
                  </div>
                </td>
              )}
              {cargoColumns[3]?.show && (
                <td className="row-item">
                  <div className="bold">{row?.created_at}</div>
                </td>
              )}
              {cargoColumns[4].show && (
                <td className="row-item">
                  <div className="bold">{row?.seal_id}</div>
                </td>
              )}
              {cargoColumns[5].show && (
                <td className="row-item">
                  <div
                    className={`bold ${
                      handleCheckIsStorage(
                        row?.start_storage_date,
                        row?.end_storage_date
                      ) &&
                      Number(
                        handleGetStorageDaysCount(row?.start_storage_date)
                      ) > 7 &&
                      "red"
                    }`}
                  >
                    {handleCheckIsStorage(
                      row?.start_storage_date,
                      row?.end_storage_date
                    )
                      ? handleGetStorageDaysCount(
                          row?.start_storage_date
                        ).toString()
                      : "-"}
                  </div>
                </td>
              )}
              {cargoColumns[6].show && (
                <td className="row-item">
                  <div className="bold">{row?.declarations_number}</div>
                </td>
              )}
              {cargoColumns[7].show && (
                <td className="row-item">
                  <div className="bold">{row?.status_id}</div>
                  {row.priority === 1 && row.check_send_customs === 0 && (
                    <div className="green">{t("cargos.importantCargos")}</div>
                  )}
                  {row.check_send_customs === 1 && (
                    <div className="orange">Можно отправлять на таможню</div>
                  )}
                </td>
              )}
              {cargoColumns[8].show && (
                <td className="row-item">
                  <div className="bold">
                    {row?.product_volume} {t("cargo.kub")}
                  </div>
                </td>
              )}
              {cargoColumns[9].show && (
                <td className="row-item">
                  <div className="bold">
                    {row?.weight} {t("cargo.kg")}
                  </div>
                </td>
              )}
              {cargoColumns[10].show && (
                <td>
                  <div className="actions">
                    <a
                      href={`/#/edit-cargo/${row.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={editIcon} alt="" />
                    </a>
                    <a
                      href={`/#/cargo/${row.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={showIcon} alt="" className="cargoLink" />
                    </a>
                  </div>
                </td>
              )}
            </tr>
          ))}
      </Table> */}
      {data && data.response.data.length === 0 && !loading && <EmptyTable />}
      {/* {data && data.response.data.length > 0 && (
        <Paginator
          currentPage={data?.response?.current_page ?? 1}
          pagNum={data?.response?.last_page ?? 1}
          onPageChange={handleChangePage}
        />
      )} */}
    </StlyedCargosTable>
  );
};

const StlyedCargosTable = styled.div`
  margin-top: 30px;
  overflow: auto;
  height: 46vh;
  .red {
    color: #f53b3b !important;
  }
  table {
    margin-bottom: 50px;
    tr:nth-child(2) td {
      padding-top: 15px;
    }
    th {
      padding: 10px 0 30px;
    }
    .selected {
      background: #5e81f43d !important;
    }
  }
  .loading {
    padding-bottom: 100px;
  }
  .actions {
    display: flex;
    align-items: center;
    img {
      height: 25px;
    }
    .cargoLink {
      height: 20px;
      margin-left: 10px;
    }
  }
  .overload-header-status {
    margin-top: 10px;
    div {
      margin: 0 !important;
    }
  }
  .onRework {
    background: #fd45383b !important;
  }
  .hsCode {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 20px;
  }
  @media (max-width: 1330px) {
    height: 44vh;
  }
  @media (min-width: 1330px) {
    height: 60vh;
  }
`;
