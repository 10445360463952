import { useState } from "react";
import styled from "styled-components";
import { CarsList } from "./CarsList/CarsList";
import { ActiveCar } from "./ActiveCar/ActiveCar";
import { Footer } from "./Footer/Footer";
import { changeArrayItemValueByIndex } from "../../../helpers/modificateData";
import { Documents } from "../../../components/Documents/Documents";

interface Props {
  formData: any;
  onUpdateFormData: (data: any) => void;
  onAddReceiver: () => void;
  onRemoveReceiver: (index: number, id?: number) => void;
  onAddDocument: (data: File[], receiverIndex: number) => void;
  onRemoveDocument: (
    index: number,
    receiverIndex: number,
    receiverId?: number
  ) => void;
  documents: [];
}

export const Cars = ({
  formData,
  onUpdateFormData,
  onAddReceiver,
  onRemoveReceiver,
  onAddDocument,
  onRemoveDocument,
  documents,
}: Props) => {
  const [activeCar, setActiveCar] = useState<number>(0);
  const [documentsModal, setDocumentsModal] = useState<boolean>(false);
  const totalRecipientsValue: number =
    formData?.receivers
      ?.map((recipient: any) => Number(recipient.volume))
      ?.reduce(
        (previousValue: number, currentValue: any) =>
          currentValue + previousValue
      ) ?? 0;

  const handleOpenDocumentsModal = () => setDocumentsModal(true);

  const handleRemoveReceiver = (index: number, id?: number) => {
    onRemoveReceiver(index, id);
    setActiveCar(0);
  };
  return (
    <>
      <StyledCars>
        <Documents
          open={documentsModal}
          onClose={() => setDocumentsModal(false)}
          documents={documents[activeCar] ?? []}
          onRemoveDocument={(index) =>
            onRemoveDocument(
              index,
              activeCar,
              formData?.receivers[activeCar]?.id
            )
          }
          onAddDocuments={(data: File[]) => onAddDocument(data, activeCar)}
        />
        <CarsList
          formData={formData}
          activeCar={activeCar}
          onSelectCar={(index: number) => setActiveCar(index)}
          onRemoveReceiver={handleRemoveReceiver}
        />
        <ActiveCar
          data={formData?.receivers[activeCar] ?? {}}
          onChangeField={(
            field: string,
            value: string | File[] | number | Date
          ) =>
            onUpdateFormData({
              ...formData,
              receivers: changeArrayItemValueByIndex(
                activeCar,
                formData.receivers,
                field,
                value
              ),
            })
          }
          divideStatus={formData.divided_status}
          onChangeDivideStatus={() =>
            onUpdateFormData({
              ...formData,
              divided_status: formData.divided_status === 0 ? 1 : 0,
            })
          }
          documents={documents[activeCar] ?? []}
          onRemoveDocument={(index) =>
            onRemoveDocument(
              index,
              activeCar,
              formData?.receivers[activeCar]?.id
            )
          }
          activeCarIndex={activeCar}
          totalValueKub={formData?.product_volume ?? 0}
          totalCarsValueKub={totalRecipientsValue}
        />
      </StyledCars>
      <Footer
        onAddReceiver={onAddReceiver}
        onOpenDocuments={handleOpenDocumentsModal}
      />
    </>
  );
};

const StyledCars = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  margin-bottom: 22px;
  gap: 20px;
  .cars-card {
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    padding: 10px;
    background: #fff;
    overflow: auto;
    height: calc(100vh - 131px - 30px - 144px - 72px);
  }
  @media (max-width: 1500px) {
    grid-template-columns: 400px 1fr;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    .cars-card {
      height: calc(100vh - 131px - 30px - 144px - 72px - 90px - 22px);
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    .cars-card {
      height: max-content;
    }
  }
`;
