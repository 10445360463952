import styled from "styled-components";
import { Input } from "../../../../../../components/Input/Input";

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export const Counter = ({ value, onChange }: Props) => (
  <StyledCounter>
    <Input
      counterValue={Number(value)}
      onChangeCounterValue={(value: number) => onChange(value)}
      onChange={() => null}
      counter
      noInput
    />
  </StyledCounter>
);

const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 25px;
  div {
    border-bottom: none;
  }
  input {
    width: 50px;
    border-bottom: none;
  }
`;
