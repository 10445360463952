import styled from "styled-components";
import { Header } from "./Header";
import { Total } from "./Total";
import { Row } from "./Row/Row";
import { TotalData } from "../Oplata";

interface Props {
  data: any;
  onChangeField: (index: number, fieldName: string, value: number) => void;
  totalData: TotalData;
  remainderData: number[];
}

export const Table = ({
  data,
  onChangeField,
  totalData,
  remainderData,
}: Props) => (
  <StyledTable className="no-select">
    <Header />
    <Total {...totalData} />
    {data?.map((row: any, i: number) => (
      <Row
        key={i}
        title={row.title}
        price={row.price}
        onChangeField={(fieldName, value) => onChangeField(i, fieldName, value)}
        discount={row?.discount}
        paidValue={row?.paidValue}
        remainder={remainderData[i] ?? 0}
      />
    ))}
  </StyledTable>
);

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  color: #575756;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: auto;
  max-width: 80vw;
  & > div {
    border-left: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
  }
  & > div:nth-child(5n + 1) {
    border-left: none;
  }
`;
