import { useTranslation } from "react-i18next";
import { useActions } from "../../../hooks/actions";
import { useAppSelect } from "../../../hooks/redux";
import { useLazyEditMyColumnsQuery } from "../../../store/admin/admin.api";
import { Checkbox } from "../../Checkbox/Checkbox";
import { useEffect } from "react";
import { COLUMN_OPTIONS, ColumnOption } from "../../../store/cargo/cargo.slice";

export const FilterColumns = () => {
  const { setCargoColumns } = useActions();
  const { cargoColumns } = useAppSelect((state) => state.cargo);
  const { user } = useAppSelect((state) => state.auth);
  const [saveMyColumns] = useLazyEditMyColumnsQuery();
  const { t } = useTranslation("common");

  const handleToggleCheckbox = (optionIndex: number) => {
    const updatedColumns = cargoColumns.map((column: ColumnOption, i: number) =>
      i === optionIndex ? { ...column, show: !column.show } : column
    );
    setCargoColumns(updatedColumns);
    saveMyColumns({
      user_id: user?.id,
      columns: JSON.stringify(updatedColumns),
    });
  };

  useEffect(() => {
    if (user?.columns) {
      const parsedColumns = JSON.parse(
        user?.columns ?? JSON.stringify(COLUMN_OPTIONS)
      );
      setCargoColumns(parsedColumns);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div>
      {cargoColumns.map((column: ColumnOption, i: number) => (
        <div className="opt">
          <Checkbox
            checked={column.show}
            onChange={() => handleToggleCheckbox(i)}
          />
          <div className="label">{t(column.title)}</div>
        </div>
      ))}
    </div>
  );
};
