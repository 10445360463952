import { useState, useEffect } from "react";
import {
  CONFIRM_INIT,
  Confirm,
  ConfirmInterface,
} from "../../../components/Confirm/Confirm";
import { Table } from "../../../components/Table/Table";
import { EmployeeRow } from "./EmployeeRow";
import styled from "styled-components";
import { Paginator } from "../../../components/Paginator/Paginator";
import { TableData } from "../../../models/models";
import { useLazyDeleteUsersQuery } from "../../../store/admin/admin.api";
import cogoToast from "cogo-toast";

interface Props {
  loading: boolean;
  sort: { field: string; desc: boolean };
  onSort: (field: string) => void;
  employees?: any;
  tableData: TableData;
  onChangeTableData: (data: TableData) => void;
  onEdit: (data: any) => void;
  onRefreshData: () => void;
}

const COLUMNS = [
  { title: "settings.nameAndSurname", field: "name" },
  { title: "employees.rolesAndStatus", field: "role_id" },
  { title: "settings.email", field: "email" },
  { title: "settings.phone", field: "phone" },
  { title: "employees.activeNotification", field: "updated_at" },
];

export const EmployeesTable = ({
  loading,
  sort,
  onSort,
  employees,
  tableData,
  onChangeTableData,
  onEdit,
  onRefreshData,
}: Props) => {
  const [confirmData, setComfirmData] =
    useState<ConfirmInterface>(CONFIRM_INIT);
  const [deleteUsers] = useLazyDeleteUsersQuery();
  const [selected, setSelected] = useState<{ title: string; id: number }[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  // CHANGE SELECTED
  const handleRemoveSelected = (cargoId: number) => {
    setSelected(selected.filter((c) => c.id !== cargoId));
  };
  const handleSelect = (title: string, cargoId: number) => {
    const isSelected = !!selected.find((c) => c.id === cargoId);
    isSelected
      ? handleRemoveSelected(cargoId)
      : setSelected([...selected, { title, id: cargoId }]);
  };

  useEffect(() => {
    setIsAllSelected(
      selected.length === employees.length && employees.length > 0
    );
  }, [selected, employees]);

  const handleDeleteUsers = () => {
    deleteUsers(selected.map((e) => e.id)).then((resp) => {
      if (resp.status === "fulfilled") {
        cogoToast.success("Cотрудники успешно удалены", {
          hideAfter: 3,
          position: "top-right",
        });
        setSelected([]);
        setComfirmData(CONFIRM_INIT);
        onRefreshData();
      } else {
        cogoToast.error("Ошибка удаления сотрудников", {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  const handleConfirmDelete = () => {
    if (selected.length > 0) {
      setComfirmData({
        open: true,
        title: "Вы точно хотите удалить cотрудника(ов)",
        onCancel: () => setComfirmData(CONFIRM_INIT),
        onConfirm: () => handleDeleteUsers(),
      });
    }
  };

  return (
    <StyledEmployeesTable>
      <Confirm
        {...confirmData}
        items={selected}
        onRemoveItem={handleSelect}
        closeOnEmptyItems
      />
      <Table
        loading={loading}
        columns={COLUMNS.map((column: { title: string; field: string }) => ({
          title: column.title,
          active: sort.field === column.field,
          down: sort.field === column.field && sort.desc,
          onSort: () => onSort(column.field),
        }))}
        pageSize={tableData.perPage}
        onChangePageSize={(value: number) =>
          onChangeTableData({ ...tableData, perPage: value })
        }
        isAllSelected={isAllSelected}
        onSelectAllCargos={() =>
          setSelected(
            isAllSelected
              ? []
              : employees?.map((b: any) => ({ title: b.name, id: b.id }))
          )
        }
        onRemove={handleConfirmDelete}
        classes="table-wrapper"
      >
        {employees.map((employee: any, i: number) => (
          <EmployeeRow
            key={i}
            onEdit={() => onEdit(employee)}
            onSelect={() => handleSelect(employee.name, employee.id)}
            isSelected={
              !!selected.find(
                (e: { title: string; id: number }) => e.id === employee.id
              )
            }
            name={employee?.name ?? ""}
            avatar={employee?.avatar}
            roleId={employee.role_id}
            email={employee?.email ?? ""}
            phone={employee?.phone ?? ""}
            lastUpdate={employee?.updated_at ?? ""}
          />
        ))}
      </Table>
      <Paginator
        tableData={tableData}
        onChangeTableData={onChangeTableData}
        loading={loading}
        classes="paginator"
      />
    </StyledEmployeesTable>
  );
};

const StyledEmployeesTable = styled.div`
  overflow: auto;
  .table-wrapper {
    overflow: auto;
    height: calc(100vh - 120px - 30px - 80px - 70px);
    width: 100%;
    overflow: auto;
    border-radius: 20px;
    @media (max-width: 1000px) {
      height: 300px;
    }
    th:nth-child(1),
    th:nth-child(2) {
      width: 50px;
    }
  }
  .paginator {
    margin: 20px 0 0 auto;
  }
  @media (max-width: 1000px) {
    .paginator {
      margin: 20px auto 120px;
    }
  }
`;
