import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { DocumentLink } from "../../components/DocumentLink/DocumentLink";
import { handleDownload } from "../../helpers/files";

interface Props {
  act: string;
  cargoId: number | null;
  open: boolean;
  onClose: () => void;
}

export const SuccessEndOverload = ({ act, cargoId, onClose, open }: Props) => {
  const { t } = useTranslation("common");

  const handleDownloadDocument = () => {
    handleDownload(act);
    onClose();
  };

  return (
    <StyledSuccessEndOverload className="overlay" open={open}>
      <div className="modal">
        <div className="document">
          <DocumentLink title={t("teams.printAct")} link={"/#/teams"} />
        </div>
        <div className="modal-footer">
          <Button
            title={t("common.no")}
            onClick={onClose}
            color="blue"
            outline
            size="large"
          />
          <Button
            title={t("common.yes")}
            onClick={handleDownloadDocument}
            color="blue"
            size="large"
          />
        </div>
      </div>
    </StyledSuccessEndOverload>
  );
};

interface StyledSuccessEndOverloadProps {
  open: boolean;
}

const StyledSuccessEndOverload = styled.div<StyledSuccessEndOverloadProps>`
  align-items: center;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  .modal {
    padding: 16px;
    max-width: 440px;
    width: 90%;
    background: #ffffff;
    border-radius: 8px;
    height: max-content;
  }
  .document {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
