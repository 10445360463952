export const changeArrayItemValueByIndex = (
  index: number,
  array: any[],
  field: string,
  value: string | File[] | boolean | number | Date
) => {
  return array.map((item, i) =>
    i === index ? { ...item, [field]: value } : item
  );
};
