import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { authActions } from "../store/auth/auth.slice";
import { cargoActions } from "../store/cargo/cargo.slice";
import { notificationActions } from "../store/notification/notification.slice";
import { teamsActions } from "../store/teams/teams.slice";

const actions = {
  ...authActions,
  ...cargoActions,
  ...notificationActions,
  ...teamsActions,
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
