import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  fullPrice: number;
  currencySign: string;
}

export const Total = ({ fullPrice, currencySign }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledTotal>
      <div className="title">
        {fullPrice}
        {currencySign}
      </div>
      <div className="subtitle">{t("cargoPrice.total")}</div>
    </StyledTotal>
  );
};

const StyledTotal = styled.div`
  font-family: "Archivo", sans-serif;
  text-align: center;
  line-height: 140%;
  margin-bottom: 30px;
  .title {
    font-weight: 700;
    font-size: 32px;
    color: #23262b;
  }
  .subtitle {
    font-weight: 400;
    font-size: 13px;
    color: #09101d;
  }
`;
