import styled from "styled-components";
import { useState, useEffect } from "react";
import { useLazyChangeLanguageQuery } from "../../store/auth/auth.api";
import { useAppSelect } from "../../hooks/redux";
import { useActions } from "../../hooks/actions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const Language = () => {
  const location = useLocation();
  const { i18n } = useTranslation("common");
  const { user } = useAppSelect((state) => state.auth);
  const [activeLanguage, setActiveLanguage] = useState<string>(
    user?.language ?? "ru"
  );
  const [changeLanguage] = useLazyChangeLanguageQuery();
  const { changeActiveLanguage } = useActions();

  const handleChangeLanguage = () => {
    const lang = activeLanguage === "ru" ? "cn" : "ru";

    setActiveLanguage(lang);
    changeLanguage({ user_id: user?.id, lang: lang }).then(() => {
      if (location.pathname === "/cargos-board") {
        window.location.reload();
      }
    });
    // @ts-expect-error
    changeActiveLanguage(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    setActiveLanguage(user?.language ?? "ru");
    i18n.changeLanguage(user?.language ?? "ru");
    // eslint-disable-next-line
  }, [user]);

  return (
    <StyledLanguage onClick={handleChangeLanguage}>
      {activeLanguage}
    </StyledLanguage>
  );
};

const StyledLanguage = styled.button`
  position: relative;
  margin-right: 10px;
  background: #b1b1b1;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
`;
