import ReactCalendar from "react-calendar";
import styled from "styled-components";
import { YearSelect } from "./YearSelect";

interface Props {
  value: Date;
  valueTo: Date;
  onChange: (value: any) => void;
  dateTo?: boolean;
  onChangeYear: (year: number, type: string) => void;
}
export const Calendar = ({
  value,
  valueTo,
  onChange,
  dateTo,
  onChangeYear,
}: Props) => {
  const handleFormatMonth = (locale: string, d: Date): string => {
    const date = new Date(d);
    const month = (1 + date.getMonth()).toString();

    return month;
  };

  return (
    <StyledCalendar>
      <YearSelect
        value={dateTo ? valueTo : value}
        onChangeYear={(year: number) =>
          onChangeYear(year, dateTo ? "to" : "from")
        }
      />
      <ReactCalendar
        onChange={onChange}
        value={[value, valueTo]}
        activeStartDate={dateTo ? valueTo : value}
        selectRange={false}
        maxDetail={"year"}
        formatMonth={handleFormatMonth}
      />
    </StyledCalendar>
  );
};

const StyledCalendar = styled.div`
  .react-calendar__navigation {
    display: none;
  }
  .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, max-content);
    grid-auto-rows: 30px;
    gap: 3px 0;
  }
  .react-calendar__year-view__months__month {
    color: #575756;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 700;
    background: #f5f5f5;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    margin-right: 3px;
    overflow: initial !important;
  }
  .react-calendar__tile--range {
    border-radius: 0px;
    color: #b1b1b1;
    background: #b1b1b1;
    margin-right: 0px !important;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: -4px;
      width: 5px;
      height: 30px;
      background: #b1b1b1;
    }
  }

  .react-calendar__tile--rangeStart {
    border-radius: 5px 0 0px 5px;
    background: #b1b1b1;
    color: #575756;
    margin-right: -3px !important;
  }
  .react-calendar__tile--rangeEnd {
    border-radius: 0 5px 5px 0;
    background: #b1b1b1;
    color: #575756;
    &:before {
      display: none;
    }
  }
  .react-calendar__tile--rangeBothEnds {
    border-radius: 5px !important;
    &:before {
      display: none;
    }
  }
  .react-calendar__year-view__months__month:nth-child(
      3n
    ).react-calendar__tile--range {
    border-radius: 0px 5px 5px 0;
    &:before {
      display: none;
    }
  }
  .react-calendar__year-view__months__month:nth-child(
      3n
    ).react-calendar__tile--rangeStart {
    border-radius: 5px 5px;
  }
  .react-calendar__year-view__months__month:nth-child(
      3n + 1
    ).react-calendar__tile--range {
    border-radius: 5px 0 0 5px;
  }
  .react-calendar__year-view__months__month:nth-child(
      3n + 1
    ).react-calendar__tile--rangeEnd {
    border-radius: 5px;
    &:before {
      display: none;
    }
  }
  .react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
    border-radius: 8px;
    background: #b1b1b1;
  }
  .react-calendar__month-view button {
    width: 53px !important;
  }
`;
