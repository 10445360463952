import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import searchIcon from "../../assets/images/icons/search.svg";
import { useActions } from "../../hooks/actions";
import { useAppSelect } from "../../hooks/redux";
import { Input } from "../Input/Input";

interface Props {
  classes?: string;
}

export const Search = ({ classes }: Props) => {
  const navigate = useNavigate();
  const { cargoSearch } = useAppSelect((state) => state.cargo);
  const { setCargoSearch } = useActions();
  const { t } = useTranslation("common");

  const handleChangeSearch = (value: string) => {
    navigate("/cargos");
    setCargoSearch(value);
  };

  return (
    <StyledSearch className={classes}>
      <Input
        value={cargoSearch}
        onChange={(value) => handleChangeSearch(value)}
        placeholder={t("header.search")}
        classes="input"
        noClear
      />
      <img src={searchIcon} alt="" className="search-icon" />
    </StyledSearch>
  );
};

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  max-width: 240px;
  position: relative;
  .input {
    .input-wrapper {
      width: 95%;
      width: 240px;
    }
    input {
      padding-right: 30px;
      font-size: 12px;
      line-height: 14px;
    }
  }
  input::placeholder {
    color: #868686;
  }
  .search-icon {
    position: absolute;
    right: 10px;
    bottom: 9px;
  }
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;
