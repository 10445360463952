import styled from "styled-components";

interface Props {
  value: string;
  onChange: (value: string) => void;
  options: { icon: any; value: string }[];
}

export const Switch = ({ value, onChange, options }: Props) => (
  <StyledSwitch>
    {options?.map((opt, i) => (
      <div
        key={i}
        className={`${value === opt.value && "active"}`}
        onClick={() => onChange(opt.value)}
      >
        {opt.icon}
      </div>
    ))}
  </StyledSwitch>
);

const StyledSwitch = styled.div`
  width: 110px;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  align-items: center;
  background: #ececec;
  border-radius: 10px;
  padding: 2px;
  flex-shrink: 0;
  div {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
  }
  .active {
    background: #b1b1b1;
    path {
      fill: #ececec;
    }
  }
`;
