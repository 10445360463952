// @ts-expect-error
import Board from "react-trello";
import { Card } from "./Card/Card";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLazyChangeCargosPositionQuery,
  useLazyChangeCargoStatusQuery,
  useLazyGetKanbanCargosQuery,
} from "../../../store/canban/canban.api";
import cogoToast from "cogo-toast";
import { Loading } from "./Loading";
import { formatKanbanData, handleUpdateDataAfterDraging } from "./boardHelpers";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useAppSelect } from "../../../hooks/redux";
import { Header } from "./Header";

interface Props {
  sidebarOpen: boolean;
  selectedCargos: { title: string; id: number }[];
  onSelectCargo: (title: string, cargoId: number) => void;
  selectedClient: undefined | string;
}

export const CargosCanban = ({
  sidebarOpen,
  selectedCargos,
  onSelectCargo,
  selectedClient,
}: Props) => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const { user } = useAppSelect((state) => state.auth);
  const [changeCargoStatus] = useLazyChangeCargoStatusQuery();
  const [changeCargosPosition] = useLazyChangeCargosPositionQuery();
  const [canbanCargos, setCanbanCargos] = useState<any>([]);
  const [getCanbanCargos] = useLazyGetKanbanCargosQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const isFirstLoad = useRef(true);
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });

  const handleGetCanbanCargos = (isLoading?: boolean) => {
    setLoading(!isLoading);
    getCanbanCargos({
      client_id: selectedClient,
      status: sort?.field?.length > 0 ? sort?.field : undefined,
      sort: sort?.desc ? 1 : 0,
    }).then((resp) => {
      setLoading(false);
      setCanbanCargos(resp?.data?.response);
    });
  };

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
  };

  useEffect(() => {
    handleGetCanbanCargos();
  }, []);

  useEffect(() => {
    handleGetCanbanCargos(true);
  }, [selectedClient, sort]);

  function handleEndOverload(laneId: string, cardId: number, lastData: any) {
    setData(
      lastData.map((column: any) =>
        column.id === laneId
          ? {
              ...column,
              cards: column.cards.map((c: any) =>
                c.id === cardId
                  ? { ...c, start_overload_date: null, draggable: true }
                  : c
              ),
            }
          : column
      )
    );
  }

  function handleEndStorage(laneId: string, cardId: number, lastData: any) {
    setData(
      lastData.map((column: any) =>
        column.id === laneId
          ? {
              ...column,
              cards: column.cards.map((c: any) =>
                c.id === cardId
                  ? { ...c, start_storage_date: null, draggable: true }
                  : c
              ),
            }
          : column
      )
    );
  }

  const handleErrorDragCargo = (lastData: any) => {
    setLoading(false);
    cogoToast.error("Ошибка перемещения груза", {
      hideAfter: 3,
      position: "top-right",
    });
    setData(lastData.map((column: any) => column));
  };

  const handleSaveCargosPosition = (columnId: string, updateData: any) => {
    if (search.length === 0) {
      const selectedColumn = updateData.find(
        (column: any) => column.id === columnId
      );
      const cargos = selectedColumn.cards.map((card: any, i: number) => ({
        id: card.id,
        position: i,
      }));
      changeCargosPosition({ status: columnId, cargos })
        .then((resp) => {
          if (resp.status === "fulfilled") {
            cogoToast.success("Груз успешно перемещен", {
              hideAfter: 3,
              position: "top-right",
            });
            handleGetCanbanCargos(true);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(() => handleErrorDragCargo(updateData));
    } else {
      cogoToast.success("Груз успешно перемещен", {
        hideAfter: 3,
        position: "top-right",
      });
      handleGetCanbanCargos(true);
    }
    setLoading(false);
  };

  const handleUpdateData = (
    lastData: any,
    fromColumn: any,
    toColumn: any,
    id: number,
    index: number,
    card: any
  ) => {
    const updateData = handleUpdateDataAfterDraging(
      lastData,
      fromColumn,
      toColumn,
      id,
      index,
      card
    );
    handleSaveCargosPosition(toColumn, updateData);
  };

  const handleDragEnd = (
    id: number,
    fromColumn: string,
    toColumn: string,
    index: number,
    card: any
  ) => {
    let lastData: any = data;
    if (card.position === index && toColumn === fromColumn) {
    } else if (card.draggable) {
      setLoading(true);
      changeCargoStatus({ cargo_id: id, status: toColumn, position: index })
        .then((resp) => {
          if (resp.status === "fulfilled") {
            handleUpdateData(lastData, fromColumn, toColumn, id, index, card);
          } else {
            setLoading(false);
            handleErrorDragCargo(lastData);
          }
        })
        .catch(() => handleErrorDragCargo(lastData));
    } else {
      setData(lastData.map((column: any) => column));
    }
  };

  const handleFormatCanbanCargosData = (cargosData: any) =>
    setData(formatKanbanData(cargosData, user, t));

  useEffect(() => {
    if (canbanCargos && user && isFirstLoad.current) {
      isFirstLoad.current = false;
      handleFormatCanbanCargosData(canbanCargos);
    }
  }, [canbanCargos, user]);

  useEffect(() => {
    if (canbanCargos && user) {
      isFirstLoad.current = false;
      handleFormatCanbanCargosData(canbanCargos);
    }
  }, [canbanCargos]);

  useEffect(() => {
    if (selectedCargos.length === 0) {
      handleGetCanbanCargos(true);
    }
  }, [selectedCargos]);

  return (
    <StyledCargosBoard sidebarOpen={sidebarOpen}>
      <div className="board-wrapper">
        <Loading loading={loading} />
        <Board
          data={{
            lanes: data.map((column: any) => ({
              ...column,
              cards: column.cards.filter((c: any) =>
                search
                  ? c?.hs?.toLowerCase().includes(search?.toLowerCase())
                  : true
              ),
            })),
          }}
          handleDragEnd={handleDragEnd}
          components={{
            Card: (props: any) => (
              <Card
                {...props}
                onEndOverload={handleEndOverload}
                onEndStorage={handleEndStorage}
                data={data}
                onSelect={onSelectCargo}
                selectedCargos={selectedCargos}
                onRefreshData={handleGetCanbanCargos}
              />
            ),
            LaneHeader: (props: any) => (
              <Header {...props} onSort={handleSort} sort={sort} />
            ),
          }}
        />
      </div>
    </StyledCargosBoard>
  );
};

interface StyledCargosBoardProps {
  sidebarOpen: boolean;
}

const StyledCargosBoard = styled.div<StyledCargosBoardProps>`
  .react-trello-board {
    background: none;
  }
  .board-wrapper {
    position: relative;
    min-height: 50vh;
  }
  .smooth-dnd-container.horizontal {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    gap: 20px;
  }
  .smooth-dnd-container {
    section {
      min-width: 250px;
      max-width: 335px;
      width: calc(
        (
            100vw - 60px - ${(props) => (props.sidebarOpen ? 227 : 110)}px -
              30px - 60px
          ) / 4
      );
      border-radius: 20px;
      padding: 2px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      margin: 0 !important;
      & > div {
        width: 100%;
      }
      .smooth-dnd-container {
        width: 100%;
        padding: 0 8px;
        height: calc(100vh - 120px - 30px - 70px - 70px - 100px);
        width: 100%;
        overflow: auto;
        border-radius: 20px;
        @media (max-width: 1200px) {
          height: calc(100vh - 120px - 30px - 70px - 70px - 72px - 72px);
        }
        @media (max-width: 1000px) {
          height: 400px;
        }
      }
    }
  }
  .react-trello-board {
    height: max-content;
  }
`;
