import { useEffect, useState } from "react";
import { Header } from "./Header";
import { useLazyGetUsersQuery } from "../../store/admin/admin.api";
import { EmployeesTable } from "./EmployeesTable/EmployeesTable";
import { TableData } from "../../models/models";
import { EmployeeModal } from "./EmployeeModal";

export const Employees = () => {
  const [getUsers, { data }] = useLazyGetUsersQuery();
  const [selectedEmployee, setSelectedEmployee] = useState<undefined | string>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableData>({
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });
  const [sort, setSort] = useState<{ field: string; desc: boolean }>({
    field: "",
    desc: false,
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editEmployee, setEditEmployee] = useState<any>(null);

  const handleEditEmployee = (data: any) => {
    setOpenModal(true);
    setEditEmployee(data);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setEditEmployee(null);
  };

  const handleGetEmployees = (tableData: TableData) => {
    setLoading(true);
    getUsers({
      page: tableData.currentPage,
      sortBy: sort.field,
      sortDesc: sort.desc,
      perPage: tableData.perPage,
    }).then((resp: any) => {
      setLoading(false);
      setTableData({
        ...tableData,
        lastPage: resp?.data.response.last_page,
        total: resp?.data.response.total,
      });
    });
  };

  useEffect(() => {
    handleGetEmployees(tableData);
  }, []);

  const handleChangeTableData = (data: TableData) => {
    setTableData(data);
    handleGetEmployees(data);
  };

  const handleSort = (title: string) => {
    const updatedDesc = title === sort.field ? !sort.desc : false;
    setSort({ field: title, desc: updatedDesc });
    handleChangeTableData({ ...tableData, currentPage: 1 });
  };

  const handleSelectEmployee = (value: undefined | string) =>
    setSelectedEmployee(value);

  return (
    <div>
      <EmployeeModal
        open={openModal}
        onClose={handleCloseModal}
        editEmployee={editEmployee}
        onRefreshData={() => handleGetEmployees(tableData)}
      />
      <Header
        selectedEmployee={selectedEmployee}
        onSelectEmployee={handleSelectEmployee}
        employees={
          data?.response?.data
            ? data?.response?.data?.map((e: any) => ({
                title: e.name,
                value: e.id.toString(),
              }))
            : []
        }
        onInvite={() => setOpenModal(true)}
      />
      <EmployeesTable
        loading={loading}
        sort={sort}
        onSort={handleSort}
        employees={
          data?.response?.data
            ? data?.response?.data.filter((e: any) =>
                selectedEmployee ? e.id.toString() === selectedEmployee : true
              )
            : []
        }
        tableData={tableData}
        onChangeTableData={handleChangeTableData}
        onEdit={handleEditEmployee}
        onRefreshData={() => handleGetEmployees(tableData)}
      />
    </div>
  );
};
