import { useState } from "react";
import styled from "styled-components";
import { Calendar } from "./Calendar/Calendar";
import { Preloader } from "../Preloader/Preloader";

interface Props {
  classes?: string;
  valueFrom: Date;
  onChangeValueFrom: (date: Date) => void;
  valueTo: Date;
  onChangeValueTo: (date: Date) => void;
}

export const DatePicker = ({
  classes,
  valueFrom,
  onChangeValueFrom,
  valueTo,
  onChangeValueTo,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeYear = (year: number, type: string) => {
    setLoading(true);
    if (type === "from") {
      const currentDate = valueFrom;
      currentDate.setFullYear(year);
      onChangeValueFrom(currentDate);
    } else {
      const currentDate = valueTo;
      currentDate.setFullYear(year);
      onChangeValueTo(currentDate);
    }
    setTimeout(() => setLoading(false), 200);
  };

  return (
    <StyledDatePicker className={`${classes}`}>
      {loading ? (
        <Preloader classes="loader" />
      ) : (
        <div className="calendars no-select">
          <Calendar
            value={valueFrom}
            valueTo={valueTo}
            onChange={onChangeValueFrom}
            onChangeYear={handleChangeYear}
          />
          <Calendar
            value={valueFrom}
            valueTo={valueTo}
            onChange={onChangeValueTo}
            onChangeYear={handleChangeYear}
            dateTo
          />
        </div>
      )}
    </StyledDatePicker>
  );
};

const StyledDatePicker = styled.div`
  .calendars {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 22px;
  }
  .loader {
    img {
      height: 40px;
      margin-top: 40px;
    }
  }
`;
