import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useAppSelect } from "../../../hooks/redux";
import { useGetUsersRolesQuery } from "../../../store/admin/admin.api";
import { Avatar } from "../../Avatar/Avatar";
import { Dropdown } from "./Dropdown";
import { EditCargoPrice } from "./EditCargoPrice/EditCargoPrice";

interface Props {
  onEditProfile: () => void;
}

export const Profile = ({ onEditProfile }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openEditCargoPrice, setOpenEditCargoPrice] = useState<boolean>(false);
  const { user } = useAppSelect((state) => state.auth);
  const { data: usersRoles } = useGetUsersRolesQuery(null);
  const userRole = usersRoles?.find(
    (role: any) => Number(role.value) === user?.role_id
  )?.title;

  const handleToggleEditCargoPrice = (value: boolean) =>
    setOpenEditCargoPrice(value);

  // HANDLE CLOSE DROPDOWN
  const handleCheckIsClickOutside = (e: any) => {
    const isProfileDropdown =
      e?.target?.offsetParent?.classList?.contains("header-dropdown");
    const isOption = e?.target?.classList?.contains("header-dropdown-dropdown");
    setOpen(isOption ? false : isProfileDropdown ? !open : false);
  };

  useEffect(() => {
    window.addEventListener("click", handleCheckIsClickOutside);
    return () => window.removeEventListener("click", handleCheckIsClickOutside);
    // eslint-disable-next-line
  }, []);

  const handleEditProfile = () => {
    onEditProfile();
  };

  return (
    <>
      <StyledProfile
        onClick={() => setOpen(!open)}
        className="header-dropdown"
        open={open}
      >
        <Avatar
          classses="header-dropdown"
          userAvatar={user?.avatar}
          userName={user?.name ?? ""}
        />
        <div className="profile-info">
          <div className="name">{user?.name ?? ""}</div>
          <div className="role">{userRole ?? ""}</div>
        </div>
        <Dropdown
          open={open}
          onEditProfile={handleEditProfile}
          onEditCargoPrice={() => handleToggleEditCargoPrice(true)}
        />
      </StyledProfile>

      <EditCargoPrice
        open={openEditCargoPrice}
        onClose={() => handleToggleEditCargoPrice(false)}
      />
    </>
  );
};

interface StyledProfileProps {
  open: boolean;
}

const StyledProfile = styled.div<StyledProfileProps>`
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
  position: relative;
  padding: 10px 30px 10px 10px;
  border: 1px solid #f5f5f5;
  background: #fff;
  border-radius: ${(props) => (props.open ? "10px 10px 0 0" : "10px")};
  width: 220px;
  .profile-info {
    margin-left: 10px;
    width: 80%;
  }
  .name {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #575756;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .role {
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    color: #575756;
    text-transform: lowercase;
  }
  @media (max-width: 600px) {
    .name,
    .role {
      display: none;
    }
    width: 40px;
    border: none;
  }
`;
