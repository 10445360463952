import { useTranslation } from "react-i18next";
import styled from "styled-components";
import checkIcon from "../../assets/images/icons/Check.svg";

interface Props {
  checked: boolean;
  label: string;
  onClick: () => void;
}

export const Checkbox = ({ checked, label, onClick }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledCheckbox checked={checked} onClick={onClick}>
      <div>
        <img src={checkIcon} alt="" />
      </div>
      <span>{t(label)}</span>
    </StyledCheckbox>
  );
};

interface StyledCheckboxProps {
  checked: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 116%;
  color: #1c1c1e;
  cursor: pointer;
  margin-bottom: 16px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 2px solid #d1d1d6;
    border-radius: 4px;
    margin-right: 16px;
    flex-shrink: 0;
    img {
      display: none;
    }
    ${(props) =>
      props.checked &&
      `
      background: #007AFF;
      border: none;
      img {
          display: block;
      }
    `}
  }
`;
