import { InfoItem } from "./InfoItem";

interface Props {
  data: { title: string; value: string | number; bold?: boolean }[];
}

export const InfoList = ({ data }: Props) => (
  <div>
    {data.length > 0 &&
      data.map((item, i) => (
        <InfoItem
          key={i}
          title={item?.title ?? "-"}
          value={item?.value?.toString() ?? "-"}
          bold={item?.bold}
        />
      ))}
  </div>
);
