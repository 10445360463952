import styled from "styled-components";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { BackButton } from "../../../components/BackButton/BackButton";
import { SendButton } from "./SendButton";

interface Props {
  cargoData: any;
  onRefreshCargoData: () => void;
}

export const Header = ({ cargoData, onRefreshCargoData }: Props) => (
  <StyledHeader>
    <PageHeader title="Спецификация груза" />
    <div className="bts">
      <BackButton />
      <SendButton
        cargoData={cargoData?.response}
        onRefreshCargoData={onRefreshCargoData}
      />
    </div>
  </StyledHeader>
);

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  .bts {
    display: flex;
    align-items: center;
  }
  @media (max-width: 800px) {
    .bts {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;
