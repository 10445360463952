import styled from "styled-components";
import { ResetButton } from "./ResetButton";
import { Title } from "./Title";

interface Props {
  onReset: () => void;
}

export const Header = ({ onReset }: Props) => (
  <StyledHeader>
    <Title />
    <ResetButton onReset={onReset} />
  </StyledHeader>
);

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
