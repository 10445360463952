import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  onReset: () => void;
}

export const ResetButton = ({ onReset }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledResetButton onClick={onReset}>{t("cargos.reset")}</StyledResetButton>
  );
};

const StyledResetButton = styled.div`
  padding: 8px;
  border: 2px solid #e5e5ea;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1c1c1e;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background: #747b84;
    color: #ffff;
    border: 2px solid #747b84;
  }
`;
