import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";

interface Props {
  onOpenDocuments: () => void;
  onSendMessage: () => void;
  sendDisabled: boolean;
  isEdit?: boolean;
}

export const Footer = ({
  onOpenDocuments,
  onSendMessage,
  sendDisabled,
  isEdit,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledFooter>
      <Button
        title="Документы"
        onClick={onOpenDocuments}
        classes="footer-btn"
        color="blue"
        disabled={isEdit}
      />
      <Button
        title={t(isEdit ? "common.save" : "cargo.send")}
        onClick={onSendMessage}
        classes="footer-btn"
        color="blue"
        disabled={sendDisabled}
      />
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 2px;
  width: max-content;
  border-radius: 10px;
  .footer-btn {
    height: 46px;
    padding: 13px 0 18px;
    width: 164px;
    font-size: 12px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    &:first-child {
      margin-right: 2px;
    }
  }
`;
