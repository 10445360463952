import styled from "styled-components";
import { CarCard } from "./CarCard";

interface Props {
  formData: any;
  activeCar: number;
  onSelectCar: (index: number) => void;
  onRemoveReceiver: (index: number, id?: number) => void;
}

export const CarsList = ({
  formData,
  activeCar,
  onSelectCar,
  onRemoveReceiver,
}: Props) => (
  <StyledCardsList className="cars-card">
    {formData?.receivers?.length
      ? formData?.receivers?.map((car: any, i: number) => (
          <CarCard
            key={i}
            active={activeCar === i && formData?.receivers?.length > 1}
            onSelect={() => onSelectCar(i)}
            index={1 + i}
            company={car.company}
            iin={car.iin}
            carNumber={car.car_number}
            technicalPassportNumber={car.technical_passport_number}
            dateDeparture={car.date_departure}
            volume={car.volume}
            onRemove={
              formData?.receivers?.length === 1
                ? undefined
                : () => onRemoveReceiver(i, car?.id)
            }
          />
        ))
      : null}
  </StyledCardsList>
);

const StyledCardsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 10px;
  @media (max-width: 1200px) {
    height: max-content !important;
    display: flex;
    overflow: auto;
  }
`;
