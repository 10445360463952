import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  id: number | null;
  role_id: number | null;
  name: string | null;
  phone: string | null;
  email: string | null;
  email_verified_at?: any;
  created_at: Date | null;
  updated_at: Date | null;
  telegram: string | null;
  columns: string | null;
  cargos_on_agreement?: number | null;
  notifications: number;
  language: any;
  avatar: string | null;
  alert_status?: number;
  cargoes_inspection?: number;
  cargoes_new_comments?: number;
}

interface Auth {
  user: null | User;
}

const initialState: Auth = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    changeNotificationCount(state, action: PayloadAction<number>) {
      if (state.user) {
        state.user = { ...state.user, notifications: action.payload };
      }
    },
    reduceNotificationCount(state) {
      if (state.user) {
        state.user = {
          ...state.user,
          notifications: state.user.notifications - 1,
        };
      }
    },
    changeActiveLanguage(state, action: any) {
      if (state.user) {
        state.user = { ...state.user, language: action.payload };
      }
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
