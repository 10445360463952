import styled from "styled-components";

interface Props {
  checked: boolean;
  onClick: () => void;
  anable: boolean;
  small?: boolean;
  approveColor: string;
}

export const CheckButton = ({
  checked,
  onClick,
  anable,
  small,
  approveColor,
}: Props) => (
  <StyledCheckButton
    small={small}
    checked={checked}
    onClick={() => (anable ? onClick() : null)}
    anable={anable}
    approveColor={approveColor}
  />
);

interface StyledIconProps {
  small?: boolean;
  checked: boolean;
  anable: boolean;
  approveColor: string;
}

const StyledCheckButton = styled.div<StyledIconProps>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.small ? 10 : 33)}px;
  ${(props) => props.small && "width: 50px;"}
  background: ${(props) => (props.checked ? props.approveColor : "#ECECEC")};
  border-radius: ${(props) => (props.small ? 2 : 6)}px;
  cursor: ${(props) => (props.anable ? "pointer" : "no-drop")};
  margin-left: ${(props) => (props.small ? 10 : 10)}px;
`;
