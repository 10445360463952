import styled from "styled-components";
import { Input } from "../../../../../components/Input/Input";
import closeIcon from "../../../../../assets/images/icons/close.svg";

interface Props {
  placeholder: string;
  value: string[];
  onChange: (value: string[]) => void;
}

export const Inputs = ({ placeholder, value, onChange }: Props) => {
  const handleChangeValue = (newValue: string, index: number): void => {
    const updatedValue: string[] = value.map((v: string, i: number) =>
      i === index ? newValue : v
    );
    onChange(updatedValue);
  };

  const handleRemoveInput = (inputIndex: number) => {
    const updatedValue: string[] = value.filter(
      (v: string, i: number) => i !== inputIndex
    );
    onChange(updatedValue);
  };

  return (
    <StyledInputs>
      {value.map((v: string, index: number) => (
        <div className="item">
          <Input
            key={index}
            value={v}
            onChange={(newValue) => handleChangeValue(newValue, index)}
            placeholder={placeholder}
            noClear
            classes="input"
          />
          {value.length > 1 && (
            <img
              src={closeIcon}
              alt=""
              className="close-icon"
              onClick={() => handleRemoveInput(index)}
            />
          )}
        </div>
      ))}
    </StyledInputs>
  );
};

const StyledInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;
  max-height: 13vh;
  overflow: auto;
  .item {
    display: flex;
    align-items: center;
    width: 100%;
    .input {
      width: 100%;
    }
    .close-icon {
      cursor: pointer;
      height: 14px;
    }
  }
`;
