import { useTranslation } from "react-i18next";
import styled from "styled-components";
import plusIcon from "../../assets/images/icons/plus-blue.svg";

interface Props {
  onClick: () => void;
}

export const ShowMore = ({ onClick }: Props) => {
  const { t } = useTranslation("common");

  return (
    <StyledShowMore onClick={onClick}>
      <img src={plusIcon} alt="" />
      {t("cargos.showAll")}
    </StyledShowMore>
  );
};

const StyledShowMore = styled.div`
  display: flex;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #007aff;
  transition: all 0.3s ease;
  cursor: pointer;
  img {
    margin-right: 16px;
  }
  &:hover {
    opacity: 0.5;
  }
`;
