import { RadioButton } from "../../RadioButton/RadioButton";

const PAGE_SIZES = [25, 50, 100];

interface Props {
  pageSize: number;
  onChangePageSize: (value: number) => void;
}

export const PageSize = ({ pageSize, onChangePageSize }: Props) => (
  <>
    {PAGE_SIZES.map((size: number, i: number) => (
      <div className="opt" key={i}>
        <RadioButton
          checked={pageSize === size}
          onChange={() => onChangePageSize(size)}
        />
        <div className="label">Показывать по {size}</div>
      </div>
    ))}
  </>
);
