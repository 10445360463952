import cogoToast from "cogo-toast";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { useAppSelect } from "../../hooks/redux";
import {
  useLazyCreateCompanyQuery,
  useLazyEditCompanyQuery,
} from "../../store/companies/companies.api";
import { Modal } from "../../components/Modal/Modal";
import { emailValidation } from "../../helpers/validation";

interface Props {
  open: boolean;
  editingCompany?: any;
  onClose: () => void;
  onRefreshData: () => void;
}

export const CompanyModal = ({
  open,
  editingCompany,
  onClose,
  onRefreshData,
}: Props) => {
  const { user } = useAppSelect((state) => state.auth);
  const [title, setTitle] = useState<string>("");
  const [cod, setCod] = useState<string>("");
  const [contractNumber, setContractNumber] = useState<string>("");
  const [responsible, setResponsible] = useState<string>("");
  const [binCode, setBinCode] = useState<string>("");
  const [innCode, setInnCode] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [deliveryCost, setDeliveryCost] = useState<string>("");
  const [declarationCost, setDeclarationCost] = useState<string>("");
  const [storageCost, setStorageCost] = useState<string>("");
  const [overloadCost, setOverloadCost] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [createCompany] = useLazyCreateCompanyQuery();
  const [editCompany] = useLazyEditCompanyQuery();
  const { t } = useTranslation("common");
  const [errors, setErrors] = useState<{ email: null | string }>({
    email: null,
  });

  const handleChangeEmail = (value: string) => {
    setEmail(value);
    setErrors({
      email: emailValidation(value) ? "Введите корректный email" : null,
    });
  };

  const handleResetValues = () => {
    setTitle("");
    setCod("");
    setContractNumber("");
    setEmail("");
    setPassword("");
    setDeliveryCost("");
    setDeclarationCost("");
    setStorageCost("");
    setOverloadCost("");
    setResponsible("");
    setBinCode("");
    setInnCode("");
    setComment("");
    setErrors({ email: null });
  };

  const handleCloseModal = () => {
    handleResetValues();
    onClose();
  };

  const handleCreateCompany = () => {
    setLoading(true);
    createCompany({
      title,
      cod,
      contract_number: contractNumber,
      email,
      password,
      cost_delivery: deliveryCost,
      cost_declaration: declarationCost,
      cost_storage: storageCost,
      cost_overload: overloadCost,
      responsible,
      bin: binCode,
      inn: innCode,
      comment,
    }).then((resp: any) => {
      setLoading(false);
      if (resp.status === "fulfilled") {
        handleCloseModal();
        cogoToast.success(t("companies.companyCreated"), {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
      } else {
        const errors = resp?.error?.data?.errors
          ? Object.entries(resp?.error?.data?.errors)
              .map((error) => error[1])
              .join(", \n")
          : "";

        cogoToast.error(errors, {
          hideAfter: 3,
          position: "top-right",
          heading: t("companies.companyCreatedError"),
        });
      }
    });
  };

  useEffect(() => {
    if (editingCompany) {
      setTitle(editingCompany?.title ?? "");
      setCod(editingCompany?.cod ?? "");
      setContractNumber(editingCompany?.contract_number ?? "");
      setEmail(editingCompany?.email ?? "");
      setPassword(editingCompany?.password ?? "");
      setResponsible(editingCompany.responsible ?? "");
      setBinCode(editingCompany?.bin ?? "");
      setInnCode(editingCompany?.inn ?? "");
      setComment(editingCompany?.comment ?? "");
      setDeliveryCost(editingCompany?.costs?.cost_delivery?.toString() ?? "");
      setDeclarationCost(
        editingCompany?.costs?.cost_declaration?.toString() ?? ""
      );
      setStorageCost(editingCompany?.costs?.cost_storage?.toString() ?? "");
      setOverloadCost(editingCompany?.costs?.cost_overload?.toString() ?? "");
    }
  }, [editingCompany]);

  const handleEditCompany = () => {
    setLoading(true);
    editCompany({
      client_id: editingCompany?.id,
      title,
      cod,
      contract_number: contractNumber,
      email,
      cost_delivery: deliveryCost,
      cost_declaration: declarationCost,
      cost_storage: storageCost,
      cost_overload: overloadCost,
      responsible,
      bin: binCode,
      inn: innCode,
      comment,
    }).then((resp) => {
      setLoading(false);
      if (resp.status === "fulfilled") {
        handleCloseModal();
        cogoToast.success(t("companies.companySaved"), {
          hideAfter: 3,
          position: "top-right",
        });
        onRefreshData();
      } else {
        cogoToast.error(t("companies.companySaveError"), {
          hideAfter: 3,
          position: "top-right",
        });
      }
    });
  };

  return (
    <Modal
      title={!!editingCompany ? "Редактирование клиента" : "Добавление клиента"}
      open={open}
      onCancel={handleCloseModal}
      onSubmit={() =>
        !!editingCompany ? handleEditCompany() : handleCreateCompany()
      }
      cancelBtnText="Отмена"
      submitBtnText={t(
        !!editingCompany ? "Cохранить изменения" : "companies.creatingCompany"
      )}
      disabledSubmit={
        loading ||
        !!errors.email ||
        (!editingCompany && email.length === 0) ||
        (!editingCompany && password.length === 0) ||
        title.length === 0 ||
        cod.length === 0 ||
        deliveryCost.length === 0 ||
        declarationCost.length === 0 ||
        storageCost.length === 0 ||
        overloadCost.length === 0
      }
    >
      <StyledCompanyModal>
        <Input
          placeholder={t("companies.name")}
          value={title}
          onChange={(value: string) => setTitle(value)}
          classes="input"
        />
        <Input
          placeholder={t("companies.code")}
          value={cod}
          onChange={(value: string) => setCod(value)}
          classes="input"
          disabled={!!editingCompany}
        />
        <Input
          placeholder={t("companies.contractNumber")}
          value={contractNumber}
          onChange={(value: string) => setContractNumber(value)}
          classes="input"
        />
        <Input
          placeholder={"Ответственный"}
          value={responsible}
          onChange={(value: string) => setResponsible(value)}
          classes="input"
        />
        <div className="input-group">
          <Input
            placeholder={"BIN"}
            value={binCode}
            onChange={(value: string) => setBinCode(value)}
            classes="input"
          />
          <Input
            placeholder={"INN"}
            value={innCode}
            onChange={(value: string) => setInnCode(value)}
            classes="input"
          />
        </div>
        <Input
          placeholder={t("cargo.comment")}
          value={comment}
          onChange={(value: string) => setComment(value)}
          classes="input"
        />
        <Input
          placeholder={t("settings.email")}
          value={email}
          onChange={handleChangeEmail}
          classes="input"
          disabled={!!editingCompany}
          error={!!errors.email}
          errorMessage={errors.email ?? null}
        />
        {!editingCompany && (
          <Input
            placeholder={t("auth.password")}
            value={password}
            onChange={(value: string) => setPassword(value)}
            classes="input"
            password
          />
        )}
        {user?.role_id === 1 && (
          <>
            <div className="input-group">
              <Input
                placeholder={t("cargo.deliveryCost")}
                value={deliveryCost}
                onChange={(value: string) => setDeliveryCost(value)}
                classes="input"
                number
              />
              <Input
                placeholder={t("cargo.declarationCost")}
                value={declarationCost}
                onChange={(value: string) => setDeclarationCost(value)}
                classes="input"
                number
              />
            </div>
            <div className="input-group">
              <Input
                placeholder={t("cargo.storageCost")}
                value={storageCost}
                onChange={(value: string) => setStorageCost(value)}
                classes="input"
                number
              />
              <Input
                placeholder={t("cargo.overloadCost")}
                value={overloadCost}
                onChange={(value: string) => setOverloadCost(value)}
                classes="input"
                number
              />
            </div>
          </>
        )}
      </StyledCompanyModal>
    </Modal>
  );
};

const StyledCompanyModal = styled.div`
  .input {
    margin-top: 10px;
  }
  .input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
`;
